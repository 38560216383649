﻿:root, [data-bs-theme="light"] {
  --mdb-red: #e3041c;
  --mdb-pink: #e91e63;
  --mdb-purple: #9c27b0;
  --mdb-indigo: #3f51b5;
  --mdb-blue: #2196f3;
  --mdb-cyan: #00abf0;
  --mdb-teal: #20c997;
  --mdb-green: #00ae00;
  --mdb-yellow: #ffc107;
  --mdb-orange: #ffa802;
  --mdb-white: #fff;
  --mdb-black: #000;
  --mdb-gray: #1a2e35;
  --mdb-gray-dark: #0d171a;
  --mdb-gray-50: #e8ebec;
  --mdb-gray-100: #d2d8d9;
  --mdb-gray-200: #a6b0b3;
  --mdb-gray-300: #79898e;
  --mdb-gray-400: #4d6168;
  --mdb-gray-500: #203a42;
  --mdb-gray-600: #1a2e35;
  --mdb-gray-700: #132328;
  --mdb-gray-800: #0d171a;
  --mdb-gray-900: #060c0d;
  --mdb-primary: #e3041c;
  --mdb-secondary: #203a42;
  --mdb-success: #00ae00;
  --mdb-danger: #e3041c;
  --mdb-warning: #ffc107;
  --mdb-info: #00abf0;
  --mdb-light: #d2d8d9;
  --mdb-dark: #060c0d;
  --mdb-primary-rgb: 227, 4, 28;
  --mdb-secondary-rgb: 32, 58, 66;
  --mdb-success-rgb: 0, 174, 0;
  --mdb-danger-rgb: 227, 4, 28;
  --mdb-warning-rgb: 255, 193, 7;
  --mdb-info-rgb: 0, 171, 240;
  --mdb-light-rgb: 210, 216, 217;
  --mdb-dark-rgb: 6, 12, 13;
  --mdb-primary-text-emphasis: #b60316;
  --mdb-secondary-text-emphasis: #0d171a;
  --mdb-success-text-emphasis: #006800;
  --mdb-info-text-emphasis: #0078a8;
  --mdb-warning-text-emphasis: #997404;
  --mdb-danger-text-emphasis: #b60316;
  --mdb-light-text-emphasis: #132328;
  --mdb-dark-text-emphasis: #a6b0b3;
  --mdb-primary-bg-subtle: #fbd9dd;
  --mdb-secondary-bg-subtle: #dee1e3;
  --mdb-success-bg-subtle: #d9f3d9;
  --mdb-info-bg-subtle: #d9f2fd;
  --mdb-warning-bg-subtle: #fff6da;
  --mdb-danger-bg-subtle: #fbd9dd;
  --mdb-light-bg-subtle: #d2d8d9;
  --mdb-dark-bg-subtle: #060c0d;
  --mdb-primary-border-subtle: #f49ba4;
  --mdb-secondary-border-subtle: #a6b0b3;
  --mdb-success-border-subtle: #99df99;
  --mdb-info-border-subtle: #99ddf9;
  --mdb-warning-border-subtle: #ffe69c;
  --mdb-danger-border-subtle: #f49ba4;
  --mdb-light-border-subtle: #a6b0b3;
  --mdb-dark-border-subtle: #203a42;
  --mdb-white-rgb: 255, 255, 255;
  --mdb-black-rgb: 0, 0, 0;
  --mdb-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mdb-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --mdb-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --mdb-root-font-size: .875rem;
  --mdb-body-font-family: var(--mdb-font-roboto);
  --mdb-body-font-size: 1rem;
  --mdb-body-font-weight: 400;
  --mdb-body-line-height: 1.6;
  --mdb-body-color: #0d171a;
  --mdb-body-color-rgb: 13, 23, 26;
  --mdb-body-bg: #e8ebec;
  --mdb-body-bg-rgb: 232, 235, 236;
  --mdb-emphasis-color: #000;
  --mdb-emphasis-color-rgb: 0, 0, 0;
  --mdb-secondary-color: #0d171abf;
  --mdb-secondary-color-rgb: 13, 23, 26;
  --mdb-secondary-bg: #a6b0b3;
  --mdb-secondary-bg-rgb: 166, 176, 179;
  --mdb-tertiary-color: #0d171a80;
  --mdb-tertiary-color-rgb: 13, 23, 26;
  --mdb-tertiary-bg: #fbfbfb;
  --mdb-tertiary-bg-rgb: 251, 251, 251;
  --mdb-heading-color: inherit;
  --mdb-link-color: #e3041c;
  --mdb-link-color-rgb: 227, 4, 28;
  --mdb-link-decoration: ;
  --mdb-link-hover-color: #d8041b;
  --mdb-link-hover-color-rgb: 216, 4, 27;
  --mdb-link-hover-decoration: underline;
  --mdb-code-color: #e91e63;
  --mdb-highlight-color: #0d171a;
  --mdb-highlight-bg: #ffeb3b;
  --mdb-border-width: 1px;
  --mdb-border-style: solid;
  --mdb-border-color: #d2d8d9;
  --mdb-border-color-translucent: #0000002d;
  --mdb-border-radius: .25rem;
  --mdb-border-radius-sm: .25rem;
  --mdb-border-radius-lg: .5rem;
  --mdb-border-radius-xl: 1rem;
  --mdb-border-radius-xxl: 2rem;
  --mdb-border-radius-2xl: var(--mdb-border-radius-xxl);
  --mdb-border-radius-pill: 50rem;
  --mdb-box-shadow: 0 .5rem 1rem #00000026;
  --mdb-box-shadow-sm: 0 .125rem .25rem #00000013;
  --mdb-box-shadow-lg: 0 1rem 3rem #0000002d;
  --mdb-box-shadow-inset: inset 0 1px 2px #00000013;
  --mdb-focus-ring-width: .25rem;
  --mdb-focus-ring-opacity: .25;
  --mdb-focus-ring-color: #e3041c40;
  --mdb-form-valid-color: #00ae00;
  --mdb-form-valid-border-color: #00ae00;
  --mdb-form-invalid-color: #e3041c;
  --mdb-form-invalid-border-color: #e3041c;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --mdb-body-color: #fff;
  --mdb-body-color-rgb: 255, 255, 255;
  --mdb-body-bg: #303030;
  --mdb-body-bg-rgb: 48, 48, 48;
  --mdb-emphasis-color: #fff;
  --mdb-emphasis-color-rgb: 255, 255, 255;
  --mdb-secondary-color: #ffffffbf;
  --mdb-secondary-color-rgb: 255, 255, 255;
  --mdb-secondary-bg: #0d171a;
  --mdb-secondary-bg-rgb: 13, 23, 26;
  --mdb-tertiary-color: #ffffff80;
  --mdb-tertiary-color-rgb: 255, 255, 255;
  --mdb-tertiary-bg: #0a1214;
  --mdb-tertiary-bg-rgb: 10, 18, 20;
  --mdb-primary-text-emphasis: #e93649;
  --mdb-secondary-text-emphasis: #a6b0b3;
  --mdb-success-text-emphasis: #66ce66;
  --mdb-info-text-emphasis: #4dc4f5;
  --mdb-warning-text-emphasis: #ffda6a;
  --mdb-danger-text-emphasis: #e93649;
  --mdb-light-text-emphasis: #d2d8d9;
  --mdb-dark-text-emphasis: #a6b0b3;
  --mdb-primary-bg-subtle: #2d0106;
  --mdb-secondary-bg-subtle: #060c0d;
  --mdb-success-bg-subtle: #002300;
  --mdb-info-bg-subtle: #002230;
  --mdb-warning-bg-subtle: #332701;
  --mdb-danger-bg-subtle: #2d0106;
  --mdb-light-bg-subtle: #0d171a;
  --mdb-dark-bg-subtle: #060c0d;
  --mdb-primary-border-subtle: #880211;
  --mdb-secondary-border-subtle: #132328;
  --mdb-success-border-subtle: #006800;
  --mdb-info-border-subtle: #006790;
  --mdb-warning-border-subtle: #997404;
  --mdb-danger-border-subtle: #880211;
  --mdb-light-border-subtle: #132328;
  --mdb-dark-border-subtle: #0d171a;
  --mdb-heading-color: inherit;
  --mdb-link-color: #ee6877;
  --mdb-link-hover-color: #ef707e;
  --mdb-link-color-rgb: 238, 104, 119;
  --mdb-link-hover-color-rgb: 239, 112, 126;
  --mdb-code-color: #f278a1;
  --mdb-highlight-color: #fff;
  --mdb-highlight-bg: #f9a825;
  --mdb-border-color: #ffffff1f;
  --mdb-border-color-translucent: #ffffff26;
  --mdb-form-valid-color: #81c784;
  --mdb-form-valid-border-color: #81c784;
  --mdb-form-invalid-color: #e57373;
  --mdb-form-invalid-border-color: #e57373;
}

*, :before, :after {
  box-sizing: border-box;
}

:root {
  font-size: var(--mdb-root-font-size);
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--mdb-body-font-family);
  font-size: var(--mdb-body-font-size);
  font-weight: var(--mdb-body-font-weight);
  line-height: var(--mdb-body-line-height);
  color: var(--mdb-body-color);
  text-align: var(--mdb-body-text-align);
  background-color: var(--mdb-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--mdb-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--mdb-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--mdb-highlight-color);
  background-color: var(--mdb-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--mdb-link-color-rgb), var(--mdb-link-opacity, 1));
}

a:hover {
  --mdb-link-color-rgb: var(--mdb-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--mdb-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--mdb-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--mdb-body-bg);
  background-color: var(--mdb-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--mdb-secondary-color);
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #1a2e35;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail, .table td.image img {
  border: var(--mdb-border-width) solid #d2d8d9;
  border-radius: var(--mdb-border-radius);
  box-shadow: var(--mdb-box-shadow-sm);
  background-color: #fff;
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--mdb-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: calc(var(--mdb-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --mdb-breakpoint-xs: 0;
  --mdb-breakpoint-sm: 576px;
  --mdb-breakpoint-md: 768px;
  --mdb-breakpoint-lg: 992px;
  --mdb-breakpoint-xl: 1200px;
  --mdb-breakpoint-xxl: 1400px;
}

.row {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  margin-top: calc(-1 * var(--mdb-gutter-y));
  margin-right: calc(-.5 * var(--mdb-gutter-x));
  margin-left: calc(-.5 * var(--mdb-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: calc(var(--mdb-gutter-x) * .5);
  margin-top: var(--mdb-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --mdb-gutter-x: 0;
}

.g-0, .gy-0 {
  --mdb-gutter-y: 0;
}

.g-1, .gx-1 {
  --mdb-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --mdb-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --mdb-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --mdb-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --mdb-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --mdb-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --mdb-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --mdb-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --mdb-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --mdb-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --mdb-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --mdb-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --mdb-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --mdb-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --mdb-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --mdb-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --mdb-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --mdb-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --mdb-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --mdb-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --mdb-gutter-y: 3rem;
  }
}

.table {
  --mdb-table-color-type: initial;
  --mdb-table-bg-type: initial;
  --mdb-table-color-state: initial;
  --mdb-table-bg-state: initial;
  --mdb-table-color: var(--mdb-body-color);
  --mdb-table-bg: transparent;
  --mdb-table-border-color: var(--mdb-border-color);
  --mdb-table-accent-bg: transparent;
  --mdb-table-striped-color: var(--mdb-body-color);
  --mdb-table-striped-bg: #00000005;
  --mdb-table-active-color: var(--mdb-body-color);
  --mdb-table-active-bg: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-table-hover-color: var(--mdb-body-color);
  --mdb-table-hover-bg: #00000006;
  vertical-align: top;
  border-color: var(--mdb-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--mdb-table-color-state, var(--mdb-table-color-type, var(--mdb-table-color)));
  background-color: var(--mdb-table-bg);
  border-bottom-width: var(--mdb-border-width);
  box-shadow: inset 0 0 0 9999px var(--mdb-table-bg-state, var(--mdb-table-bg-type, var(--mdb-table-accent-bg)));
  padding: 1rem 1.4rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--mdb-border-width) * 2) solid inherit;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--mdb-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--mdb-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --mdb-table-color-type: var(--mdb-table-striped-color);
  --mdb-table-bg-type: var(--mdb-table-striped-bg);
}

.table-active {
  --mdb-table-color-state: var(--mdb-table-active-color);
  --mdb-table-bg-state: var(--mdb-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --mdb-table-color-state: var(--mdb-table-hover-color);
  --mdb-table-bg-state: var(--mdb-table-hover-bg);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  color: var(--mdb-form-control-label-color);
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--mdb-border-width));
  padding-bottom: calc(.375rem + var(--mdb-border-width));
  font-size: inherit;
  color: var(--mdb-form-control-label-color);
  margin-bottom: 0;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--mdb-border-width));
  padding-bottom: calc(.5rem + var(--mdb-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--mdb-border-width));
  padding-bottom: calc(.25rem + var(--mdb-border-width));
  font-size: .775rem;
}

.form-text {
  color: var(--mdb-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: var(--mdb-surface-color);
  appearance: none;
  border: var(--mdb-border-width) solid #a6b0b3;
  border-radius: var(--mdb-border-radius);
  box-shadow: var(--mdb-box-shadow-inset);
  background-color: #fff;
  background-clip: padding-box;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  transition: all .2s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--mdb-surface-color);
  background-color: var(--mdb-body-bg);
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem #00abf040;
  border-color: #80d5f8;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.6em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: #203a42;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #d2d8d9;
}

.form-control::file-selector-button {
  color: var(--mdb-surface-color);
  background-color: var(--mdb-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--mdb-border-width);
  margin: -.375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--mdb-secondary-bg);
}

.form-control-plaintext {
  width: 100%;
  color: var(--mdb-surface-color);
  border: solid #0000;
  border-width: var(--mdb-border-width) 0;
  background-color: #0000;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.6;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.6em + .5rem + calc(var(--mdb-border-width) * 2));
  border-radius: var(--mdb-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .775rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--mdb-border-width) * 2));
  border-radius: var(--mdb-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + .75rem + calc(var(--mdb-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.6em + .5rem + calc(var(--mdb-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--mdb-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + .75rem + calc(var(--mdb-border-width) * 2));
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--mdb-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--mdb-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.6em + .5rem + calc(var(--mdb-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + calc(var(--mdb-border-width) * 2));
}

.form-select {
  --mdb-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%230d171a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  width: 100%;
  color: var(--mdb-surface-color);
  appearance: none;
  background-color: var(--mdb-body-bg);
  background-image: var(--mdb-form-select-bg-img), var(--mdb-form-select-bg-icon, none);
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: var(--mdb-border-radius);
  box-shadow: var(--mdb-box-shadow-inset);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  transition: all .2s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  box-shadow: var(--mdb-box-shadow-inset), 0 0 0 .25rem #e3041c40;
  border-color: #e3041c;
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--mdb-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--mdb-surface-color);
}

.form-select-sm {
  border-radius: var(--mdb-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .775rem;
}

.form-select-lg {
  border-radius: var(--mdb-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

[data-bs-theme="dark"] .form-select {
  --mdb-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.6rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --mdb-form-check-bg: var(--mdb-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--mdb-form-check-bg);
  background-image: var(--mdb-form-check-bg-image);
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .3em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--mdb-input-focus-border-color);
  outline: 0;
  box-shadow: 0 0 0 .25rem #e3041c40;
}

.form-check-input:checked {
  background-color: #e3041c;
  border-color: #e3041c;
}

.form-check-input:checked[type="checkbox"] {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --mdb-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #e3041c;
  border-color: #1a2e35;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --mdb-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--mdb-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --mdb-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--mdb-input-focus-border-color%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --mdb-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --mdb-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #e3041c;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f7b4bb;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--mdb-secondary-bg);
  width: 100%;
  height: .5rem;
  box-shadow: var(--mdb-box-shadow-inset);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #e3041c;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f7b4bb;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--mdb-secondary-bg);
  width: 100%;
  height: .5rem;
  box-shadow: var(--mdb-box-shadow-inset);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--mdb-form-control-disabled-bg);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--mdb-form-control-disabled-bg);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--mdb-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--mdb-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--mdb-border-width) solid #0000;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--mdb-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  border-radius: var(--mdb-border-radius);
  background-color: #fff;
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--mdb-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--mdb-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #1a2e35;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: #d2d8d9;
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--mdb-surface-color);
  text-align: center;
  white-space: nowrap;
  border: var(--mdb-border-width) solid var(--mdb-border-color);
  border-radius: var(--mdb-border-radius);
  background-color: #e8ebec;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--mdb-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--mdb-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .775rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--mdb-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: var(--mdb-form-valid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--mdb-success);
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: 6px 16px;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--mdb-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ae00' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --mdb-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ae00' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.6em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--mdb-form-valid-border-color);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--mdb-form-valid-color);
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: var(--mdb-form-invalid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--mdb-danger);
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: 6px 16px;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--mdb-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e3041c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3041c' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --mdb-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e3041c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3041c' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.6em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--mdb-form-invalid-border-color);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--mdb-form-invalid-color);
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --mdb-btn-padding-x: 1.5rem;
  --mdb-btn-padding-y: .375rem;
  --mdb-btn-font-family: ;
  --mdb-btn-font-size: 1rem;
  --mdb-btn-font-weight: 500;
  --mdb-btn-line-height: 1.5;
  --mdb-btn-color: var(--mdb-body-color);
  --mdb-btn-bg: transparent;
  --mdb-btn-border-width: 2px;
  --mdb-btn-border-color: transparent;
  --mdb-btn-border-radius: .25rem;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-disabled-opacity: .65;
  --mdb-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--mdb-btn-focus-shadow-rgb), .5);
  padding: var(--mdb-btn-padding-y) var(--mdb-btn-padding-x);
  font-family: var(--mdb-btn-font-family);
  font-size: var(--mdb-btn-font-size);
  font-weight: var(--mdb-btn-font-weight);
  line-height: var(--mdb-btn-line-height);
  color: var(--mdb-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--mdb-btn-border-width) solid var(--mdb-btn-border-color);
  border-radius: var(--mdb-btn-border-radius);
  background-color: var(--mdb-btn-bg);
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
  text-decoration: none;
}

.btn-check + .btn:hover {
  color: var(--mdb-btn-color);
  background-color: var(--mdb-btn-bg);
  border-color: var(--mdb-btn-border-color);
}

.btn:focus-visible {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
  box-shadow: var(--mdb-btn-box-shadow), var(--mdb-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--mdb-btn-hover-border-color);
  box-shadow: var(--mdb-btn-box-shadow), var(--mdb-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--mdb-btn-active-color);
  background-color: var(--mdb-btn-active-bg);
  border-color: var(--mdb-btn-active-border-color);
  box-shadow: var(--mdb-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn-check:checked:focus-visible + .btn {
  box-shadow: var(--mdb-btn-active-shadow), var(--mdb-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--mdb-btn-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-btn-disabled-bg);
  border-color: var(--mdb-btn-disabled-border-color);
  opacity: var(--mdb-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #e3041c;
  --mdb-btn-border-color: #e3041c;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #d8041b;
  --mdb-btn-hover-border-color: #b60316;
  --mdb-btn-focus-shadow-rgb: 231, 42, 62;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #cc0419;
  --mdb-btn-active-border-color: #aa0315;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #e3041c;
  --mdb-btn-disabled-border-color: #e3041c;
}

.btn-secondary {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #203a42;
  --mdb-btn-border-color: #203a42;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #1e373f;
  --mdb-btn-hover-border-color: #1a2e35;
  --mdb-btn-focus-shadow-rgb: 65, 88, 94;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #1d343b;
  --mdb-btn-active-border-color: #182c32;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #203a42;
  --mdb-btn-disabled-border-color: #203a42;
}

.btn-success {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #00ae00;
  --mdb-btn-border-color: #00ae00;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #00a500;
  --mdb-btn-hover-border-color: #008b00;
  --mdb-btn-focus-shadow-rgb: 38, 186, 38;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #009d00;
  --mdb-btn-active-border-color: #008300;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #00ae00;
  --mdb-btn-disabled-border-color: #00ae00;
}

.btn-danger {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #e3041c;
  --mdb-btn-border-color: #e3041c;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #d8041b;
  --mdb-btn-hover-border-color: #b60316;
  --mdb-btn-focus-shadow-rgb: 231, 42, 62;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #cc0419;
  --mdb-btn-active-border-color: #aa0315;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #e3041c;
  --mdb-btn-disabled-border-color: #e3041c;
}

.btn-warning {
  --mdb-btn-color: #000;
  --mdb-btn-bg: #ffc107;
  --mdb-btn-border-color: #ffc107;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #ffc413;
  --mdb-btn-hover-border-color: #ffc720;
  --mdb-btn-focus-shadow-rgb: 217, 164, 6;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #ffc720;
  --mdb-btn-active-border-color: #ffc720;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #ffc107;
  --mdb-btn-disabled-border-color: #ffc107;
}

.btn-info {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #00abf0;
  --mdb-btn-border-color: #00abf0;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #00a2e4;
  --mdb-btn-hover-border-color: #0089c0;
  --mdb-btn-focus-shadow-rgb: 38, 184, 242;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #009ad8;
  --mdb-btn-active-border-color: #0080b4;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #00abf0;
  --mdb-btn-disabled-border-color: #00abf0;
}

.btn-light {
  --mdb-btn-color: #000;
  --mdb-btn-bg: #d2d8d9;
  --mdb-btn-border-color: #d2d8d9;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #c8cdce;
  --mdb-btn-hover-border-color: #a8adae;
  --mdb-btn-focus-shadow-rgb: 179, 184, 184;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #bdc2c3;
  --mdb-btn-active-border-color: #9ea2a3;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #d2d8d9;
  --mdb-btn-disabled-border-color: #d2d8d9;
}

.btn-dark {
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #060c0d;
  --mdb-btn-border-color: #060c0d;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #121819;
  --mdb-btn-hover-border-color: #1f2425;
  --mdb-btn-focus-shadow-rgb: 43, 48, 49;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #1f2425;
  --mdb-btn-active-border-color: #1f2425;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #060c0d;
  --mdb-btn-disabled-border-color: #060c0d;
}

.btn-outline-primary {
  --mdb-btn-color: #e3041c;
  --mdb-btn-border-color: #e3041c;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #e3041c;
  --mdb-btn-hover-border-color: #e3041c;
  --mdb-btn-focus-shadow-rgb: 227, 4, 28;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #e3041c;
  --mdb-btn-active-border-color: #e3041c;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #e3041c;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #e3041c;
  --mdb-gradient: none;
}

.btn-outline-secondary {
  --mdb-btn-color: #203a42;
  --mdb-btn-border-color: #203a42;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #203a42;
  --mdb-btn-hover-border-color: #203a42;
  --mdb-btn-focus-shadow-rgb: 32, 58, 66;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #203a42;
  --mdb-btn-active-border-color: #203a42;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #203a42;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #203a42;
  --mdb-gradient: none;
}

.btn-outline-success {
  --mdb-btn-color: #00ae00;
  --mdb-btn-border-color: #00ae00;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #00ae00;
  --mdb-btn-hover-border-color: #00ae00;
  --mdb-btn-focus-shadow-rgb: 0, 174, 0;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #00ae00;
  --mdb-btn-active-border-color: #00ae00;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #00ae00;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #00ae00;
  --mdb-gradient: none;
}

.btn-outline-danger {
  --mdb-btn-color: #e3041c;
  --mdb-btn-border-color: #e3041c;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #e3041c;
  --mdb-btn-hover-border-color: #e3041c;
  --mdb-btn-focus-shadow-rgb: 227, 4, 28;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #e3041c;
  --mdb-btn-active-border-color: #e3041c;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #e3041c;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #e3041c;
  --mdb-gradient: none;
}

.btn-outline-warning {
  --mdb-btn-color: #ffc107;
  --mdb-btn-border-color: #ffc107;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #ffc107;
  --mdb-btn-hover-border-color: #ffc107;
  --mdb-btn-focus-shadow-rgb: 255, 193, 7;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #ffc107;
  --mdb-btn-active-border-color: #ffc107;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #ffc107;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #ffc107;
  --mdb-gradient: none;
}

.btn-outline-info {
  --mdb-btn-color: #00abf0;
  --mdb-btn-border-color: #00abf0;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #00abf0;
  --mdb-btn-hover-border-color: #00abf0;
  --mdb-btn-focus-shadow-rgb: 0, 171, 240;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #00abf0;
  --mdb-btn-active-border-color: #00abf0;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #00abf0;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #00abf0;
  --mdb-gradient: none;
}

.btn-outline-light {
  --mdb-btn-color: #d2d8d9;
  --mdb-btn-border-color: #d2d8d9;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #d2d8d9;
  --mdb-btn-hover-border-color: #d2d8d9;
  --mdb-btn-focus-shadow-rgb: 210, 216, 217;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #d2d8d9;
  --mdb-btn-active-border-color: #d2d8d9;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #d2d8d9;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #d2d8d9;
  --mdb-gradient: none;
}

.btn-outline-dark {
  --mdb-btn-color: #060c0d;
  --mdb-btn-border-color: #060c0d;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #060c0d;
  --mdb-btn-hover-border-color: #060c0d;
  --mdb-btn-focus-shadow-rgb: 6, 12, 13;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #060c0d;
  --mdb-btn-active-border-color: #060c0d;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-disabled-color: #060c0d;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #060c0d;
  --mdb-gradient: none;
}

.btn-link {
  --mdb-btn-font-weight: 400;
  --mdb-btn-color: #e3041c;
  --mdb-btn-bg: transparent;
  --mdb-btn-border-color: transparent;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-active-color: #d8041b;
  --mdb-btn-active-border-color: transparent;
  --mdb-btn-disabled-color: #203a42;
  --mdb-btn-disabled-border-color: transparent;
  --mdb-btn-box-shadow: 0 0 0 #000;
  --mdb-btn-focus-shadow-rgb: 231, 42, 62;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--mdb-btn-color);
}

.btn-link:hover {
  color: var(--mdb-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --mdb-btn-padding-y: .5rem;
  --mdb-btn-padding-x: 1.6875rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-border-radius: var(--mdb-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --mdb-btn-padding-y: .25rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-border-radius: var(--mdb-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --mdb-dropdown-zindex: 1000;
  --mdb-dropdown-min-width: 10rem;
  --mdb-dropdown-padding-x: 0;
  --mdb-dropdown-padding-y: .5rem;
  --mdb-dropdown-spacer: .125rem;
  --mdb-dropdown-font-size: 1rem;
  --mdb-dropdown-color: var(--mdb-surface-color);
  --mdb-dropdown-bg: var(--mdb-surface-bg);
  --mdb-dropdown-border-color: var(--mdb-border-color-translucent);
  --mdb-dropdown-border-radius: .5rem;
  --mdb-dropdown-border-width: var(--mdb-border-width);
  --mdb-dropdown-inner-border-radius: calc(.5rem - var(--mdb-border-width));
  --mdb-dropdown-divider-bg: var(--mdb-divider-color);
  --mdb-dropdown-divider-margin-y: .5rem;
  --mdb-dropdown-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-dropdown-link-color: var(--mdb-surface-color);
  --mdb-dropdown-link-hover-color: var(--mdb-surface-color);
  --mdb-dropdown-link-hover-bg: #e8ebec;
  --mdb-dropdown-link-active-color: #fff;
  --mdb-dropdown-link-active-bg: #e3041c;
  --mdb-dropdown-link-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-dropdown-item-padding-x: 1rem;
  --mdb-dropdown-item-padding-y: .5rem;
  --mdb-dropdown-header-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-dropdown-header-padding-x: 1rem;
  --mdb-dropdown-header-padding-y: .5rem;
  z-index: var(--mdb-dropdown-zindex);
  min-width: var(--mdb-dropdown-min-width);
  padding: var(--mdb-dropdown-padding-y) var(--mdb-dropdown-padding-x);
  font-size: var(--mdb-dropdown-font-size);
  color: var(--mdb-dropdown-color);
  text-align: left;
  background-color: var(--mdb-dropdown-bg);
  border: var(--mdb-dropdown-border-width) solid var(--mdb-dropdown-border-color);
  border-radius: var(--mdb-dropdown-border-radius);
  box-shadow: var(--mdb-dropdown-box-shadow);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--mdb-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--mdb-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--mdb-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--mdb-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--mdb-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--mdb-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  clear: both;
  color: var(--mdb-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--mdb-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--mdb-dropdown-link-hover-color);
  background-color: var(--mdb-dropdown-link-hover-bg);
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--mdb-dropdown-link-active-color);
  background-color: var(--mdb-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--mdb-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--mdb-dropdown-header-padding-y) var(--mdb-dropdown-header-padding-x);
  color: var(--mdb-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  color: var(--mdb-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --mdb-dropdown-color: #79898e;
  --mdb-dropdown-bg: #0d171a;
  --mdb-dropdown-border-color: var(--mdb-border-color-translucent);
  --mdb-dropdown-box-shadow: ;
  --mdb-dropdown-link-color: #79898e;
  --mdb-dropdown-link-hover-color: #fff;
  --mdb-dropdown-divider-bg: var(--mdb-divider-color);
  --mdb-dropdown-link-hover-bg: #ffffff26;
  --mdb-dropdown-link-active-color: #fff;
  --mdb-dropdown-link-active-bg: #e3041c;
  --mdb-dropdown-link-disabled-color: #203a42;
  --mdb-dropdown-header-color: #203a42;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .25rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.26563rem;
  padding-right: 1.26563rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav, .sub-menu {
  --mdb-nav-link-padding-x: 1rem;
  --mdb-nav-link-padding-y: .75rem;
  --mdb-nav-link-font-weight: ;
  --mdb-nav-link-color: var(--mdb-link-color);
  --mdb-nav-link-hover-color: var(--mdb-link-hover-color);
  --mdb-nav-link-disabled-color: var(--mdb-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link, .sub-link {
  padding: var(--mdb-nav-link-padding-y) var(--mdb-nav-link-padding-x);
  font-size: var(--mdb-nav-link-font-size);
  font-weight: var(--mdb-nav-link-font-weight);
  color: var(--mdb-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link, .sub-link {
    transition: none;
  }
}

.nav-link:hover, .sub-link:hover, .nav-link:focus, .sub-link:focus {
  color: var(--mdb-nav-link-hover-color);
  text-decoration: none;
}

.nav-link:focus-visible, .sub-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #e3041c40;
}

.nav-link.disabled, .disabled.sub-link, .nav-link:disabled, .sub-link:disabled {
  color: var(--mdb-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --mdb-nav-tabs-border-width: 2px;
  --mdb-nav-tabs-border-color: #d2d8d9;
  --mdb-nav-tabs-border-radius: var(--mdb-border-radius);
  --mdb-nav-tabs-link-hover-border-color: transparent transparent #a6b0b3;
  --mdb-nav-tabs-link-active-color: #e3041c;
  --mdb-nav-tabs-link-active-bg: var(--mdb-body-bg);
  --mdb-nav-tabs-link-active-border-color: #e3041c;
  border-bottom: var(--mdb-nav-tabs-border-width) solid var(--mdb-nav-tabs-border-color);
}

.nav-tabs .nav-link, .nav-tabs .sub-link {
  margin-bottom: calc(-1 * var(--mdb-nav-tabs-border-width));
  border: var(--mdb-nav-tabs-border-width) solid #0000;
  border-top-left-radius: var(--mdb-nav-tabs-border-radius);
  border-top-right-radius: var(--mdb-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .sub-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .sub-link:focus {
  isolation: isolate;
  border-color: var(--mdb-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .active.sub-link, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item.show .sub-link {
  color: var(--mdb-nav-tabs-link-active-color);
  background-color: var(--mdb-nav-tabs-link-active-bg);
  border-color: var(--mdb-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--mdb-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --mdb-nav-pills-border-radius: var(--mdb-border-radius);
  --mdb-nav-pills-link-active-color: #fff;
  --mdb-nav-pills-link-active-bg: #e3041c;
}

.nav-pills .nav-link, .nav-pills .sub-link {
  border-radius: var(--mdb-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .active.sub-link, .nav-pills .show > .nav-link, .nav-pills .show > .sub-link {
  color: var(--mdb-nav-pills-link-active-color);
  background-color: var(--mdb-nav-pills-link-active-bg);
}

.nav-underline {
  --mdb-nav-underline-gap: 1rem;
  --mdb-nav-underline-border-width: .125rem;
  --mdb-nav-underline-link-active-color: var(--mdb-emphasis-color);
  gap: var(--mdb-nav-underline-gap);
}

.nav-underline .nav-link, .nav-underline .sub-link {
  border-bottom: var(--mdb-nav-underline-border-width) solid #0000;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .sub-link:hover, .nav-underline .nav-link:focus, .nav-underline .sub-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .active.sub-link, .nav-underline .show > .nav-link, .nav-underline .show > .sub-link {
  color: var(--mdb-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill > .nav-link, .nav-fill > .sub-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified > .sub-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --mdb-navbar-padding-x: 0;
  --mdb-navbar-padding-y: .5rem;
  --mdb-navbar-color: rgba(var(--mdb-emphasis-color-rgb), .65);
  --mdb-navbar-hover-color: rgba(var(--mdb-emphasis-color-rgb), .8);
  --mdb-navbar-disabled-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-navbar-active-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-brand-padding-y: 0;
  --mdb-navbar-brand-margin-end: 1rem;
  --mdb-navbar-brand-font-size: 1.25rem;
  --mdb-navbar-brand-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-brand-hover-color: rgba(var(--mdb-emphasis-color-rgb), 1);
  --mdb-navbar-nav-link-padding-x: .5rem;
  --mdb-navbar-toggler-padding-y: .25rem;
  --mdb-navbar-toggler-padding-x: .75rem;
  --mdb-navbar-toggler-font-size: 1.25rem;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2813, 23, 26, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --mdb-navbar-toggler-border-color: rgba(var(--mdb-emphasis-color-rgb), .15);
  --mdb-navbar-toggler-border-radius: .25rem;
  --mdb-navbar-toggler-focus-width: .25rem;
  --mdb-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--mdb-navbar-padding-y) var(--mdb-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--mdb-navbar-brand-padding-y);
  padding-bottom: var(--mdb-navbar-brand-padding-y);
  margin-right: var(--mdb-navbar-brand-margin-end);
  font-size: var(--mdb-navbar-brand-font-size);
  color: var(--mdb-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--mdb-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --mdb-nav-link-padding-x: 0;
  --mdb-nav-link-padding-y: .75rem;
  --mdb-nav-link-font-weight: ;
  --mdb-nav-link-color: var(--mdb-navbar-color);
  --mdb-nav-link-hover-color: var(--mdb-navbar-hover-color);
  --mdb-nav-link-disabled-color: var(--mdb-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .active.sub-link, .navbar-nav .nav-link.show, .navbar-nav .show.sub-link {
  color: var(--mdb-navbar-active-color);
}

.navbar-text {
  color: var(--mdb-navbar-color);
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--mdb-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--mdb-navbar-toggler-padding-y) var(--mdb-navbar-toggler-padding-x);
  font-size: var(--mdb-navbar-toggler-font-size);
  color: var(--mdb-navbar-color);
  border: var(--mdb-border-width) solid var(--mdb-navbar-toggler-border-color);
  border-radius: var(--mdb-navbar-toggler-border-radius);
  transition: var(--mdb-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--mdb-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--mdb-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--mdb-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .sub-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .sub-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .sub-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .sub-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link, .navbar-expand-xxl .navbar-nav .sub-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .sub-link {
  padding-right: var(--mdb-navbar-nav-link-padding-x);
  padding-left: var(--mdb-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --mdb-navbar-color: #ffffff8c;
  --mdb-navbar-hover-color: #ffffffbf;
  --mdb-navbar-disabled-color: #ffffff40;
  --mdb-navbar-active-color: #fff;
  --mdb-navbar-brand-color: #fff;
  --mdb-navbar-brand-hover-color: #fff;
  --mdb-navbar-toggler-border-color: #ffffff1a;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: .5rem;
  --mdb-card-title-color: ;
  --mdb-card-subtitle-color: ;
  --mdb-card-border-width: var(--mdb-border-width);
  --mdb-card-border-color: #0000002d;
  --mdb-card-border-radius: .25rem;
  --mdb-card-box-shadow: 0 0px 3px 0 #00000012, 0 2px 2px 0 #0000000a;
  --mdb-card-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-card-cap-padding-y: .75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: #fff0;
  --mdb-card-cap-color: ;
  --mdb-card-height: ;
  --mdb-card-color: ;
  --mdb-card-bg: var(--mdb-surface-bg);
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: .75rem;
  min-width: 0;
  height: var(--mdb-card-height);
  color: var(--mdb-body-color);
  word-wrap: break-word;
  background-color: var(--mdb-card-bg);
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: var(--mdb-card-border-radius);
  box-shadow: var(--mdb-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--mdb-card-inner-border-radius);
  border-bottom-left-radius: var(--mdb-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--mdb-card-spacer-y) var(--mdb-card-spacer-x);
  color: var(--mdb-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--mdb-card-title-spacer-y);
  color: var(--mdb-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--mdb-card-title-spacer-y));
  color: var(--mdb-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: var(--mdb-card-spacer-x);
}

.card-header {
  padding: var(--mdb-card-cap-padding-y) var(--mdb-card-cap-padding-x);
  color: var(--mdb-card-cap-color);
  background-color: var(--mdb-card-cap-bg);
  border-bottom: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--mdb-card-inner-border-radius) var(--mdb-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--mdb-card-cap-padding-y) var(--mdb-card-cap-padding-x);
  color: var(--mdb-card-cap-color);
  background-color: var(--mdb-card-cap-bg);
  border-top: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--mdb-card-inner-border-radius) var(--mdb-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--mdb-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--mdb-card-cap-padding-y));
  margin-left: calc(-.5 * var(--mdb-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active, .card-header-tabs .active.sub-link {
  background-color: var(--mdb-card-bg);
  border-bottom-color: var(--mdb-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--mdb-card-cap-padding-x));
  margin-left: calc(-.5 * var(--mdb-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--mdb-card-img-overlay-padding);
  border-radius: var(--mdb-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--mdb-card-inner-border-radius);
  border-bottom-left-radius: var(--mdb-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--mdb-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --mdb-accordion-color: var(--mdb-body-color);
  --mdb-accordion-bg: var(--mdb-body-bg);
  --mdb-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --mdb-accordion-border-color: var(--mdb-border-color);
  --mdb-accordion-border-width: var(--mdb-border-width);
  --mdb-accordion-border-radius: .5rem;
  --mdb-accordion-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-accordion-btn-padding-x: 1.5rem;
  --mdb-accordion-btn-padding-y: 1.15rem;
  --mdb-accordion-btn-color: var(--mdb-surface-color);
  --mdb-accordion-btn-bg: var(--mdb-accordion-bg);
  --mdb-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%230d171a' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --mdb-accordion-btn-icon-width: 1.25rem;
  --mdb-accordion-btn-icon-transform: rotate(-180deg);
  --mdb-accordion-btn-icon-transition: transform .2s ease-in-out;
  --mdb-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23e3041c' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --mdb-accordion-btn-focus-box-shadow: inset 0 -1px 0 var(--mdb-border-color);
  --mdb-accordion-body-padding-x: 1.5rem;
  --mdb-accordion-body-padding-y: 1.15rem;
  --mdb-accordion-active-color: #e3041c;
  --mdb-accordion-active-bg: var(--mdb-surface-bg);
}

.accordion-button {
  width: 100%;
  padding: var(--mdb-accordion-btn-padding-y) var(--mdb-accordion-btn-padding-x);
  color: var(--mdb-accordion-btn-color);
  text-align: left;
  background-color: var(--mdb-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--mdb-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--mdb-accordion-active-color);
  background-color: var(--mdb-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--mdb-accordion-border-width)) 0 var(--mdb-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--mdb-accordion-btn-active-icon);
  transform: var(--mdb-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--mdb-accordion-btn-icon-width);
  height: var(--mdb-accordion-btn-icon-width);
  content: "";
  background-image: var(--mdb-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--mdb-accordion-btn-icon-width);
  transition: var(--mdb-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--mdb-accordion-color);
  background-color: var(--mdb-accordion-bg);
  border: var(--mdb-accordion-border-width) solid var(--mdb-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--mdb-accordion-border-radius);
  border-top-right-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--mdb-accordion-inner-border-radius);
  border-top-right-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--mdb-accordion-inner-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-body {
  padding: var(--mdb-accordion-body-padding-y) var(--mdb-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --mdb-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --mdb-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e3041c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --mdb-breadcrumb-padding-x: 0;
  --mdb-breadcrumb-padding-y: 0;
  --mdb-breadcrumb-margin-bottom: 1rem;
  --mdb-breadcrumb-bg: ;
  --mdb-breadcrumb-border-radius: ;
  --mdb-breadcrumb-divider-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-padding-x: .5rem;
  --mdb-breadcrumb-item-active-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  padding: var(--mdb-breadcrumb-padding-y) var(--mdb-breadcrumb-padding-x);
  margin-bottom: var(--mdb-breadcrumb-margin-bottom);
  font-size: var(--mdb-breadcrumb-font-size);
  background-color: var(--mdb-breadcrumb-bg);
  border-radius: var(--mdb-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--mdb-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--mdb-breadcrumb-item-padding-x);
  color: var(--mdb-breadcrumb-divider-color);
  content: var(--mdb-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--mdb-breadcrumb-item-active-color);
}

.pagination {
  --mdb-pagination-padding-x: .75rem;
  --mdb-pagination-padding-y: .375rem;
  --mdb-pagination-font-size: .9rem;
  --mdb-pagination-color: var(--mdb-body-color);
  --mdb-pagination-bg: var(--mdb-body-bg);
  --mdb-pagination-border-width: var(--mdb-border-width);
  --mdb-pagination-border-color: var(--mdb-border-color);
  --mdb-pagination-border-radius: .25rem;
  --mdb-pagination-hover-color: var(--mdb-body-color);
  --mdb-pagination-hover-bg: var(--mdb-highlight-bg-color);
  --mdb-pagination-hover-border-color: var(--mdb-border-color);
  --mdb-pagination-focus-color: var(--mdb-link-hover-color);
  --mdb-pagination-focus-bg: var(--mdb-highlight-bg-color);
  --mdb-pagination-focus-box-shadow: 0 0 0 .25rem #e3041c40;
  --mdb-pagination-active-color: var(--mdb-primary-text-emphasis);
  --mdb-pagination-active-bg: var(--mdb-primary-bg-subtle);
  --mdb-pagination-active-border-color: #e3041c;
  --mdb-pagination-disabled-color: rgba(var(--mdb-body-color-rgb), .55);
  --mdb-pagination-disabled-bg: transparent;
  --mdb-pagination-disabled-border-color: var(--mdb-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--mdb-pagination-padding-y) var(--mdb-pagination-padding-x);
  font-size: var(--mdb-pagination-font-size);
  color: var(--mdb-pagination-color);
  background-color: var(--mdb-pagination-bg);
  border: var(--mdb-pagination-border-width) solid var(--mdb-pagination-border-color);
  text-decoration: none;
  transition: all .3s linear;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--mdb-pagination-hover-color);
  background-color: var(--mdb-pagination-hover-bg);
  border-color: var(--mdb-pagination-hover-border-color);
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  color: var(--mdb-pagination-focus-color);
  background-color: var(--mdb-pagination-focus-bg);
  box-shadow: var(--mdb-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--mdb-pagination-active-color);
  background-color: var(--mdb-pagination-active-bg);
  border-color: var(--mdb-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--mdb-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-pagination-disabled-bg);
  border-color: var(--mdb-pagination-disabled-border-color);
}

.pagination-lg {
  --mdb-pagination-padding-x: 1.5rem;
  --mdb-pagination-padding-y: .75rem;
  --mdb-pagination-font-size: 1.25rem;
  --mdb-pagination-border-radius: var(--mdb-border-radius-lg);
}

.pagination-sm {
  --mdb-pagination-padding-x: .5rem;
  --mdb-pagination-padding-y: .25rem;
  --mdb-pagination-font-size: .875rem;
  --mdb-pagination-border-radius: var(--mdb-border-radius-sm);
}

.badge {
  --mdb-badge-padding-x: .65em;
  --mdb-badge-padding-y: .35em;
  --mdb-badge-font-size: .75em;
  --mdb-badge-font-weight: 700;
  --mdb-badge-color: #fff;
  --mdb-badge-border-radius: .27rem;
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  font-size: var(--mdb-badge-font-size);
  font-weight: var(--mdb-badge-font-weight);
  color: var(--mdb-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--mdb-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --mdb-alert-bg: transparent;
  --mdb-alert-padding-x: 1.5rem;
  --mdb-alert-padding-y: 1.25rem;
  --mdb-alert-margin-bottom: 1rem;
  --mdb-alert-color: inherit;
  --mdb-alert-border-color: transparent;
  --mdb-alert-border: var(--mdb-border-width) solid var(--mdb-alert-border-color);
  --mdb-alert-border-radius: .5rem;
  --mdb-alert-link-color: inherit;
  padding: var(--mdb-alert-padding-y) var(--mdb-alert-padding-x);
  margin-bottom: var(--mdb-alert-margin-bottom);
  color: var(--mdb-alert-color);
  background-color: var(--mdb-alert-bg);
  border: var(--mdb-alert-border);
  border-radius: var(--mdb-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--mdb-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.5rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.5625rem 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --mdb-alert-color: var(--mdb-primary-text-emphasis);
  --mdb-alert-bg: var(--mdb-primary-bg-subtle);
  --mdb-alert-border-color: var(--mdb-primary-border-subtle);
  --mdb-alert-link-color: var(--mdb-primary-text-emphasis);
}

.alert-secondary {
  --mdb-alert-color: var(--mdb-secondary-text-emphasis);
  --mdb-alert-bg: var(--mdb-secondary-bg-subtle);
  --mdb-alert-border-color: var(--mdb-secondary-border-subtle);
  --mdb-alert-link-color: var(--mdb-secondary-text-emphasis);
}

.alert-success {
  --mdb-alert-color: var(--mdb-success-text-emphasis);
  --mdb-alert-bg: var(--mdb-success-bg-subtle);
  --mdb-alert-border-color: var(--mdb-success-border-subtle);
  --mdb-alert-link-color: var(--mdb-success-text-emphasis);
}

.alert-danger {
  --mdb-alert-color: var(--mdb-danger-text-emphasis);
  --mdb-alert-bg: var(--mdb-danger-bg-subtle);
  --mdb-alert-border-color: var(--mdb-danger-border-subtle);
  --mdb-alert-link-color: var(--mdb-danger-text-emphasis);
}

.alert-warning {
  --mdb-alert-color: var(--mdb-warning-text-emphasis);
  --mdb-alert-bg: var(--mdb-warning-bg-subtle);
  --mdb-alert-border-color: var(--mdb-warning-border-subtle);
  --mdb-alert-link-color: var(--mdb-warning-text-emphasis);
}

.alert-info {
  --mdb-alert-color: var(--mdb-info-text-emphasis);
  --mdb-alert-bg: var(--mdb-info-bg-subtle);
  --mdb-alert-border-color: var(--mdb-info-border-subtle);
  --mdb-alert-link-color: var(--mdb-info-text-emphasis);
}

.alert-light {
  --mdb-alert-color: var(--mdb-light-text-emphasis);
  --mdb-alert-bg: var(--mdb-light-bg-subtle);
  --mdb-alert-border-color: var(--mdb-light-border-subtle);
  --mdb-alert-link-color: var(--mdb-light-text-emphasis);
}

.alert-dark {
  --mdb-alert-color: var(--mdb-dark-text-emphasis);
  --mdb-alert-bg: var(--mdb-dark-bg-subtle);
  --mdb-alert-border-color: var(--mdb-dark-border-subtle);
  --mdb-alert-link-color: var(--mdb-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 4px;
  }
}

.progress, .progress-stacked {
  --mdb-progress-height: 4px;
  --mdb-progress-font-size: .75rem;
  --mdb-progress-bg: var(--mdb-secondary-bg);
  --mdb-progress-border-radius: var(--mdb-border-radius);
  --mdb-progress-box-shadow: var(--mdb-box-shadow-inset);
  --mdb-progress-bar-color: #fff;
  --mdb-progress-bar-bg: #e3041c;
  --mdb-progress-bar-transition: width .6s ease;
  height: var(--mdb-progress-height);
  font-size: var(--mdb-progress-font-size);
  background-color: var(--mdb-progress-bg);
  border-radius: var(--mdb-progress-border-radius);
  box-shadow: var(--mdb-progress-box-shadow);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--mdb-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--mdb-progress-bar-bg);
  transition: var(--mdb-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--mdb-progress-height) var(--mdb-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --mdb-list-group-color: var(--mdb-body-color);
  --mdb-list-group-bg: transparent;
  --mdb-list-group-border-color: var(--mdb-border-color);
  --mdb-list-group-border-width: var(--mdb-border-width);
  --mdb-list-group-border-radius: .5rem;
  --mdb-list-group-item-padding-x: 1.5rem;
  --mdb-list-group-item-padding-y: .5rem;
  --mdb-list-group-action-color: var(--mdb-secondary-color);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-tertiary-bg);
  --mdb-list-group-action-active-color: var(--mdb-body-color);
  --mdb-list-group-action-active-bg: var(--mdb-secondary-bg);
  --mdb-list-group-disabled-color: rgba(var(--mdb-body-color-rgb), .5);
  --mdb-list-group-disabled-bg: transparent;
  --mdb-list-group-active-color: #fff;
  --mdb-list-group-active-bg: #e3041c;
  --mdb-list-group-active-border-color: #e3041c;
  border-radius: var(--mdb-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--mdb-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--mdb-list-group-action-hover-color);
  background-color: var(--mdb-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--mdb-list-group-action-active-color);
  background-color: var(--mdb-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--mdb-list-group-item-padding-y) var(--mdb-list-group-item-padding-x);
  color: var(--mdb-list-group-color);
  background-color: var(--mdb-list-group-bg);
  border: var(--mdb-list-group-border-width) solid var(--mdb-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--mdb-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--mdb-list-group-active-color);
  background-color: var(--mdb-list-group-active-bg);
  border-color: var(--mdb-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--mdb-list-group-border-width));
  border-top-width: var(--mdb-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--mdb-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--mdb-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--mdb-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--mdb-list-group-border-width));
  border-left-width: var(--mdb-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--mdb-list-group-border-width));
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--mdb-list-group-border-width));
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--mdb-list-group-border-width));
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--mdb-list-group-border-width));
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--mdb-list-group-border-width));
    border-left-width: var(--mdb-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--mdb-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --mdb-list-group-color: var(--mdb-primary-text-emphasis);
  --mdb-list-group-bg: var(--mdb-primary-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-primary-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-primary-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-primary-border-subtle);
  --mdb-list-group-active-color: var(--mdb-primary-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-primary-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-primary-text-emphasis);
}

.list-group-item-secondary {
  --mdb-list-group-color: var(--mdb-secondary-text-emphasis);
  --mdb-list-group-bg: var(--mdb-secondary-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-secondary-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-secondary-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-secondary-border-subtle);
  --mdb-list-group-active-color: var(--mdb-secondary-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-secondary-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-secondary-text-emphasis);
}

.list-group-item-success {
  --mdb-list-group-color: var(--mdb-success-text-emphasis);
  --mdb-list-group-bg: var(--mdb-success-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-success-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-success-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-success-border-subtle);
  --mdb-list-group-active-color: var(--mdb-success-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-success-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-success-text-emphasis);
}

.list-group-item-danger {
  --mdb-list-group-color: var(--mdb-danger-text-emphasis);
  --mdb-list-group-bg: var(--mdb-danger-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-danger-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-danger-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-danger-border-subtle);
  --mdb-list-group-active-color: var(--mdb-danger-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-danger-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-danger-text-emphasis);
}

.list-group-item-warning {
  --mdb-list-group-color: var(--mdb-warning-text-emphasis);
  --mdb-list-group-bg: var(--mdb-warning-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-warning-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-warning-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-warning-border-subtle);
  --mdb-list-group-active-color: var(--mdb-warning-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-warning-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-warning-text-emphasis);
}

.list-group-item-info {
  --mdb-list-group-color: var(--mdb-info-text-emphasis);
  --mdb-list-group-bg: var(--mdb-info-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-info-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-info-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-info-border-subtle);
  --mdb-list-group-active-color: var(--mdb-info-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-info-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-info-text-emphasis);
}

.list-group-item-light {
  --mdb-list-group-color: var(--mdb-light-text-emphasis);
  --mdb-list-group-bg: var(--mdb-light-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-light-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-light-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-light-border-subtle);
  --mdb-list-group-active-color: var(--mdb-light-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-light-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-light-text-emphasis);
}

.list-group-item-dark {
  --mdb-list-group-color: var(--mdb-dark-text-emphasis);
  --mdb-list-group-bg: var(--mdb-dark-bg-subtle);
  --mdb-list-group-border-color: var(--mdb-dark-border-subtle);
  --mdb-list-group-action-hover-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-hover-bg: var(--mdb-dark-border-subtle);
  --mdb-list-group-action-active-color: var(--mdb-emphasis-color);
  --mdb-list-group-action-active-bg: var(--mdb-dark-border-subtle);
  --mdb-list-group-active-color: var(--mdb-dark-bg-subtle);
  --mdb-list-group-active-bg: var(--mdb-dark-text-emphasis);
  --mdb-list-group-active-border-color: var(--mdb-dark-text-emphasis);
}

.btn-close {
  --mdb-btn-close-color: #000;
  --mdb-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --mdb-btn-close-opacity: .5;
  --mdb-btn-close-hover-opacity: .75;
  --mdb-btn-close-focus-shadow: 0 0 0 .25rem #e3041c40;
  --mdb-btn-close-focus-opacity: 1;
  --mdb-btn-close-disabled-opacity: .25;
  --mdb-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--mdb-btn-close-color);
  background: #0000 var(--mdb-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--mdb-btn-close-opacity);
  border: 0;
  border-radius: .25rem;
  padding: .25em;
}

.btn-close:hover {
  color: var(--mdb-btn-close-color);
  opacity: var(--mdb-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--mdb-btn-close-focus-shadow);
  opacity: var(--mdb-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--mdb-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--mdb-btn-close-white-filter);
}

.toast {
  --mdb-toast-zindex: 1060;
  --mdb-toast-padding-x: 1rem;
  --mdb-toast-padding-y: .65rem;
  --mdb-toast-spacing: 1.5rem;
  --mdb-toast-max-width: 350px;
  --mdb-toast-font-size: .875rem;
  --mdb-toast-color: ;
  --mdb-toast-bg: var(--mdb-surface-bg);
  --mdb-toast-border-width: var(--mdb-border-width);
  --mdb-toast-border-color: var(--mdb-border-color-translucent);
  --mdb-toast-border-radius: .5rem;
  --mdb-toast-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-toast-header-color: var(--mdb-secondary-color);
  --mdb-toast-header-bg: var(--mdb-surface-bg);
  --mdb-toast-header-border-color: var(--mdb-border-color-translucent);
  width: var(--mdb-toast-max-width);
  max-width: 100%;
  font-size: var(--mdb-toast-font-size);
  color: var(--mdb-toast-color);
  pointer-events: auto;
  background-color: var(--mdb-toast-bg);
  border: var(--mdb-toast-border-width) solid var(--mdb-toast-border-color);
  box-shadow: var(--mdb-toast-box-shadow);
  border-radius: var(--mdb-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --mdb-toast-zindex: 1060;
  z-index: var(--mdb-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--mdb-toast-spacing);
}

.toast-header {
  padding: var(--mdb-toast-padding-y) var(--mdb-toast-padding-x);
  color: var(--mdb-toast-header-color);
  background-color: var(--mdb-toast-header-bg);
  border-bottom: var(--mdb-toast-border-width) solid var(--mdb-toast-header-border-color);
  border-top-left-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  border-top-right-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-body {
  padding: var(--mdb-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --mdb-modal-zindex: 1055;
  --mdb-modal-width: 500px;
  --mdb-modal-padding: 1rem;
  --mdb-modal-margin: .5rem;
  --mdb-modal-color: var(--mdb-surface-color);
  --mdb-modal-bg: var(--mdb-surface-bg);
  --mdb-modal-border-color: var(--mdb-border-color-translucent);
  --mdb-modal-border-width: var(--mdb-border-width);
  --mdb-modal-border-radius: .5rem;
  --mdb-modal-box-shadow: var(--mdb-box-shadow-sm);
  --mdb-modal-inner-border-radius: calc(.5rem - (var(--mdb-border-width)));
  --mdb-modal-header-padding-x: 1rem;
  --mdb-modal-header-padding-y: 1rem;
  --mdb-modal-header-padding: 1rem 1rem;
  --mdb-modal-header-border-color: var(--mdb-divider-color);
  --mdb-modal-header-border-width: 2px;
  --mdb-modal-title-line-height: 1.6;
  --mdb-modal-footer-gap: .5rem;
  --mdb-modal-footer-bg: ;
  --mdb-modal-footer-border-color: var(--mdb-divider-color);
  --mdb-modal-footer-border-width: 2px;
  z-index: var(--mdb-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: var(--mdb-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--mdb-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--mdb-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--mdb-modal-color);
  pointer-events: auto;
  background-color: var(--mdb-modal-bg);
  border: var(--mdb-modal-border-width) solid var(--mdb-modal-border-color);
  border-radius: var(--mdb-modal-border-radius);
  box-shadow: var(--mdb-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --mdb-backdrop-zindex: 1050;
  --mdb-backdrop-bg: #000;
  --mdb-backdrop-opacity: .5;
  z-index: var(--mdb-backdrop-zindex);
  background-color: var(--mdb-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--mdb-backdrop-opacity);
}

.modal-header {
  padding: var(--mdb-modal-header-padding);
  border-bottom: var(--mdb-modal-header-border-width) solid var(--mdb-modal-header-border-color);
  border-top-left-radius: var(--mdb-modal-inner-border-radius);
  border-top-right-radius: var(--mdb-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--mdb-modal-header-padding-y) * .5) calc(var(--mdb-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--mdb-modal-header-padding-y)) calc(-.5 * var(--mdb-modal-header-padding-x)) calc(-.5 * var(--mdb-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--mdb-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--mdb-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--mdb-modal-padding)  - var(--mdb-modal-footer-gap) * .5);
  background-color: var(--mdb-modal-footer-bg);
  border-top: var(--mdb-modal-footer-border-width) solid var(--mdb-modal-footer-border-color);
  border-bottom-right-radius: var(--mdb-modal-inner-border-radius);
  border-bottom-left-radius: var(--mdb-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--mdb-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --mdb-modal-margin: 1.75rem;
    --mdb-modal-box-shadow: var(--mdb-box-shadow);
  }

  .modal-dialog {
    max-width: var(--mdb-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --mdb-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --mdb-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --mdb-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --mdb-tooltip-zindex: 1090;
  --mdb-tooltip-max-width: 200px;
  --mdb-tooltip-padding-x: 16px;
  --mdb-tooltip-padding-y: 6px;
  --mdb-tooltip-margin: ;
  --mdb-tooltip-font-size: .875rem;
  --mdb-tooltip-color: var(--mdb-surface-inverted-color);
  --mdb-tooltip-bg: var(--mdb-surface-inverted-bg);
  --mdb-tooltip-border-radius: .25rem;
  --mdb-tooltip-opacity: .9;
  --mdb-tooltip-arrow-width: .8rem;
  --mdb-tooltip-arrow-height: .4rem;
  z-index: var(--mdb-tooltip-zindex);
  margin: var(--mdb-tooltip-margin);
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip .tooltip-arrow {
  width: var(--mdb-tooltip-arrow-width);
  height: var(--mdb-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--mdb-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--mdb-tooltip-arrow-height) calc(var(--mdb-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--mdb-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--mdb-tooltip-arrow-height));
  width: var(--mdb-tooltip-arrow-height);
  height: var(--mdb-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--mdb-tooltip-arrow-width) * .5) var(--mdb-tooltip-arrow-height) calc(var(--mdb-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--mdb-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--mdb-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--mdb-tooltip-arrow-width) * .5) var(--mdb-tooltip-arrow-height);
  border-bottom-color: var(--mdb-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--mdb-tooltip-arrow-height));
  width: var(--mdb-tooltip-arrow-height);
  height: var(--mdb-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--mdb-tooltip-arrow-width) * .5) 0 calc(var(--mdb-tooltip-arrow-width) * .5) var(--mdb-tooltip-arrow-height);
  border-left-color: var(--mdb-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--mdb-tooltip-max-width);
  padding: var(--mdb-tooltip-padding-y) var(--mdb-tooltip-padding-x);
  color: var(--mdb-tooltip-color);
  text-align: center;
  background-color: var(--mdb-tooltip-bg);
  border-radius: var(--mdb-tooltip-border-radius);
}

.popover {
  --mdb-popover-zindex: 1080;
  --mdb-popover-max-width: 276px;
  --mdb-popover-font-size: .875rem;
  --mdb-popover-bg: var(--mdb-surface-bg);
  --mdb-popover-border-width: 1px;
  --mdb-popover-border-color: var(--mdb-divider-color);
  --mdb-popover-border-radius: .5rem;
  --mdb-popover-inner-border-radius: calc(.5rem - 1px);
  --mdb-popover-box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-popover-header-padding-x: 1rem;
  --mdb-popover-header-padding-y: .5rem;
  --mdb-popover-header-font-size: 1rem;
  --mdb-popover-header-color: var(--mdb-surface-color);
  --mdb-popover-header-bg: var(--mdb-surface-bg);
  --mdb-popover-body-padding-x: 1rem;
  --mdb-popover-body-padding-y: 1rem;
  --mdb-popover-body-color: var(--mdb-surface-color);
  --mdb-popover-arrow-width: 1rem;
  --mdb-popover-arrow-height: .5rem;
  --mdb-popover-arrow-border: var(--mdb-popover-border-color);
  z-index: var(--mdb-popover-zindex);
  max-width: var(--mdb-popover-max-width);
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-popover-font-size);
  word-wrap: break-word;
  background-color: var(--mdb-popover-bg);
  border: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-radius: var(--mdb-popover-border-radius);
  box-shadow: var(--mdb-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--mdb-popover-arrow-width);
  height: var(--mdb-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--mdb-popover-arrow-height))  - var(--mdb-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--mdb-popover-arrow-height) calc(var(--mdb-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--mdb-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--mdb-popover-border-width);
  border-top-color: var(--mdb-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--mdb-popover-arrow-height))  - var(--mdb-popover-border-width));
  width: var(--mdb-popover-arrow-height);
  height: var(--mdb-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--mdb-popover-arrow-width) * .5) var(--mdb-popover-arrow-height) calc(var(--mdb-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--mdb-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--mdb-popover-border-width);
  border-right-color: var(--mdb-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--mdb-popover-arrow-height))  - var(--mdb-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--mdb-popover-arrow-width) * .5) var(--mdb-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--mdb-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--mdb-popover-border-width);
  border-bottom-color: var(--mdb-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--mdb-popover-arrow-width);
  margin-left: calc(-.5 * var(--mdb-popover-arrow-width));
  content: "";
  border-bottom: var(--mdb-popover-border-width) solid var(--mdb-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--mdb-popover-arrow-height))  - var(--mdb-popover-border-width));
  width: var(--mdb-popover-arrow-height);
  height: var(--mdb-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--mdb-popover-arrow-width) * .5) 0 calc(var(--mdb-popover-arrow-width) * .5) var(--mdb-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--mdb-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--mdb-popover-border-width);
  border-left-color: var(--mdb-popover-bg);
}

.popover-header {
  padding: var(--mdb-popover-header-padding-y) var(--mdb-popover-header-padding-x);
  font-size: var(--mdb-popover-header-font-size);
  color: var(--mdb-popover-header-color);
  background-color: var(--mdb-popover-header-bg);
  border-bottom: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-top-left-radius: var(--mdb-popover-inner-border-radius);
  border-top-right-radius: var(--mdb-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--mdb-popover-body-padding-y) var(--mdb-popover-body-padding-x);
  color: var(--mdb-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target], .carousel-indicators [data-mdb-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target], .carousel-indicators [data-mdb-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target], .carousel-dark .carousel-indicators [data-mdb-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"] .carousel .carousel-indicators [data-mdb-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-mdb-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--mdb-spinner-width);
  height: var(--mdb-spinner-height);
  vertical-align: var(--mdb-spinner-vertical-align);
  animation: var(--mdb-spinner-animation-speed) linear infinite var(--mdb-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-border-width: .25em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-border;
  border: var(--mdb-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --mdb-spinner-width: 1rem;
  --mdb-spinner-height: 1rem;
  --mdb-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --mdb-spinner-width: 1rem;
  --mdb-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --mdb-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --mdb-offcanvas-zindex: 1045;
  --mdb-offcanvas-width: 400px;
  --mdb-offcanvas-height: 30vh;
  --mdb-offcanvas-padding-x: 1rem;
  --mdb-offcanvas-padding-y: 1rem;
  --mdb-offcanvas-color: var(--mdb-body-color);
  --mdb-offcanvas-bg: var(--mdb-body-bg);
  --mdb-offcanvas-border-width: var(--mdb-border-width);
  --mdb-offcanvas-border-color: var(--mdb-border-color-translucent);
  --mdb-offcanvas-box-shadow: var(--mdb-box-shadow-sm);
  --mdb-offcanvas-transition: transform .3s ease-in-out;
  --mdb-offcanvas-title-line-height: 1.6;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    transition: var(--mdb-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--mdb-offcanvas-height);
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    transition: var(--mdb-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--mdb-offcanvas-height);
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    transition: var(--mdb-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--mdb-offcanvas-height);
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    transition: var(--mdb-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--mdb-offcanvas-height);
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    transition: var(--mdb-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--mdb-offcanvas-height);
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--mdb-offcanvas-zindex);
  max-width: 100%;
  color: var(--mdb-offcanvas-color);
  visibility: hidden;
  background-color: var(--mdb-offcanvas-bg);
  box-shadow: var(--mdb-offcanvas-box-shadow);
  transition: var(--mdb-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--mdb-offcanvas-width);
  border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--mdb-offcanvas-width);
  border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--mdb-offcanvas-height);
  border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--mdb-offcanvas-height);
  border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--mdb-offcanvas-padding-y) var(--mdb-offcanvas-padding-x);
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--mdb-offcanvas-padding-y) * .5) calc(var(--mdb-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--mdb-offcanvas-padding-y)) calc(-.5 * var(--mdb-offcanvas-padding-x)) calc(-.5 * var(--mdb-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  line-height: var(--mdb-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--mdb-offcanvas-padding-y) var(--mdb-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--mdb-primary-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--mdb-secondary-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--mdb-success-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--mdb-danger-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--mdb-warning-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--mdb-info-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--mdb-light-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--mdb-dark-rgb), var(--mdb-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--mdb-primary-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-primary-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(216, 4, 27, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(216, 4, 27, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--mdb-secondary-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-secondary-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(30, 55, 63, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 55, 63, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--mdb-success-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-success-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(0, 165, 0, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 165, 0, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--mdb-danger-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-danger-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(216, 4, 27, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(216, 4, 27, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--mdb-warning-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-warning-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 196, 19, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 196, 19, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--mdb-info-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-info-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(0, 162, 228, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 162, 228, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--mdb-light-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-light-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(212, 218, 219, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(212, 218, 219, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--mdb-dark-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-dark-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(6, 11, 12, var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(6, 11, 12, var(--mdb-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--mdb-emphasis-color-rgb), var(--mdb-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--mdb-emphasis-color-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--mdb-emphasis-color-rgb), var(--mdb-link-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--mdb-emphasis-color-rgb), var(--mdb-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--mdb-focus-ring-x, 0) var(--mdb-focus-ring-y, 0) var(--mdb-focus-ring-blur, 0) var(--mdb-focus-ring-width) var(--mdb-focus-ring-color);
  outline: 0;
}

.icon-link {
  text-decoration-color: rgba(var(--mdb-link-color-rgb), var(--mdb-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--mdb-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--mdb-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --mdb-aspect-ratio: 100%;
}

.ratio-4x3 {
  --mdb-aspect-ratio: 75%;
}

.ratio-16x9 {
  --mdb-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --mdb-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--mdb-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-cover {
  object-fit: cover !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: .05 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-15 {
  opacity: .15 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-35 {
  opacity: .35 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-45 {
  opacity: .45 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-55 {
  opacity: .55 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-65 {
  opacity: .65 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-85 {
  opacity: .85 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-95 {
  opacity: .95 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block, .loading #loading {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--mdb-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--mdb-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--mdb-box-shadow-lg) !important;
}

.shadow-none, .shadow-0 {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0 0px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 1px 1px 0 rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-2 {
  box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-3 {
  box-shadow: 0 2px 6px -1px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 6px 18px -1px rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-4 {
  box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-5 {
  box-shadow: 0 2px 25px -5px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 25px 21px -5px rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-6 {
  box-shadow: 0 2px 35px -12px rgba(var(--mdb-box-shadow-color-rgb), .21), 0 50px 40px -5px rgba(var(--mdb-box-shadow-color-rgb), .04) !important;
}

.shadow-1-soft {
  box-shadow: 0 1px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-2-soft {
  box-shadow: 0 2px 10px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-3-soft {
  box-shadow: 0 5px 15px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-4-soft {
  box-shadow: 0 10px 20px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-5-soft {
  box-shadow: 0 15px 30px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-6-soft {
  box-shadow: 0 20px 40px 0 rgba(var(--mdb-box-shadow-color-rgb), .05) !important;
}

.shadow-1-strong {
  box-shadow: 0 0px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 1px 1px 0 rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-2-strong {
  box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-3-strong {
  box-shadow: 0 2px 6px -1px rgba(var(--mdb-box-shadow-color-rgb), .16), 0 6px 18px -1px rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-4-strong {
  box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .16), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-5-strong {
  box-shadow: 0 2px 25px -5px rgba(var(--mdb-box-shadow-color-rgb), .16), 0 25px 21px -5px rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-6-strong {
  box-shadow: 0 2px 35px -12px rgba(var(--mdb-box-shadow-color-rgb), .26), 0 50px 40px -5px rgba(var(--mdb-box-shadow-color-rgb), .1) !important;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(var(--mdb-box-shadow-color-rgb), .06) !important;
}

.focus-ring-primary {
  --mdb-focus-ring-color: rgba(var(--mdb-primary-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-secondary {
  --mdb-focus-ring-color: rgba(var(--mdb-secondary-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-success {
  --mdb-focus-ring-color: rgba(var(--mdb-success-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-danger {
  --mdb-focus-ring-color: rgba(var(--mdb-danger-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-warning {
  --mdb-focus-ring-color: rgba(var(--mdb-warning-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-info {
  --mdb-focus-ring-color: rgba(var(--mdb-info-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-light {
  --mdb-focus-ring-color: rgba(var(--mdb-light-rgb), var(--mdb-focus-ring-opacity));
}

.focus-ring-dark {
  --mdb-focus-ring-color: rgba(var(--mdb-dark-rgb), var(--mdb-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-primary-rgb), var(--mdb-border-opacity)) !important;
}

.border-secondary {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-secondary-rgb), var(--mdb-border-opacity)) !important;
}

.border-success {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-success-rgb), var(--mdb-border-opacity)) !important;
}

.border-danger {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-danger-rgb), var(--mdb-border-opacity)) !important;
}

.border-warning {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-warning-rgb), var(--mdb-border-opacity)) !important;
}

.border-info {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-info-rgb), var(--mdb-border-opacity)) !important;
}

.border-light {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-light-rgb), var(--mdb-border-opacity)) !important;
}

.border-dark {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-dark-rgb), var(--mdb-border-opacity)) !important;
}

.border-black {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-black-rgb), var(--mdb-border-opacity)) !important;
}

.border-white {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-white-rgb), var(--mdb-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--mdb-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--mdb-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--mdb-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--mdb-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--mdb-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--mdb-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--mdb-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--mdb-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --mdb-border-opacity: .1;
}

.border-opacity-25 {
  --mdb-border-opacity: .25;
}

.border-opacity-50 {
  --mdb-border-opacity: .5;
}

.border-opacity-75 {
  --mdb-border-opacity: .75;
}

.border-opacity-100 {
  --mdb-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

.mb-10 {
  margin-bottom: 8rem !important;
}

.mb-11 {
  margin-bottom: 10rem !important;
}

.mb-12 {
  margin-bottom: 12rem !important;
}

.mb-13 {
  margin-bottom: 14rem !important;
}

.mb-14 {
  margin-bottom: 16rem !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--mdb-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-primary-rgb), var(--mdb-text-opacity)) !important;
}

.text-secondary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-secondary-rgb), var(--mdb-text-opacity)) !important;
}

.text-success {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-success-rgb), var(--mdb-text-opacity)) !important;
}

.text-danger {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-danger-rgb), var(--mdb-text-opacity)) !important;
}

.text-warning {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-warning-rgb), var(--mdb-text-opacity)) !important;
}

.text-info {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-info-rgb), var(--mdb-text-opacity)) !important;
}

.text-light {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-light-rgb), var(--mdb-text-opacity)) !important;
}

.text-dark {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-dark-rgb), var(--mdb-text-opacity)) !important;
}

.text-black {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-black-rgb), var(--mdb-text-opacity)) !important;
}

.text-white {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-white-rgb), var(--mdb-text-opacity)) !important;
}

.text-body {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-body-color-rgb), var(--mdb-text-opacity)) !important;
}

.text-muted {
  --mdb-text-opacity: 1;
  color: var(--mdb-secondary-color) !important;
}

.text-black-50 {
  --mdb-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --mdb-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --mdb-text-opacity: 1;
  color: var(--mdb-secondary-color) !important;
}

.text-body-tertiary {
  --mdb-text-opacity: 1;
  color: var(--mdb-tertiary-color) !important;
}

.text-body-emphasis {
  --mdb-text-opacity: 1;
  color: var(--mdb-emphasis-color) !important;
}

.text-reset {
  --mdb-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --mdb-text-opacity: .25;
}

.text-opacity-50 {
  --mdb-text-opacity: .5;
}

.text-opacity-75 {
  --mdb-text-opacity: .75;
}

.text-opacity-100 {
  --mdb-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--mdb-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--mdb-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--mdb-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--mdb-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--mdb-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--mdb-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--mdb-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--mdb-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --mdb-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --mdb-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --mdb-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --mdb-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --mdb-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-primary-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-secondary-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-success {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-success-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-danger {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-danger-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-warning {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-warning-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-info {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-info-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-light {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-light-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline-dark {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-dark-rgb), var(--mdb-link-underline-opacity)) !important;
}

.link-underline {
  --mdb-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--mdb-link-color-rgb), var(--mdb-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --mdb-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --mdb-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --mdb-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --mdb-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --mdb-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --mdb-link-underline-opacity: 1;
}

.bg-primary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-secondary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-secondary-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-success {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-success-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-danger {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-danger-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-warning {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-warning-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-info {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-info-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-light {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-light-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-dark {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-dark-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-black {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-black-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-white {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-white-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-body {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-body-bg-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-transparent {
  --mdb-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-secondary-bg-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-body-tertiary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-tertiary-bg-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-opacity-10 {
  --mdb-bg-opacity: .1;
}

.bg-opacity-25 {
  --mdb-bg-opacity: .25;
}

.bg-opacity-50 {
  --mdb-bg-opacity: .5;
}

.bg-opacity-75 {
  --mdb-bg-opacity: .75;
}

.bg-opacity-100 {
  --mdb-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--mdb-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--mdb-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--mdb-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--mdb-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--mdb-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--mdb-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--mdb-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--mdb-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--mdb-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--mdb-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--mdb-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: .375rem !important;
}

.rounded-5 {
  border-radius: .5rem !important;
}

.rounded-circle, .avatar {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--mdb-border-radius-pill) !important;
}

.rounded-6 {
  border-radius: .75rem !important;
}

.rounded-7 {
  border-radius: 1rem !important;
}

.rounded-8 {
  border-radius: 1.25rem !important;
}

.rounded-9 {
  border-radius: 1.5rem !important;
}

.rounded-top {
  border-top-left-radius: var(--mdb-border-radius) !important;
  border-top-right-radius: var(--mdb-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--mdb-border-radius-sm) !important;
  border-top-right-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--mdb-border-radius) !important;
  border-top-right-radius: var(--mdb-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--mdb-border-radius-lg) !important;
  border-top-right-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--mdb-border-radius-xl) !important;
  border-top-right-radius: var(--mdb-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--mdb-border-radius-xxl) !important;
  border-top-right-radius: var(--mdb-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--mdb-border-radius-pill) !important;
  border-top-right-radius: var(--mdb-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--mdb-border-radius) !important;
  border-bottom-right-radius: var(--mdb-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--mdb-border-radius-sm) !important;
  border-bottom-right-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--mdb-border-radius) !important;
  border-bottom-right-radius: var(--mdb-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--mdb-border-radius-lg) !important;
  border-bottom-right-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--mdb-border-radius-xl) !important;
  border-bottom-right-radius: var(--mdb-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--mdb-border-radius-xxl) !important;
  border-bottom-right-radius: var(--mdb-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--mdb-border-radius-pill) !important;
  border-bottom-right-radius: var(--mdb-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--mdb-border-radius) !important;
  border-bottom-left-radius: var(--mdb-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--mdb-border-radius-sm) !important;
  border-bottom-left-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--mdb-border-radius) !important;
  border-bottom-left-radius: var(--mdb-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--mdb-border-radius-lg) !important;
  border-bottom-left-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--mdb-border-radius-xl) !important;
  border-bottom-left-radius: var(--mdb-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--mdb-border-radius-xxl) !important;
  border-bottom-left-radius: var(--mdb-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--mdb-border-radius-pill) !important;
  border-bottom-left-radius: var(--mdb-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--mdb-border-radius) !important;
  border-top-left-radius: var(--mdb-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--mdb-border-radius-sm) !important;
  border-top-left-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--mdb-border-radius) !important;
  border-top-left-radius: var(--mdb-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--mdb-border-radius-lg) !important;
  border-top-left-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--mdb-border-radius-xl) !important;
  border-top-left-radius: var(--mdb-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--mdb-border-radius-xxl) !important;
  border-top-left-radius: var(--mdb-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--mdb-border-radius-pill) !important;
  border-top-left-radius: var(--mdb-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.ls-tighter {
  letter-spacing: -.05em !important;
}

.ls-tight {
  letter-spacing: -.025em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.ls-wide {
  letter-spacing: .025em !important;
}

.ls-wider {
  letter-spacing: .05em !important;
}

.ls-widest {
  letter-spacing: .1em !important;
}

.object-top {
  object-position: top !important;
}

.object-center {
  object-position: center !important;
}

.object-bottom {
  object-position: bottom !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 16rem !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4rem !important;
  }

  .mb-md-8 {
    margin-bottom: 5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 6rem !important;
  }

  .mb-md-10 {
    margin-bottom: 8rem !important;
  }

  .mb-md-11 {
    margin-bottom: 10rem !important;
  }

  .mb-md-12 {
    margin-bottom: 12rem !important;
  }

  .mb-md-13 {
    margin-bottom: 14rem !important;
  }

  .mb-md-14 {
    margin-bottom: 16rem !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 16rem !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 16rem !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 16rem !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.diagonal-fractions {
  font-variant-numeric: diagonal-fractions;
}

.bg-super-light {
  --mdb-bg-super-light: #fbfbfb;
  background-color: var(--mdb-bg-super-light);
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.table-fixed {
  table-layout: fixed;
}

.table-auto {
  table-layout: auto;
}

.link-primary, .link-secondary, .link-success, .link-danger, .link-warning, .link-info, .link-light, .link-dark {
  transition: color .15s;
}

:root, [data-mdb-theme="light"] {
  --mdb-font-roboto: "Roboto", sans-serif;
  --mdb-bg-opacity: 1;
  --mdb-text-hover-opacity: .8;
  --mdb-surface-color: #0d171a;
  --mdb-surface-color-rgb: 13, 23, 26;
  --mdb-surface-bg: #fff;
  --mdb-surface-inverted-color: #fff;
  --mdb-surface-inverted-color-rgb: 255, 255, 255;
  --mdb-surface-inverted-bg: #6d6d6d;
  --mdb-divider-color: #f5f5f5;
  --mdb-divider-blurry-color: #666;
  --mdb-highlight-bg-color: #a6b0b3;
  --mdb-scrollbar-rail-bg: #a6b0b3;
  --mdb-scrollbar-thumb-bg: #203a42;
  --mdb-picker-header-bg: #e3041c;
  --mdb-timepicker-clock-face-bg: var(--mdb-secondary-bg);
  --mdb-sidenav-backdrop-opacity: .1;
  --mdb-input-focus-border-color: var(--mdb-primary);
  --mdb-input-focus-label-color: var(--mdb-primary);
  --mdb-form-control-border-color: #a6b0b3;
  --mdb-form-control-label-color: #1a2e35;
  --mdb-form-control-disabled-bg: #e9ecef;
  --mdb-box-shadow-color: #000;
  --mdb-box-shadow-color-rgb: 0, 0, 0;
  --mdb-stepper-mobile-bg: #e8ebec;
  --mdb-datepicker-cell-in-range-background-color: #f49ba4;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --mdb-surface-color: #fff;
  --mdb-surface-color-rgb: 255, 255, 255;
  --mdb-surface-bg: #424242;
  --mdb-surface-inverted-color: #fff;
  --mdb-surface-inverted-color-rgb: 255, 255, 255;
  --mdb-surface-inverted-bg: #757575;
  --mdb-divider-color: #ffffff1f;
  --mdb-divider-blurry-color: #b3b3b3;
  --mdb-highlight-bg-color: #1f2425;
  --mdb-scrollbar-rail-bg: #203a42;
  --mdb-scrollbar-thumb-bg: #a6b0b3;
  --mdb-picker-header-bg: #323232;
  --mdb-timepicker-clock-face-bg: #132328;
  --mdb-sidenav-backdrop-opacity: .5;
  --mdb-form-control-border-color: #ffffffb3;
  --mdb-form-control-label-color: #4d6168;
  --mdb-form-control-disabled-bg: #132328;
  --mdb-box-shadow-color: #000;
  --mdb-box-shadow-color-rgb: 0, 0, 0;
  --mdb-stepper-mobile-bg: #0a1214;
  --mdb-datepicker-cell-in-range-background-color: #132328;
}

.note {
  --mdb-note-padding: 10px;
  --mdb-note-border-width: 6px;
  --mdb-note-border-radius: 5px;
  --mdb-note-strong-font-weight: 600;
  padding: var(--mdb-note-padding);
  border-left: var(--mdb-note-border-width) solid;
  border-radius: var(--mdb-note-border-radius);
}

.note strong {
  font-weight: var(--mdb-note-strong-font-weight);
}

.note-primary {
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
}

.note-secondary {
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
}

.note-success {
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
}

.note-danger {
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
}

.note-warning {
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
}

.note-info {
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
}

.note-light {
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.note-dark {
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
}

@media (width >= 1199px) {
  .w-responsive {
    width: 75%;
  }
}

.bg-primary {
  --mdb--bg-opacity: 1;
  background-color: rgba(227, 4, 28, var(--mdb--bg-opacity));
}

.bg-secondary {
  --mdb--bg-opacity: 1;
  background-color: rgba(32, 58, 66, var(--mdb--bg-opacity));
}

.bg-success {
  --mdb--bg-opacity: 1;
  background-color: rgba(0, 174, 0, var(--mdb--bg-opacity));
}

.bg-danger {
  --mdb--bg-opacity: 1;
  background-color: rgba(227, 4, 28, var(--mdb--bg-opacity));
}

.bg-warning {
  --mdb--bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--mdb--bg-opacity));
}

.bg-info {
  --mdb--bg-opacity: 1;
  background-color: rgba(0, 171, 240, var(--mdb--bg-opacity));
}

.bg-light {
  --mdb--bg-opacity: 1;
  background-color: rgba(210, 216, 217, var(--mdb--bg-opacity));
}

.bg-dark {
  --mdb--bg-opacity: 1;
  background-color: rgba(6, 12, 13, var(--mdb--bg-opacity));
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.mask {
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hover-overlay .mask {
  --mdb-image-hover-transition: all .3s ease-in-out;
  opacity: 0;
  transition: var(--mdb-image-hover-transition);
}

.hover-overlay .mask:hover {
  opacity: 1;
}

.hover-zoom {
  --mdb-image-hover-zoom-transition: all .3s linear;
  --mdb-image-hover-zoom-transform: scale(1.1);
}

.hover-zoom img, .hover-zoom video {
  transition: var(--mdb-image-hover-zoom-transition);
}

.hover-zoom:hover img, .hover-zoom:hover video {
  transform: var(--mdb-image-hover-zoom-transform);
}

.hover-shadow, .card.hover-shadow, .hover-shadow-soft, .card.hover-shadow-soft {
  --mdb-image-hover-shadow-transition: all .3s ease-in-out;
  transition: var(--mdb-image-hover-shadow-transition);
}

.hover-shadow:hover, .card.hover-shadow:hover, .hover-shadow-soft:hover, .card.hover-shadow-soft:hover {
  transition: var(--mdb-image-hover-shadow-transition);
}

.hover-shadow, .card.hover-shadow {
  --mdb-image-hover-shadow-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .16), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .1);
  box-shadow: none;
}

.hover-shadow:hover, .card.hover-shadow:hover {
  box-shadow: var(--mdb-image-hover-shadow-box-shadow);
}

.hover-shadow-soft, .card.hover-shadow-soft {
  --mdb-image-hover-shadow-box-shadow-soft: 0 2px 25px -5px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 25px 21px -5px rgba(var(--mdb-box-shadow-color-rgb), .04);
  box-shadow: none;
}

.hover-shadow-soft:hover, .card.hover-shadow-soft:hover {
  box-shadow: var(--mdb-image-hover-shadow-box-shadow-soft);
}

.form-control {
  min-height: auto;
  box-shadow: none;
  padding: 4.5px 12px 3.68px;
  transition: all .1s linear;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e3041c;
  transition: all .1s linear;
  box-shadow: inset 0 0 0 1px #e3041c;
}

.form-control.form-control-sm {
  font-size: .775rem;
  line-height: 1.5;
}

.form-control.form-control-lg {
  border-radius: .25rem;
  line-height: 2.15;
}

.form-outline {
  width: 100%;
  position: relative;
}

.form-outline .form-helper {
  color: #757575;
  width: 100%;
  font-size: .875em;
  position: absolute;
}

.form-outline .form-helper .form-counter {
  text-align: right;
}

.form-outline .trailing {
  right: 10px;
  left: initial;
  pointer-events: none;
  color: var(--mdb-surface-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-outline .form-icon-trailing {
  padding-right: 2rem !important;
}

.form-outline .form-control {
  background: none;
  border: 0;
  min-height: auto;
  padding: .5em .75rem;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  transform-origin: 0 0;
  max-width: 90%;
  color: var(--mdb-form-control-label-color);
  margin-bottom: 0;
  padding-top: .37rem;
  transition: all .2s ease-out;
  position: absolute;
  top: 0;
  left: .75rem;
  overflow: hidden;
}

.form-outline .form-control ~ .form-notch {
  text-align: left;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch div {
  pointer-events: none;
  border: 1px solid;
  border-color: var(--mdb-form-control-border-color);
  box-sizing: border-box;
  background: none;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-notch .form-notch-leading {
  border-right: none;
  border-radius: .25rem 0 0 .25rem;
  width: .5rem;
  height: 100%;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch .form-notch-middle {
  border-left: none;
  border-right: none;
  flex: none;
  width: auto;
  max-width: calc(100% - 1rem);
  height: 100%;
}

.form-outline .form-control ~ .form-notch .form-notch-trailing {
  border-left: none;
  border-radius: 0 .25rem .25rem 0;
  flex-grow: 1;
  height: 100%;
}

.form-outline .form-control:not(.placeholder-active)::placeholder {
  opacity: 0;
}

.form-outline .form-control:focus::placeholder, .form-outline .form-control.active::placeholder {
  opacity: 1;
}

.form-outline .form-control:focus ~ .form-label, .form-outline .form-control.active ~ .form-label {
  transform: translateY(-1rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control:focus ~ .form-label {
  color: var(--mdb-input-focus-label-color);
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  border-left: none;
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #0000 #80d5f8 #80d5f8;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 1px #80d5f8;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.active ~ .form-notch .form-notch-leading {
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #80d5f8;
  box-shadow: -1px 0 #80d5f8, 0 1px #80d5f8, 0 -1px #80d5f8;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.active ~ .form-notch .form-notch-trailing {
  border-left: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #80d5f8;
  box-shadow: 1px 0 #80d5f8, 0 -1px #80d5f8, 0 1px #80d5f8;
}

.form-outline .form-control:disabled, .form-outline .form-control.disabled, .form-outline .form-control[readonly] {
  background-color: var(--mdb-form-control-disabled-bg);
}

.form-outline .form-control:disabled ~ .timepicker-toggle-button, .form-outline .form-control:disabled ~ .datepicker-toggle-button, .form-outline .form-control:disabled ~ .datetimepicker-toggle-button, .form-outline .form-control:disabled ~ .select-arrow, .form-outline .form-control:disabled ~ .trailing, .form-outline .form-control.disabled ~ .timepicker-toggle-button, .form-outline .form-control.disabled ~ .datepicker-toggle-button, .form-outline .form-control.disabled ~ .datetimepicker-toggle-button, .form-outline .form-control.disabled ~ .select-arrow, .form-outline .form-control.disabled ~ .trailing, .form-outline .form-control[readonly] ~ .timepicker-toggle-button, .form-outline .form-control[readonly] ~ .datepicker-toggle-button, .form-outline .form-control[readonly] ~ .datetimepicker-toggle-button, .form-outline .form-control[readonly] ~ .select-arrow, .form-outline .form-control[readonly] ~ .trailing {
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-outline .form-control.form-control-lg {
  font-size: 1rem;
  line-height: 2.15;
}

.form-outline .form-control.form-control-lg ~ .form-label {
  padding-top: .7rem;
}

.form-outline .form-control.form-control-lg:focus ~ .form-label, .form-outline .form-control.form-control-lg.active ~ .form-label {
  transform: translateY(-1.25rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control.form-control-sm {
  padding-top: .32rem;
  padding-bottom: .32rem;
  font-size: .775rem;
  line-height: 1.5;
}

.form-outline .form-control.form-control-sm ~ .form-label {
  padding-top: .33rem;
  font-size: .775rem;
}

.form-outline .form-control.form-control-sm:focus ~ .form-label, .form-outline .form-control.form-control-sm.active ~ .form-label {
  transform: translateY(-.85rem)translateY(.1rem)scale(.8);
}

.form-outline.form-white .form-control {
  color: #fff;
}

.form-outline.form-white .form-control ~ .form-label {
  color: #e8ebec;
}

.form-outline.form-white .form-control ~ .form-notch div {
  border-color: #e8ebec;
}

.form-outline.form-white .form-control:focus ~ .form-label {
  color: #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #0000 #fff #fff;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #fff;
  box-shadow: -1px 0 #fff, 0 1px #fff, 0 -1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #fff;
  box-shadow: 1px 0 #fff, 0 -1px #fff, 0 1px #fff;
}

.form-outline.form-white .form-control::placeholder {
  color: #ffffffb3;
}

.form-outline.form-white .form-control:disabled, .form-outline.form-white .form-control.disabled, .form-outline.form-white .form-control[readonly] {
  background-color: #ffffff73;
}

.form-control {
  border: 1px solid #a6b0b3 !important;
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.form-control:focus {
  background-color: var(--mdb-form-search-bg);
  border-color: #00abf0 !important;
  box-shadow: inset 0 0 0 1px #00abf0 !important;
}

.form-outline .form-control:focus {
  box-shadow: inset 0 0 0 1px #00abf0 !important;
}

.select-input.form-control[readonly]:not([disabled]) {
  background-color: #0000;
}

.form-select {
  transition: all .2s linear;
}

.form-select:focus {
  border-color: #e3041c;
  outline: 0;
  box-shadow: inset 0 0 0 1px #e3041c;
}

.form-outline .select-input {
  padding-top: .5em;
  padding-bottom: .5em;
}

.select-fake-value {
  display: none !important;
}

.select-options-wrapper .form-check-input + .select-option-secondary-text {
  margin-left: 26px;
}

.form-check {
  min-height: 1.5rem;
}

.form-check-input {
  background-color: var(--mdb-body-bg);
  border: .125rem solid var(--mdb-form-control-border-color);
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
}

.form-check-input:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: #0000;
  border-radius: 50%;
  width: .875rem;
  height: .875rem;
  position: absolute;
  transform: scale(0);
  box-shadow: 0 0 0 13px #0000;
}

.form-check-input:hover {
  cursor: pointer;
}

.form-check-input:hover:before {
  opacity: .04;
  box-shadow: 0px 0px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
}

.form-check-input:focus {
  box-shadow: none;
  border-color: var(--mdb-form-control-border-color);
  transition: border-color .2s;
}

.form-check-input:focus:before {
  opacity: .12;
  box-shadow: 0px 0px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
}

.form-check-input:checked {
  border-color: #e3041c;
}

.form-check-input:checked:before {
  opacity: .16;
}

.form-check-input:checked:after {
  content: "";
  position: absolute;
}

.form-check-input:checked:focus {
  border-color: #e3041c;
}

.form-check-input:checked:focus:before {
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 0 0 0 13px #e3041c;
}

.form-check-input:indeterminate:focus:before {
  box-shadow: 0 0 0 13px #e3041c;
}

.form-check-input[type="checkbox"] {
  border-radius: .25rem;
  margin-top: .19em;
  margin-right: 6px;
}

.form-check-input[type="checkbox"]:focus:after {
  content: "";
  z-index: 1;
  background-color: var(--mdb-body-bg);
  border-radius: 0;
  width: .875rem;
  height: .875rem;
  display: block;
  position: absolute;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #e3041c;
  background-image: none;
}

.form-check-input[type="checkbox"]:checked:after {
  background-color: #0000;
  border: .125rem solid #fff;
  border-top: 0;
  border-left: 0;
  width: .375rem;
  height: .8125rem;
  margin-top: -1px;
  margin-left: .25rem;
  display: block;
  transform: rotate(45deg);
}

.form-check-input[type="checkbox"]:checked:focus {
  background-color: #e3041c;
}

.form-check-input[type="checkbox"]:indeterminate {
  border-color: #e3041c;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .125em;
  margin-right: 4px;
}

.form-check-input[type="radio"]:before {
  width: 1rem;
  height: 1rem;
}

.form-check-input[type="radio"]:after {
  content: "";
  z-index: 1;
  background-color: var(--mdb-body-bg);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
}

.form-check-input[type="radio"]:checked {
  background-image: none;
  background-color: var(--mdb-body-bg);
}

.form-check-input[type="radio"]:checked:after {
  background-color: #e3041c;
  border-color: #e3041c;
  border-radius: 50%;
  width: .625rem;
  height: .625rem;
  transition: border-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input[type="radio"]:checked:focus {
  background-color: var(--mdb-body-bg);
}

.form-check-label {
  padding-left: .15rem;
}

.form-check-label:hover {
  cursor: pointer;
}

.form-switch .form-check-input {
  background-image: none;
  background-color: rgba(var(--mdb-emphasis-color-rgb), .25);
  border-width: 0;
  border-radius: .4375rem;
  width: 2rem;
  height: .875rem;
  margin-top: .3em;
  margin-right: 8px;
}

.form-switch .form-check-input:after {
  content: "";
  z-index: 2;
  background-color: var(--mdb-surface-bg);
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .04);
  border: none;
  border-radius: 50%;
  margin-top: -.1875rem;
  transition: background-color .2s, transform .2s;
  position: absolute;
}

.form-switch .form-check-input:focus {
  background-image: none;
}

.form-switch .form-check-input:focus:before {
  box-shadow: 3px -1px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
}

.form-switch .form-check-input:focus:after {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
}

.form-switch .form-check-input:checked, .form-switch .form-check-input:checked:focus {
  background-image: none;
}

.form-switch .form-check-input:checked:focus:before {
  margin-left: 1.0625rem;
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 3px -1px 0 13px #00abf0;
}

.form-switch .form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-switch .form-check-input:checked[type="checkbox"]:after {
  content: "";
  z-index: 2;
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: 0 3px 1px -2px rgba(var(--mdb-box-shadow-color-rgb), .2), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .14), 0 1px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  background-color: #00abf0;
  border: none;
  border-radius: 50%;
  margin-top: -3px;
  margin-left: 1.0625rem;
  transition: background-color .2s, transform .2s;
  position: absolute;
}

.form-control[type="file"] {
  border-color: var(--mdb-form-control-border-color);
}

.form-control[type="file"]::-webkit-file-upload-button {
  background-color: #0000;
}

.form-control[type="file"]:disabled {
  background-color: var(--mdb-form-control-disabled-bg);
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-control[type="file"]:disabled::file-selector-button {
  color: rgba(var(--mdb-surface-color-rgb), .5);
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #0000;
}

.input-group {
  flex-wrap: nowrap;
}

.input-group > .form-control {
  height: calc(2.45rem + 2px);
  min-height: calc(2.45rem + 2px);
  padding-top: .35rem;
  padding-bottom: .35rem;
  transition: all .2s linear;
}

.input-group > .form-control:focus {
  border-color: #00abf0;
  outline: 0;
  transition: all .2s linear;
  box-shadow: inset 0 0 0 1px #00abf0;
}

.input-group-text {
  background-color: #0000;
  padding-top: .26rem;
  padding-bottom: .26rem;
}

.input-group-text > .form-check-input[type="checkbox"] {
  margin-left: 1px;
  margin-right: 1px;
}

.input-group-text > .form-check-input[type="radio"] {
  margin-right: 0;
}

.input-group-lg > .form-control {
  height: calc(2.645rem + 2px);
  padding-top: .33rem;
  padding-bottom: .33rem;
  font-size: 1rem;
}

.input-group-lg .input-group-text {
  font-size: 1rem;
}

.input-group-sm > .form-control {
  height: calc(1.66rem + 2px);
  min-height: calc(1.66rem + 2px);
  padding-top: .33rem;
  padding-bottom: .33rem;
  font-size: .775rem;
}

.input-group-sm .input-group-text {
  font-size: .775rem;
  line-height: 1.5;
}

.input-group.form-outline .input-group-text {
  border-left: 0;
}

.input-group.form-outline input + .input-group-text {
  border: 0;
  border-left: 1px solid #bdbdbd;
}

.input-group .form-outline:not(:first-child), .input-group .select-wrapper:not(:first-child), .input-group .form-outline:not(:first-child) .form-notch-leading, .input-group .select-wrapper:not(:first-child) .form-notch-leading {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group .form-outline:not(:last-child), .input-group .select-wrapper:not(:last-child), .input-group .form-outline:not(:last-child) .form-notch-trailing, .input-group .select-wrapper:not(:last-child) .form-notch-trailing {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > [class*="btn-outline-"] + [class*="btn-outline-"] {
  border-left: 0;
}

.input-group > .btn[class*="btn-outline-"] {
  padding-top: .47rem;
}

.input-group > .btn {
  padding-top: .59rem;
}

.input-group.input-group-lg .input-group-text {
  height: calc(2.645rem + 2px);
}

.input-group .input-group-text {
  height: calc(2.45rem + 2px);
}

.input-group .btn {
  line-height: 1;
}

.input-group.input-group-sm .input-group-text {
  height: calc(1.66rem + 2px);
}

:-webkit-any(INPUT:-webkit-autofill, SELECT:-webkit-autofill, TEXTAREA:-webkit-autofill), :-webkit-any(INPUT:-webkit-autofill:hover, INPUT:-webkit-autofill:focus, INPUT:-webkit-autofill:active, SELECT:-webkit-autofill:hover, SELECT:-webkit-autofill:focus, SELECT:-webkit-autofill:active, TEXTAREA:-webkit-autofill:hover, TEXTAREA:-webkit-autofill:focus, TEXTAREA:-webkit-autofill:active) {
  box-shadow: inset 0 0 20px 20px var(--mdb-body-bg);
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  -webkit-box-shadow: 0 0 20px 20px var(--mdb-body-bg) inset !important;
}

:is(INPUT:autofill, SELECT:autofill, TEXTAREA:autofill), :is(INPUT:autofill:hover, INPUT:autofill:focus, INPUT:autofill:active, SELECT:autofill:hover, SELECT:autofill:focus, SELECT:autofill:active, TEXTAREA:autofill:hover, TEXTAREA:autofill:focus, TEXTAREA:autofill:active) {
  box-shadow: inset 0 0 20px 20px var(--mdb-body-bg);
  -webkit-background-clip: text;
  animation-name: onautofillstart;
  -webkit-box-shadow: 0 0 20px 20px var(--mdb-body-bg) inset !important;
}

:-webkit-any(INPUT:not(:-webkit-autofill), SELECT:not(:-webkit-autofill), TEXTAREA:not(:-webkit-autofill)) {
  animation-name: onautofillcancel;
}

:is(INPUT:not(:autofill), SELECT:not(:autofill), TEXTAREA:not(:autofill)) {
  animation-name: onautofillcancel;
}

@keyframes onautofillstart {
}

@keyframes onautofillcancel {
}

.was-validated .input-group .invalid-feedback, .was-validated .input-group .valid-feedback, .input-group .invalid-feedback, .input-group .valid-feedback {
  margin-top: 2.5rem;
}

.valid-feedback {
  color: #00ae00;
  width: auto;
  margin-top: -.75rem;
  font-size: .875rem;
  display: none;
  position: absolute;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #00ae00e6;
  max-width: 100%;
  margin-top: .1rem;
  padding: 6px 16px;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
  border-radius: .25rem !important;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none;
  border-color: #00ae00;
  margin-bottom: 1rem;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00ae00;
  box-shadow: 0 0 0 .25rem #00ae0040;
}

.was-validated .form-outline .form-control:valid ~ .form-label, .form-outline .form-control.is-valid ~ .form-label {
  color: #00ae00;
}

.was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid ~ .form-notch .form-notch-trailing {
  border-color: #00ae00;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #00ae00;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-leading {
  border-color: #00ae00;
  box-shadow: -1px 0 #00ae00, 0 1px #00ae00, 0 -1px #00ae00;
}

.was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-trailing {
  border-color: #00ae00;
  box-shadow: 1px 0 #00ae00, 0 -1px #00ae00, 0 1px #00ae00;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #00ae00, 0 1px #00ae00, 0 -1px #00ae00;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #00ae00;
}

.was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #00ae00, 0 -1px #00ae00, 0 1px #00ae00;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00ae00;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00ae00;
  box-shadow: 0 0 0 .25rem #00ae0040;
}

.was-validated .form-select:valid ~ .valid-feedback, .form-select.is-valid ~ .valid-feedback {
  margin-top: 0;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:valid .valid-feedback, input[type="file"].form-control.is-valid .valid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:valid:focus, input[type="file"].form-control.is-valid:focus {
  border-color: #00ae00;
  box-shadow: inset 0 0 0 1px #00ae00;
}

.was-validated input[type="file"].form-control:valid:focus ~ .form-file-label, input[type="file"].form-control.is-valid:focus ~ .form-file-label {
  box-shadow: none;
}

.was-validated input[type="file"].form-control:valid:focus-within ~ .form-file-label .form-file-text, .was-validated input[type="file"].form-control:valid:focus-within ~ .form-file-label .form-file-button, input[type="file"].form-control.is-valid:focus-within ~ .form-file-label .form-file-text, input[type="file"].form-control.is-valid:focus-within ~ .form-file-label .form-file-button, .was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00ae00;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00ae00;
}

.was-validated .form-check-input:valid:checked:focus:before, .form-check-input.is-valid:checked:focus:before {
  box-shadow: 0 0 0 13px #00ae00;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:valid:focus:before, .form-check-input.is-valid:focus:before {
  box-shadow: 0 0 0 13px #00ae00;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00ae00;
  margin-bottom: 1rem;
}

.was-validated .form-check-input:valid[type="checkbox"]:checked:focus, .form-check-input.is-valid[type="checkbox"]:checked:focus {
  background-color: #00ae00;
  border-color: #00ae00;
}

.was-validated .form-check-input:valid[type="radio"]:checked, .form-check-input.is-valid[type="radio"]:checked {
  background-color: #fff;
  border-color: #00ae00;
}

.was-validated .form-check-input:valid[type="radio"]:checked:focus:before, .form-check-input.is-valid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #00ae00;
}

.was-validated .form-check-input:valid[type="radio"]:checked:after, .form-check-input.is-valid[type="radio"]:checked:after {
  background-color: #00ae00;
  border-color: #00ae00;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .form-switch .form-check-input:valid:focus:before, .form-switch .form-check-input.is-valid:focus:before {
  box-shadow: 3px -1px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
}

.was-validated .form-switch .form-check-input:valid:checked[type="checkbox"]:after, .form-switch .form-check-input.is-valid:checked[type="checkbox"]:after {
  box-shadow: 0 3px 1px -2px rgba(var(--mdb-box-shadow-color-rgb), .2), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .14), 0 1px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  background-color: #00ae00;
}

.was-validated .form-switch .form-check-input:valid:checked:focus:before, .form-switch .form-check-input.is-valid:checked:focus:before {
  box-shadow: 3px -1px 0 13px #00ae00;
}

.invalid-feedback {
  color: #e3041c;
  width: auto;
  margin-top: -.75rem;
  font-size: .875rem;
  display: none;
  position: absolute;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #e3041ce6;
  max-width: 100%;
  margin-top: .1rem;
  padding: 6px 16px;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
  border-radius: .25rem !important;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
  border-color: #e3041c;
  margin-bottom: 1rem;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e3041c;
  box-shadow: 0 0 0 .25rem #e3041c40;
}

.was-validated .form-outline .form-control:invalid ~ .form-label, .form-outline .form-control.is-invalid ~ .form-label {
  color: #e3041c;
}

.was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-trailing {
  border-color: #e3041c;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid.active ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #e3041c;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-leading {
  border-color: #e3041c;
  box-shadow: -1px 0 #e3041c, 0 1px #e3041c, 0 -1px #e3041c;
}

.was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-trailing {
  border-color: #e3041c;
  box-shadow: 1px 0 #e3041c, 0 -1px #e3041c, 0 1px #e3041c;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #e3041c, 0 1px #e3041c, 0 -1px #e3041c;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #e3041c;
}

.was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #e3041c, 0 -1px #e3041c, 0 1px #e3041c;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #e3041c;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #e3041c;
  box-shadow: 0 0 0 .25rem #e3041c40;
}

.was-validated .form-select:invalid ~ .invalid-feedback, .form-select.is-invalid ~ .invalid-feedback {
  margin-top: 0;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:invalid .invalid-feedback, input[type="file"].form-control.is-invalid .invalid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:invalid:focus, input[type="file"].form-control.is-invalid:focus {
  border-color: #e3041c;
  box-shadow: inset 0 0 0 1px #e3041c;
}

.was-validated input[type="file"].form-control:invalid:focus ~ .form-file-label, input[type="file"].form-control.is-invalid:focus ~ .form-file-label {
  box-shadow: none;
}

.was-validated input[type="file"].form-control:invalid:focus-within ~ .form-file-label .form-file-text, .was-validated input[type="file"].form-control:invalid:focus-within ~ .form-file-label .form-file-button, input[type="file"].form-control.is-invalid:focus-within ~ .form-file-label .form-file-text, input[type="file"].form-control.is-invalid:focus-within ~ .form-file-label .form-file-button, .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #e3041c;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #e3041c;
}

.was-validated .form-check-input:invalid:checked:focus:before, .form-check-input.is-invalid:checked:focus:before {
  box-shadow: 0 0 0 13px #e3041c;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:invalid:focus:before, .form-check-input.is-invalid:focus:before {
  box-shadow: 0 0 0 13px #e3041c;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e3041c;
  margin-bottom: 1rem;
}

.was-validated .form-check-input:invalid[type="checkbox"]:checked:focus, .form-check-input.is-invalid[type="checkbox"]:checked:focus {
  background-color: #e3041c;
  border-color: #e3041c;
}

.was-validated .form-check-input:invalid[type="radio"]:checked, .form-check-input.is-invalid[type="radio"]:checked {
  background-color: #fff;
  border-color: #e3041c;
}

.was-validated .form-check-input:invalid[type="radio"]:checked:focus:before, .form-check-input.is-invalid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #e3041c;
}

.was-validated .form-check-input:invalid[type="radio"]:checked:after, .form-check-input.is-invalid[type="radio"]:checked:after {
  background-color: #e3041c;
  border-color: #e3041c;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .form-switch .form-check-input:invalid:focus:before, .form-switch .form-check-input.is-invalid:focus:before {
  box-shadow: 3px -1px 0px 13px rgba(var(--mdb-box-shadow-color-rgb), .6);
}

.was-validated .form-switch .form-check-input:invalid:checked[type="checkbox"]:after, .form-switch .form-check-input.is-invalid:checked[type="checkbox"]:after {
  box-shadow: 0 3px 1px -2px rgba(var(--mdb-box-shadow-color-rgb), .2), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), .14), 0 1px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  background-color: #e3041c;
}

.was-validated .form-switch .form-check-input:invalid:checked:focus:before, .form-switch .form-check-input.is-invalid:checked:focus:before {
  box-shadow: 3px -1px 0 13px #e3041c;
}

.form-range:focus {
  box-shadow: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: none;
}

.form-range:focus::-ms-thumb {
  box-shadow: none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  box-shadow: none;
  appearance: none;
  margin-top: -6px;
}

.form-range::-webkit-slider-runnable-track {
  height: 4px;
  box-shadow: none;
  background-color: var(--mdb-secondary-bg);
  border-radius: 0;
}

.form-range::-moz-range-thumb {
  box-shadow: none;
  appearance: none;
}

.form-range::-moz-range-track {
  box-shadow: none;
}

.table {
  --mdb-table-font-size: 1rem;
  --mdb-table-divider-color: currentcolor;
  font-size: var(--mdb-table-font-size);
}

.table th {
  font-weight: 500;
}

.table tbody {
  font-weight: 400;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

.table-color1 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffd5cc;
  --mdb-table-border-color: #ccaaa3;
  --mdb-table-striped-bg: #f2cac2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6bfb8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecc5bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color2 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffe4cc;
  --mdb-table-border-color: #ccb6a3;
  --mdb-table-striped-bg: #f2d9c2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6cdb8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecd3bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color3 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffc;
  --mdb-table-border-color: #cccca3;
  --mdb-table-striped-bg: #f2f2c2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6e6b8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ececbd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color4 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccfacc;
  --mdb-table-border-color: #a3c8a3;
  --mdb-table-striped-bg: #c2edc2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8e1b8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bde7bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color5 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccebe5;
  --mdb-table-border-color: #a3bcb8;
  --mdb-table-striped-bg: #c2dfda;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8d3ce;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bdd9d4;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color6 {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccfafa;
  --mdb-table-border-color: #a3c8c8;
  --mdb-table-striped-bg: #c2eeee;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8e1e1;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bde7e7;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color7 {
  --mdb-table-color: #000;
  --mdb-table-bg: #cce6ff;
  --mdb-table-border-color: #a3b8cc;
  --mdb-table-striped-bg: #c2daf2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8cfe6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bdd4ec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color8 {
  --mdb-table-color: #000;
  --mdb-table-bg: #dcf;
  --mdb-table-border-color: #b1a3cc;
  --mdb-table-striped-bg: #d2c2f2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #c7b8e6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ccbdec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color9 {
  --mdb-table-color: #000;
  --mdb-table-bg: #e7ccf5;
  --mdb-table-border-color: #b9a3c4;
  --mdb-table-striped-bg: #dcc2e9;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #d0b8dc;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #d6bde2;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-color10 {
  --mdb-table-color: #000;
  --mdb-table-bg: #fcf;
  --mdb-table-border-color: #cca3cc;
  --mdb-table-striped-bg: #f2c2f2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6b8e6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecbdec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-red {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffd5cc;
  --mdb-table-border-color: #ccaaa3;
  --mdb-table-striped-bg: #f2cac2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6bfb8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecc5bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-orange {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffe4cc;
  --mdb-table-border-color: #ccb6a3;
  --mdb-table-striped-bg: #f2d9c2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6cdb8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecd3bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-yellow {
  --mdb-table-color: #000;
  --mdb-table-bg: #ffc;
  --mdb-table-border-color: #cccca3;
  --mdb-table-striped-bg: #f2f2c2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6e6b8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ececbd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-green {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccfacc;
  --mdb-table-border-color: #a3c8a3;
  --mdb-table-striped-bg: #c2edc2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8e1b8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bde7bd;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-teal {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccebe5;
  --mdb-table-border-color: #a3bcb8;
  --mdb-table-striped-bg: #c2dfda;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8d3ce;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bdd9d4;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-cyan {
  --mdb-table-color: #000;
  --mdb-table-bg: #ccfafa;
  --mdb-table-border-color: #a3c8c8;
  --mdb-table-striped-bg: #c2eeee;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8e1e1;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bde7e7;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-blue {
  --mdb-table-color: #000;
  --mdb-table-bg: #cce6ff;
  --mdb-table-border-color: #a3b8cc;
  --mdb-table-striped-bg: #c2daf2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #b8cfe6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #bdd4ec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-indigo {
  --mdb-table-color: #000;
  --mdb-table-bg: #dcf;
  --mdb-table-border-color: #b1a3cc;
  --mdb-table-striped-bg: #d2c2f2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #c7b8e6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ccbdec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-purple {
  --mdb-table-color: #000;
  --mdb-table-bg: #e7ccf5;
  --mdb-table-border-color: #b9a3c4;
  --mdb-table-striped-bg: #dcc2e9;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #d0b8dc;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #d6bde2;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-pink {
  --mdb-table-color: #000;
  --mdb-table-bg: #fcf;
  --mdb-table-border-color: #cca3cc;
  --mdb-table-striped-bg: #f2c2f2;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e6b8e6;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ecbdec;
  --mdb-table-hover-color: #000;
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-hover > tbody > tr {
  transition: all .5s;
}

.table-hover > tbody > tr:hover {
  --mdb-table-accent-bg: transparent;
  background-color: var(--mdb-table-hover-bg);
}

.table-group-divider {
  border-top: calc(2 * var(--mdb-border-width)) solid;
  border-top-color: inherit;
}

.table-divider-color {
  border-top-color: var(--mdb-table-divider-color);
}

.btn {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5rem;
  --mdb-btn-border-width: 0;
  --mdb-btn-border-color: none;
  --mdb-btn-border-radius: .25rem;
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-active-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  padding-top: var(--mdb-btn-padding-top);
  padding-bottom: var(--mdb-btn-padding-bottom);
  text-transform: uppercase;
  vertical-align: bottom;
  border-radius: var(--mdb-btn-border-radius);
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

:not(.btn-check) + .btn:hover, .btn:first-child:hover, .btn:focus-visible, .btn:hover {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-check:focus-visible + .btn, .btn-check:focus + .btn, .btn:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  box-shadow: var(--mdb-btn-box-shadow);
}

[class*="btn-outline-"] {
  --mdb-btn-padding-top: .5rem;
  --mdb-btn-padding-bottom: .375rem;
  --mdb-btn-padding-x: 1.375rem;
  --mdb-btn-border-width: 2px;
  --mdb-btn-line-height: 1.5;
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  border-width: var(--mdb-btn-border-width);
  box-shadow: none;
  border-style: solid;
}

:not(.btn-check) + [class*="btn-outline-"]:hover, [class*="btn-outline-"]:first-child:hover, [class*="btn-outline-"]:focus-visible, [class*="btn-outline-"]:hover, .btn-check:focus-visible + [class*="btn-outline-"], .btn-check:focus + [class*="btn-outline-"], [class*="btn-outline-"]:focus, .btn-check:checked + [class*="btn-outline-"], .btn-check:active + [class*="btn-outline-"], [class*="btn-outline-"]:active, [class*="btn-outline-"].active, [class*="btn-outline-"].show, .btn-check:checked + [class*="btn-outline-"]:focus, .btn-check:active + [class*="btn-outline-"]:focus, [class*="btn-outline-"]:active:focus, [class*="btn-outline-"].active:focus, [class*="btn-outline-"].show:focus, [class*="btn-outline-"]:disabled, [class*="btn-outline-"].disabled, fieldset:disabled [class*="btn-outline-"] {
  box-shadow: none;
}

[class*="btn-outline-"].btn-lg, .btn-group-lg > [class*="btn-outline-"].btn {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5625rem;
  --mdb-btn-padding-x: 1.5625rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

[class*="btn-outline-"].btn-sm, .btn-group-sm > [class*="btn-outline-"].btn {
  --mdb-btn-padding-top: .25rem;
  --mdb-btn-padding-bottom: .1875rem;
  --mdb-btn-padding-x: .875rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-secondary, .btn-check:focus-visible + .btn-secondary, .btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show, .btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .btn-secondary.show:focus, .btn-secondary:disabled, .btn-secondary.disabled, fieldset:disabled .btn-secondary {
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-bg: #e3041c;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #d8041b;
  --mdb-btn-hover-bg: #d8041b;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #d8041b;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #cc0419;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #d8041b4d, 0 4px 18px 0 #d8041b33;
}

:not(.btn-check) + .btn-primary:hover, .btn-primary:first-child:hover, .btn-primary:focus-visible, .btn-primary:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-primary, .btn-check:focus + .btn-primary, .btn-primary:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show, .btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .btn-primary.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-primary:hover, .btn-check:active + .btn-primary:hover, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-primary:disabled, .btn-primary.disabled, fieldset:disabled .btn-primary {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-primary {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-primary:hover, [data-mdb-theme="dark"] .btn-primary:active, [data-mdb-theme="dark"] .btn-primary:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-secondary {
  --mdb-btn-bg: #fbd9dd;
  --mdb-btn-color: #9f0314;
  --mdb-btn-box-shadow: 0 4px 9px -4px #fbdbdf;
  --mdb-btn-hover-bg: #eeced2;
  --mdb-btn-hover-color: #9f0314;
  --mdb-btn-focus-bg: #eeced2;
  --mdb-btn-focus-color: #9f0314;
  --mdb-btn-active-bg: #eeced2;
  --mdb-btn-active-color: #9f0314;
  --mdb-btn-box-shadow-state: transparent;
}

:not(.btn-check) + .btn-secondary:hover, .btn-secondary:first-child:hover, .btn-secondary:focus-visible, .btn-secondary:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-secondary, .btn-check:focus + .btn-secondary, .btn-secondary:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show, .btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .btn-secondary.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-secondary:hover, .btn-check:active + .btn-secondary:hover, .btn-secondary:active:hover, .btn-secondary.active:hover, .btn-secondary.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-secondary:disabled, .btn-secondary.disabled, fieldset:disabled .btn-secondary {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-success {
  --mdb-btn-bg: #00ae00;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #00a500;
  --mdb-btn-hover-bg: #00a500;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #00a500;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #009d00;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #00a5004d, 0 4px 18px 0 #00a50033;
}

:not(.btn-check) + .btn-success:hover, .btn-success:first-child:hover, .btn-success:focus-visible, .btn-success:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-success, .btn-check:focus + .btn-success, .btn-success:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .btn-success.show, .btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .btn-success.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-success:hover, .btn-check:active + .btn-success:hover, .btn-success:active:hover, .btn-success.active:hover, .btn-success.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-success:disabled, .btn-success.disabled, fieldset:disabled .btn-success {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-success {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-success:hover, [data-mdb-theme="dark"] .btn-success:active, [data-mdb-theme="dark"] .btn-success:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-danger {
  --mdb-btn-bg: #e3041c;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #d8041b;
  --mdb-btn-hover-bg: #d8041b;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #d8041b;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #cc0419;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #d8041b4d, 0 4px 18px 0 #d8041b33;
}

:not(.btn-check) + .btn-danger:hover, .btn-danger:first-child:hover, .btn-danger:focus-visible, .btn-danger:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-danger, .btn-check:focus + .btn-danger, .btn-danger:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .btn-danger.show, .btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .btn-danger.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-danger:hover, .btn-check:active + .btn-danger:hover, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-danger:disabled, .btn-danger.disabled, fieldset:disabled .btn-danger {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-danger {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-danger:hover, [data-mdb-theme="dark"] .btn-danger:active, [data-mdb-theme="dark"] .btn-danger:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-warning {
  --mdb-btn-bg: #ffc107;
  --mdb-btn-color: #000;
  --mdb-btn-box-shadow: 0 4px 9px -4px #ffc413;
  --mdb-btn-hover-bg: #ffc413;
  --mdb-btn-hover-color: #000;
  --mdb-btn-focus-bg: #ffc413;
  --mdb-btn-focus-color: #000;
  --mdb-btn-active-bg: #ffc720;
  --mdb-btn-active-color: #000;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #f2b7074d, 0 4px 18px 0 #f2b70733;
}

:not(.btn-check) + .btn-warning:hover, .btn-warning:first-child:hover, .btn-warning:focus-visible, .btn-warning:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-warning, .btn-check:focus + .btn-warning, .btn-warning:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .btn-warning.show, .btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .btn-warning.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-warning:hover, .btn-check:active + .btn-warning:hover, .btn-warning:active:hover, .btn-warning.active:hover, .btn-warning.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-warning:disabled, .btn-warning.disabled, fieldset:disabled .btn-warning {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-warning {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-warning:hover, [data-mdb-theme="dark"] .btn-warning:active, [data-mdb-theme="dark"] .btn-warning:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-info {
  --mdb-btn-bg: #00abf0;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #00a2e4;
  --mdb-btn-hover-bg: #00a2e4;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #00a2e4;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #009ad8;
  --mdb-btn-active-color: #fff;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #00a2e44d, 0 4px 18px 0 #00a2e433;
}

:not(.btn-check) + .btn-info:hover, .btn-info:first-child:hover, .btn-info:focus-visible, .btn-info:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-info, .btn-check:focus + .btn-info, .btn-info:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .btn-info.show, .btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .btn-info.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-info:hover, .btn-check:active + .btn-info:hover, .btn-info:active:hover, .btn-info.active:hover, .btn-info.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-info:disabled, .btn-info.disabled, fieldset:disabled .btn-info {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-info {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-info:hover, [data-mdb-theme="dark"] .btn-info:active, [data-mdb-theme="dark"] .btn-info:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-light {
  --mdb-btn-bg: #d2d8d9;
  --mdb-btn-color: #132328;
  --mdb-btn-box-shadow: 0 4px 9px -4px #d4dadb;
  --mdb-btn-hover-bg: #c8cdce;
  --mdb-btn-hover-color: #132328;
  --mdb-btn-focus-bg: #d4dadb;
  --mdb-btn-focus-color: #132328;
  --mdb-btn-active-bg: #bdc2c3;
  --mdb-btn-active-color: #132328;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #c8cdce4d, 0 4px 18px 0 #c8cdce33;
}

:not(.btn-check) + .btn-light:hover, .btn-light:first-child:hover, .btn-light:focus-visible, .btn-light:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-light, .btn-check:focus + .btn-light, .btn-light:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .btn-light.show, .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .btn-light.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-light:hover, .btn-check:active + .btn-light:hover, .btn-light:active:hover, .btn-light.active:hover, .btn-light.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-light:disabled, .btn-light.disabled, fieldset:disabled .btn-light {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-light {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-light:hover, [data-mdb-theme="dark"] .btn-light:active, [data-mdb-theme="dark"] .btn-light:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-dark {
  --mdb-btn-bg: #060c0d;
  --mdb-btn-color: #a6b0b3;
  --mdb-btn-box-shadow: 0 4px 9px -4px #121819;
  --mdb-btn-hover-bg: #121819;
  --mdb-btn-hover-color: #a6b0b3;
  --mdb-btn-focus-bg: #121819;
  --mdb-btn-focus-color: #a6b0b3;
  --mdb-btn-active-bg: #1f2425;
  --mdb-btn-active-color: #a6b0b3;
  --mdb-btn-box-shadow-state: 0 8px 9px -4px #060b0c4d, 0 4px 18px 0 #060b0c33;
}

:not(.btn-check) + .btn-dark:hover, .btn-dark:first-child:hover, .btn-dark:focus-visible, .btn-dark:hover {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:focus-visible + .btn-dark, .btn-check:focus + .btn-dark, .btn-dark:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
  background-color: var(--mdb-btn-focus-bg);
}

.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .btn-dark.show, .btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .btn-dark.show:focus {
  box-shadow: var(--mdb-btn-box-shadow-state);
}

.btn-check:checked + .btn-dark:hover, .btn-check:active + .btn-dark:hover, .btn-dark:active:hover, .btn-dark.active:hover, .btn-dark.show:hover {
  background-color: var(--mdb-btn-active-bg);
}

.btn-dark:disabled, .btn-dark.disabled, fieldset:disabled .btn-dark {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-dark {
  box-shadow: 0 4px 9px -4px #00000059;
}

[data-mdb-theme="dark"] .btn-dark:hover, [data-mdb-theme="dark"] .btn-dark:active, [data-mdb-theme="dark"] .btn-dark:focus {
  box-shadow: 0 4px 18px -2px #000000b3;
}

.btn-outline-primary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #e3041c;
  --mdb-btn-hover-bg: #fef2f4;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-focus-bg: #fef2f4;
  --mdb-btn-focus-color: #d8041b;
  --mdb-btn-active-bg: #fef2f4;
  --mdb-btn-active-color: #cc0419;
  --mdb-btn-outline-border-color: #e3041c;
  --mdb-btn-outline-focus-border-color: #b60316;
  --mdb-btn-outline-hover-border-color: #b60316;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-primary:hover, .btn-outline-primary:first-child:hover, .btn-outline-primary:focus-visible, .btn-outline-primary:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-primary, .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled, fieldset:disabled .btn-outline-primary {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-primary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #e93649;
  --mdb-btn-hover-bg: #440108;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-focus-bg: #440108;
  --mdb-btn-focus-color: #d8041b;
  --mdb-btn-active-bg: #440108;
  --mdb-btn-active-color: #cc0419;
}

.btn-outline-secondary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #9f0314;
  --mdb-btn-hover-bg: #faf2f3;
  --mdb-btn-hover-color: #9f0314;
  --mdb-btn-focus-bg: #faf2f3;
  --mdb-btn-focus-color: #9f0314;
  --mdb-btn-active-bg: #faf2f3;
  --mdb-btn-active-color: #9f0314;
  --mdb-btn-outline-border-color: #fbd9dd;
  --mdb-btn-outline-focus-border-color: #eeced2;
  --mdb-btn-outline-hover-border-color: #eeced2;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-secondary:hover, .btn-outline-secondary:first-child:hover, .btn-outline-secondary:focus-visible, .btn-outline-secondary:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-secondary, .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled, fieldset:disabled .btn-outline-secondary {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-secondary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #f7b4bb;
  --mdb-btn-hover-bg: #5b020b;
  --mdb-btn-hover-color: #f49ba4;
  --mdb-btn-focus-bg: #5b020b;
  --mdb-btn-focus-color: #f49ba4;
  --mdb-btn-active-bg: #5b020b;
  --mdb-btn-active-color: #f49ba4;
  --mdb-btn-outline-border-color: #f1828e;
  --mdb-btn-outline-focus-border-color: #e57b86;
  --mdb-btn-outline-hover-border-color: #e57b86;
  border-color: var(--mdb-btn-outline-border-color);
}

.btn-outline-success {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #00ae00;
  --mdb-btn-hover-bg: #f2fbf2;
  --mdb-btn-hover-color: #00a500;
  --mdb-btn-focus-bg: #f2fbf2;
  --mdb-btn-focus-color: #00a500;
  --mdb-btn-active-bg: #f2fbf2;
  --mdb-btn-active-color: #009d00;
  --mdb-btn-outline-border-color: #00ae00;
  --mdb-btn-outline-focus-border-color: #008b00;
  --mdb-btn-outline-hover-border-color: #008b00;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-success:hover, .btn-outline-success:first-child:hover, .btn-outline-success:focus-visible, .btn-outline-success:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-success, .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-success:disabled, .btn-outline-success.disabled, fieldset:disabled .btn-outline-success {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-success {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #33be33;
  --mdb-btn-hover-bg: #003400;
  --mdb-btn-hover-color: #00a500;
  --mdb-btn-focus-bg: #003400;
  --mdb-btn-focus-color: #00a500;
  --mdb-btn-active-bg: #003400;
  --mdb-btn-active-color: #009d00;
}

.btn-outline-danger {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #e3041c;
  --mdb-btn-hover-bg: #fef2f4;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-focus-bg: #fef2f4;
  --mdb-btn-focus-color: #d8041b;
  --mdb-btn-active-bg: #fef2f4;
  --mdb-btn-active-color: #cc0419;
  --mdb-btn-outline-border-color: #e3041c;
  --mdb-btn-outline-focus-border-color: #b60316;
  --mdb-btn-outline-hover-border-color: #b60316;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-danger:hover, .btn-outline-danger:first-child:hover, .btn-outline-danger:focus-visible, .btn-outline-danger:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-danger, .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled, fieldset:disabled .btn-outline-danger {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-danger {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #e93649;
  --mdb-btn-hover-bg: #440108;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-focus-bg: #440108;
  --mdb-btn-focus-color: #d8041b;
  --mdb-btn-active-bg: #440108;
  --mdb-btn-active-color: #cc0419;
}

.btn-outline-warning {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #ffc107;
  --mdb-btn-hover-bg: #fffcf3;
  --mdb-btn-hover-color: #f2b707;
  --mdb-btn-focus-bg: #fffcf3;
  --mdb-btn-focus-color: #f2b707;
  --mdb-btn-active-bg: #fffcf3;
  --mdb-btn-active-color: #e6ae06;
  --mdb-btn-outline-border-color: #ffc107;
  --mdb-btn-outline-focus-border-color: #cc9a06;
  --mdb-btn-outline-hover-border-color: #cc9a06;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-warning:hover, .btn-outline-warning:first-child:hover, .btn-outline-warning:focus-visible, .btn-outline-warning:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-warning, .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled, fieldset:disabled .btn-outline-warning {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-warning {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #ffcd39;
  --mdb-btn-hover-bg: #4d3a02;
  --mdb-btn-hover-color: #f2b707;
  --mdb-btn-focus-bg: #4d3a02;
  --mdb-btn-focus-color: #f2b707;
  --mdb-btn-active-bg: #4d3a02;
  --mdb-btn-active-color: #e6ae06;
}

.btn-outline-info {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #00abf0;
  --mdb-btn-hover-bg: #f2fbfe;
  --mdb-btn-hover-color: #00a2e4;
  --mdb-btn-focus-bg: #f2fbfe;
  --mdb-btn-focus-color: #00a2e4;
  --mdb-btn-active-bg: #f2fbfe;
  --mdb-btn-active-color: #009ad8;
  --mdb-btn-outline-border-color: #00abf0;
  --mdb-btn-outline-focus-border-color: #0089c0;
  --mdb-btn-outline-hover-border-color: #0089c0;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-info:hover, .btn-outline-info:first-child:hover, .btn-outline-info:focus-visible, .btn-outline-info:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-info, .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-info:disabled, .btn-outline-info.disabled, fieldset:disabled .btn-outline-info {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-info {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #33bcf3;
  --mdb-btn-hover-bg: #003348;
  --mdb-btn-hover-color: #00a2e4;
  --mdb-btn-focus-bg: #003348;
  --mdb-btn-focus-color: #00a2e4;
  --mdb-btn-active-bg: #003348;
  --mdb-btn-active-color: #009ad8;
}

.btn-outline-light {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #d2d8d9;
  --mdb-btn-hover-bg: #fdfdfd;
  --mdb-btn-hover-color: #c8cdce;
  --mdb-btn-focus-bg: #fdfdfd;
  --mdb-btn-focus-color: #c8cdce;
  --mdb-btn-active-bg: #fdfdfd;
  --mdb-btn-active-color: #bdc2c3;
  --mdb-btn-outline-border-color: #d2d8d9;
  --mdb-btn-outline-focus-border-color: #a8adae;
  --mdb-btn-outline-hover-border-color: #a8adae;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-light:hover, .btn-outline-light:first-child:hover, .btn-outline-light:focus-visible, .btn-outline-light:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-light, .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-light:disabled, .btn-outline-light.disabled, fieldset:disabled .btn-outline-light {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-light {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #dbe0e1;
  --mdb-btn-hover-bg: #3f4141;
  --mdb-btn-hover-color: #c8cdce;
  --mdb-btn-focus-bg: #3f4141;
  --mdb-btn-focus-color: #c8cdce;
  --mdb-btn-active-bg: #3f4141;
  --mdb-btn-active-color: #bdc2c3;
}

.btn-outline-dark {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #060c0d;
  --mdb-btn-hover-bg: #f3f3f3;
  --mdb-btn-hover-color: #060b0c;
  --mdb-btn-focus-bg: #f3f3f3;
  --mdb-btn-focus-color: #060b0c;
  --mdb-btn-active-bg: #f3f3f3;
  --mdb-btn-active-color: #050b0c;
  --mdb-btn-outline-border-color: #060c0d;
  --mdb-btn-outline-focus-border-color: #050a0a;
  --mdb-btn-outline-hover-border-color: #050a0a;
  border-color: var(--mdb-btn-outline-border-color);
}

:not(.btn-check) + .btn-outline-dark:hover, .btn-outline-dark:first-child:hover, .btn-outline-dark:focus-visible, .btn-outline-dark:hover {
  border-color: var(--mdb-btn-outline-hover-border-color);
}

.btn-check:focus-visible + .btn-outline-dark, .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.show {
  border-color: var(--mdb-btn-outline-active-border-color);
}

.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.show:focus {
  border-color: var(--mdb-btn-outline-focus-border-color);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled, fieldset:disabled .btn-outline-dark {
  border-color: var(--mdb-btn-outline-border-color);
}

[data-mdb-theme="dark"] .btn-outline-dark {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #383d3d;
  --mdb-btn-hover-bg: #020404;
  --mdb-btn-hover-color: #060b0c;
  --mdb-btn-focus-bg: #020404;
  --mdb-btn-focus-color: #060b0c;
  --mdb-btn-active-bg: #020404;
  --mdb-btn-active-color: #050b0c;
}

.btn-link {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #e3041c;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-hover-bg: #f5f5f5;
  --mdb-btn-focus-color: #cc0419;
  --mdb-btn-active-color: #c10318;
  --mdb-btn-disabled-color: #203a42;
  --mdb-btn-box-shadow: none;
  box-shadow: var(--mdb-btn-box-shadow);
}

:not(.btn-check) + .btn-link:hover, .btn-link:first-child:hover, .btn-link:focus-visible, .btn-link:hover {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: underline;
}

.btn-check:focus-visible + .btn-link, .btn-check:focus + .btn-link, .btn-link:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-link, .btn-check:active + .btn-link, .btn-link:active, .btn-link.active, .btn-link.show {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-link:focus, .btn-check:active + .btn-link:focus, .btn-link:active:focus, .btn-link.active:focus, .btn-link.show:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-link:disabled, .btn-link.disabled, fieldset:disabled .btn-link {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #e3041c;
  --mdb-btn-hover-color: #d8041b;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-focus-color: #cc0419;
  --mdb-btn-active-color: #c10318;
  --mdb-btn-disabled-color: #203a42;
  --mdb-btn-box-shadow: none;
  box-shadow: var(--mdb-btn-box-shadow);
  padding-left: 0;
  padding-right: 0;
}

:not(.btn-check) + .btn-tertiary:hover, .btn-tertiary:first-child:hover, .btn-tertiary:focus-visible, .btn-tertiary:hover {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: underline;
}

.btn-check:focus-visible + .btn-tertiary, .btn-check:focus + .btn-tertiary, .btn-tertiary:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-tertiary, .btn-check:active + .btn-tertiary, .btn-tertiary:active, .btn-tertiary.active, .btn-tertiary.show {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:checked + .btn-tertiary:focus, .btn-check:active + .btn-tertiary:focus, .btn-tertiary:active:focus, .btn-tertiary.active:focus, .btn-tertiary.show:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary:disabled, .btn-tertiary.disabled, fieldset:disabled .btn-tertiary {
  box-shadow: var(--mdb-btn-box-shadow);
}

[data-mdb-theme="dark"] .btn-secondary {
  --mdb-btn-bg: #f49ba4;
  --mdb-btn-hover-bg: #f1828e;
  --mdb-btn-focus-bg: #f1828e;
  --mdb-btn-active-bg: #f1828e;
}

[data-mdb-theme="dark"] .btn-link {
  --mdb-btn-color: #203a42;
  --mdb-btn-hover-color: #63757b;
  --mdb-btn-hover-bg: #0d171a;
  --mdb-btn-focus-color: #63757b;
  --mdb-btn-active-color: #63757b;
}

[data-mdb-theme="dark"] .btn-tertiary {
  --mdb-btn-color: #203a42;
  --mdb-btn-hover-color: #63757b;
  --mdb-btn-focus-color: #63757b;
  --mdb-btn-active-color: #63757b;
}

.btn-lg, .btn-group-lg > .btn {
  --mdb-btn-padding-top: .75rem;
  --mdb-btn-padding-bottom: .6875rem;
  --mdb-btn-padding-x: 1.6875rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

.btn-sm, .btn-group-sm > .btn {
  --mdb-btn-padding-top: .375rem;
  --mdb-btn-padding-bottom: .3125rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-rounded {
  --mdb-btn-border-radius: 10rem;
  border-radius: var(--mdb-btn-border-radius);
}

.btn-floating, [class*="btn-outline-"].btn-floating {
  --mdb-btn-border-radius: 50%;
  border-radius: var(--mdb-btn-border-radius);
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
  position: relative;
}

.btn-floating {
  --mdb-btn-width: 2.3125rem;
  --mdb-btn-height: 2.3125rem;
  --mdb-btn-icon-width: 2.3125rem;
  --mdb-btn-icon-line-height: 2.3125rem;
  --mdb-btn-width-lg: 2.8125rem;
  --mdb-btn-height-lg: 2.8125rem;
  --mdb-btn-icon-width-lg: 2.8125rem;
  --mdb-btn-icon-line-height-lg: 2.8125rem;
  --mdb-btn-width-sm: 1.8125rem;
  --mdb-btn-height-sm: 1.8125rem;
  --mdb-btn-icon-width-sm: 1.8125rem;
  --mdb-btn-icon-line-height-sm: 1.8125rem;
  width: var(--mdb-btn-width);
  height: var(--mdb-btn-height);
}

.btn-floating .fas, .btn-floating .far, .btn-floating .fab {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

.btn-floating.btn-lg, .btn-group-lg > .btn-floating.btn {
  width: var(--mdb-btn-width-lg);
  height: var(--mdb-btn-height-lg);
}

.btn-floating.btn-lg .fas, .btn-group-lg > .btn-floating.btn .fas, .btn-floating.btn-lg .far, .btn-group-lg > .btn-floating.btn .far, .btn-floating.btn-lg .fab, .btn-group-lg > .btn-floating.btn .fab {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

.btn-floating.btn-sm, .btn-group-sm > .btn-floating.btn {
  width: var(--mdb-btn-width-sm);
  height: var(--mdb-btn-height-sm);
}

.btn-floating.btn-sm .fas, .btn-group-sm > .btn-floating.btn .fas, .btn-floating.btn-sm .far, .btn-group-sm > .btn-floating.btn .far, .btn-floating.btn-sm .fab, .btn-group-sm > .btn-floating.btn .fab {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

[class*="btn-outline-"].btn-floating {
  --mdb-btn-icon-width: 2.0625rem;
  --mdb-btn-icon-width-lg: 2.5625rem;
  --mdb-btn-icon-width-sm: 1.5625rem;
  --mdb-btn-icon-line-height: 2.0625rem;
  --mdb-btn-icon-line-height-lg: 2.5625rem;
  --mdb-btn-icon-line-height-sm: 1.5625rem;
}

[class*="btn-outline-"].btn-floating .fas, [class*="btn-outline-"].btn-floating .far, [class*="btn-outline-"].btn-floating .fab {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

[class*="btn-outline-"].btn-floating.btn-lg .fas, .btn-group-lg > [class*="btn-outline-"].btn-floating.btn .fas, [class*="btn-outline-"].btn-floating.btn-lg .far, .btn-group-lg > [class*="btn-outline-"].btn-floating.btn .far, [class*="btn-outline-"].btn-floating.btn-lg .fab, .btn-group-lg > [class*="btn-outline-"].btn-floating.btn .fab {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

[class*="btn-outline-"].btn-floating.btn-sm .fas, .btn-group-sm > [class*="btn-outline-"].btn-floating.btn .fas, [class*="btn-outline-"].btn-floating.btn-sm .far, .btn-group-sm > [class*="btn-outline-"].btn-floating.btn .far, [class*="btn-outline-"].btn-floating.btn-sm .fab, .btn-group-sm > [class*="btn-outline-"].btn-floating.btn .fab {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

.fixed-action-btn {
  --mdb-btn-right: 2.1875rem;
  --mdb-btn-bottom: 2.1875rem;
  --mdb-btn-zindex: 1030;
  --mdb-btn-padding-top: .9375rem;
  --mdb-btn-padding-bottom: 1.25rem;
  --mdb-btn-padding-x: 1.25rem;
  --mdb-btn-margin-bottom: 1.5rem;
  right: var(--mdb-btn-right);
  bottom: var(--mdb-btn-bottom);
  z-index: var(--mdb-btn-zindex);
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  flex-flow: column-reverse;
  align-items: center;
  height: auto;
  margin-bottom: 0;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.fixed-action-btn > .btn-floating {
  z-index: 10;
  position: relative;
  transform: scale(1.2);
}

.fixed-action-btn ul {
  text-align: center;
  opacity: 0;
  z-index: -1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  transition: transform .4s, opacity .4s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.fixed-action-btn ul li {
  z-index: 0;
  margin-right: auto;
  margin-bottom: var(--mdb-btn-margin-bottom);
  margin-left: auto;
  display: flex;
}

.fixed-action-btn ul li:first-of-type {
  margin-top: calc(var(--mdb-btn-margin-bottom) * .5);
}

.fixed-action-btn ul a.btn {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.fixed-action-btn ul a.btn.shown, .fixed-action-btn.active ul {
  opacity: 1;
}

.btn-block {
  --mdb-btn-margin-top: .5rem;
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: var(--mdb-btn-margin-top);
}

.btn {
  text-transform: none;
}

hr.divider-horizontal:not([size]) {
  height: 2px;
}

.divider-horizontal {
  opacity: 1;
  background-color: #f5f5f5;
  height: 2px;
}

.divider-vertical {
  opacity: 1;
  background-color: #f5f5f5;
  width: 2px;
  margin: 0 1rem;
  display: inline-block;
}

hr.divider-horizontal-blurry {
  background-color: #0000;
  background-image: linear-gradient(90deg, #0000, #666, #0000);
}

hr.divider-vertical-blurry {
  background-color: #0000;
  background-image: linear-gradient(#0000, #666, #0000);
  width: 1px;
  top: 0;
  right: 0;
}

.dropdown-menu {
  --mdb-dropdown-item-border-radius: .5rem;
  color: var(--mdb-dropdown-color);
  box-shadow: var(--mdb-dropdown-box-shadow);
  font-size: var(--mdb-dropdown-font-size);
  margin: 0;
  margin-top: var(--mdb-dropdown-spacer);
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  top: 100%;
  left: 0;
}

.dropdown-menu > li {
  border-radius: 0;
}

.dropdown-menu > li:first-child, .dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--mdb-dropdown-item-border-radius);
  border-top-right-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-menu > li:not(:first-child):not(:last-child) .dropdown-item {
  border-radius: 0;
}

.dropdown-menu > li:last-child, .dropdown-menu > li:last-child .dropdown-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: var(--mdb-dropdown-item-border-radius);
}

.dropdown-menu.animation {
  --mdb-dropdown-menu-animated-animation-duration: .55s;
  --mdb-dropdown-menu-animated-animation-timing-function: ease;
  animation-duration: var(--mdb-dropdown-menu-animated-animation-duration);
  animation-timing-function: var(--mdb-dropdown-menu-animated-animation-timing-function);
  display: block;
}

.dropdown-item {
  --mdb-dropdown-state-color: var(--mdb-surface-color);
  --mdb-dropdown-state-background-color: #eee;
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  color: var(--mdb-dropdown-color);
  border-radius: 0;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active, .dropdown-item:active {
  color: var(--mdb-dropdown-state-color);
  background-color: var(--mdb-dropdown-state-background-color);
}

.dropdown-item:focus {
  outline: none;
}

.hidden-arrow.dropdown-toggle:after {
  display: none;
}

.animation {
  padding: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@media (prefers-reduced-motion) {
  .animation {
    animation: unset !important;
    transition: none !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
}

.dropdown-divider {
  --mdb-dropdown-divider-border-top-width: 2px;
  --mdb-dropdown-divider-border-top-bg: var(--mdb-divider-color);
  border-top: var(--mdb-dropdown-divider-border-top-width) solid var(--mdb-dropdown-divider-border-top-bg);
  opacity: 1;
}

:-webkit-any(.dropdown-menu INPUT:not(:-webkit-autofill), .dropdown-menu SELECT:not(:-webkit-autofill), .dropdown-menu TEXTAREA:not(:-webkit-autofill)) {
  animation-name: none !important;
}

:is(.dropdown-menu INPUT:not(:autofill), .dropdown-menu SELECT:not(:autofill), .dropdown-menu TEXTAREA:not(:autofill)) {
  animation-name: none !important;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  top: -7px;
  left: 100%;
}

.dropdown-menu .dropdown-submenu-left {
  left: auto;
  right: 100%;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.btn-group, .btn-group-vertical {
  --mdb-btn-box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-active-box-shadow: 0 8px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .15), 0 4px 18px 0 rgba(var(--mdb-box-shadow-color-rgb), .1);
  --mdb-btn-group-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: var(--mdb-btn-box-shadow);
  transition: var(--mdb-btn-group-transition);
}

.btn-group:hover, .btn-group-vertical:hover {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-group:focus, .btn-group.focus, .btn-group-vertical:focus, .btn-group-vertical.focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group:active, .btn-group.active, .btn-group-vertical:active, .btn-group-vertical.active {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-group:active:focus, .btn-group.active:focus, .btn-group-vertical:active:focus, .btn-group-vertical.active:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group:disabled, .btn-group.disabled, fieldset:disabled .btn-group, .btn-group-vertical:disabled, .btn-group-vertical.disabled, fieldset:disabled .btn-group-vertical {
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  box-shadow: none;
}

:not(.btn-check) + .btn-group > .btn:hover, .btn-group > .btn:first-child:hover, .btn-group > .btn:focus-visible, .btn-group > .btn:hover, :not(.btn-check) + .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:first-child:hover, .btn-group-vertical > .btn:focus-visible, .btn-group-vertical > .btn:hover {
  box-shadow: none !important;
}

.btn-check:focus-visible + .btn-group > .btn, .btn-check:focus + .btn-group > .btn, .btn-group > .btn:focus, .btn-check:focus-visible + .btn-group-vertical > .btn, .btn-check:focus + .btn-group-vertical > .btn, .btn-group-vertical > .btn:focus, .btn-check:checked + .btn-group > .btn, .btn-check:active + .btn-group > .btn, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn.show, .btn-check:checked + .btn-group-vertical > .btn, .btn-check:active + .btn-group-vertical > .btn, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn.show, .btn-check:checked + .btn-group > .btn:focus, .btn-check:active + .btn-group > .btn:focus, .btn-group > .btn:active:focus, .btn-group > .btn.active:focus, .btn-group > .btn.show:focus, .btn-check:checked + .btn-group-vertical > .btn:focus, .btn-check:active + .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active:focus, .btn-group-vertical > .btn.active:focus, .btn-group-vertical > .btn.show:focus, .btn-group > .btn:disabled, .btn-group > .btn.disabled, fieldset:disabled .btn-group > .btn, .btn-group-vertical > .btn:disabled, .btn-group-vertical > .btn.disabled, fieldset:disabled .btn-group-vertical > .btn, .btn-group > .btn-group, .btn-group-vertical > .btn-group {
  box-shadow: none;
}

.btn-group > .btn-link:first-child, .btn-group > .btn-tertiary:first-child, .btn-group-vertical > .btn-link:first-child, .btn-group-vertical > .btn-tertiary:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-link:last-child, .btn-group > .btn-tertiary:last-child, .btn-group-vertical > .btn-link:last-child, .btn-group-vertical > .btn-tertiary:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group, .btn-group-lg > .btn, .btn-group-sm > .btn {
  --mdb-btn-border-radius: .25rem;
  border-radius: var(--mdb-btn-border-radius);
}

.nav-tabs .nav-link, .nav-tabs .sub-link {
  --mdb-nav-tabs-link-font-weight: 600;
  --mdb-nav-tabs-link-font-size: 1rem;
  --mdb-nav-tabs-link-color: #4d6168;
  --mdb-nav-tabs-link-padding-top: .75rem;
  --mdb-nav-tabs-link-padding-bottom: .75rem;
  --mdb-nav-tabs-link-padding-x: 1rem;
  --mdb-nav-tabs-link-hover-bgc: var(--mdb-highlight-bg-color);
  --mdb-nav-tabs-link-border-bottom-width: 2px;
  --mdb-nav-tabs-link-active-color: #e3041c;
  --mdb-nav-tabs-link-active-border-color: #e3041c;
  border-width: 0;
  border-bottom: var(--mdb-nav-tabs-link-border-bottom-width) solid #0000;
  text-transform: uppercase;
  line-height: 1;
  font-weight: var(--mdb-nav-tabs-link-font-weight);
  font-size: var(--mdb-nav-tabs-link-font-size);
  color: var(--mdb-nav-tabs-link-color);
  padding: var(--mdb-nav-tabs-link-padding-top) var(--mdb-nav-tabs-link-padding-x) var(--mdb-nav-tabs-link-padding-bottom) var(--mdb-nav-tabs-link-padding-x);
  border-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .sub-link:hover {
  background-color: var(--mdb-nav-tabs-link-hover-bgc);
  border-color: #0000;
}

.nav-tabs .nav-link:focus, .nav-tabs .sub-link:focus {
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .active.sub-link, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item.show .sub-link {
  color: var(--mdb-nav-tabs-link-active-color);
  border-color: var(--mdb-nav-tabs-link-active-border-color);
}

.nav-pills {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.nav-pills .nav-link, .nav-pills .sub-link {
  --mdb-nav-pills-link-border-radius: .25rem;
  --mdb-nav-pills-link-font-size: 1rem;
  --mdb-nav-pills-link-padding-top: .75rem;
  --mdb-nav-pills-link-padding-bottom: .75rem;
  --mdb-nav-pills-link-padding-x: 1rem;
  --mdb-nav-pills-link-line-height: 1.4;
  --mdb-nav-pills-link-hover-bg: transparent;
  --mdb-nav-pills-link-font-weight: 600;
  --mdb-nav-pills-link-color: #4d6168;
  --mdb-nav-pills-margin: .25rem;
  border-radius: var(--mdb-nav-pills-link-border-radius);
  font-size: var(--mdb-nav-pills-link-font-size);
  text-transform: uppercase;
  padding: var(--mdb-nav-pills-link-padding-top) var(--mdb-nav-pills-link-padding-x) var(--mdb-nav-pills-link-padding-bottom) var(--mdb-nav-pills-link-padding-x);
  line-height: var(--mdb-nav-pills-link-line-height);
  background-color: var(--mdb-nav-pills-link-hover-bg);
  font-weight: var(--mdb-nav-pills-link-font-weight);
  color: var(--mdb-nav-pills-link-color);
  margin: var(--mdb-nav-pills-margin);
}

.nav-pills .nav-link.active, .nav-pills .active.sub-link, .nav-pills .show > .nav-link, .nav-pills .show > .sub-link {
  --mdb-nav-pills-link-active-bg: #e3041c;
  --mdb-nav-pills-link-active-color: #fff;
  background-color: var(--mdb-nav-pills-link-active-bg);
  color: var(--mdb-nav-pills-link-active-color);
}

.nav-fill .nav-item .nav-link, .nav-fill .nav-item .sub-link, .nav-justified .nav-item .nav-link, .nav-justified .nav-item .sub-link {
  width: auto;
}

.nav-tabs {
  border-bottom: 2px solid #d2d8d9;
}

.nav-tabs .nav-link, .nav-tabs .sub-link {
  text-transform: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .sub-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .sub-link:focus {
  border-color: #0000 #0000 #a6b0b3;
}

.nav-tabs .nav-link.active, .nav-tabs .active.sub-link, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item.show .sub-link {
  background-color: #0000;
}

.nav-tabs .nav-link.active:hover, .nav-tabs .active.sub-link:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .active.sub-link:focus, .nav-tabs .nav-item.show .nav-link:hover, .nav-tabs .nav-item.show .sub-link:hover, .nav-tabs .nav-item.show .nav-link:focus, .nav-tabs .nav-item.show .sub-link:focus {
  color: #e3041c;
  border-color: #e3041c;
}

.nav-pills .nav-link, .nav-pills .sub-link {
  text-transform: none;
}

.navbar {
  --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 4px rgba(var(--mdb-box-shadow-color-rgb), .05);
  --mdb-navbar-padding-top: .5625rem;
  --mdb-navbar-brand-img-margin-right: .25rem;
  box-shadow: var(--mdb-navbar-box-shadow);
  padding-top: var(--mdb-navbar-padding-top);
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-dark .navbar-toggler, .navbar-light .navbar-toggler {
  border: 0;
}

.navbar-brand {
  align-items: center;
  display: flex;
}

.navbar-brand img {
  margin-right: var(--mdb-navbar-brand-img-margin-right);
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-light .navbar-toggler-icon, .navbar-dark .navbar-toggler-icon {
  background-image: none;
}

.navbar-dark, .navbar[data-mdb-theme="dark"] {
  --mdb-navbar-color: #ffffff8c;
  --mdb-navbar-hover-color: #ffffffbf;
  --mdb-navbar-disabled-color: #ffffff40;
  --mdb-navbar-active-color: #fff;
  --mdb-navbar-brand-color: #fff;
  --mdb-navbar-brand-hover-color: #fff;
  --mdb-navbar-toggler-border-color: #ffffff1a;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  border: 0;
}

.card .bg-image {
  border-top-left-radius: var(--mdb-card-border-radius);
  border-top-right-radius: var(--mdb-card-border-radius);
}

.card[class*="bg-"] .card-header {
  --mdb-card-header-border-bottom-color: #0000002d;
  border-bottom-color: var(--mdb-card-header-border-bottom-color);
}

.card[class*="bg-"] .card-footer {
  --mdb-card-footer-border-top-color: #0000002d;
  border-top-color: var(--mdb-card-footer-border-top-color);
}

.card-header {
  --mdb-card-header-border-width: 2px;
  --mdb-card-header-border-color: var(--mdb-divider-color);
  border-bottom: var(--mdb-card-header-border-width) solid var(--mdb-card-header-border-color);
}

.card-body[class*="bg-"] {
  border-bottom-left-radius: var(--mdb-card-border-radius);
  border-bottom-right-radius: var(--mdb-card-border-radius);
}

.card-footer {
  --mdb-card-footer-border-color: var(--mdb-divider-color);
  --mdb-card-footer-border-width: 2px;
  border-top: var(--mdb-card-footer-border-width) solid var(--mdb-card-footer-border-color);
}

.card-img-left {
  border-top-left-radius: var(--mdb-card-border-radius);
  border-bottom-left-radius: var(--mdb-card-border-radius);
}

.navbar .breadcrumb {
  --mdb-breadcrumb-item-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-hover-color: rgba(var(--mdb-emphasis-color-rgb), .7);
  --mdb-breadcrumb-item-before-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-breadcrumb-item-transition: color .15s ease-in-out;
  background-color: #0000;
  margin-bottom: 0;
}

.navbar .breadcrumb .breadcrumb-item a {
  color: var(--mdb-breadcrumb-item-color);
  transition: var(--mdb-breadcrumb-item-transition);
}

.navbar .breadcrumb .breadcrumb-item a:hover, .navbar .breadcrumb .breadcrumb-item a:focus {
  color: var(--mdb-breadcrumb-item-hover-color);
}

.navbar .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: var(--mdb-breadcrumb-item-before-color);
}

.pagination {
  --mdb-pagination-border-radius: .25rem;
  --mdb-pagination-active-transition: all .2s linear;
  --mdb-pagination-active-font-weight: 500;
  --mdb-pagination-circle-border-radius: 50%;
  --mdb-pagination-circle-padding-x: .841rem;
  --mdb-pagination-circle-padding-l-lg: 1.39941rem;
  --mdb-pagination-circle-padding-r-lg: 1.39942rem;
  --mdb-pagination-circle-padding-l-sm: .696rem;
  --mdb-pagination-circle-padding-r-sm: .688rem;
}

.page-link {
  border-radius: var(--mdb-pagination-border-radius);
  background-color: #0000;
  border: 0;
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

.page-link.active, .active > .page-link {
  transition: var(--mdb-pagination-active-transition);
  font-weight: var(--mdb-pagination-active-font-weight);
  border: 0;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--mdb-pagination-border-radius);
  border-bottom-left-radius: var(--mdb-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--mdb-pagination-border-radius);
  border-bottom-right-radius: var(--mdb-pagination-border-radius);
}

.pagination-circle .page-item:first-child .page-link, .pagination-circle .page-item:last-child .page-link {
  border-radius: var(--mdb-pagination-circle-border-radius);
}

.pagination-circle .page-link {
  border-radius: var(--mdb-pagination-circle-border-radius);
  padding-left: var(--mdb-pagination-circle-padding-x);
  padding-right: var(--mdb-pagination-circle-padding-x);
}

.pagination-circle.pagination-lg .page-link {
  padding-left: var(--mdb-pagination-circle-padding-l-lg);
  padding-right: var(--mdb-pagination-circle-padding-r-lg);
}

.pagination-circle.pagination-sm .page-link {
  padding-left: var(--mdb-pagination-circle-padding-l-sm);
  padding-right: var(--mdb-pagination-circle-padding-r-sm);
}

.badge-dot {
  --mdb-badge-border-radius: 4.5px;
  --mdb-badge-height: 9px;
  --mdb-badge-width: 9px;
  --mdb-badge-margin-left: -.3125rem;
  min-width: 0;
  width: var(--mdb-badge-width);
  height: var(--mdb-badge-height);
  border-radius: var(--mdb-badge-border-radius);
  margin-left: var(--mdb-badge-margin-left);
  padding: 0;
  position: absolute;
}

.badge-dot:empty {
  display: inline-block;
}

.badge-notification {
  --mdb-badge-font-size: .75em;
  --mdb-badge-padding-x: .5em;
  --mdb-badge-padding-y: .2857em;
  --mdb-badge-margin-top: 0;
  --mdb-badge-margin-left: 0;
  font-size: var(--mdb-badge-font-size);
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  margin-top: var(--mdb-badge-margin-top);
  margin-left: var(--mdb-badge-margin-left);
  position: absolute;
}

.badge-primary {
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
}

.badge-primary i {
  color: var(--mdb-primary-text-emphasis);
}

.badge-secondary {
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
}

.badge-secondary i {
  color: var(--mdb-secondary-text-emphasis);
}

.badge-success {
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
}

.badge-success i {
  color: var(--mdb-success-text-emphasis);
}

.badge-danger {
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
}

.badge-danger i {
  color: var(--mdb-danger-text-emphasis);
}

.badge-warning {
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
}

.badge-warning i {
  color: var(--mdb-warning-text-emphasis);
}

.badge-info {
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
}

.badge-info i {
  color: var(--mdb-info-text-emphasis);
}

.badge-light {
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.badge-light i {
  color: var(--mdb-light-text-emphasis);
}

.badge-dark {
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
}

.badge-dark i {
  color: var(--mdb-dark-text-emphasis);
}

.alert {
  border: 0;
}

.alert-absolute {
  position: absolute;
}

.alert-fixed {
  --mdb-alert-fixed-z-index: 1070;
  z-index: var(--mdb-alert-fixed-z-index);
  position: fixed;
}

.parent-alert-relative {
  position: relative;
}

.alert-primary {
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
}

.alert-primary i, .alert-primary .alert-link {
  color: var(--mdb-primary-text-emphasis);
}

.alert-primary .alert-link:hover {
  color: rgba(var(--mdb-primary-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-secondary {
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
}

.alert-secondary i, .alert-secondary .alert-link {
  color: var(--mdb-secondary-text-emphasis);
}

.alert-secondary .alert-link:hover {
  color: rgba(var(--mdb-secondary-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-success {
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
}

.alert-success i, .alert-success .alert-link {
  color: var(--mdb-success-text-emphasis);
}

.alert-success .alert-link:hover {
  color: rgba(var(--mdb-success-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-danger {
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
}

.alert-danger i, .alert-danger .alert-link {
  color: var(--mdb-danger-text-emphasis);
}

.alert-danger .alert-link:hover {
  color: rgba(var(--mdb-danger-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-warning {
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
}

.alert-warning i, .alert-warning .alert-link {
  color: var(--mdb-warning-text-emphasis);
}

.alert-warning .alert-link:hover {
  color: rgba(var(--mdb-warning-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-info {
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
}

.alert-info i, .alert-info .alert-link {
  color: var(--mdb-info-text-emphasis);
}

.alert-info .alert-link:hover {
  color: rgba(var(--mdb-info-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-light {
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.alert-light i, .alert-light .alert-link {
  color: var(--mdb-light-text-emphasis);
}

.alert-light .alert-link:hover {
  color: rgba(var(--mdb-light-text-emphasis), var(--mdb-text-hover-opacity));
}

.alert-dark {
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
}

.alert-dark i, .alert-dark .alert-link {
  color: var(--mdb-dark-text-emphasis);
}

.alert-dark .alert-link:hover {
  color: rgba(var(--mdb-dark-text-emphasis), var(--mdb-text-hover-opacity));
}

.progress {
  box-shadow: none;
  border-radius: 0;
}

.progress-circular {
  --mdb-progress-circular-size: 48px;
  --mdb-progress-circular-bar-width: 4px;
  --mdb-progress-circular-color: var(--mdb-emphasis-color);
  width: var(--mdb-progress-circular-size);
  height: var(--mdb-progress-circular-size);
  background-color: #0000;
  display: inline-block;
  position: relative;
}

.progress-circular .progress-bar {
  background-color: var(--mdb-progress-bar-bg);
}

.progress-circular .progress-bar:before {
  content: "";
  box-sizing: border-box;
  background: conic-gradient(transparent calc(var(--percentage) * 1%), var(--mdb-progress-bg) 0%);
  width: 100%;
  height: 100%;
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--mdb-progress-circular-bar-width)), black calc(100% - var(--mdb-progress-circular-bar-width)  + 1px));
  mask: radial-gradient(farthest-side, transparent calc(100% - var(--mdb-progress-circular-bar-width)), black calc(100% - var(--mdb-progress-circular-bar-width)  + 1px));
  background-color: inherit;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-circular .progress-label {
  color: var(--mdb-progress-circular-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-group {
  --mdb-list-group-item-transition-time: .5s;
}

.list-group-item {
  --mdb-list-group-item-active-margin-top: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: var(--mdb-list-group-item-active-margin-top);
}

.list-group-item-action, .list-group-item-action:hover {
  transition: var(--mdb-list-group-item-transition-time);
}

.list-group-light {
  --mdb-list-group-light-item-py: 1rem;
  --mdb-list-group-light-item-border: 2px solid var(--mdb-divider-color);
  --mdb-list-group-light-item-border-width: 2px;
  --mdb-list-group-light-active-border-radius: .5rem;
  --mdb-list-group-light-active-bg: var(--mdb-primary-bg-subtle);
  --mdb-list-group-light-active-color: var(--mdb-primary-text-emphasis);
}

.list-group-light .list-group-item {
  padding: var(--mdb-list-group-light-item-py) 0;
  border: var(--mdb-list-group-light-item-border);
}

.list-group-light > .list-group-item {
  border-width: 0 0 var(--mdb-list-group-light-item-border-width);
}

.list-group-light > .list-group-item:last-of-type {
  border: none;
}

.list-group-light .active {
  border-radius: var(--mdb-list-group-light-active-border-radius);
  background-color: var(--mdb-list-group-light-active-bg);
  color: var(--mdb-list-group-light-active-color);
  border: none;
}

.list-group-light .list-group-item-action:hover, .list-group-light .list-group-item-action:focus {
  border-radius: var(--mdb-list-group-light-active-border-radius);
}

.list-group-small {
  --mdb-list-group-small-item-py: .5rem;
}

.list-group-small .list-group-item {
  padding: var(--mdb-list-group-small-item-py) 0;
}

.list-group-item-primary {
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
}

.list-group-item-primary i {
  color: var(--mdb-primary-link-emphasis);
}

.list-group-item-secondary {
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
}

.list-group-item-secondary i {
  color: var(--mdb-secondary-link-emphasis);
}

.list-group-item-success {
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
}

.list-group-item-success i {
  color: var(--mdb-success-link-emphasis);
}

.list-group-item-danger {
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
}

.list-group-item-danger i {
  color: var(--mdb-danger-link-emphasis);
}

.list-group-item-warning {
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
}

.list-group-item-warning i {
  color: var(--mdb-warning-link-emphasis);
}

.list-group-item-info {
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
}

.list-group-item-info i {
  color: var(--mdb-info-link-emphasis);
}

.list-group-item-light {
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
}

.list-group-item-light i {
  color: var(--mdb-light-link-emphasis);
}

.list-group-item-dark {
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
}

.list-group-item-dark i {
  color: var(--mdb-dark-link-emphasis);
}

.btn-close:focus {
  box-shadow: none;
}

.modal-content {
  --mdb-modal-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  box-shadow: var(--mdb-modal-box-shadow);
  border: 0;
}

.toast {
  --mdb-toast-border-bottom-width: 2px;
  --mdb-toast-btn-close-width: 1.3em;
  --mdb-toast-btn-close-mr: -.375rem;
  --mdb-toast-btn-close-ml: .75rem;
  --mdb-toast-bg: var(--mdb-surface-bg);
  --mdb-toast-header-bg: var(--mdb-surface-bg);
  border: 0;
}

.toast .btn-close {
  width: var(--mdb-toast-btn-close-width);
}

.toast-header {
  border-bottom-width: var(--mdb-toast-border-bottom-width);
}

.toast-header .btn-close {
  margin-right: var(--mdb-toast-btn-close-mr);
  margin-left: var(--mdb-toast-btn-close-ml);
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  z-index: var(--mdb-toast-zindex);
  position: fixed;
}

.toast-primary {
  background-color: var(--mdb-primary-bg-subtle);
  color: var(--mdb-primary-text-emphasis);
  border-color: var(--mdb-primary-border-subtle);
}

.toast-primary i {
  color: var(--mdb-primary-text-emphasis);
}

.toast-secondary {
  background-color: var(--mdb-secondary-bg-subtle);
  color: var(--mdb-secondary-text-emphasis);
  border-color: var(--mdb-secondary-border-subtle);
}

.toast-secondary i {
  color: var(--mdb-secondary-text-emphasis);
}

.toast-success {
  background-color: var(--mdb-success-bg-subtle);
  color: var(--mdb-success-text-emphasis);
  border-color: var(--mdb-success-border-subtle);
}

.toast-success i {
  color: var(--mdb-success-text-emphasis);
}

.toast-danger {
  background-color: var(--mdb-danger-bg-subtle);
  color: var(--mdb-danger-text-emphasis);
  border-color: var(--mdb-danger-border-subtle);
}

.toast-danger i {
  color: var(--mdb-danger-text-emphasis);
}

.toast-warning {
  background-color: var(--mdb-warning-bg-subtle);
  color: var(--mdb-warning-text-emphasis);
  border-color: var(--mdb-warning-border-subtle);
}

.toast-warning i {
  color: var(--mdb-warning-text-emphasis);
}

.toast-info {
  background-color: var(--mdb-info-bg-subtle);
  color: var(--mdb-info-text-emphasis);
  border-color: var(--mdb-info-border-subtle);
}

.toast-info i {
  color: var(--mdb-info-text-emphasis);
}

.toast-light {
  background-color: var(--mdb-light-bg-subtle);
  color: var(--mdb-light-text-emphasis);
  border-color: var(--mdb-light-border-subtle);
}

.toast-light i {
  color: var(--mdb-light-text-emphasis);
}

.toast-dark {
  background-color: var(--mdb-dark-bg-subtle);
  color: var(--mdb-dark-text-emphasis);
  border-color: var(--mdb-dark-border-subtle);
}

.toast-dark i {
  color: var(--mdb-dark-text-emphasis);
}

.tooltip {
  --mdb-tooltip-font-size: 14px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  display: none;
}

.tooltip-inner {
  font-size: var(--mdb-tooltip-font-size);
}

.popover {
  --mdb-popover-border-bottom-width: 2px;
}

.popover .popover-arrow {
  display: none;
}

.popover-header {
  border-bottom: var(--mdb-popover-border-bottom-width) solid var(--mdb-popover-border-color);
}

.nav-pills.menu-sidebar .nav-link, .nav-pills.menu-sidebar .sub-link {
  --mdb-scrollspy-menu-sidebar-font-size: .8rem;
  --mdb-scrollspy-menu-sidebar-color: var(--mdb-body-color);
  --mdb-scrollspy-menu-sidebar-line-height: 1.1rem;
  --mdb-scrollspy-menu-sidebar-padding-x: 5px;
  --mdb-scrollspy-menu-sidebar-font-weight: 400;
  --mdb-scrollspy-menu-sidebar-transition: all .2s ease-in-out;
  --mdb-scrollspy-menu-sidebar-margin-y: 3px;
  font-size: var(--mdb-scrollspy-menu-sidebar-font-size);
  color: var(--mdb-scrollspy-menu-sidebar-color);
  line-height: var(--mdb-scrollspy-menu-sidebar-line-height);
  padding: 0 var(--mdb-scrollspy-menu-sidebar-padding-x);
  font-weight: var(--mdb-scrollspy-menu-sidebar-font-weight);
  transition: var(--mdb-scrollspy-menu-sidebar-transition);
  text-transform: initial;
  margin-top: var(--mdb-scrollspy-menu-sidebar-margin-y);
  margin-bottom: var(--mdb-scrollspy-menu-sidebar-margin-y);
  background-color: #0000;
}

.nav-pills.menu-sidebar .nav-link.active, .nav-pills.menu-sidebar .active.sub-link, .nav-pills.menu-sidebar .show > .nav-link, .nav-pills.menu-sidebar .show > .sub-link {
  --mdb-scrollspy-menu-sidebar-active-color: #e3041c;
  --mdb-scrollspy-menu-sidebar-active-font-weight: 600;
  --mdb-scrollspy-menu-sidebar-active-border-width: .125rem;
  --mdb-scrollspy-menu-sidebar-active-border-color: #e3041c;
  box-shadow: none;
  color: var(--mdb-scrollspy-menu-sidebar-active-color);
  font-weight: var(--mdb-scrollspy-menu-sidebar-active-font-weight);
  border-left: var(--mdb-scrollspy-menu-sidebar-active-border-width) solid var(--mdb-scrollspy-menu-sidebar-active-border-color);
  background-color: #0000;
  border-radius: 0;
}

.nav-pills.menu-sidebar .collapsible-scrollspy ~ .nav, .nav-pills.menu-sidebar .collapsible-scrollspy ~ .sub-menu {
  --mdb-scrollspy-collapsible-nav-transition-time: .5s;
  transition: height var(--mdb-scrollspy-collapsible-nav-transition-time) ease;
  flex-wrap: nowrap;
}

.ripple-surface {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ripple-surface-unbound {
  overflow: visible;
}

.ripple-wave {
  --mdb-ripple-wave-cubicBezier: cubic-bezier(0, 0, .15, 1);
  --mdb-ripple-wave-border-radius: 50%;
  --mdb-ripple-wave-opacity: .5;
  --mdb-ripple-wave-transform: scale(0);
  --mdb-ripple-wave-z-index: 999;
  --mdb-ripple-wave-active-transform: scale(1);
  border-radius: var(--mdb-ripple-wave-border-radius);
  opacity: var(--mdb-ripple-wave-opacity);
  pointer-events: none;
  touch-action: none;
  transform: var(--mdb-ripple-wave-transform);
  transition-property: transform, opacity;
  transition-timing-function: var(--mdb-ripple-wave-cubicBezier), var(--mdb-ripple-wave-cubicBezier);
  z-index: var(--mdb-ripple-wave-z-index);
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
  position: absolute;
}

.ripple-wave.active {
  transform: var(--mdb-ripple-wave-active-transform);
  opacity: 0;
}

.btn .ripple-wave, .input-wrapper .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-primary .ripple-wave {
  background-image: radial-gradient(circle, #e3041c33 0, #e3041c4d 40%, #e3041c66 50%, #e3041c80 60%, #e3041c00 70%);
}

.ripple-surface-secondary .ripple-wave {
  background-image: radial-gradient(circle, #203a4233 0, #203a424d 40%, #203a4266 50%, #203a4280 60%, #203a4200 70%);
}

.ripple-surface-success .ripple-wave {
  background-image: radial-gradient(circle, #00ae0033 0, #00ae004d 40%, #00ae0066 50%, #00ae0080 60%, #00ae0000 70%);
}

.ripple-surface-danger .ripple-wave {
  background-image: radial-gradient(circle, #e3041c33 0, #e3041c4d 40%, #e3041c66 50%, #e3041c80 60%, #e3041c00 70%);
}

.ripple-surface-warning .ripple-wave {
  background-image: radial-gradient(circle, #ffc10733 0, #ffc1074d 40%, #ffc10766 50%, #ffc10780 60%, #ffc10700 70%);
}

.ripple-surface-info .ripple-wave {
  background-image: radial-gradient(circle, #00abf033 0, #00abf04d 40%, #00abf066 50%, #00abf080 60%, #00abf000 70%);
}

.ripple-surface-light .ripple-wave {
  background-image: radial-gradient(circle, #d2d8d933 0, #d2d8d94d 40%, #d2d8d966 50%, #d2d8d980 60%, #d2d8d900 70%);
}

.ripple-surface-dark .ripple-wave {
  background-image: radial-gradient(circle, #060c0d33 0, #060c0d4d 40%, #060c0d66 50%, #060c0d80 60%, #060c0d00 70%);
}

.range {
  --mdb-range-thumb-height: 30px;
  --mdb-range-thumb-width: 30px;
  --mdb-range-thumb-top: -35px;
  --mdb-range-thumb-margin-left: -15px;
  --mdb-range-thumb-border-radius: 50% 50% 50% 0;
  --mdb-range-thumb-transform: scale(0);
  --mdb-range-thumb-transition: transform .2s ease-in-out;
  --mdb-range-thumb-value-font-size: 12px;
  --mdb-range-thumb-value-line-height: 30px;
  --mdb-range-thumb-value-color: #fff;
  --mdb-range-thumb-value-font-weight: 500;
  --mdb-range-thumb-background: #e3041c;
  position: relative;
}

.range .thumb {
  height: var(--mdb-range-thumb-height);
  width: var(--mdb-range-thumb-width);
  top: var(--mdb-range-thumb-top);
  margin-left: var(--mdb-range-thumb-margin-left);
  text-align: center;
  border-radius: var(--mdb-range-thumb-border-radius);
  transform: var(--mdb-range-thumb-transform);
  transform-origin: bottom;
  transition: var(--mdb-range-thumb-transition);
  display: block;
  position: absolute;
}

.range .thumb:after {
  content: "";
  border-radius: var(--mdb-range-thumb-border-radius);
  background: var(--mdb-range-thumb-background);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
}

.range .thumb .thumb-value {
  font-size: var(--mdb-range-thumb-value-font-size);
  line-height: var(--mdb-range-thumb-value-line-height);
  color: var(--mdb-range-thumb-value-color);
  font-weight: var(--mdb-range-thumb-value-font-weight);
  z-index: 2;
  display: block;
}

.range .thumb.thumb-active {
  transform: scale(1);
}

.accordion-button:not(.collapsed):focus {
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
}

.accordion-button:focus {
  border-color: var(--mdb-accordion-btn-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.accordion-flush {
  --mdb-accordion-flush-btn-box-shadow: inset 0 -2px 0 var(--mdb-divider-color);
  --mdb-accordion-flush-border-bottom: 2px solid var(--mdb-divider-color);
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: var(--mdb-accordion-flush-btn-box-shadow);
}

.accordion-flush .accordion-item {
  border-bottom: var(--mdb-accordion-flush-border-bottom);
}

.accordion-borderless {
  --mdb-accordion-borderless-btn-border-radius: .5rem;
  --mdb-accordion-borderless-btn-bg: var(--mdb-primary-bg-subtle);
  --mdb-accordion-borderless-btn-color: var(--mdb-primary-text-emphasis);
}

.accordion-borderless .accordion-item {
  border: 0;
}

.accordion-borderless .accordion-item .accordion-button {
  border-radius: var(--mdb-accordion-borderless-btn-border-radius);
}

.accordion-borderless .accordion-item .accordion-button:not(.collapsed) {
  background-color: var(--mdb-accordion-borderless-btn-bg);
  color: var(--mdb-accordion-borderless-btn-color);
  box-shadow: none;
}

.carousel-control-prev-icon:after {
  content: "";
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
}

.carousel-control-next-icon:after {
  content: "";
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
}

.modal {
  --mdb-modal-top-left-top: 10px;
  --mdb-modal-top-left-left: 10px;
  --mdb-modal-top-right-top: 10px;
  --mdb-modal-top-right-right: 10px;
  --mdb-modal-bottom-left-bottom: 10px;
  --mdb-modal-bottom-left-left: 10px;
  --mdb-modal-bottom-right-bottom: 10px;
  --mdb-modal-bottom-right-right: 10px;
  --mdb-modal-fade-top-transform: translate3d(0, -25%, 0);
  --mdb-modal-fade-right-transform: translate3d(25%, 0, 0);
  --mdb-modal-fade-bottom-transform: translate3d(0, 25%, 0);
  --mdb-modal-fade-left-transform: translate3d(-25%, 0, 0);
  --mdb-modal-side-right: 10px;
  --mdb-modal-side-bottom: 10px;
  --mdb-modal-non-invasive-box-shadow: 0 2px 6px -1px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 6px 18px -1px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-modal-non-invasive-box-shadow-top: 0 -10px 20px 0 rgba(var(--mdb-box-shadow-color-rgb), .05);
}

@media (width >= 768px) {
  .modal .modal-dialog.modal-top {
    top: 0;
  }

  .modal .modal-dialog.modal-left {
    left: 0;
  }

  .modal .modal-dialog.modal-right {
    right: 0;
  }

  .modal .modal-dialog.modal-top-left {
    top: var(--mdb-modal-top-left-top);
    left: var(--mdb-modal-top-left-left);
  }

  .modal .modal-dialog.modal-top-right {
    top: var(--mdb-modal-top-right-top);
    right: var(--mdb-modal-top-right-right);
  }

  .modal .modal-dialog.modal-bottom-left {
    bottom: var(--mdb-modal-bottom-left-bottom);
    left: var(--mdb-modal-bottom-left-left);
  }

  .modal .modal-dialog.modal-bottom-right {
    right: var(--mdb-modal-bottom-right-right);
    bottom: var(--mdb-modal-bottom-right-bottom);
  }
}

.modal .modal-dialog.modal-bottom {
  bottom: 0;
}

.modal.fade.top:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-top-transform);
}

.modal.fade.right:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-right-transform);
}

.modal.fade.bottom:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-bottom-transform);
}

.modal.fade.left:not(.show) .modal-dialog {
  transform: var(--mdb-modal-fade-left-transform);
}

@media (width >= 992px) {
  .modal .modal-side {
    width: 100%;
    right: var(--mdb-modal-side-right);
    bottom: var(--mdb-modal-side-bottom);
    margin: 0;
    position: absolute;
  }
}

.modal .modal-frame {
  width: 100%;
  max-width: 100%;
  margin: 0;
  position: absolute;
}

.modal-open .modal.frame {
  overflow-y: hidden;
}

.modal-non-invasive-open {
  overflow-y: auto;
}

.modal-non-invasive-open .modal.modal-non-invasive-show {
  display: table;
}

@media (width >= 992px) {
  .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-right, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-left, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-side {
    bottom: 0;
  }

  .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.bottom, .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.bottom .modal-content {
    box-shadow: var(--mdb-modal-non-invasive-box-shadow-top);
  }

  .modal-non-invasive-open .modal.modal-non-invasive-show.modal.frame.top {
    box-shadow: var(--mdb-modal-non-invasive-box-shadow);
  }

  .modal-non-invasive-open .modal.modal-non-invasive-show .modal-side.modal-bottom-right .modal-content, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-side.modal-bottom-left .modal-content {
    box-shadow: var(--mdb-modal-non-invasive-box-shadow-top);
  }
}

.modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-right {
  right: 0;
}

.modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-bottom-left, .modal-non-invasive-open .modal.modal-non-invasive-show .modal-dialog.modal-top-left {
  left: 0;
}

.modal-non-invasive-open .modal.modal-non-invasive-show .modal-side {
  right: 0;
}

.ps {
  --mdb-scrollbar-rail-x-y-transition-opacity-bg: background-color .2s linear, opacity .2s linear;
  --mdb-scrollbar-z-index: 1035;
  --mdb-scrollbar-rail-x-y-length: .9375rem;
  --mdb-scrollbar-rail-x-y-opacity: .6;
  --mdb-scrollbar-rail-x-y-hover-opacity: .9;
  --mdb-scrollbar-rail-x-y-bg-color: transparent;
  --mdb-scrollbar-rail-x-y-clicking-length: .6875rem;
  --mdb-scrollbar-rail-x-transition-height-bg: background-color .2s linear, height .2s ease-in-out;
  --mdb-scrollbar-rail-y-transition-width-bg: background-color .2s linear, width .2s ease-in-out;
  --mdb-scrollbar-thumb-x-y-color: var(--mdb-scrollbar-thumb-bg);
  --mdb-scrollbar-thumb-x-y-border-radius: .375rem;
  --mdb-scrollbar-thumb-x-y-length: .375rem;
  --mdb-scrollbar-thumb-x-y-position-length: .125rem;
  overflow-anchor: none;
  touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x, .ps__rail-y {
  opacity: 0;
  transition: var(--mdb-scrollbar-rail-x-y-transition-opacity-bg);
  z-index: var(--mdb-scrollbar-z-index);
  display: none;
  position: absolute;
}

.ps__rail-x {
  height: var(--mdb-scrollbar-rail-x-y-length);
  bottom: 0;
}

.ps__rail-y {
  width: var(--mdb-scrollbar-rail-x-y-length);
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: var(--mdb-scrollbar-rail-x-y-opacity);
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: var(--mdb-scrollbar-rail-x-y-bg-color);
  opacity: var(--mdb-scrollbar-rail-x-y-hover-opacity);
}

.ps__thumb-x, .ps__thumb-y {
  background-color: var(--mdb-scrollbar-thumb-x-y-color);
  border-radius: var(--mdb-scrollbar-thumb-x-y-border-radius);
  position: absolute;
}

.ps__thumb-x {
  transition: var(--mdb-scrollbar-rail-x-transition-height-bg);
  height: var(--mdb-scrollbar-thumb-x-y-length);
  bottom: var(--mdb-scrollbar-thumb-x-y-position-length);
}

.ps__thumb-y {
  transition: var(--mdb-scrollbar-rail-y-transition-width-bg);
  width: var(--mdb-scrollbar-thumb-x-y-length);
  right: var(--mdb-scrollbar-thumb-x-y-position-length);
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  height: var(--mdb-scrollbar-rail-x-y-clicking-length);
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  width: var(--mdb-scrollbar-rail-x-y-clicking-length);
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.perfect-scrollbar {
  position: relative;
}

.sidenav {
  --mdb-sidenav-transform: translateX(-100%);
  --mdb-sidenav-zindex: 1035;
  --mdb-sidenav-color: var(--mdb-surface-color);
  --mdb-sidenav-background-color: var(--mdb-surface-bg);
  --mdb-sidenav-width: 240px;
  --mdb-sidenav-height: 100vh;
  --mdb-sidenav-box-shadow: 0 4px 12px 0 rgba(var(--mdb-box-shadow-color-rgb), .07), 0 2px 4px rgba(var(--mdb-box-shadow-color-rgb), .05);
  --mdb-sidenav-data-hidden-false-transform: translateX(0%);
  --mdb-sidenav-data-color-light-color: #fff9;
  --mdb-sidenav-data-right-true-transform: translateX(100%);
  --mdb-sidenav-data-slim-collapsed-true-width: 77px;
  --mdb-sidenav-menu-padding: .2rem;
  --mdb-sidenav-collapse-sidenav-link-font-size: .78rem;
  --mdb-sidenav-collapse-sidenav-link-height: 1.5rem;
  --mdb-sidenav-link-font-size: .89rem;
  --mdb-sidenav-link-padding-y: 1rem;
  --mdb-sidenav-link-padding-x: 1.5rem;
  --mdb-sidenav-collapse-sidenav-link-padding-left: 3.4rem;
  --mdb-sidenav-link-height: 3rem;
  --mdb-sidenav-link-border-radius: 5px;
  --mdb-sidenav-link-transition: all .3s linear;
  --mdb-sidenav-link-hover-color: inherit;
  --mdb-sidenav-link-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-sidenav-link-active-focus-background-color: var(--mdb-highlight-bg-color);
  --mdb-sidenav-link-active-color: inherit;
  --mdb-sidenav-link-active-focus-color: inherit;
  --mdb-sidenav-subheading-font-size: .6rem;
  --mdb-sidenav-subheading-padding-y: 1rem;
  --mdb-sidenav-subheading-padding-x: 1.5rem;
  --mdb-sidenav-subheading-fw: 700;
  --mdb-sidenav-sm-link-pt: .4rem;
  --mdb-sidenav-sm-link-pb: .4rem;
  --mdb-sidenav-rotate-icon-margin-right: .8rem;
  --mdb-sidenav-rotate-icon-transition: transform .3s;
  --mdb-sidenav-light-color: #fff9;
  transform: var(--mdb-sidenav-transform);
  z-index: var(--mdb-sidenav-zindex);
  color: var(--mdb-sidenav-color);
  background-color: var(--mdb-sidenav-background-color);
  width: var(--mdb-sidenav-width);
  height: var(--mdb-sidenav-height);
  box-shadow: var(--mdb-sidenav-box-shadow);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sidenav[data-mdb-hidden="false"] {
  transform: var(--mdb-sidenav-data-hidden-false-transform);
}

.sidenav[data-mdb-color="light"] {
  color: var(--mdb-sidenav-data-color-light-color);
}

.sidenav[data-mdb-right="true"] {
  right: 0;
  left: unset;
  transform: var(--mdb-sidenav-data-right-true-transform);
}

.sidenav[data-mdb-position="absolute"] {
  height: 100%;
  position: absolute;
}

.sidenav[data-mdb-position="relative"] {
  height: 100%;
  position: relative;
}

.sidenav [data-mdb-slim="true"] {
  display: none;
}

.sidenav[data-mdb-slim-collapsed="true"] {
  width: var(--mdb-sidenav-data-slim-collapsed-true-width);
}

.sidenav[data-mdb-slim-collapsed="true"] [data-mdb-slim="false"] {
  display: none;
}

.sidenav[data-mdb-slim-collapsed="true"] [data-mdb-slim="true"] {
  display: unset;
}

.sidenav-menu, .sidenav-collapse {
  padding: 0 var(--mdb-sidenav-menu-padding);
  margin: 0;
  list-style: none;
  position: relative;
}

.sidenav-collapse {
  padding: 0;
  display: none;
}

.sidenav-collapse.show, .sidenav-collapse.collapsing {
  display: block;
}

.sidenav-collapse .sidenav-link {
  font-size: var(--mdb-sidenav-collapse-sidenav-link-font-size);
  height: var(--mdb-sidenav-collapse-sidenav-link-height);
  padding-left: var(--mdb-sidenav-collapse-sidenav-link-padding-left);
}

.sidenav-item {
  position: relative;
}

.sidenav-link {
  cursor: pointer;
  font-size: var(--mdb-sidenav-link-font-size);
  padding: var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x);
  height: var(--mdb-sidenav-link-height);
  color: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--mdb-sidenav-link-border-radius);
  transition: var(--mdb-sidenav-link-transition);
  align-items: center;
  display: flex;
  overflow: hidden;
}

.sidenav-link:hover {
  color: inherit;
  background-color: var(--mdb-sidenav-link-hover-background-color);
  outline: none;
}

.sidenav-link:active, .sidenav-link:focus {
  color: inherit;
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
  outline: none;
}

.sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-link i {
  color: #9fa6b2;
}

.sidenav-subheading {
  color: unset;
  text-transform: uppercase;
  font-size: var(--mdb-sidenav-subheading-font-size);
  padding: var(--mdb-sidenav-subheading-padding-y) var(--mdb-sidenav-subheading-padding-x);
  font-weight: var(--mdb-sidenav-subheading-fw);
}

.sidenav-sm .sidenav-link {
  padding-top: var(--mdb-sidenav-sm-link-pt);
  padding-bottom: var(--mdb-sidenav-sm-link-pb);
  height: initial;
}

.rotate-icon {
  margin-left: auto;
  margin-right: var(--mdb-sidenav-rotate-icon-margin-right);
  transition: var(--mdb-sidenav-rotate-icon-transition);
  position: absolute;
  right: 0;
}

.sidenav-backdrop {
  --mdb-sidenav-backdrop-zindex: 1034;
  --mdb-sidenav-backdrop-background-color: rgba(0, 0, 0, var(--mdb-sidenav-backdrop-opacity));
  z-index: var(--mdb-sidenav-backdrop-zindex);
  background-color: var(--mdb-sidenav-backdrop-background-color);
  top: 0;
  left: 0;
}

.sidenav-light {
  color: var(--mdb-sidenav-light-color);
}

.sidenav-slim {
  --mdb-sidenav-slim-link-padding-left: 1rem;
}

.sidenav-slim .sidenav-link {
  padding-left: var(--mdb-sidenav-slim-link-padding-left);
}

.sidenav-primary {
  --mdb-sidenav-link-hover-background-color: #e3041c0d;
  --mdb-sidenav-link-active-focus-background-color: #e3041c0d;
}

.sidenav-primary .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-primary .sidenav-link:active, .sidenav-primary .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-primary .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-secondary {
  --mdb-sidenav-link-hover-background-color: #203a420d;
  --mdb-sidenav-link-active-focus-background-color: #203a420d;
}

.sidenav-secondary .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-secondary .sidenav-link:active, .sidenav-secondary .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-secondary .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-success {
  --mdb-sidenav-link-hover-background-color: #00ae000d;
  --mdb-sidenav-link-active-focus-background-color: #00ae000d;
}

.sidenav-success .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-success .sidenav-link:active, .sidenav-success .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-success .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-danger {
  --mdb-sidenav-link-hover-background-color: #e3041c0d;
  --mdb-sidenav-link-active-focus-background-color: #e3041c0d;
}

.sidenav-danger .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-danger .sidenav-link:active, .sidenav-danger .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-danger .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-warning {
  --mdb-sidenav-link-hover-background-color: #ffc1070d;
  --mdb-sidenav-link-active-focus-background-color: #ffc1070d;
}

.sidenav-warning .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-warning .sidenav-link:active, .sidenav-warning .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-warning .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-info {
  --mdb-sidenav-link-hover-background-color: #00abf00d;
  --mdb-sidenav-link-active-focus-background-color: #00abf00d;
}

.sidenav-info .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-info .sidenav-link:active, .sidenav-info .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-info .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-light {
  --mdb-sidenav-link-hover-background-color: #d2d8d90d;
  --mdb-sidenav-link-active-focus-background-color: #d2d8d90d;
}

.sidenav-light .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-light .sidenav-link:active, .sidenav-light .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-light .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.sidenav-dark {
  --mdb-sidenav-link-hover-background-color: #060c0d0d;
  --mdb-sidenav-link-active-focus-background-color: #060c0d0d;
}

.sidenav-dark .sidenav-item .sidenav-link:hover {
  color: var(--mdb-sidenav-link-hover-color);
  background-color: var(--mdb-sidenav-link-hover-background-color);
}

.sidenav-dark .sidenav-link:active, .sidenav-dark .sidenav-link:focus {
  color: var(--mdb-sidenav-link-active-focus-color);
  background-color: var(--mdb-sidenav-link-active-focus-background-color);
}

.sidenav-dark .sidenav-link.active {
  color: var(--mdb-sidenav-link-active-color);
}

.animation {
  --mdb-animation-delay-1s: 1s;
  --mdb-animation-delay-2s: 3s;
  --mdb-animation-delay-3s: 3s;
  --mdb-animation-delay-4s: 4s;
  --mdb-animation-delay-5s: 5s;
  --mdb-animation-fast-duration: .8s;
  --mdb-animation-faster-duration: .5s;
  --mdb-animation-slow-duration: 2s;
  --mdb-animation-slower-duration: 3s;
}

.animation.infinite {
  animation-iteration-count: infinite;
}

.animation.delay-1s {
  animation-delay: var(--mdb-animation-delay-1s);
}

.animation.delay-2s {
  animation-delay: var(--mdb-animation-delay-2s);
}

.animation.delay-3s {
  animation-delay: var(--mdb-animation-delay-3s);
}

.animation.delay-4s {
  animation-delay: var(--mdb-animation-delay-4s);
}

.animation.delay-5s {
  animation-delay: var(--mdb-animation-delay-5s);
}

.animation.fast {
  animation-duration: var(--mdb-animation-fast-duration);
}

.animation.faster {
  animation-duration: var(--mdb-animation-faster-duration);
}

.animation.slow {
  animation-duration: var(--mdb-animation-slow-duration);
}

.animation.slower {
  animation-duration: var(--mdb-animation-slower-duration);
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-down-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-down-transform-to);
  }
}

.fade-in-down {
  --mdb-animation-fade-in-down-transform-from: translate3d(0, -100%, 0);
  --mdb-animation-fade-in-down-transform-to: translate3d(0, 0, 0);
  animation-name: fade-in-down;
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-left-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-left-transform-to);
  }
}

.fade-in-left {
  --mdb-animation-fade-in-left-transform-from: translate3d(-100%, 0, 0);
  --mdb-animation-fade-in-left-transform-to: translate3d(0, 0, 0);
  animation-name: fade-in-left;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-right-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-right-transform-to);
  }
}

.fade-in-right {
  --mdb-animation-fade-in-right-transform-from: translate3d(100%, 0, 0);
  --mdb-animation-fade-in-right-transform-to: translate3d(0, 0, 0);
  animation-name: fade-in-right;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: var(--mdb-animation-fade-in-up-transform-from);
  }

  to {
    opacity: 1;
    transform: var(--mdb-animation-fade-in-up-transform-to);
  }
}

.fade-in-up {
  --mdb-animation-fade-in-up-transform-from: translate3d(0, 100%, 0);
  --mdb-animation-fade-in-up-transform-to: translate3d(0, 0, 0);
  animation-name: fade-in-up;
}

@keyframes fade-out-down {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-down-transform-to);
  }
}

.fade-out-down {
  --mdb-animation-fade-out-down-transform-to: translate3d(0, 100%, 0);
  animation-name: fade-out-down;
}

@keyframes fade-out-left {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-left-transform-to);
  }
}

.fade-out-left {
  --mdb-animation-fade-out-left-transform-to: translate3d(-100%, 0, 0);
  animation-name: fade-out-left;
}

@keyframes fade-out-right {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-right-transform-to);
  }
}

.fade-out-right {
  --mdb-animation-fade-out-right-transform-to: translate3d(100%, 0, 0);
  animation-name: fade-out-right;
}

@keyframes fade-out-up {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: var(--mdb-animation-fade-out-up-transform-to);
  }
}

.fade-out-up {
  --mdb-animation-fade-out-up-transform-to: translate3d(0, -100%, 0);
  animation-name: fade-out-up;
}

@keyframes slide-in-down {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-down-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-down-transform-to);
  }
}

.slide-in-down {
  --mdb-animation-slide-in-down-transform-from: translate3d(0, -100%, 0);
  --mdb-animation-slide-in-down-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-down;
}

@keyframes slide-in-left {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-left-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-left-transform-to);
  }
}

.slide-in-left {
  --mdb-animation-slide-in-left-transform-from: translate3d(-100%, 0, 0);
  --mdb-animation-slide-in-left-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-left;
}

@keyframes slide-in-right {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-right-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-right-transform-to);
  }
}

.slide-in-right {
  --mdb-animation-slide-in-right-transform-from: translate3d(100%, 0, 0);
  --mdb-animation-slide-in-right-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-right;
}

@keyframes slide-in-up {
  from {
    visibility: visible;
    transform: var(--mdb-animation-slide-in-up-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-in-up-transform-to);
  }
}

.slide-in-up {
  --mdb-animation-slide-in-up-transform-from: translate3d(0, 100%, 0);
  --mdb-animation-slide-in-up-transform-to: translate3d(0, 0, 0);
  animation-name: slide-in-up;
}

@keyframes slide-out-down {
  from {
    transform: var(--mdb-animation-slide-out-down-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-down-transform-to);
  }
}

.slide-out-down {
  --mdb-animation-slide-out-down-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-down-transform-to: translate3d(0, 100%, 0);
  animation-name: slide-out-down;
}

@keyframes slide-out-left {
  from {
    transform: var(--mdb-animation-slide-out-left-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-left-transform-to);
  }
}

.slide-out-left {
  --mdb-animation-slide-out-left-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-left-transform-to: translate3d(-100%, 0, 0);
  animation-name: slide-out-left;
}

@keyframes slide-out-right {
  from {
    transform: var(--mdb-animation-slide-out-right-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-right-transform-to);
  }
}

.slide-out-right {
  --mdb-animation-slide-out-right-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-right-transform-to: translate3d(100%, 0, 0);
  animation-name: slide-out-right;
}

@keyframes slide-out-up {
  from {
    transform: var(--mdb-animation-slide-out-up-transform-from);
  }

  to {
    visibility: hidden;
    transform: var(--mdb-animation-slide-out-up-transform-to);
  }
}

.slide-out-up {
  --mdb-animation-slide-out-up-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-out-up-transform-to: translate3d(0, -100%, 0);
  animation-name: slide-out-up;
}

@keyframes slide-down {
  from {
    transform: var(--mdb-animation-slide-down-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-down-transform-to);
  }
}

.slide-down {
  --mdb-animation-slide-down-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-down-transform-to: translate3d(0, 100%, 0);
  animation-name: slide-down;
}

@keyframes slide-left {
  from {
    transform: var(--mdb-animation-slide-left-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-left-transform-to);
  }
}

.slide-left {
  --mdb-animation-slide-left-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-left-transform-to: translate3d(-100%, 0, 0);
  animation-name: slide-left;
}

@keyframes slide-right {
  from {
    transform: var(--mdb-animation-slide-right-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-right-transform-to);
  }
}

.slide-right {
  --mdb-animation-slide-right-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-right-transform-to: translate3d(100%, 0, 0);
  animation-name: slide-right;
}

@keyframes slide-up {
  from {
    transform: var(--mdb-animation-slide-up-transform-from);
  }

  to {
    transform: var(--mdb-animation-slide-up-transform-to);
  }
}

.slide-up {
  --mdb-animation-slide-up-transform-from: translate3d(0, 0, 0);
  --mdb-animation-slide-up-transform-to: translate3d(0, -100%, 0);
  animation-name: slide-up;
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: var(--mdb-animation-zoom-in-transform-from);
  }

  50% {
    opacity: 1;
  }
}

.zoom-in {
  --mdb-animation-zoom-in-transform-from: scale3d(.3, .3, .3);
  animation-name: zoom-in;
}

@keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: var(--mdb-animation-zoom-out-transform-50);
  }

  to {
    opacity: 0;
  }
}

.zoom-out {
  --mdb-animation-zoom-out-transform-50: scale3d(.3, .3, .3);
  animation-name: zoom-out;
}

@keyframes tada {
  from {
    transform: var(--mdb-animation-tada-transform-from);
  }

  10%, 20% {
    transform: var(--mdb-animation-tada-transform-20);
  }

  30%, 50%, 70%, 90% {
    transform: var(--mdb-animation-tada-transform-90);
  }

  40%, 60%, 80% {
    transform: var(--mdb-animation-tada-transform-80);
  }

  to {
    transform: var(--mdb-animation-tada-transform-to);
  }
}

.tada {
  --mdb-animation-tada-transform-from: scale3d(1, 1, 1);
  --mdb-animation-tada-transform-20: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  --mdb-animation-tada-transform-90: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  --mdb-animation-tada-transform-80: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  --mdb-animation-tada-transform-to: scale3d(1, 1, 1);
  animation-name: tada;
}

@keyframes pulse {
  from {
    transform: var(--mdb-animation-pulse-transform-from);
  }

  50% {
    transform: var(--mdb-animation-pulse-transform-50);
  }

  to {
    transform: var(--mdb-animation-pulse-transform-to);
  }
}

.pulse {
  --mdb-animation-pulse-transform-from: scale3d(1, 1, 1);
  --mdb-animation-pulse-transform-50: scale3d(1.05, 1.05, 1.05);
  --mdb-animation-pulse-transform-to: scale3d(1, 1, 1);
  animation-name: pulse;
}

.lightbox img:not(.lightbox-disabled) {
  cursor: zoom-in;
}

.lightbox-gallery {
  --mdb-lightbox-zindex: 1100;
  --mdb-lightbox-toolbar-zindex: 1110;
  --mdb-lightbox-gallery-background-color: #000000e6;
  --mdb-lightbox-gallery-transition: all .3s ease-out;
  --mdb-lightbox-gallery-toolbar-height: 50px;
  --mdb-lightbox-gallery-toolbar-transition: opacity .4s;
  --mdb-lightbox-gallery-toolbar-button-width: 50px;
  --mdb-lightbox-gallery-toolbar-button-height: 50px;
  --mdb-lightbox-gallery-toolbar-button-color: #b3b3b3;
  --mdb-lightbox-gallery-toolbar-button-transition: color .2s;
  --mdb-lightbox-gallery-toolbar-button-hover-color: #fff;
  --mdb-lightbox-gallery-content-top: 50px;
  --mdb-lightbox-gallery-content-left: 50px;
  --mdb-lightbox-gallery-content-width: calc(100% - 100px);
  --mdb-lightbox-gallery-content-height: calc(100% - 100px);
  --mdb-lightbox-gallery-arrow-width: 50px;
  --mdb-lightbox-gallery-arrow-transition: opacity .4s;
  --mdb-lightbox-gallery-arrow-button-width: 50px;
  --mdb-lightbox-gallery-arrow-button-height: 50px;
  --mdb-lightbox-gallery-arrow-button-color: #b3b3b3;
  --mdb-lightbox-gallery-arrow-button-transition: color .2s;
  --mdb-lightbox-gallery-arrow-button-hover-color: #fff;
  --mdb-lightbox-gallery-button-focus-color: #fff;
  --mdb-lightbox-gallery-image-transform: scale(.25);
  --mdb-lightbox-gallery-image-transition: all .4s ease-out;
  --mdb-lightbox-gallery-counter-color: #b3b3b3;
  --mdb-lightbox-gallery-counter-padding-x: 10px;
  --mdb-lightbox-gallery-caption-color: #fff;
  --mdb-lightbox-gallery-caption-margin-x: 10px;
  --mdb-lightbox-gallery-caption-wrapper-height: 50px;
  --mdb-lightbox-gallery-loader-transition: opacity 1s;
  --mdb-lightbox-icons-font: var(--fa-font-solid);
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: var(--mdb-lightbox-zindex);
  background-color: var(--mdb-lightbox-gallery-background-color);
  opacity: 0;
  pointer-events: none;
  transition: var(--mdb-lightbox-gallery-transition);
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-toolbar {
  width: 100%;
  height: var(--mdb-lightbox-gallery-toolbar-height);
  z-index: var(--mdb-lightbox-toolbar-zindex);
  transition: var(--mdb-lightbox-gallery-toolbar-transition);
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-toolbar .lightbox-gallery-left-tools {
  float: left;
  height: 100%;
}

.lightbox-gallery .lightbox-gallery-toolbar .lightbox-gallery-right-tools {
  float: right;
}

.lightbox-gallery .lightbox-gallery-toolbar button {
  width: var(--mdb-lightbox-gallery-toolbar-button-width);
  height: var(--mdb-lightbox-gallery-toolbar-button-height);
  color: var(--mdb-lightbox-gallery-toolbar-button-color);
  transition: var(--mdb-lightbox-gallery-toolbar-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-toolbar button:hover {
  color: var(--mdb-lightbox-gallery-toolbar-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-toolbar button:before {
  font: var(--mdb-lightbox-icons-font);
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-fullscreen-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-fullscreen-btn.active:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-zoom-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-zoom-btn.active:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-toolbar button.lightbox-gallery-close-btn:before {
  content: "";
}

.lightbox-gallery .lightbox-gallery-counter {
  height: 100%;
  color: var(--mdb-lightbox-gallery-counter-color);
  padding: 0 var(--mdb-lightbox-gallery-counter-padding-x);
  direction: ltr;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.lightbox-gallery .lightbox-gallery-content {
  top: var(--mdb-lightbox-gallery-content-top);
  left: var(--mdb-lightbox-gallery-content-left);
  width: var(--mdb-lightbox-gallery-content-width);
  height: var(--mdb-lightbox-gallery-content-height);
  position: fixed;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image {
  opacity: 0;
  width: 100%;
  height: 100%;
  transform: var(--mdb-lightbox-gallery-image-transform);
  transition: var(--mdb-lightbox-gallery-image-transition);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image img {
  cursor: pointer;
  pointer-events: auto;
  transform-origin: 0 0;
  max-width: 100%;
  height: auto;
  transition: transform .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-content .lightbox-gallery-image img.vertical {
  width: auto;
  height: 100%;
  max-height: 100%;
  max-width: initial;
}

.lightbox-gallery .lightbox-gallery-arrow-right {
  width: var(--mdb-lightbox-gallery-arrow-width);
  height: 100%;
  transition: var(--mdb-lightbox-gallery-arrow-transition);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.lightbox-gallery .lightbox-gallery-arrow-right button {
  width: var(--mdb-lightbox-gallery-arrow-button-width);
  height: var(--mdb-lightbox-gallery-arrow-button-height);
  color: var(--mdb-lightbox-gallery-arrow-button-color);
  transition: var(--mdb-lightbox-gallery-arrow-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-arrow-right button:hover {
  color: var(--mdb-lightbox-gallery-arrow-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-arrow-right button:before {
  font: var(--mdb-lightbox-icons-font);
  content: "";
}

.lightbox-gallery .lightbox-gallery-arrow-left {
  width: var(--mdb-lightbox-gallery-arrow-width);
  height: 100%;
  transition: var(--mdb-lightbox-gallery-arrow-transition);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-arrow-left button {
  width: var(--mdb-lightbox-gallery-arrow-button-width);
  height: var(--mdb-lightbox-gallery-arrow-button-height);
  color: var(--mdb-lightbox-gallery-arrow-button-color);
  transition: var(--mdb-lightbox-gallery-arrow-button-transition);
  background: none;
  border: none;
}

.lightbox-gallery .lightbox-gallery-arrow-left button:hover {
  color: var(--mdb-lightbox-gallery-arrow-button-hover-color);
}

.lightbox-gallery .lightbox-gallery-arrow-left button:before {
  font: var(--mdb-lightbox-icons-font);
  content: "";
}

.lightbox-gallery .lightbox-gallery-caption-wrapper {
  width: 100%;
  height: var(--mdb-lightbox-gallery-caption-wrapper-height);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.lightbox-gallery .lightbox-gallery-caption-wrapper .lightbox-gallery-caption {
  color: var(--mdb-lightbox-gallery-caption-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 var(--mdb-lightbox-gallery-caption-margin-x);
  text-align: center;
  overflow: hidden;
}

.lightbox-gallery .lightbox-gallery-loader {
  z-index: 2;
  opacity: 1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  transition: var(--mdb-lightbox-gallery-loader-transition);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.lightbox-gallery button:focus {
  color: var(--mdb-lightbox-gallery-button-focus-color);
  outline: none;
}

.disabled-scroll {
  position: relative;
  overflow-y: hidden;
}

@media only screen and (device-width >= 768px) {
  .disabled-scroll.replace-scrollbar {
    --mdb-lightbox-disabled-scroll-media-padding-right: 17px;
    padding-right: var(--mdb-lightbox-disabled-scroll-media-padding-right);
  }
}

.rating {
  --mdb-rating-icon-padding: .25rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.rating li {
  list-style: none;
}

.rating i {
  padding: var(--mdb-rating-icon-padding);
}

.rating i.fa-sm {
  line-height: 1;
}

.rating:not([data-mdb-readonly="true"]) li {
  cursor: pointer;
}

.timepicker-wrapper {
  --mdb-timepicker-wrapper-bg: #0006;
  --mdb-timepicker-elements-min-width: 310px;
  --mdb-timepicker-elements-min-height: 325px;
  --mdb-timepicker-elements-background: var(--mdb-surface-bg);
  --mdb-timepicker-elements-border-top-right-radius: .6rem;
  --mdb-timepicker-elements-border-top-left-radius: .6rem;
  --mdb-timepicker-elements-media-border-bottom-left-radius: 0;
  --mdb-timepicker-head-bg: var(--mdb-picker-header-bg);
  --mdb-timepicker-head-height: 100px;
  --mdb-timepicker-head-border-top-right-radius: .5rem;
  --mdb-timepicker-head-border-top-left-radius: .5rem;
  --mdb-timepicker-head-padding-y: 10px;
  --mdb-timepicker-head-padding-right: 24px;
  --mdb-timepicker-head-padding-left: 50px;
  --mdb-timepicker-head-media-padding: 10px;
  --mdb-timepicker-head-media-padding-right: 10px;
  --mdb-timepicker-head-media-min-height: 305px;
  --mdb-timepicker-head-inline-border-bottom-right-radius: .5rem;
  --mdb-timepicker-head-inline-border-bottom-left-radius: .5rem;
  --mdb-timepicker-button-font-size: .8rem;
  --mdb-timepicker-button-min-width: 64px;
  --mdb-timepicker-button-font-weight: 500;
  --mdb-timepicker-button-line-height: 40px;
  --mdb-timepicker-button-border-radius: 10px;
  --mdb-timepicker-button-letter-spacing: .1rem;
  --mdb-timepicker-button-color: var(--mdb-surface-color);
  --mdb-timepicker-button-transition: background-color .25s cubic-bezier(.4, 0, .2, 1) 0s, box-shadow .25s cubic-bezier(.4, 0, .2, 1) 0s, border .25s cubic-bezier(.4, 0, .2, 1) 0s;
  --mdb-timepicker-button-padding-x: 10px;
  --mdb-timepicker-button-height: 40px;
  --mdb-timepicker-button-margin-bottom: 10px;
  --mdb-timepicker-button-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .08);
  --mdb-timepicker-button-focus-bg: rgba(var(--mdb-emphasis-color-rgb), .08);
  --mdb-timepicker-submit-inline-height: 48px;
  --mdb-timepicker-submit-inline-min-width: 48px;
  --mdb-timepicker-submit-inline-margin-left: 30px;
  --mdb-timepicker-submit-inline-color: #fff;
  --mdb-timepicker-current-font-size: 3.75rem;
  --mdb-timepicker-current-font-weight: 300;
  --mdb-timepicker-current-line-height: 1.2;
  --mdb-timepicker-current-letter-spacing: -.00833em;
  --mdb-timepicker-current-color: #fff;
  --mdb-timepicker-current-opacity: .54;
  --mdb-timepicker-clock-wrapper-min-width: 310px;
  --mdb-timepicker-clock-wrapper-max-width: 325px;
  --mdb-timepicker-clock-wrapper-min-height: 305px;
  --mdb-timepicker-clock-wrapper-text-color: var(--mdb-surface-color);
  --mdb-timepicker-mode-wrapper-font-size: 18px;
  --mdb-timepicker-mode-wrapper-color: #ffffff8a;
  --mdb-timepicker-clock-inner-top: 50%;
  --mdb-timepicker-clock-inner-left: 50%;
  --mdb-timepicker-clock-inner-transform: translate(-50%, -50%);
  --mdb-timepicker-clock-inner-width: 160px;
  --mdb-timepicker-clock-inner-height: 160px;
  --mdb-timepicker-time-tips-inner-width: 32px;
  --mdb-timepicker-time-tips-inner-height: 32px;
  --mdb-timepicker-time-tips-inner-font-size: 1.1rem;
  --mdb-timepicker-time-tips-inner-font-weight: 300;
  --mdb-timepicker-clock-width: 260px;
  --mdb-timepicker-clock-height: 260px;
  --mdb-timepicker-clock-bg: var(--mdb-timepicker-clock-face-bg);
  --mdb-timepicker-time-tips-inner-active-color: #fff;
  --mdb-timepicker-time-tips-inner-active-bg: #e3041c;
  --mdb-timepicker-time-tips-inner-active-font-weight: 400;
  --mdb-timepicker-time-tips-inner-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-timepicker-dot-font-weight: 300;
  --mdb-timepicker-dot-line-height: 1.2;
  --mdb-timepicker-dot-letter-spacing: -.00833em;
  --mdb-timepicker-dot-color: #fff;
  --mdb-timepicker-dot-media-font-size: 3rem;
  --mdb-timepicker-dot-media-font-weight: 400;
  --mdb-timepicker-dot-font-size: 3.75rem;
  --mdb-timepicker-dot-opacity: .54;
  --mdb-timepicker-current-inline-font-size: 2.5rem;
  --mdb-timepicker-item-width: 20px;
  --mdb-timepicker-item-height: 20px;
  --mdb-timepicker-item-padding: 10px;
  --mdb-timepicker-item-font-size: 1.1em;
  --mdb-timepicker-item-middle-dot-top: 50%;
  --mdb-timepicker-item-middle-dot-left: 50%;
  --mdb-timepicker-item-middle-dot-width: 6px;
  --mdb-timepicker-item-middle-dot-height: 6px;
  --mdb-timepicker-item-middle-dot-transform: translate(-50%, -50%);
  --mdb-timepicker-item-middle-dot-border-radius: 50%;
  --mdb-timepicker-item-middle-dot-bg: #e3041c;
  --mdb-timepicker-hand-pointer-bg: #e3041c;
  --mdb-timepicker-hand-pointer-bottom: 50%;
  --mdb-timepicker-hand-pointer-height: 40%;
  --mdb-timepicker-hand-pointer-left: calc(50% - 1px);
  --mdb-timepicker-hand-pointer-width: 2px;
  --mdb-timepicker-transform-transition: transform .4s cubic-bezier(.4, 0, .2, 1) 0s, height .4s cubic-bezier(.4, 0, .2, 1) 0s;
  --mdb-timepicker-time-tips-width: 32px;
  --mdb-timepicker-time-tips-height: 32px;
  --mdb-timepicker-time-tips-font-size: 1.1rem;
  --mdb-timepicker-time-tips-active-color: #fff;
  --mdb-timepicker-circle-top: -21px;
  --mdb-timepicker-circle-left: -15px;
  --mdb-timepicker-circle-width: 4px;
  --mdb-timepicker-circle-border-width: 14px;
  --mdb-timepicker-circle-border-color: #e3041c;
  --mdb-timepicker-circle-height: 4px;
  --mdb-timepicker-circle-active-background-color: #fff;
  --mdb-timepicker-hour-mode-color: #fff;
  --mdb-timepicker-hour-mode-opacity: .54;
  --mdb-timepicker-hour-mode-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .15);
  --mdb-timepicker-hour-mode-font-size: 2.5rem;
  --mdb-timepicker-hour-mode-active-color: #fff;
  --mdb-timepicker-footer-border-bottom-left-radius: .5rem;
  --mdb-timepicker-footer-border-bottom-right-radius: .5rem;
  --mdb-timepicker-footer-height: 56px;
  --mdb-timepicker-footer-padding-x: 12px;
  --mdb-timepicker-footer-bg: var(--mdb-surface-bg);
  --mdb-timepicker-container-max-height: calc(100% - 64px);
  --mdb-timepicker-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-timepicker-icon-up-down-transition: .2s ease all;
  --mdb-timepicker-icon-up-down-transform: translate(-50%, -50%);
  --mdb-timepicker-icon-up-down-left: 50%;
  --mdb-timepicker-icon-up-down-width: 30px;
  --mdb-timepicker-icon-up-down-height: 30px;
  --mdb-timepicker-icon-up-top: -35px;
  --mdb-timepicker-icon-down-bottom: -47px;
  --mdb-timepicker-icon-btn-width: 30px;
  --mdb-timepicker-icon-btn-height: 30px;
  --mdb-timepicker-input-color: #e3041c;
  --mdb-timepicker-clock-animation: show-up-clock .35s linear;
  touch-action: none;
  z-index: var(--mdb-timepicker-zindex);
  opacity: 0;
  background-color: var(--mdb-timepicker-wrapper-bg);
  inset: 0;
}

.timepicker-wrapper-inline {
  --mdb-timepicker-wrapper-inline-border-radius: .5rem;
  border-radius: var(--mdb-timepicker-wrapper-inline-border-radius);
  overflow: hidden;
}

.timepicker-elements {
  min-width: var(--mdb-timepicker-elements-min-width);
  min-height: var(--mdb-timepicker-elements-min-height);
  background: var(--mdb-timepicker-elements-background);
  border-top-right-radius: var(--mdb-timepicker-elements-border-top-right-radius);
  border-top-left-radius: var(--mdb-timepicker-elements-border-top-left-radius);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-elements {
    border-bottom-left-radius: var(--mdb-timepicker-elements-media-border-bottom-left-radius);
    min-width: auto;
    min-height: auto;
    overflow-y: auto;
    flex-direction: row !important;
  }
}

.timepicker-elements-inline {
  min-height: auto;
}

.timepicker-head {
  background-color: var(--mdb-timepicker-head-bg);
  height: var(--mdb-timepicker-head-height);
  border-top-right-radius: var(--mdb-timepicker-head-border-top-right-radius);
  border-top-left-radius: var(--mdb-timepicker-head-border-top-left-radius);
  padding: var(--mdb-timepicker-head-padding-y) var(--mdb-timepicker-head-padding-right) var(--mdb-timepicker-head-padding-y) var(--mdb-timepicker-head-padding-left);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-head {
    padding: var(--mdb-timepicker-head-media-padding);
    height: auto;
    min-height: var(--mdb-timepicker-head-media-min-height);
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: var(--mdb-timepicker-head-media-padding-right) !important;
  }
}

.timepicker-head-inline {
  border-bottom-right-radius: var(--mdb-timepicker-head-inline-border-bottom-right-radius);
  border-bottom-left-radius: var(--mdb-timepicker-head-inline-border-bottom-left-radius);
  padding: 0;
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-head-content {
    flex-direction: column;
  }
}

.timepicker-button {
  font-size: var(--mdb-timepicker-button-font-size);
  min-width: var(--mdb-timepicker-button-min-width);
  box-sizing: border-box;
  font-weight: var(--mdb-timepicker-button-font-weight);
  line-height: var(--mdb-timepicker-button-line-height);
  border-radius: var(--mdb-timepicker-button-border-radius);
  letter-spacing: var(--mdb-timepicker-button-letter-spacing);
  text-transform: uppercase;
  color: var(--mdb-timepicker-button-color);
  transition: var(--mdb-timepicker-button-transition);
  padding: 0 var(--mdb-timepicker-button-padding-x);
  height: var(--mdb-timepicker-button-height);
  margin-bottom: var(--mdb-timepicker-button-margin-bottom);
  background-color: #0000;
  border: none;
  outline: none;
}

.timepicker-button:hover {
  background-color: var(--mdb-timepicker-button-hover-bg);
}

.timepicker-button:focus {
  background-color: var(--mdb-timepicker-button-focus-bg);
  outline: none;
}

.timepicker-submit-inline {
  height: var(--mdb-timepicker-submit-inline-height);
  min-width: var(--mdb-timepicker-submit-inline-min-width);
  margin-left: var(--mdb-timepicker-submit-inline-margin-left);
  color: var(--mdb-timepicker-submit-inline-color);
  border-radius: 100%;
  display: inline-block;
}

.timepicker-current {
  font-size: var(--mdb-timepicker-current-font-size);
  font-weight: var(--mdb-timepicker-current-font-weight);
  line-height: var(--mdb-timepicker-current-line-height);
  letter-spacing: var(--mdb-timepicker-current-letter-spacing);
  color: var(--mdb-timepicker-current-color);
  opacity: var(--mdb-timepicker-current-opacity);
  vertical-align: unset;
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.timepicker-current.active {
  opacity: 1;
}

.timepicker-current-wrapper {
  direction: ltr;
}

.timepicker-mode-wrapper {
  font-size: var(--mdb-timepicker-mode-wrapper-font-size);
  color: var(--mdb-timepicker-mode-wrapper-color);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-mode-wrapper {
    flex-direction: row !important;
    justify-content: space-around !important;
  }
}

.timepicker-mode-wrapper.active {
  opacity: 1;
}

.timepicker-clock-wrapper {
  min-width: var(--mdb-timepicker-clock-wrapper-min-width);
  max-width: var(--mdb-timepicker-clock-wrapper-max-width);
  min-height: var(--mdb-timepicker-clock-wrapper-min-height);
  height: 100%;
  color: var(--mdb-timepicker-clock-wrapper-text-color);
  overflow-x: hidden;
}

.timepicker-clock {
  width: var(--mdb-timepicker-clock-width);
  height: var(--mdb-timepicker-clock-height);
  cursor: default;
  background-color: var(--mdb-timepicker-clock-bg);
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
}

.timepicker-clock-inner {
  top: var(--mdb-timepicker-clock-inner-top);
  left: var(--mdb-timepicker-clock-inner-left);
  transform: var(--mdb-timepicker-clock-inner-transform);
  width: var(--mdb-timepicker-clock-inner-width);
  height: var(--mdb-timepicker-clock-inner-height);
  border-radius: 100%;
  position: absolute;
}

.timepicker-time-tips-minutes, .timepicker-time-tips-inner, .timepicker-time-tips-hours {
  width: var(--mdb-timepicker-time-tips-inner-width);
  height: var(--mdb-timepicker-time-tips-inner-height);
  text-align: center;
  cursor: pointer;
  font-size: var(--mdb-timepicker-time-tips-inner-font-size);
  font-weight: var(--mdb-timepicker-time-tips-inner-font-weight);
  background-color: #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.timepicker-time-tips-minutes.active, .timepicker-time-tips-inner.active, .timepicker-time-tips-hours.active {
  color: var(--mdb-timepicker-time-tips-inner-active-color);
  background-color: var(--mdb-timepicker-time-tips-inner-active-bg);
  font-weight: var(--mdb-timepicker-time-tips-inner-active-font-weight);
}

.timepicker-time-tips-minutes:focus, .timepicker-time-tips-inner:focus, .timepicker-time-tips-hours:focus {
  outline: none;
}

.timepicker-time-tips-minutes.disabled, .timepicker-time-tips-inner.disabled, .timepicker-time-tips-hours.disabled {
  color: var(--mdb-timepicker-time-tips-inner-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.timepicker-time-tips-minutes::selection, .timepicker-time-tips-inner::selection, .timepicker-time-tips-hours::selection {
  background-color: #0000;
}

.timepicker-current, .timepicker-dot {
  font-weight: var(--mdb-timepicker-dot-font-weight);
  line-height: var(--mdb-timepicker-dot-line-height);
  letter-spacing: var(--mdb-timepicker-dot-letter-spacing);
  color: var(--mdb-timepicker-dot-color);
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-current, .timepicker-dot {
    font-size: var(--mdb-timepicker-dot-media-font-size);
    font-weight: var(--mdb-timepicker-dot-media-font-weight);
  }
}

.timepicker-dot {
  font-size: var(--mdb-timepicker-dot-font-size);
  opacity: var(--mdb-timepicker-dot-opacity);
  background: none;
  border: none;
  padding: 0;
}

.timepicker-current-inline {
  font-size: var(--mdb-timepicker-current-inline-font-size);
}

.timepicker-item {
  width: var(--mdb-timepicker-item-width);
  height: var(--mdb-timepicker-item-height);
  text-align: center;
  padding: var(--mdb-timepicker-item-padding);
  user-select: none;
  cursor: default;
  font-size: var(--mdb-timepicker-item-font-size);
  z-index: 3;
  border-radius: 100%;
  position: absolute;
}

.timepicker-middle-dot {
  top: var(--mdb-timepicker-item-middle-dot-top);
  left: var(--mdb-timepicker-item-middle-dot-left);
  width: var(--mdb-timepicker-item-middle-dot-width);
  height: var(--mdb-timepicker-item-middle-dot-height);
  transform: var(--mdb-timepicker-item-middle-dot-transform);
  border-radius: var(--mdb-timepicker-item-middle-dot-border-radius);
  background-color: var(--mdb-timepicker-item-middle-dot-bg);
}

.timepicker-hand-pointer {
  background-color: var(--mdb-timepicker-hand-pointer-bg);
  bottom: var(--mdb-timepicker-hand-pointer-bottom);
  height: var(--mdb-timepicker-hand-pointer-height);
  left: var(--mdb-timepicker-hand-pointer-left);
  transform-origin: center bottom 0;
  width: var(--mdb-timepicker-hand-pointer-width);
}

.timepicker-transform {
  transition: var(--mdb-timepicker-transform-transition);
}

.timepicker-time-tips {
  width: var(--mdb-timepicker-time-tips-width);
  height: var(--mdb-timepicker-time-tips-height);
  text-align: center;
  cursor: pointer;
  font-size: var(--mdb-timepicker-time-tips-font-size);
  border-radius: 100%;
  position: absolute;
}

.timepicker-time-tips.active {
  color: var(--mdb-timepicker-time-tips-active-color);
}

.timepicker-circle {
  top: var(--mdb-timepicker-circle-top);
  left: var(--mdb-timepicker-circle-left);
  width: var(--mdb-timepicker-circle-width);
  border: var(--mdb-timepicker-circle-border-width) solid var(--mdb-timepicker-circle-border-color);
  height: var(--mdb-timepicker-circle-height);
  box-sizing: content-box;
  background-color: #0000;
  border-radius: 100%;
}

.timepicker-circle.active {
  background-color: var(--mdb-timepicker-circle-active-background-color);
}

.timepicker-hour-mode {
  color: var(--mdb-timepicker-hour-mode-color);
  opacity: var(--mdb-timepicker-hour-mode-opacity);
  background-color: #0000;
  border: none;
  padding: 0;
}

.timepicker-hour-mode, .timepicker-hour, .timepicker-minute {
  cursor: pointer;
}

.timepicker-hour-mode:hover, .timepicker-hour-mode:focus, .timepicker-hour:hover, .timepicker-hour:focus, .timepicker-minute:hover, .timepicker-minute:focus {
  background-color: var(--mdb-timepicker-hour-mode-hover-bg);
  outline: none;
}

.timepicker-hour-mode.active, .timepicker-hour.active, .timepicker-minute.active {
  color: #fff;
  opacity: 1;
}

.timepicker-hour-mode-current-inline, .timepicker-hour-current-inline, .timepicker-minute-current-inline {
  font-size: 2.5rem;
}

.timepicker-footer {
  border-bottom-left-radius: var(--mdb-timepicker-footer-border-bottom-left-radius);
  border-bottom-right-radius: var(--mdb-timepicker-footer-border-bottom-right-radius);
  width: 100%;
  height: var(--mdb-timepicker-footer-height);
  padding-left: var(--mdb-timepicker-footer-padding-x);
  padding-right: var(--mdb-timepicker-footer-padding-x);
  background-color: var(--mdb-timepicker-footer-bg);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.timepicker-container {
  max-height: var(--mdb-timepicker-container-max-height);
  box-shadow: var(--mdb-timepicker-container-box-shadow);
  overflow-y: auto;
}

.timepicker-icon-up, .timepicker-icon-down {
  opacity: 0;
  transition: var(--mdb-timepicker-icon-up-down-transition);
  cursor: pointer;
  transform: var(--mdb-timepicker-icon-up-down-transform);
  left: var(--mdb-timepicker-icon-up-down-left);
  width: var(--mdb-timepicker-icon-up-down-width);
  height: var(--mdb-timepicker-icon-up-down-height);
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timepicker-icon-up.active, .timepicker-icon-up:hover, .timepicker-icon-down.active, .timepicker-icon-down:hover {
  opacity: 1;
}

.timepicker-icon-up {
  top: var(--mdb-timepicker-icon-up-top);
}

.timepicker-icon-down {
  bottom: var(--mdb-timepicker-icon-down-bottom);
}

.timepicker-inline-hour-icons button, .timepicker-inline-minutes-icons button {
  cursor: default;
  opacity: 1 !important;
}

.timepicker-inline-hour-icons button:hover, .timepicker-inline-minutes-icons button:hover {
  background-color: unset !important;
}

.timepicker-clock-animation {
  animation: var(--mdb-timepicker-clock-animation);
}

.timepicker-icon-btn {
  width: var(--mdb-timepicker-icon-btn-width);
  height: var(--mdb-timepicker-icon-btn-height);
}

.timepicker-toggle-button {
  --mdb-timepicker-btn-icon-right: -10px;
  --mdb-timepicker-btn-icon-top: 50%;
  --mdb-timepicker-btn-icon-transform: translate(-50%, -50%);
  --mdb-timepicker-btn-icon-transition: all .3s ease;
  --mdb-timepicker-btn-icon-hover-color: #e3041c;
  --mdb-timepicker-btn-icon-focus-color: #e3041c;
  right: var(--mdb-timepicker-btn-icon-right);
  top: var(--mdb-timepicker-btn-icon-top);
  transform: var(--mdb-timepicker-btn-icon-transform);
  transition: var(--mdb-timepicker-btn-icon-transition);
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  padding-inline: 6px;
  position: absolute;
}

.timepicker-toggle-button:hover {
  color: var(--mdb-timepicker-btn-icon-hover-color);
}

.timepicker-toggle-button:focus {
  color: var(--mdb-timepicker-btn-icon-focus-color);
}

.timepicker-input:focus + .timepicker-toggle-button, .timepicker-input:focus + .timepicker-toggle-button i {
  color: var(--mdb-timepicker-input-color);
}

.timepicker a.timepicker-toggle-button, .timepicker-toggle-button.timepicker-icon {
  right: 1px;
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.timepicker-modal {
  --mdb-timepicker-zindex: 1065;
  z-index: var(--mdb-timepicker-zindex);
}

.timepicker-modal .fade.show {
  opacity: 1;
}

.navbar {
  --mdb-navbar-scroll-transition: background .5s ease-in-out, padding .5s ease-in-out;
  --mdb-navbar-scroll-scrolled-padding-y: 5px;
  --mdb-navbar-scroll-scrolled-color: #4f4f4f;
  --mdb-navbar-scroll-color: #fff;
  --mdb-navbar-scrolled-bg: #fff;
}

.navbar.navbar-scroll {
  transition: var(--mdb-navbar-scroll-transition);
}

.navbar.navbar-scroll.navbar-scrolled {
  padding-top: var(--mdb-navbar-scroll-scrolled-padding-y);
  padding-bottom: var(--mdb-navbar-scroll-scrolled-padding-y);
}

.navbar-scroll .nav-link, .navbar-scroll .sub-link, .navbar-scroll .fa-bars {
  color: var(--mdb-navbar-scroll-color);
}

.navbar-scrolled .nav-link, .navbar-scrolled .sub-link, .navbar-scrolled .fa-bars {
  color: var(--mdb-navbar-scroll-scrolled-color);
}

.navbar-scrolled {
  background-color: var(--mdb-navbar-scrolled-bg);
}

:root {
  --mdb-datepicker-backdrop-background-color: #0006;
  --mdb-datepicker-zindex: 1065;
  --mdb-datepicker-container-zindex: 1066;
  --mdb-datepicker-toggle-right: -10px;
  --mdb-datepicker-toggle-top: 50%;
  --mdb-datepicker-toggle-focus-color: #e3041c;
}

.datepicker-toggle-button {
  right: var(--mdb-datepicker-toggle-right);
  top: var(--mdb-datepicker-toggle-top);
  background-color: #0000;
  border: none;
  outline: none;
  padding-inline: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.datepicker-toggle-button:focus, .datepicker-toggle-button:hover {
  color: var(--mdb-datepicker-toggle-focus-color);
}

.datepicker-backdrop {
  background-color: var(--mdb-datepicker-backdrop-background-color);
  width: 100%;
  height: 100%;
  z-index: var(--mdb-datepicker-zindex);
  position: fixed;
  inset: 0;
}

.datepicker-dropdown-container {
  --mdb-datepicker-dropdown-container-width: 328px;
  --mdb-datepicker-dropdown-container-height: 380px;
  --mdb-datepicker-dropdown-container-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-dropdown-container-border-radius: .5rem;
  --mdb-datepicker-dropdown-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  width: var(--mdb-datepicker-dropdown-container-width);
  height: var(--mdb-datepicker-dropdown-container-height);
  background-color: var(--mdb-datepicker-dropdown-container-background-color);
  border-radius: var(--mdb-datepicker-dropdown-container-border-radius);
  box-shadow: var(--mdb-datepicker-dropdown-container-box-shadow);
  z-index: var(--mdb-datepicker-container-zindex);
}

.datepicker-modal-container {
  --mdb-datepicker-modal-container-transform: translate(-50%, -50%);
  --mdb-datepicker-modal-container-width: 328px;
  --mdb-datepicker-modal-container-height: 512px;
  --mdb-datepicker-modal-container-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-modal-container-border-radius: .6rem .6rem .5rem .5rem;
  --mdb-datepicker-modal-container-box-shadow: 0 2px 15px -3px rgba(var(--mdb-box-shadow-color-rgb), .07), 0 10px 20px -2px rgba(var(--mdb-box-shadow-color-rgb), .04);
  --mdb-datepicker-modal-container-date-media-margin-top: 100px;
  --mdb-datepicker-modal-container-day-cell-media-width: 32px;
  --mdb-datepicker-modal-container-day-cell-media-height: 32px;
  --mdb-datepicker-modal-container-media-width: 475px;
  --mdb-datepicker-modal-container-media-height: 360px;
  --mdb-datepicker-header-border-radius-landscape: .5rem 0 0 .5rem;
  --mdb-datepicker-header-height: 120px;
  --mdb-datepicker-header-padding-x: 24px;
  --mdb-datepicker-header-background-color: var(--mdb-picker-header-bg);
  --mdb-datepicker-header-border-radius: .5rem .5rem 0 0;
  --mdb-datepicker-title-height: 32px;
  --mdb-datepicker-title-text-font-size: 10px;
  --mdb-datepicker-title-text-font-weight: 400;
  --mdb-datepicker-title-text-letter-spacing: 1.7px;
  --mdb-datepicker-title-text-color: #fff;
  --mdb-datepicker-date-height: 72px;
  --mdb-datepicker-date-text-font-size: 34px;
  --mdb-datepicker-date-text-font-weight: 400;
  --mdb-datepicker-date-text-color: #fff;
  --mdb-datepicker-footer-height: 56px;
  --mdb-datepicker-footer-padding-x: 12px;
  --mdb-datepicker-footer-btn-background-color: var(--mdb-surface-bg);
  --mdb-datepicker-footer-btn-color: var(--mdb-surface-color);
  --mdb-datepicker-footer-btn-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-footer-btn-padding-x: 10px;
  --mdb-datepicker-footer-btn-font-size: .8rem;
  --mdb-datepicker-footer-btn-font-weight: 500;
  --mdb-datepicker-footer-btn-height: 40px;
  --mdb-datepicker-footer-btn-line-height: 40px;
  --mdb-datepicker-footer-btn-letter-spacing: .1rem;
  --mdb-datepicker-footer-btn-border-radius: 10px;
  --mdb-datepicker-footer-btn-margin-bottom: 10px;
  --mdb-datepicker-footer-btn-state-background-color: var(--mdb-highlight-bg-color);
  transform: var(--mdb-datepicker-modal-container-transform);
  width: var(--mdb-datepicker-modal-container-width);
  height: var(--mdb-datepicker-modal-container-height);
  background-color: var(--mdb-datepicker-modal-container-background-color);
  border-radius: var(--mdb-datepicker-modal-container-border-radius);
  box-shadow: var(--mdb-datepicker-modal-container-box-shadow);
  z-index: var(--mdb-datepicker-container-zindex);
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-modal-container .datepicker-header {
    height: 100%;
  }

  .datepicker-modal-container .datepicker-date {
    margin-top: var(--mdb-datepicker-modal-container-date-media-margin-top);
  }

  .datepicker-modal-container {
    width: var(--mdb-datepicker-modal-container-media-width);
    height: var(--mdb-datepicker-modal-container-media-height);
    flex-direction: row;
  }

  .datepicker-modal-container .datepicker-day-cell {
    width: var(--mdb-datepicker-modal-container-day-cell-media-width);
    height: var(--mdb-datepicker-modal-container-day-cell-media-height);
  }
}

.datepicker-header {
  height: var(--mdb-datepicker-header-height);
  padding-right: var(--mdb-datepicker-header-padding-x);
  padding-left: var(--mdb-datepicker-header-padding-x);
  background-color: var(--mdb-datepicker-header-background-color);
  border-radius: var(--mdb-datepicker-header-border-radius);
  flex-direction: column;
  display: flex;
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-header {
    border-radius: var(--mdb-datepicker-header-border-radius-landscape);
  }
}

.datepicker-title {
  height: var(--mdb-datepicker-title-height);
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-title-text {
  font-size: var(--mdb-datepicker-title-text-font-size);
  font-weight: var(--mdb-datepicker-title-text-font-weight);
  text-transform: uppercase;
  letter-spacing: var(--mdb-datepicker-title-text-letter-spacing);
  color: var(--mdb-datepicker-title-text-color);
}

.datepicker-date {
  height: var(--mdb-datepicker-date-height);
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-date-text {
  font-size: var(--mdb-datepicker-date-text-font-size);
  font-weight: var(--mdb-datepicker-date-text-font-weight);
  color: var(--mdb-datepicker-date-text-color);
}

.datepicker-main {
  --mdb-datepicker-date-controls-padding-top: 10px;
  --mdb-datepicker-date-controls-padding-x: 12px;
  --mdb-datepicker-date-controls-color: #000000a3;
  --mdb-datepicker-view-change-button-padding: 10px;
  --mdb-datepicker-view-change-button-color: var(--mdb-surface-color);
  --mdb-datepicker-view-change-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-view-change-button-font-weight: 500;
  --mdb-datepicker-view-change-button-font-size: .9rem;
  --mdb-datepicker-view-change-button-border-radius: 10px;
  --mdb-datepicker-view-change-button-state-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-view-change-button-after-border-width: 5px;
  --mdb-datepicker-view-change-button-after-margin-left: 5px;
  --mdb-datepicker-arrow-controls-margin-top: 10px;
  --mdb-datepicker-previous-button-width: 40px;
  --mdb-datepicker-previous-button-height: 40px;
  --mdb-datepicker-previous-button-line-height: 40px;
  --mdb-datepicker-previous-button-color: var(--mdb-surface-color);
  --mdb-datepicker-previous-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-previous-button-margin-right: 24px;
  --mdb-datepicker-previous-button-state-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-previous-button-state-border-radius: 50%;
  --mdb-datepicker-previous-button-after-margin: 15.5px;
  --mdb-datepicker-previous-button-after-border-width: 2px;
  --mdb-datepicker-previous-button-after-transform: translateX(2px) rotate(-45deg);
  --mdb-datepicker-next-button-width: 40px;
  --mdb-datepicker-next-button-height: 40px;
  --mdb-datepicker-next-button-line-height: 40px;
  --mdb-datepicker-next-button-color: var(--mdb-surface-color);
  --mdb-datepicker-next-button-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-next-button-margin-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-next-button-state-border-radius: 50%;
  --mdb-datepicker-next-button-after-margin: 15.5px;
  --mdb-datepicker-next-button-after-border-width: 2px;
  --mdb-datepicker-next-button-after-transform: translateX(-2px) rotate(45deg);
  --mdb-datepicker-view-padding-x: 12px;
  --mdb-datepicker-table-width: 304px;
  --mdb-datepicker-day-heading-width: 40px;
  --mdb-datepicker-day-heading-height: 40px;
  --mdb-datepicker-day-heading-font-size: 12px;
  --mdb-datepicker-day-heading-font-weight: 400;
  --mdb-datepicker-day-heading-color: var(--mdb-surface-color);
  --mdb-datepicker-cell-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-datepicker-cell-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-cell-selected-background-color: #e3041c;
  --mdb-datepicker-cell-selected-color: #fff;
  --mdb-datepicker-cell-focused-background-color: var(--mdb-highlight-bg-color);
  --mdb-datepicker-cell-focused-selected-background-color: #e3041c;
  --mdb-datepicker-cell-border-width: 1px;
  --mdb-datepicker-cell-border-color: var(--mdb-surface-color);
  --mdb-datepicker-cell-color: var(--mdb-surface-color);
  --mdb-datepicker-small-cell-width: 40px;
  --mdb-datepicker-small-cell-height: 40px;
  --mdb-datepicker-small-cell-content-width: 40px;
  --mdb-datepicker-small-cell-content-height: 40px;
  --mdb-datepicker-small-cell-content-line-height: 40px;
  --mdb-datepicker-small-cell-content-border-radius: 50%;
  --mdb-datepicker-small-cell-content-font-size: 13px;
  --mdb-datepicker-large-cell-width: 76px;
  --mdb-datepicker-large-cell-height: 42px;
  --mdb-datepicker-large-cell-content-width: 72px;
  --mdb-datepicker-large-cell-content-height: 40px;
  --mdb-datepicker-large-cell-content-line-height: 40px;
  --mdb-datepicker-large-cell-content-padding-y: 1px;
  --mdb-datepicker-large-cell-content-padding-x: 2px;
  --mdb-datepicker-large-cell-content-border-radius: 999px;
  height: 100%;
  position: relative;
}

.datepicker-date-controls {
  padding: var(--mdb-datepicker-date-controls-padding-top) var(--mdb-datepicker-date-controls-padding-x) 0 var(--mdb-datepicker-date-controls-padding-x);
  color: var(--mdb-datepicker-date-controls-color);
  justify-content: space-between;
  display: flex;
}

.datepicker-view-change-button {
  padding: var(--mdb-datepicker-view-change-button-padding);
  color: var(--mdb-datepicker-view-change-button-color);
  font-weight: var(--mdb-datepicker-view-change-button-font-weight);
  font-size: var(--mdb-datepicker-view-change-button-font-size);
  border-radius: var(--mdb-datepicker-view-change-button-border-radius);
  box-shadow: none;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
}

.datepicker-view-change-button:hover, .datepicker-view-change-button:focus {
  background-color: var(--mdb-datepicker-view-change-button-state-background-color);
}

.datepicker-view-change-button:after {
  content: "";
  border-left: var(--mdb-datepicker-view-change-button-after-border-width) solid #0000;
  border-right: var(--mdb-datepicker-view-change-button-after-border-width) solid #0000;
  border-top-width: var(--mdb-datepicker-view-change-button-after-border-width);
  width: 0;
  height: 0;
  margin: 0 0 0 var(--mdb-datepicker-view-change-button-after-margin-left);
  vertical-align: middle;
  border-top-style: solid;
  display: inline-block;
}

.datepicker-view-change-button.disabled {
  color: var(--mdb-datepicker-view-change-button-disabled-color);
}

.datepicker-arrow-controls {
  margin-top: var(--mdb-datepicker-arrow-controls-margin-top);
}

.datepicker-previous-button {
  width: var(--mdb-datepicker-previous-button-width);
  height: var(--mdb-datepicker-previous-button-height);
  line-height: var(--mdb-datepicker-previous-button-line-height);
  color: var(--mdb-datepicker-previous-button-color);
  margin: 0;
  margin-right: var(--mdb-datepicker-previous-button-margin-right);
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
}

.datepicker-previous-button:hover, .datepicker-previous-button:focus {
  background-color: var(--mdb-datepicker-previous-button-state-background-color);
  border-radius: var(--mdb-datepicker-previous-button-state-border-radius);
}

.datepicker-previous-button.disabled {
  color: var(--mdb-datepicker-previous-button-disabled-color);
}

.datepicker-previous-button:after {
  content: "";
  margin: var(--mdb-datepicker-previous-button-after-margin);
  border: 0 solid;
  border-top-width: var(--mdb-datepicker-previous-button-after-border-width);
  border-left-width: var(--mdb-datepicker-previous-button-after-border-width);
  transform: var(--mdb-datepicker-previous-button-after-transform);
  position: absolute;
  inset: 0;
}

.datepicker-next-button {
  width: var(--mdb-datepicker-next-button-width);
  height: var(--mdb-datepicker-next-button-height);
  line-height: var(--mdb-datepicker-next-button-line-height);
  color: var(--mdb-datepicker-next-button-color);
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.datepicker-next-button:hover, .datepicker-next-button:focus {
  background-color: var(--mdb-datepicker-next-button-margin-background-color);
  border-radius: var(--mdb-datepicker-next-button-state-border-radius);
}

.datepicker-next-button.disabled {
  color: var(--mdb-datepicker-next-button-disabled-color);
}

.datepicker-next-button:after {
  content: "";
  margin: var(--mdb-datepicker-next-button-after-margin);
  border: 0 solid;
  border-top-width: var(--mdb-datepicker-next-button-after-border-width);
  border-right-width: var(--mdb-datepicker-next-button-after-border-width);
  transform: var(--mdb-datepicker-next-button-after-transform);
  position: absolute;
  inset: 0;
}

.datepicker-view {
  padding-left: var(--mdb-datepicker-view-padding-x);
  padding-right: var(--mdb-datepicker-view-padding-x);
  outline: none;
  display: flex;
}

.datepicker-table {
  width: var(--mdb-datepicker-table-width);
  margin-left: auto;
  margin-right: auto;
}

.datepicker-day-heading {
  width: var(--mdb-datepicker-day-heading-width);
  height: var(--mdb-datepicker-day-heading-height);
  text-align: center;
  font-size: var(--mdb-datepicker-day-heading-font-size);
  font-weight: var(--mdb-datepicker-day-heading-font-weight);
  color: var(--prefixdatepicker-day-heading-color);
}

.datepicker-date-range {
  --mdb-datepicker-date-range-month-header-height: 1.6rem;
  --mdb-datepicker-date-range-month-header-font-size: 1rem;
}

.datepicker-date-range.datepicker-dropdown-container {
  width: calc(var(--mdb-datepicker-dropdown-container-width) * 2);
  height: calc(var(--mdb-datepicker-dropdown-container-height)  + var(--mdb-datepicker-date-range-month-header-height));
}

@media screen and (width >= 320px) and (width <= 820px) {
  .datepicker-date-range.datepicker-dropdown-container {
    width: var(--mdb-datepicker-dropdown-container-width);
    height: var(--mdb-datepicker-dropdown-container-height);
  }

  .datepicker-date-range.datepicker-dropdown-container .vr, .datepicker-date-range.datepicker-dropdown-container .datepicker-table:nth-of-type(2), .datepicker-date-range.datepicker-dropdown-container .datepicker-month-header {
    display: none;
  }
}

.datepicker-date-range.datepicker-modal-container {
  width: calc(var(--mdb-datepicker-modal-container-width) * 2);
  height: calc(var(--mdb-datepicker-modal-container-height)  + var(--mdb-datepicker-date-range-month-header-height));
}

@media screen and (width >= 320px) and (width <= 700px) {
  .datepicker-date-range.datepicker-modal-container {
    height: var(--mdb-datepicker-modal-container-height);
    width: var(--mdb-datepicker-modal-container-width);
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-date {
    height: calc(var(--mdb-datepicker-date-height) * 2);
    justify-content: start;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-date .datepicker-date-text {
    font-size: calc(var(--mdb-datepicker-date-text-font-size) * .9);
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-table {
    width: calc(var(--mdb-datepicker-modal-container-day-cell-media-width) * 7);
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-view .vr, .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-table:nth-of-type(2), .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-month-header {
    display: none;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-footer-btn {
    margin-bottom: 0;
  }
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-date-range.datepicker-modal-container {
    width: var(--mdb-datepicker-modal-container-media-width);
    height: calc(var(--mdb-datepicker-modal-container-media-height)  + var(--mdb-datepicker-modal-container-day-cell-media-height));
    flex-direction: row;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-date {
    justify-content: start;
    height: 100%;
    margin-top: .5rem;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-date-controls {
    padding-top: 0;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-table {
    width: calc(var(--mdb-datepicker-modal-container-day-cell-media-width) * 7);
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-view .vr, .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-table:nth-of-type(2), .datepicker-date-range.datepicker-modal-container .datepicker-view .datepicker-month-header {
    display: none;
  }

  .datepicker-date-range.datepicker-modal-container .datepicker-footer-btn {
    margin-bottom: 0;
  }
}

.datepicker-date-range .datepicker-month-header {
  font-size: var(--mdb-datepicker-date-range-month-header-font-size);
  height: var(--mdb-datepicker-date-range-month-header-height);
}

.datepicker-cell {
  text-align: center;
  color: var(--mdb-datepicker-cell-color);
}

.datepicker-cell.disabled {
  color: var(--mdb-datepicker-cell-disabled-color);
  cursor: default;
  pointer-events: none;
}

.datepicker-cell.disabled:hover {
  cursor: default;
}

.datepicker-cell:hover {
  cursor: pointer;
}

.datepicker-cell:not(.disabled):not(.selected):hover .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-hover-background-color);
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-selected-background-color);
  color: var(--mdb-datepicker-cell-selected-color);
}

.datepicker-cell.in-range {
  background-color: var(--mdb-datepicker-cell-in-range-background-color);
}

.datepicker-cell.first-in-range {
  background: linear-gradient(to right, #fff0 50%, var(--mdb-datepicker-cell-in-range-background-color) 50%);
}

.datepicker-cell.last-in-range {
  background: linear-gradient(to left, #fff0 50%, var(--mdb-datepicker-cell-in-range-background-color) 50%);
}

.datepicker-cell:not(.selected).focused .datepicker-cell-content {
  background-color: var(--mdb-datepicker-cell-focused-background-color);
}

.datepicker-cell.focused .datepicker-cell-content.selected {
  background-color: var(--mdb-datepicker-cell-focused-selected-background-color);
}

.datepicker-cell.current .datepicker-cell-content {
  border: var(--mdb-datepicker-cell-border-width) solid var(--mdb-datepicker-cell-border-color);
}

.datepicker-small-cell {
  width: var(--mdb-datepicker-small-cell-width);
  height: var(--mdb-datepicker-small-cell-height);
}

.datepicker-small-cell-content {
  width: var(--mdb-datepicker-small-cell-content-width);
  height: var(--mdb-datepicker-small-cell-content-height);
  line-height: var(--mdb-datepicker-small-cell-content-line-height);
  border-radius: var(--mdb-datepicker-small-cell-content-border-radius);
  font-size: var(--mdb-datepicker-small-cell-content-font-size);
}

.datepicker-large-cell {
  width: var(--mdb-datepicker-large-cell-width);
  height: var(--mdb-datepicker-large-cell-height);
}

.datepicker-large-cell-content {
  width: var(--mdb-datepicker-large-cell-content-width);
  height: var(--mdb-datepicker-large-cell-content-height);
  line-height: var(--mdb-datepicker-large-cell-content-line-height);
  padding: var(--mdb-datepicker-large-cell-content-padding-y) var(--mdb-datepicker-large-cell-content-padding-x);
  border-radius: var(--mdb-datepicker-large-cell-content-border-radius);
}

.datepicker-footer {
  height: var(--mdb-datepicker-footer-height);
  width: 100%;
  padding-left: var(--mdb-datepicker-footer-padding-x);
  padding-right: var(--mdb-datepicker-footer-padding-x);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.datepicker-footer-btn {
  background-color: var(--mdb-datepicker-footer-btn-background-color);
  color: var(--mdb-datepicker-footer-btn-color);
  cursor: pointer;
  padding: 0 var(--mdb-datepicker-footer-btn-padding-x);
  text-transform: uppercase;
  font-size: var(--mdb-datepicker-footer-btn-font-size);
  font-weight: var(--mdb-datepicker-footer-btn-font-weight);
  height: var(--mdb-datepicker-footer-btn-height);
  line-height: var(--mdb-datepicker-footer-btn-line-height);
  letter-spacing: var(--mdb-datepicker-footer-btn-letter-spacing);
  border-radius: var(--mdb-datepicker-footer-btn-border-radius);
  margin-bottom: var(--mdb-datepicker-footer-btn-margin-bottom);
  border: none;
  outline: none;
}

.datepicker-footer-btn:hover, .datepicker-footer-btn:focus {
  background-color: var(--mdb-datepicker-footer-btn-state-background-color);
}

.datepicker-footer-btn.disabled {
  color: var(--mdb-datepicker-footer-btn-disabled-color);
}

.datepicker-clear-btn {
  margin-right: auto;
}

input[disabled] ~ .datepicker-toggle-button, input[readonly] ~ .datepicker-toggle-button {
  pointer-events: none;
}

:root {
  --mdb-popconfirm-zindex: 1080;
  --mdb-popconfirm-border-radius: .5rem;
}

.popconfirm {
  --mdb-popconfirm-padding: 1rem;
  --mdb-popconfirm-background-color: var(--mdb-surface-bg);
  --mdb-popconfirm-text-color: var(--mdb-surface-color);
  padding: var(--mdb-popconfirm-padding);
  background-color: var(--mdb-popconfirm-background-color);
  border-radius: var(--mdb-popconfirm-border-radius);
  opacity: 0;
  color: var(--mdb-popconfirm-text-color);
}

.popconfirm.show {
  opacity: 1;
}

.popconfirm-popover {
  --mdb-popconfirm-popover-width: 300px;
  --mdb-popconfirm-border: 1px solid var(--mdb-divider-color);
  z-index: var(--mdb-popconfirm-zindex);
  width: var(--mdb-popconfirm-popover-width);
  border-radius: var(--mdb-popconfirm-border-radius);
  border: var(--mdb-popconfirm-border);
}

.popconfirm-modal {
  --mdb-popconfirm-modal-width: 300px;
  z-index: var(--mdb-popconfirm-zindex);
  width: var(--mdb-popconfirm-modal-width);
  border-radius: var(--mdb-popconfirm-border-radius);
  position: absolute;
}

.popconfirm-buttons-container {
  justify-content: flex-end;
  display: flex;
}

.popconfirm-buttons-container .btn {
  --mdb-popconfirm-buttons-container-btn-ml: .5rem;
  margin-left: var(--mdb-popconfirm-buttons-container-btn-ml);
}

.popconfirm-backdrop {
  --mdb-popconfirm-backdrop-zindex: 1070;
  --mdb-popconfirm-backdrop-background-color: #0006;
  width: 100%;
  height: 100%;
  z-index: var(--mdb-popconfirm-backdrop-zindex);
  background-color: var(--mdb-popconfirm-backdrop-background-color);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.popconfirm-icon-container i {
  --mdb-popconfirm-icon-text-color: var(--mdb-surface-color);
  color: var(--mdb-popconfirm-icon-text-color);
}

.datatable {
  --mdb-datatable-color: var(--mdb-body-color);
  --mdb-datatable-border-color: var(--mdb-border-color);
  --mdb-datatable-striped-color: var(--mdb-body-color);
  --mdb-datatable-accent-bg: rgba(var(--mdb-emphasis-color-rgb), .02);
  --mdb-datatable-hover-color: var(--mdb-body-color);
  --mdb-datatable-hover-bg: rgba(var(--mdb-emphasis-color-rgb), .025);
  --mdb-datatable-muted-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-datatable-active-color: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-datatable-font-size: .9rem;
  --mdb-datatable-background-color: var(--mdb-body-bg);
  --mdb-datatable-table-th-td-max-width: 250px;
  --mdb-datatable-table-th-td-padding-y: 1rem;
  --mdb-datatable-table-th-td-padding-x: 1.4rem;
  --mdb-datatable-thead-tr-border-width: 1px;
  --mdb-datatable-thead-th-font-weight: 500;
  --mdb-datatable-thead-fixed-cell-background-color: var(--mdb-body-bg);
  --mdb-datatable-tbody-font-weight: 300;
  --mdb-datatable-tbody-tr-transition: all .3s ease-in;
  --mdb-datatable-tbody-tr-last-child-height: 71px;
  --mdb-datatable-tbody-loader-height: 2px;
  --mdb-datatable-tbody-progress-animation: datatableProgress 3s ease-in-out;
  --mdb-datatable-tbody-progress-width: 45%;
  --mdb-datatable-tbody-progress-opacity: .5;
  --mdb-datatable-tbody-progress-border-radius: 1px;
  --mdb-datatable-pagination-padding-y: .5rem;
  --mdb-datatable-pagination-border-width: 1px;
  --mdb-datatable-pagination-nav-font-size: .9rem;
  --mdb-datatable-pagination-buttons-margin-left: 2rem;
  --mdb-datatable-pagination-button-padding-x: 1rem;
  --mdb-datatable-sort-icon-transition-duration: .3s;
  --mdb-datatable-sort-icon-left: .4rem;
  --mdb-datatable-sort-icon-top: calc(50% - .5rem);
  --mdb-datatable-select-wrapper-font-size: .9rem;
  --mdb-datatable-select-wrapper-font-weight: 300;
  --mdb-datatable-sm-th-td-padding-y: .5rem;
  --mdb-datatable-sm-th-td-padding-x: 1.4rem;
  --mdb-datatable-sm-tbody-tr-last-child-height: 55px;
  --mdb-datatable-sm-pagination-padding: .2rem;
  --mdb-datatable-bordered-th-td-border-width: 1px;
  --mdb-datatable-hover-tbody-tr-transition: background-color .2s ease-in;
  font-size: var(--mdb-datatable-font-size);
  color: var(--mdb-datatable-color);
  background-color: var(--mdb-datatable-background-color);
}

.datatable-inner {
  background-color: inherit;
}

.datatable table {
  font-size: var(--mdb-datatable-font-size);
  color: var(--mdb-datatable-color);
  background-color: inherit;
  border-color: var(--mdb-datatable-border-color);
  margin-bottom: 0;
}

.datatable table th, .datatable table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--mdb-datatable-table-th-td-max-width);
  padding: var(--mdb-datatable-table-th-td-padding-y) var(--mdb-datatable-table-th-td-padding-x);
  overflow: hidden;
}

.datatable thead {
  background-color: inherit;
}

.datatable thead tr {
  background-color: inherit;
  border-bottom: var(--mdb-datatable-thead-tr-border-width) solid var(--mdb-datatable-border-color);
}

.datatable thead th {
  font-weight: var(--mdb-datatable-thead-th-font-weight);
  border-bottom: none;
  position: relative;
}

.datatable thead th:hover .datatable-sort-icon {
  opacity: 1;
}

.datatable thead .fixed-cell {
  z-index: 3;
  box-shadow: var(--mdb-datatable-border-color) 0px 1px;
  background-color: var(--mdb-datatable-thead-fixed-cell-background-color);
  position: sticky;
  top: 0;
}

.datatable tbody {
  font-weight: var(--mdb-datatable-tbody-font-weight);
  background-color: inherit;
}

.datatable tbody .fixed-cell {
  z-index: 1;
  background-color: inherit;
  position: sticky;
}

.datatable tbody tr {
  background-color: inherit;
  transition: var(--mdb-datatable-tbody-tr-transition);
}

.datatable tbody tr:last-child {
  height: var(--mdb-datatable-tbody-tr-last-child-height);
  border-bottom: #0000;
}

.datatable tbody tr:focus {
  outline: none;
}

.datatable tbody tr.active {
  background-color: var(--mdb-datatable-active-color);
}

.datatable tbody td:focus {
  outline: none;
}

.datatable-loader {
  width: 100%;
  height: var(--mdb-datatable-tbody-loader-height);
  position: relative;
  overflow: hidden;
}

.datatable-loader-inner {
  height: 100%;
  display: block;
}

.datatable-progress {
  animation: var(--mdb-datatable-tbody-progress-animation);
  width: var(--mdb-datatable-tbody-progress-width);
  opacity: var(--mdb-datatable-tbody-progress-opacity);
  border-radius: var(--mdb-datatable-tbody-progress-border-radius);
  height: 100%;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: block;
  position: relative;
}

@keyframes datatableProgress {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}

.datatable-pagination {
  width: 100%;
  padding: var(--mdb-datatable-pagination-padding-y) 0;
  border-top: var(--mdb-datatable-pagination-border-width) solid var(--mdb-datatable-border-color);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.datatable-pagination-nav {
  font-size: var(--mdb-datatable-pagination-nav-font-size);
  direction: ltr;
  padding: 0;
}

.datatable-pagination-buttons {
  margin-left: var(--mdb-datatable-pagination-buttons-margin-left);
}

.datatable-pagination-button {
  padding-left: var(--mdb-datatable-pagination-button-padding-x);
  padding-right: var(--mdb-datatable-pagination-button-padding-x);
  color: var(--mdb-datatable-color);
}

.datatable-sort-icon {
  opacity: 0;
  color: var(--mdb-datatable-muted-color);
  cursor: pointer;
  transition-property: opacity, transform;
  transition-duration: var(--mdb-datatable-sort-icon-transition-duration);
  left: var(--mdb-datatable-sort-icon-left);
  top: var(--mdb-datatable-sort-icon-top);
  transition-timing-function: linear;
  position: absolute;
}

.datatable-sort-icon.active {
  opacity: 1;
  color: var(--mdb-datatable-color);
}

.datatable-select-wrapper {
  font-size: var(--mdb-datatable-select-wrapper-font-size);
  font-weight: var(--mdb-datatable-select-wrapper-font-weight);
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.datatable-select-wrapper .select-input {
  max-width: 70px;
}

.datatable-select-text {
  margin: 0 1rem;
}

.datatable.datatable-sm th, .datatable.datatable-sm td {
  padding: var(--mdb-datatable-sm-th-td-padding-y) var(--mdb-datatable-sm-th-td-padding-x);
}

.datatable.datatable-sm tbody tr:last-child {
  height: var(--mdb-datatable-sm-tbody-tr-last-child-height);
  border-bottom: #0000;
}

.datatable.datatable-sm .datatable-pagination {
  padding: var(--mdb-datatable-sm-pagination-padding) 0;
}

.datatable.datatable-bordered th, .datatable.datatable-bordered td {
  border-width: var(--mdb-datatable-bordered-th-td-border-width);
}

.datatable.datatable-bordered .datatable-pagination {
  border: var(--mdb-datatable-bordered-th-td-border-width) solid var(--mdb-datatable-border-color);
}

.datatable.datatable-striped tbody tr:nth-of-type(odd) {
  background-color: var(--mdb-datatable-accent-bg);
  color: var(--mdb-datatable-striped-color);
}

.datatable.datatable-striped tbody tr:nth-of-type(odd) td {
  background-color: inherit;
  color: inherit;
}

.datatable.datatable-hover tbody tr {
  transition: var(--mdb-datatable-hover-tbody-tr-transition) !important;
}

.datatable.datatable-hover tbody tr:hover {
  color: var(--mdb-datatable-hover-color);
  background-color: var(--mdb-datatable-hover-bg) !important;
}

.datatable.datatable-hover tbody tr:hover td {
  background-color: inherit;
  color: inherit;
}

.datatable.datatable-clickable-rows tbody tr {
  cursor: pointer;
}

.datatable.datatable-clickable-rows tbody tr.active {
  background-color: var(--mdb-datatable-hover-bg);
}

.datatable.datatable-clickable-rows tbody tr.active td {
  background-color: inherit;
  color: inherit;
}

.datatable.border-primary {
  --mdb-datatable-border-color: #e3041c;
}

.datatable.border-secondary {
  --mdb-datatable-border-color: #203a42;
}

.datatable.border-success {
  --mdb-datatable-border-color: #00ae00;
}

.datatable.border-danger {
  --mdb-datatable-border-color: #e3041c;
}

.datatable.border-warning {
  --mdb-datatable-border-color: #ffc107;
}

.datatable.border-info {
  --mdb-datatable-border-color: #00abf0;
}

.datatable.border-light {
  --mdb-datatable-border-color: #d2d8d9;
}

.datatable.border-dark {
  --mdb-datatable-border-color: #060c0d;
}

.datatable.datatable-borderless {
  --mdb-datatable-border-color: transparent;
}

.datatable.datatable-loading, .datatable.datatable-loading th {
  color: var(--mdb-datatable-muted-color);
}

.datatable.datatable-loading .datatable-sort-icon {
  display: none;
}

@media (width <= 520px) {
  .datatable-pagination {
    flex-direction: column-reverse;
  }

  .datatable-pagination-nav {
    margin: .8rem 0;
  }

  .datatable-pagination-button {
    font-size: 1rem;
  }

  .datatable-pagination-buttons {
    margin: .8rem 0;
  }
}

.steps, .timeline, .stepper {
  --mdb-steps-transition: height .2s ease-in-out;
  width: 100%;
  transition: var(--mdb-steps-transition);
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.steps-step, .timeline-step, .stepper-vertical .stepper-step {
  --mdb-steps-step-after-left: 2.45rem;
  --mdb-steps-step-after-width: 1px;
  --mdb-steps-step-after-margin-top: .5rem;
  --mdb-steps-step-after-bg: rgba(var(--mdb-emphasis-color-rgb), .1);
  height: fit-content;
  position: relative;
}

.steps-step-after, .timeline-step:after, .stepper-vertical .stepper-step:not(:last-child):after {
  left: var(--mdb-steps-step-after-left);
  width: var(--mdb-steps-step-after-width);
  margin-top: var(--mdb-steps-step-after-margin-top);
  content: "";
  background-color: var(--mdb-steps-step-after-bg);
  position: absolute;
}

.steps-content, .timeline-content, .stepper-vertical .stepper-content {
  --mdb-steps-content-padding-y: 1.5rem;
  padding-top: 0;
  padding-bottom: var(--mdb-steps-content-padding-y);
  padding-right: var(--mdb-steps-content-padding-y);
  overflow: hidden;
}

.steps-head-vertical, .timeline-head, .stepper-vertical .stepper-head {
  --mdb-steps-head-vertical-padding-top: 1.5rem;
  --mdb-steps-head-vertical-padding-x: 1.5rem;
  padding-left: var(--mdb-steps-head-vertical-padding-x);
  padding-right: var(--mdb-steps-head-vertical-padding-x);
  padding-top: var(--mdb-steps-head-vertical-padding-top);
}

.steps-head-icon-vertical, .timeline-head-icon, .stepper-vertical .stepper-head-icon {
  --mdb-steps-head-icon-vertical-margin-right: .75rem;
  margin-right: var(--mdb-steps-head-icon-vertical-margin-right);
}

.steps-head-text-after-vertical, .stepper-vertical .stepper-head-text:after {
  position: absolute;
}

.steps-head, .timeline-head, .stepper-head {
  --mdb-steps-head-line-height: 1.3;
  --mdb-steps-head-hover-bgc: rgba(var(--mdb-emphasis-color-rgb), .025);
  color: unset;
  line-height: var(--mdb-steps-head-line-height);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.steps-head-hover, .stepper-head:hover {
  background-color: var(--mdb-steps-head-hover-bgc);
}

.steps-head-focus, .stepper-head:focus {
  outline: none;
}

.steps-head-text, .timeline-head-text, .stepper-head-text {
  --mdb-steps-head-text-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-steps-head-text-after-font-size: .8rem;
  color: var(--mdb-steps-head-text-color);
}

.steps-head-text-after, .timeline-head-text:after, .stepper-head-text:after {
  font-size: var(--mdb-steps-head-text-after-font-size);
  content: attr(data-mdb-content);
  display: flex;
}

.steps-head-icon, .timeline-head-icon, .stepper-head-icon {
  --mdb-steps-head-icon-font-size: .875rem;
  --mdb-steps-head-icon-width: 1.938rem;
  --mdb-steps-head-icon-height: 1.938rem;
  --mdb-steps-head-icon-font-weight: 500;
  font-size: var(--mdb-steps-head-icon-font-size);
  width: var(--mdb-steps-head-icon-width);
  height: var(--mdb-steps-head-icon-height);
  font-weight: var(--mdb-steps-head-icon-font-weight);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.steps-active-head-text, .timeline-head-text, .stepper-active .stepper-head-text {
  --mdb-steps-active-head-text-font-weight: 500;
  font-weight: var(--mdb-steps-active-head-text-font-weight);
}

.stepper {
  --mdb-stepper-padding-x: 1rem;
  --mdb-stepper-step-height: 4.5rem;
  --mdb-stepper-step-head-padding-left: 1.5rem;
  --mdb-stepper-step-head-padding-right: 1.5rem;
  --mdb-stepper-step-head-height: 1px;
  --mdb-stepper-step-head-bg: rgba(var(--mdb-emphasis-color-rgb), .1);
  --mdb-stepper-step-head-margin-right: .5rem;
  --mdb-stepper-step-head-margin-left: .5rem;
  --mdb-stepper-head-icon-margin-y: 1.5rem;
  --mdb-stepper-head-icon-margin-right: .5rem;
  --mdb-stepper-vertical-step-top: 3.25rem;
  --mdb-stepper-vertical-step-height: calc(100% - 2.45rem);
  --mdb-stepper-vertical-content-padding-left: 3.75rem;
  --mdb-stepper-vertical-content-transition: height .3s ease-in-out, margin-top .3s ease-in-out, margin-bottom .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out;
  --mdb-stepper-vertical-head-padding-bottom: 1.5rem;
  --mdb-stepper-mobile-step-margin-y: 1rem;
  --mdb-stepper-mobile-step-head-padding-x: .25rem;
  --mdb-stepper-mobile-head-icon-height: .5rem;
  --mdb-stepper-mobile-head-icon-width: .5rem;
  --mdb-stepper-mobile-content-top: 2.56rem;
  --mdb-stepper-mobile-active-head-icon-bg: var(--mdb-primary);
  --mdb-stepper-mobile-completed-head-icon-bg: var(--mdb-success);
  --mdb-stepper-head-icon-bg: var(--mdb-surface-inverted-bg);
  --mdb-stepper-head-icon-color: var(--mdb-surface-inverted-color);
  --mdb-stepper-completed-head-icon-bg: var(--mdb-success-bg-subtle);
  --mdb-stepper-completed-head-icon-color: var(--mdb-success-text-emphasis);
  --mdb-stepper-active-head-icon-bg: var(--mdb-primary-bg-subtle);
  --mdb-stepper-active-head-icon-color: var(--mdb-primary-text-emphasis);
  --mdb-stepper-invalid-head-icon-bg: var(--mdb-danger-bg-subtle);
  --mdb-stepper-invalid-head-icon-color: var(--mdb-danger-text-emphasis);
  --mdb-stepper-disabled-head-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-stepper-disabled-head-icon-bg: var(--mdb-surface-inverted-bg);
  --mdb-stepper-disabled-head-icon-color: rgba(var(--mdb-surface-inverted-color-rgb), .55);
  --mdb-stepper-mobile-head-padding-y: .5rem;
  --mdb-stepper-mobile-head-padding-x: 1rem;
  --mdb-stepper-mobile-footer-height: 2.5rem;
  --mdb-stepper-back-btn-i-margin-right: .5rem;
  --mdb-stepper-next-btn-i-margin-left: .5rem;
  --mdb-stepper-mobile-progress-bar-height: .3rem;
  --mdb-stepper-mobile-progress-height: .3rem;
  --mdb-stepper-mobile-progress-background-color: var(--mdb-secondary-bg);
  --mdb-stepper-mobile-active-progress-bar-color: var(--mdb-primary);
  --mdb-stepper-mobile-footer-bg: var(--mdb-stepper-mobile-bg);
  --mdb-stepper-mobile-head-bg: var(--mdb-stepper-mobile-bg);
  --mdb-stepper-mobile-invalid-icon-bg: var(--mdb-danger);
}

.stepper:not(.stepper-vertical) {
  justify-content: space-between;
  display: flex;
}

.stepper:not(.stepper-vertical) .stepper-content {
  width: 100%;
  padding-right: var(--mdb-stepper-padding-x);
  padding-left: var(--mdb-stepper-padding-x);
  position: absolute;
}

.stepper:not(.stepper-vertical) .stepper-step {
  height: var(--mdb-stepper-step-height);
  flex: auto;
}

.stepper:not(.stepper-vertical) .stepper-step:first-child .stepper-head {
  padding-left: var(--mdb-stepper-step-head-padding-left);
}

.stepper:not(.stepper-vertical) .stepper-step:last-child .stepper-head {
  padding-right: var(--mdb-stepper-step-head-padding-right);
}

.stepper:not(.stepper-vertical) .stepper-step:not(:first-child) .stepper-head:before {
  height: var(--mdb-stepper-step-head-height);
  width: 100%;
  margin-right: var(--mdb-stepper-step-head-margin-right);
  content: "";
  background-color: var(--mdb-stepper-step-head-bg);
  flex: 1;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after {
  height: var(--mdb-stepper-step-head-height);
  width: 100%;
  margin-left: var(--mdb-stepper-step-head-margin-left);
  content: "";
  background-color: var(--mdb-stepper-step-head-bg);
  flex: 1;
}

.stepper:not(.stepper-vertical) .stepper-head-icon {
  margin-top: var(--mdb-stepper-head-icon-margin-y);
  margin-right: var(--mdb-stepper-head-icon-margin-right);
  margin-bottom: var(--mdb-stepper-head-icon-margin-y);
  margin-left: 0;
}

.stepper-vertical .stepper-step:not(:last-child):after {
  top: var(--mdb-stepper-vertical-step-top);
  height: var(--mdb-stepper-vertical-step-height);
}

.stepper-vertical .stepper-content {
  padding-left: var(--mdb-stepper-vertical-content-padding-left);
  transition: var(--mdb-stepper-vertical-content-transition);
}

.stepper-vertical .stepper-content-hide {
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.stepper-vertical .stepper-head {
  padding-bottom: var(--mdb-stepper-vertical-head-padding-bottom);
}

.stepper.stepper-mobile {
  justify-content: center;
  align-items: flex-end;
}

.stepper.stepper-mobile.stepper-progress-bar .stepper-head-icon {
  display: none;
}

.stepper.stepper-mobile .stepper-step {
  flex: unset;
  height: fit-content;
  margin-top: var(--mdb-stepper-mobile-step-margin-y);
  margin-bottom: var(--mdb-stepper-mobile-step-margin-y);
}

.stepper.stepper-mobile .stepper-step:not(:last-child) .stepper-head:after {
  margin-left: 0;
}

.stepper.stepper-mobile .stepper-step:not(:first-child) .stepper-head:before {
  margin-right: 0;
}

.stepper.stepper-mobile .stepper-step:not(:last-child):not(:first-child) .stepper-head {
  padding-left: var(--mdb-stepper-mobile-step-head-padding-x);
  padding-right: var(--mdb-stepper-mobile-step-head-padding-x);
}

.stepper.stepper-mobile .stepper-head-icon {
  height: var(--mdb-stepper-mobile-head-icon-height);
  width: var(--mdb-stepper-mobile-head-icon-width);
  z-index: 1;
  margin: 0;
  font-size: 0;
}

.stepper.stepper-mobile .stepper-head-text {
  display: none;
}

.stepper.stepper-mobile .stepper-content {
  top: var(--mdb-stepper-mobile-content-top);
}

.stepper.stepper-mobile .stepper-active .stepper-head-icon {
  background-color: var(--mdb-stepper-mobile-active-head-icon-bg);
}

.stepper.stepper-mobile .stepper-completed .stepper-head-icon {
  background-color: var(--mdb-stepper-mobile-completed-head-icon-bg);
}

.stepper.stepper-mobile .stepper-invalid .stepper-head-icon {
  background-color: var(--mdb-stepper-mobile-invalid-icon-bg);
}

.stepper-form {
  display: inherit;
  justify-content: inherit;
  width: inherit;
  position: inherit;
}

.stepper-content {
  left: 0;
}

.stepper-head {
  cursor: pointer;
}

.stepper-head-icon {
  background-color: var(--mdb-stepper-head-icon-bg);
  color: var(--mdb-stepper-head-icon-color);
}

.stepper-completed .stepper-head-icon {
  background-color: var(--mdb-stepper-completed-head-icon-bg);
  color: var(--mdb-stepper-completed-head-icon-color);
}

.stepper-active .stepper-content {
  display: block;
}

.stepper-active .stepper-head-icon {
  background-color: var(--mdb-stepper-active-head-icon-bg);
  color: var(--mdb-stepper-active-head-icon-color);
}

.stepper-invalid .stepper-head-icon {
  background-color: var(--mdb-stepper-invalid-head-icon-bg);
  color: var(--mdb-stepper-invalid-head-icon-color);
}

.stepper-disabled .stepper-head {
  cursor: default;
}

.stepper-disabled .stepper-head-icon {
  background-color: var(--mdb-stepper-disabled-head-icon-bg);
  color: var(--mdb-stepper-disabled-head-icon-color);
}

.stepper-disabled .stepper-head-text {
  color: var(--mdb-stepper-disabled-head-color);
}

.stepper-mobile-head {
  background-color: var(--mdb-stepper-mobile-head-bg);
  width: 100%;
  height: fit-content;
  padding-top: var(--mdb-stepper-mobile-head-padding-y);
  padding-right: var(--mdb-stepper-mobile-head-padding-x);
  padding-bottom: var(--mdb-stepper-mobile-head-padding-y);
  padding-left: var(--mdb-stepper-mobile-head-padding-x);
  align-self: normal;
  position: absolute;
}

.stepper-mobile-footer {
  background-color: var(--mdb-stepper-mobile-footer-bg);
  width: 100%;
  height: var(--mdb-stepper-mobile-footer-height);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
}

.stepper-back-btn {
  display: block;
  left: 0;
}

.stepper-back-btn .btn-link {
  color: unset;
}

.stepper-back-btn i {
  margin-right: var(--mdb-stepper-back-btn-i-margin-right);
}

.stepper-next-btn {
  display: block;
  right: 0;
}

.stepper-next-btn .btn-link {
  color: unset;
}

.stepper-next-btn i {
  margin-left: var(--mdb-stepper-next-btn-i-margin-left);
}

.stepper-mobile-progress-bar {
  height: var(--mdb-stepper-mobile-progress-bar-height);
  background-color: var(--mdb-stepper-mobile-active-progress-bar-color);
  width: 0;
}

.stepper-mobile-progress {
  height: var(--mdb-stepper-mobile-progress-height);
  background-color: var(--mdb-stepper-mobile-progress-background-color);
  flex-grow: 100;
}

.timeline {
  --mdb-timeline-step-top: 2.94rem;
  --mdb-timeline-step-height: calc(100% - 1.94rem);
  --mdb-timeline-content-padding-left: 4.25rem;
  --mdb-timeline-head-padding-bottom: .5rem;
  --mdb-timeline-step-sm-left: 1.8rem;
  --mdb-timeline-step-sm-top: 2rem;
  --mdb-timeline-head-sm-margin-right: 1rem;
  --mdb-timeline-head-sm-bg: #dfdfdf;
  --mdb-timeline-head-sm-height: .7rem;
  --mdb-timeline-head-sm-width: .7rem;
  --mdb-timeline-content-sm-padding-left: 3.25rem;
}

.timeline-step:after {
  top: var(--mdb-timeline-step-top);
  height: var(--mdb-timeline-step-height);
}

.timeline-content {
  padding-left: var(--mdb-timeline-content-padding-left);
}

.timeline-head {
  padding-bottom: var(--mdb-timeline-head-padding-bottom);
}

.timeline-step-sm:after {
  left: var(--mdb-timeline-step-sm-left);
  height: 100%;
  top: var(--mdb-timeline-step-sm-top);
}

.timeline-head-sm {
  margin-right: var(--mdb-timeline-head-sm-margin-right);
  background-color: var(--mdb-timeline-head-sm-bg);
  height: var(--mdb-timeline-head-sm-height);
  width: var(--mdb-timeline-head-sm-width);
  border-radius: 100%;
}

.timeline-content-sm {
  padding-left: var(--mdb-timeline-content-sm-padding-left);
}

.sticky.animation {
  animation-duration: .2s;
}

.select-dropdown .form-check-input label {
  display: block;
}

select.select-initialized {
  display: none !important;
}

.select-wrapper {
  --mdb-form-outline-select-arrow-color: #0d171a;
  --mdb-form-outline-select-arrow-font-size: 16px;
  --mdb-form-outline-select-arrow-top: 7px;
  --mdb-form-outline-select-arrow-right: 16px;
  --mdb-form-outline-select-valid-color: #00b74a;
  --mdb-form-outline-select-invalid-color: #f93154;
  --mdb-form-outline-select-clear-btn-color: var(--mdb-surface-color);
  --mdb-form-outline-select-clear-btn-font-size: 1rem;
  --mdb-form-outline-select-clear-btn-top: 7px;
  --mdb-form-outline-select-clear-btn-right: 27px;
  --mdb-form-outline-select-clear-btn-focus-color: #e3041c;
  --mdb-form-outline-select-sm-clear-btn-font-size: .8rem;
  --mdb-form-outline-select-sm-clear-btn-top: 4px;
  --mdb-form-outline-select-lg-clear-btn-top: 11px;
  --mdb-form-outline-select-label-max-width: 80%;
  --mdb-form-outline-select-label-active-transform: translateY(-1rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-lg-label-active-transform: translateY(-1.25rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-sm-label-active-transform: translateY(-.83rem) translateY(.1rem) scale(.8);
  --mdb-form-outline-select-input-focused-color: var(--mdb-surface-color);
  --mdb-form-outline-select-label-color: #e3041c;
  --mdb-form-outline-select-notch-border-color: #00abf0;
  --mdb-form-outline-select-white-notch-border-color: #fff;
  --mdb-form-outline-select-input-focused-arrow-color: #00abf0;
  --mdb-form-outline-select-white-focus-arrow-color: #fff;
  --mdb-form-outline-select-white-arrow-color: #fff;
  --mdb-form-outline-select-white-clear-btn: #fff;
  --mdb-form-outline-select-sm-arrow-top: 3px;
  --mdb-form-outline-select-lg-arrow-top: 11px;
  --mdb-form-outline-form-notch-border-top: 1px solid transparent;
}

.select-arrow {
  color: var(--mdb-form-outline-select-arrow-color);
  text-align: center;
  font-size: var(--mdb-form-outline-select-arrow-font-size);
  top: var(--mdb-form-outline-select-arrow-top);
  right: var(--mdb-form-outline-select-arrow-right);
  position: absolute;
}

.select-arrow:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.select-arrow:empty:after {
  margin-left: 0;
}

.was-validated .form-control:valid ~ .select-arrow {
  color: var(--mdb-form-outline-select-valid-color);
}

.was-validated .form-control:invalid ~ .select-arrow {
  color: var(--mdb-form-outline-select-invalid-color);
}

.select-clear-btn {
  color: var(--mdb-form-outline-select-clear-btn-color);
  font-size: var(--mdb-form-outline-select-clear-btn-font-size);
  top: var(--mdb-form-outline-select-clear-btn-top);
  right: var(--mdb-form-outline-select-clear-btn-right);
  cursor: pointer;
  position: absolute;
}

.select-clear-btn:focus {
  color: var(--mdb-form-outline-select-clear-btn-focus-color);
  outline: none;
}

.form-control-sm ~ .select-clear-btn {
  font-size: var(--mdb-form-outline-select-sm-clear-btn-font-size);
  top: var(--mdb-form-outline-select-sm-clear-btn-top);
}

.form-control-lg ~ .select-clear-btn {
  top: var(--mdb-form-outline-select-lg-clear-btn-top);
}

.select-dropdown-container {
  --mdb-form-outline-select-dropdown-container-z-index: 1070;
  --mdb-form-outline-select-dropdown-bg: var(--mdb-surface-bg);
  --mdb-form-outline-select-dropdown-box-shadow: 0 2px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 2px 10px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  --mdb-form-outline-select-dropdown-min-width: 100px;
  --mdb-form-outline-select-dropdown-transform: scaleY(.8);
  --mdb-form-outline-select-dropdown-transition: all .2s;
  --mdb-form-outline-select-dropdown-open-transform: scaleY(1);
  --mdb-form-outline-select-dropdown-input-group-padding: 10px;
  --mdb-form-outline-select-options-wrapper-scrollbar-width: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-height: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius: 4px;
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-height: 50px;
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-bg: var(--mdb-scrollbar-thumb-bg);
  --mdb-form-outline-select-options-wrapper-scrollbar-thumb-border-radius: 4px;
  --mdb-form-outline-select-no-results-padding-left: 16px;
  --mdb-form-outline-select-no-results-padding-right: 16px;
  z-index: var(--mdb-form-outline-select-dropdown-container-z-index);
}

.select-dropdown {
  background-color: var(--mdb-form-outline-select-dropdown-bg);
  box-shadow: var(--mdb-form-outline-select-dropdown-box-shadow);
  min-width: var(--mdb-form-outline-select-dropdown-min-width);
  transform: var(--mdb-form-outline-select-dropdown-transform);
  opacity: 0;
  transition: var(--mdb-form-outline-select-dropdown-transition);
  outline: 0;
  margin: 0;
  position: relative;
}

.select-dropdown.open {
  transform: var(--mdb-form-outline-select-dropdown-open-transform);
  opacity: 1;
}

.select-dropdown > .input-group {
  padding: var(--mdb-form-outline-select-dropdown-input-group-padding);
}

.select-label {
  max-width: var(--mdb-form-outline-select-label-max-width);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-label.active {
  transform: var(--mdb-form-outline-select-label-active-transform);
}

.form-control-lg ~ .select-label.active {
  transform: var(--mdb-form-outline-select-lg-label-active-transform);
}

.form-control-sm ~ .select-label.active {
  transform: var(--mdb-form-outline-select-sm-label-active-transform);
}

.form-outline .select-label.active ~ .form-notch .form-notch-middle {
  border-left: none;
  border-right: none;
  border-top: var(--mdb-form-outline-form-notch-border-top);
}

.select-input.focused, .form-outline .form-control.select-input:focus {
  color: var(--mdb-form-outline-select-input-focused-color);
  outline: 0;
}

.select-input.focused ~ .select-label, .form-outline .form-control.select-input:focus ~ .select-label {
  color: var(--mdb-form-outline-select-label-color);
}

.select-input.focused::placeholder, .form-outline .form-control.select-input:focus::placeholder {
  opacity: 1;
}

.select-input.focused ~ .form-notch .form-notch-leading, .form-outline .form-control.select-input:focus ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .form-notch .form-notch-trailing, .form-outline .form-control.select-input:focus ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: var(--mdb-form-outline-form-notch-border-top);
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.select-input.focused ~ .select-arrow {
  color: var(--mdb-form-outline-select-input-focused-arrow-color);
}

.form-control-sm ~ .select-arrow {
  top: var(--mdb-form-outline-select-sm-arrow-top);
}

.form-control-lg ~ .select-arrow {
  top: var(--mdb-form-outline-select-lg-arrow-top);
}

.select-options-wrapper {
  overflow-y: auto;
}

.select-options-wrapper::-webkit-scrollbar {
  width: var(--mdb-form-outline-select-options-wrapper-scrollbar-width);
  height: var(--mdb-form-outline-select-options-wrapper-scrollbar-height);
}

.select-options-wrapper::-webkit-scrollbar-button:start:decrement {
  background-color: #0000;
  height: 0;
  display: block;
}

.select-options-wrapper::-webkit-scrollbar-button:end:increment {
  background-color: #0000;
  height: 0;
  display: block;
}

.select-options-wrapper::-webkit-scrollbar-track-piece {
  border-radius: 0;
  border-bottom-right-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius);
  border-bottom-left-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius);
  background-color: #0000;
}

.select-options-wrapper::-webkit-scrollbar-thumb:vertical {
  height: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-height);
  background-color: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-bg);
  border-radius: var(--mdb-form-outline-select-options-wrapper-scrollbar-thumb-border-radius);
}

.select-options-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select-option-group-label {
  --mdb-form-outline-select-option-group-label-padding-left: 16px;
  --mdb-form-outline-select-option-group-label-padding-right: 16px;
  --mdb-form-outline-select-option-group-label-font-size: 1rem;
  --mdb-form-outline-select-option-group-label-font-weight: 400;
  --mdb-form-outline-select-option-group-label-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding-left: var(--mdb-form-outline-select-option-group-label-padding-left);
  padding-right: var(--mdb-form-outline-select-option-group-label-padding-right);
  font-size: var(--mdb-form-outline-select-option-group-label-font-size);
  font-weight: var(--mdb-form-outline-select-option-group-label-font-weight);
  color: var(--mdb-form-outline-select-option-group-label-color);
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.select-option-group > .select-option {
  --mdb-form-outline-select-option-group-select-option-padding-left: 26px;
  padding-left: var(--mdb-form-outline-select-option-group-select-option-padding-left);
}

.select-option {
  --mdb-form-outline-select-option-color: var(--mdb-surface-color);
  --mdb-form-outline-select-option-padding-left: 16px;
  --mdb-form-outline-select-option-padding-right: 16px;
  --mdb-form-outline-select-option-font-size: 1rem;
  --mdb-form-outline-select-option-font-weight: 400;
  --mdb-form-outline-select-option-hover-not-disabled-bg: #00000005;
  --mdb-form-outline-select-option-active-bg: var(--mdb-highlight-bg-color);
  --mdb-form-outline-select-option-selected-active-bg: #0000000c;
  --mdb-form-outline-select-option-selected-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-form-outline-select-option-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-form-outline-select-option-text-form-check-input-margin-right: 10px;
  --mdb-form-outline-select-option-secondary-text-font-size: .8rem;
  --mdb-form-outline-select-option-secondary-text-color: rgba(var(--mdb-emphasis-color-rgb), .55);
  --mdb-form-outline-select-option-icon-width: 28px;
  --mdb-form-outline-select-option-icon-height: 28px;
  --mdb-form-outline-select-white-arrow: #fff;
  --mdb-form-outline-select-option-disabled-secondary-text-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-form-outline-select-option-selected-bg: #e3041c4d;
  --mdb-form-outline-select-option-selected-hover-bg: #0000000c;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
  color: var(--mdb-form-outline-select-option-color);
  padding-left: var(--mdb-form-outline-select-option-padding-left);
  padding-right: var(--mdb-form-outline-select-option-padding-right);
  font-size: var(--mdb-form-outline-select-option-font-size);
  font-weight: var(--mdb-form-outline-select-option-font-weight);
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.select-option:hover:not(.disabled) {
  background-color: var(--mdb-form-outline-select-option-hover-not-disabled-bg);
}

.select-option.active {
  background-color: var(--mdb-form-outline-select-option-active-bg);
}

.select-option.selected.active {
  background-color: var(--mdb-form-outline-select-option-selected-active-bg);
}

.select-option.selected:hover:not(.disabled) {
  background-color: var(--mdb-form-outline-select-option-selected-hover-bg);
}

.select-option.selected {
  background-color: var(--mdb-form-outline-select-option-selected-bg);
}

.select-option.selected.disabled {
  cursor: default;
  color: var(--mdb-form-outline-select-option-selected-disabled-color);
  background-color: #0000;
}

.select-option.disabled {
  cursor: default;
  color: var(--mdb-form-outline-select-option-disabled-color);
}

.select-option.disabled .select-option-secondary-text {
  color: var(--mdb-form-outline-select-option-disabled-secondary-text-color);
}

.select-option-text .form-check-input {
  margin-right: var(--mdb-form-outline-select-option-text-form-check-input-margin-right);
}

.select-option-secondary-text {
  font-size: var(--mdb-form-outline-select-option-secondary-text-font-size);
  color: var(--mdb-form-outline-select-option-secondary-text-color);
  line-height: normal;
  display: block;
}

.select-option-icon {
  width: var(--mdb-form-outline-select-option-icon-width);
  height: var(--mdb-form-outline-select-option-icon-height);
}

.select-custom-content {
  --mdb-form-outline-select-custom-content-padding: 16px;
  padding: var(--mdb-form-outline-select-custom-content-padding);
}

.select-no-results {
  padding-left: var(--mdb-form-outline-select-no-results-padding-left);
  padding-right: var(--mdb-form-outline-select-no-results-padding-right);
  align-items: center;
  display: flex;
}

.form-white .select-input.focused ~ .select-arrow {
  color: var(--mdb-form-outline-select-white-arrow-color);
}

.form-white .select-input:focus ~ .select-arrow {
  color: var(--mdb-form-outline-select-white-focus-arrow-color);
}

.form-white .select-arrow {
  color: var(--mdb-form-outline-select-white-arrow-color);
}

.form-white .select-clear-btn {
  color: var(--mdb-form-outline-select-white-clear-btn);
}

.form-white .select-input.focused, .form-white .form-control.select-input:focus, .form-white .select-input.focused ~ .select-label, .form-white .form-control.select-input:focus ~ .select-label {
  color: #fff;
}

.form-white .select-input.focused ~ .form-notch .form-notch-leading, .form-white .form-control.select-input:focus ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-white-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-white-notch-border-color);
}

.form-white .select-input.focused ~ .form-notch .form-notch-trailing, .form-white .form-control.select-input:focus ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-white-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-white-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-white-notch-border-color);
}

.form-outline .form-control ~ .form-label.select-fake-value, .form-outline .form-control:focus ~ .form-label.select-fake-value, .form-outline .form-control.active ~ .form-label.select-fake-value {
  display: none;
  transform: none;
}

.form-outline .form-control ~ .form-label.select-fake-value.active, .form-outline .form-control:focus ~ .form-label.select-fake-value.active, .form-outline .form-control.active ~ .form-label.select-fake-value.active {
  display: block;
}

.loading-spinner {
  --mdb-loading-spinner-top: 50%;
  --mdb-loading-spinner-left: 50%;
  --mdb-loading-spinner-transform: translate(-50%, -50%);
  --mdb-loading-spinner-color: #e3041c;
  top: var(--mdb-loading-spinner-top);
  left: var(--mdb-loading-spinner-left);
  transform: var(--mdb-loading-spinner-transform);
  color: var(--mdb-loading-spinner-color);
  z-index: 1056;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.loading-backdrop {
  background-color: var(--mdb-datepicker-backdrop-background-color);
  width: 100%;
  height: 100%;
  z-index: var(--mdb-datepicker-zindex);
  position: fixed;
  inset: 0;
}

.autocomplete-label {
  --mdb-autocomplete-label-max-width: 80%;
  --mdb-autocomplete-label-active-transform: translateY(-1rem) translateY(.1rem) scale(.8);
  --mdb-autocomplete-label-color: #e3041c;
  max-width: var(--mdb-autocomplete-label-max-width);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-label.active {
  transform: var(--mdb-autocomplete-label-active-transform);
}

.form-outline .autocomplete-label.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-left: none;
  border-right: none;
}

.select-input {
  cursor: pointer;
}

.select-input[disabled] {
  cursor: default;
}

.form-outline {
  --mdb-form-outline-select-notch-border-color: #00abf0;
}

.autocomplete-input.focused {
  --mdb-autocomplete-input-focused-color: var(--mdb-surface-color);
  color: var(--mdb-autocomplete-input-focused-color);
  outline: 0;
}

.autocomplete-input.focused ~ .autocomplete-label {
  color: var(--mdb-autocomplete-label-color);
}

.autocomplete-input.focused::placeholder {
  opacity: 1;
}

.autocomplete-input.focused ~ .form-notch .form-notch-leading {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: -1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.autocomplete-input.focused ~ .form-notch .form-notch-middle {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
  border-top: 1px solid #0000;
}

.autocomplete-input.focused ~ .form-notch .form-notch-trailing {
  border-color: var(--mdb-form-outline-select-notch-border-color);
  box-shadow: 1px 0 0 0 var(--mdb-form-outline-select-notch-border-color), 0 -1px 0 0 var(--mdb-form-outline-select-notch-border-color), 0 1px 0 0 var(--mdb-form-outline-select-notch-border-color);
}

.autocomplete-dropdown-container {
  --mdb-autocomplete-dropdown-container-zindex: 1065;
  --mdb-autocomplete-dropdown-background-color: var(--mdb-surface-bg);
  --mdb-autocomplete-dropdown-box-shadow: 0 2px 5px 0 rgba(var(--mdb-box-shadow-color-rgb), .16), 0 2px 10px 0 rgba(var(--mdb-box-shadow-color-rgb), .12);
  --mdb-autocomplete-dropdown-margin: 0;
  --mdb-autocomplete-dropdown-transform: scaleY(.8);
  --mdb-autocomplete-dropdown-transition: all .2s;
  --mdb-autocomplete-dropdown-open-transform: scaleY(1);
  --mdb-autocomplete-item-color: var(--mdb-surface-color);
  --mdb-autocomplete-item-padding: 6.5px 16px;
  --mdb-autocomplete-item-font-size: 1rem;
  --mdb-autocomplete-item-font-weight: 400;
  --mdb-autocomplete-item-hover-background-color: var(--mdb-highlight-bg-color);
  --mdb-autocomplete-item-disabled-color: rgba(var(--mdb-surface-color-rgb), .5);
  --mdb-autocomplete-scrollbar-thumb-background-color: var(--mdb-scrollbar-thumb-bg);
  z-index: var(--mdb-autocomplete-dropdown-container-zindex);
}

.autocomplete-dropdown {
  background-color: var(--mdb-autocomplete-dropdown-background-color);
  box-shadow: var(--mdb-autocomplete-dropdown-box-shadow);
  margin: var(--mdb-autocomplete-dropdown-margin);
  transform: var(--mdb-autocomplete-dropdown-transform);
  opacity: 0;
  transition: var(--mdb-autocomplete-dropdown-transition);
  outline: 0;
  position: relative;
}

.autocomplete-dropdown.open {
  transform: var(--mdb-autocomplete-dropdown-open-transform);
  opacity: 1;
}

.autocomplete-items-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.autocomplete-items-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.autocomplete-items-list::-webkit-scrollbar-button:start:decrement {
  background-color: #0000;
  height: 0;
  display: block;
}

.autocomplete-items-list::-webkit-scrollbar-button:end:increment {
  background-color: #0000;
  height: 0;
  display: block;
}

.autocomplete-items-list::-webkit-scrollbar-track-piece {
  background-color: #0000;
  border-radius: 0 0 4px 4px;
}

.autocomplete-items-list::-webkit-scrollbar-thumb:vertical {
  background-color: var(--mdb-autocomplete-scrollbar-thumb-background-color);
  border-radius: 4px;
  height: 50px;
}

.autocomplete-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
  color: var(--mdb-autocomplete-item-color);
  padding: var(--mdb-autocomplete-item-padding);
  font-size: var(--mdb-autocomplete-item-font-size);
  font-weight: var(--mdb-autocomplete-item-font-weight);
  user-select: none;
  background-color: #0000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.autocomplete-item:hover:not(.disabled), .autocomplete-item.active {
  background-color: var(--mdb-autocomplete-item-hover-background-color);
}

.autocomplete-item.disabled {
  cursor: default;
  color: var(--mdb-autocomplete-item-disabled-color);
}

.autocomplete-loader {
  border-width: .15em;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 8px;
  right: 5px;
}

.form-outline .autocomplete-input.form-control.focusing ~ .form-notch .form-notch-middle {
  transition: none;
}

.chip {
  --mdb-chip-height: 32px;
  --mdb-chip-line-height: 2;
  --mdb-chip-padding-right: 12px;
  --mdb-chip-margin-y: 5px;
  --mdb-chip-margin-right: 1rem;
  --mdb-chip-font-size: 13px;
  --mdb-chip-font-weight: 400;
  --mdb-chip-font-color: var(--mdb-surface-color);
  --mdb-chip-bg: var(--mdb-tertiary-bg);
  --mdb-chip-border-radius: 16px;
  --mdb-chip-transition-opacity: .3s linear;
  --mdb-chip-img-margin-right: 8px;
  --mdb-chip-img-margin-left: -12px;
  --mdb-chip-close-padding-left: 8px;
  --mdb-chip-close-font-size: 16px;
  --mdb-chip-close-opacity: .53;
  --mdb-chip-outline-border-width: 1px;
  --mdb-chip-md-height: 42px;
  --mdb-chip-md-br: 21px;
  --mdb-chip-lg-height: 52px;
  --mdb-chip-lg-br: 26px;
  --mdb-chip-contenteditable-border-width: 3px;
  --mdb-chip-contenteditable-border-color: var(--mdb-border-color);
  --mdb-chip-icon-color: rgba(var(--mdb-emphasis-color-rgb), .3);
  --mdb-chip-icon-transition: .2s ease-in-out;
  --mdb-chip-icon-hover-color: rgba(var(--mdb-emphasis-color-rgb), .4);
  height: var(--mdb-chip-height);
  line-height: var(--mdb-chip-line-height);
  padding: 0 var(--mdb-chip-padding-right);
  margin-top: var(--mdb-chip-margin-y);
  margin-bottom: var(--mdb-chip-margin-y);
  margin-right: var(--mdb-chip-margin-right);
  font-size: var(--mdb-chip-font-size);
  font-weight: var(--mdb-chip-font-weight);
  color: var(--mdb-chip-font-color);
  cursor: pointer;
  background-color: var(--mdb-chip-bg);
  border-radius: var(--mdb-chip-border-radius);
  transition: opacity var(--mdb-chip-transition-opacity);
  word-wrap: break-word;
  box-shadow: none;
  text-transform: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.chip:hover {
  box-shadow: none !important;
}

.chip:hover:not(.chip-outline) {
  background-color: var(--mdb-chip-bg);
  box-shadow: none !important;
}

.chip:active, .chip.active {
  background-color: var(--mdb-chip-bg);
}

.chip img {
  width: inherit;
  height: inherit;
  margin: 0 var(--mdb-chip-img-margin-right) 0 var(--mdb-chip-img-margin-left);
  border-radius: 100%;
}

.chip .close {
  float: right;
  padding-left: var(--mdb-chip-close-padding-left);
  font-size: var(--mdb-chip-close-font-size);
  opacity: var(--mdb-chip-close-opacity);
  cursor: pointer;
}

.chip .close:hover {
  color: #060c0d;
}

.chip .close-opacity {
  opacity: 0;
}

.chip-outline {
  border-width: var(--mdb-chip-outline-border-width);
  background: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chip.chip-md {
  height: var(--mdb-chip-md-height);
  border-radius: var(--mdb-chip-md-br);
}

.chip.chip-lg {
  height: var(--mdb-chip-lg-height);
  border-radius: var(--mdb-chip-lg-br);
}

.chip[contenteditable="true"] {
  border: var(--mdb-chip-contenteditable-border-width) solid var(--mdb-chip-contenteditable-border-color);
  background-color: #fff;
  outline: none;
}

.chip .far, .chip .fas {
  color: var(--mdb-chip-icon-color);
  transition: all var(--mdb-chip-icon-transition);
}

.chip .far:hover, .chip .fas:hover {
  color: var(--mdb-chip-icon-hover-color);
}

.chips {
  --mdb-chips-min-height: 45px;
  --mdb-chips-padding-bottom: 1rem;
  --mdb-chips-margin-bottom: 30px;
  --mdb-chips-transition: .3s ease;
  --mdb-chips-padding-padding: 5px;
  --mdb-chips-input-width: 150px;
  min-height: var(--mdb-chips-min-height);
  padding-bottom: var(--mdb-chips-padding-bottom);
  margin-bottom: var(--mdb-chips-margin-bottom);
  box-shadow: none;
  transition: all var(--mdb-chips-transition);
  border: none;
  outline: none;
}

.chips-transition {
  transition: all var(--mdb-chips-transition);
}

.chips-padding {
  padding: var(--mdb-chips-padding-padding);
}

.chips:hover {
  cursor: text;
}

.chips .tag.selected {
  color: #fff;
}

.chips-placeholder, .chips-initial {
  margin-bottom: 0;
  padding-bottom: 0;
}

.chips-placeholder .chips-input-wrapper, .chips-initial .chips-input-wrapper {
  transition: all var(--mdb-chips-transition);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.chips-placeholder .chips-input-wrapper input, .chips-initial .chips-input-wrapper input {
  width: var(--mdb-chips-input-width) !important;
}

.chip-opacity {
  opacity: 0;
}

.form-control.chips-input.active {
  box-shadow: none;
}

.multi-range-slider {
  --mdb-multi-range-sliderhand-focus-box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #e3041c40;
  --mdb-multi-range-slider-horizontal-height: .25rem;
  --mdb-multi-range-hand-width: 1rem;
  --mdb-multi-range-hand-height: 1rem;
  --mdb-multi-range-hand-bg: #e3041c;
  --mdb-multi-range-hand-border-radius: 50% 50% 50% 0;
  --mdb-multi-range-hand-box-shadow: 0 .1rem .25rem #0000001a;
  --mdb-multi-range-hand-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  --mdb-multi-range-hand-active-bg: #f7b4bb;
  --mdb-multi-range-track-bg: var(--mdb-secondary-bg);
  --mdb-multi-range-tooltip-value-color: #fff;
  --mdb-multi-range-tooltip-transition: top .2s, transform .2s, border-radius .2s;
  --mdb-multi-range-tooltip-border-radius: 50% 50% 50% 0;
  --mdb-multi-range-tooltip-transform: rotate(-45deg) translate(-5px, -4px) scale(0);
  --mdb-multi-range-tooltip-transform-active: rotate(-45deg) translate(-5px, -4px) scale(1);
  --mdb-multi-range-tooltip-content-size: 30px;
  --mdb-multi-range-tooltip-content-transform: rotate(45deg) translateY(25%);
  --mdb-multi-range-tooltip-content-font-size: 10px;
  --mdb-multi-range-tooltip-position-top: -18px;
  cursor: pointer;
  border: none;
  border: 1px solid var(--mdb-multi-range-track-bg);
  appearance: none;
  background-color: #0000;
  outline: none;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  position: relative;
}

.multi-range-slider:focus {
  box-shadow: var(--mdb-multi-range-sliderhand-focus-box-shadow);
  outline: none;
}

.multi-range-slider::-moz-focus-outer {
  border: 0;
}

.multi-range-slider-connects {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.multi-range-slider-horizontal {
  height: var(--mdb-multi-range-slider-horizontal-height);
}

.multi-range-slider-hand {
  width: var(--mdb-multi-range-hand-width);
  height: var(--mdb-multi-range-hand-height);
  background-color: var(--mdb-multi-range-hand-bg);
  transition: var(--mdb-multi-range-thumb-transition);
  appearance: none;
  z-index: 5;
  cursor: pointer;
  will-change: transform;
  transform-origin: 0 0;
  transform-style: flat;
  border: 0;
  border-radius: 50%;
  margin-top: -.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
}

@media (prefers-reduced-motion: reduce) {
  .multi-range-slider-hand {
    transition: none;
  }
}

.multi-range-slider-hand:active, .multi-range-slider-hand.active {
  z-index: 6;
  background-color: var(--mdb-multi-range-hand-active-bg);
}

.multi-range-slider-connect {
  background-color: var(--mdb-multi-range-track-bg);
  will-change: transform;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.multi-range-slider:focus {
  outline: none;
}

.multi-range-slider-tooltip {
  top: var(--mdb-multi-range-tooltip-position-top);
  transform-origin: 50%;
  transition: var(--mdb-multi-range-tooltip-transition);
  color: var(--mdb-multi-range-tooltip-value-color);
  border-radius: var(--mdb-multi-range-tooltip-border-radius);
  transform: var(--mdb-multi-range-tooltip-transform);
  background-color: #e3041c;
  border: none;
  position: absolute;
}

.multi-range-slider-tooltip-value {
  transform: var(--mdb-multi-range-tooltip-content-transform);
  color: #fff;
  height: var(--mdb-multi-range-tooltip-content-size);
  font-size: var(--mdb-multi-range-tooltip-content-font-size);
  width: var(--mdb-multi-range-tooltip-content-size);
  text-align: center;
  display: block;
}

.multi-range-slider-tooltip.active {
  transform: var(--mdb-multi-range-tooltip-transform-active);
  top: -38px;
}

.buttons-container {
  --mdb-datetimepicker-buttons-container-background-color: var(--mdb-picker-header-bg);
  --mdb-datetimepicker-button-toggle-width: 50%;
  --mdb-datetimepicker-button-toggle-color: white;
  --mdb-datetimepicker-button-toggle-font-size: 23px;
  --mdb-datetimepicker-button-toggle-border-radius: 10px;
  --mdb-datetimepicker-button-toggle-min-height: 40px;
  --mdb-datetimepicker-button-toggle-hover-background-color: #00000026;
  background-color: var(--mdb-datetimepicker-buttons-container-background-color);
  justify-content: space-evenly;
  align-items: flex-end;
  display: flex;
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .buttons-container {
    flex-grow: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .timepicker-elements .buttons-container {
    flex-direction: column;
    align-items: center;
    padding-right: var(--mdb-timepicker-head-media-padding-right) !important;
  }

  .timepicker-elements .buttons-container .datepicker-button-toggle, .timepicker-elements .buttons-container .timepicker-button-toggle {
    width: 100%;
  }
}

.datepicker-button-toggle, .timepicker-button-toggle {
  width: var(--mdb-datetimepicker-button-toggle-width);
  color: var(--mdb-datetimepicker-button-toggle-color);
  font-size: var(--mdb-datetimepicker-button-toggle-font-size);
  border-radius: var(--mdb-datetimepicker-button-toggle-border-radius);
  min-height: var(--mdb-datetimepicker-button-toggle-min-height);
  background-color: #0000;
  border: none;
  outline: none;
}

.datepicker-button-toggle:hover, .timepicker-button-toggle:hover {
  background-color: var(--mdb-datetimepicker-button-toggle-hover-background-color);
  outline: none;
}

.datepicker-button-toggle i, .timepicker-button-toggle i {
  pointer-events: none;
}

.datetimepicker-toggle-button {
  --mdb-datetimepicker-toggle-button-transform: translate(-50%, -50%);
  right: var(--mdb-datepicker-toggle-right);
  top: var(--mdb-datepicker-toggle-top);
  transform: var(--mdb-datetimepicker-toggle-button-transform);
  background-color: #0000;
  border: none;
  outline: none;
  padding-inline: 6px;
  position: absolute;
}

.datetimepicker-toggle-button:focus, .datetimepicker-toggle-button:hover {
  color: var(--mdb-datepicker-toggle-focus-color);
}

.treeview {
  --mdb-treeview-text-color: var(--mdb-body-color);
  --mdb-treeview-link-color: var(--mdb-body-color);
  --mdb-treeview-disabled-text-color: rgba(var(--mdb-body-color-rgb), .5);
  --mdb-treeview-bg: transparent;
  --mdb-treeview-line: var(--mdb-border-color);
  background-color: var(--mdb-treeview-bg);
  width: 100%;
}

.treeview .active, .treeview .treeview-category {
  color: var(--mdb-treeview-text-color);
  border-radius: 5px;
  padding: 0 .4rem;
}

.treeview .treeview-line {
  border-left: 2px solid var(--mdb-treeview-line);
}

.treeview .non-transitions {
  -o-transition: none !important;
  transition: none !important;
}

.treeview .treeview-disabled {
  cursor: not-allowed;
  color: var(--mdb-treeview-disabled-text-color) !important;
}

.treeview ul {
  margin-left: .8rem;
  padding-left: .8rem;
}

.treeview ul ul:not(.collapse):not(.collapsing):not(.show) {
  display: none;
}

.treeview li {
  margin: 3px 0;
  list-style-type: none;
}

.treeview li:last-child {
  margin-bottom: 0;
}

.treeview a {
  color: var(--mdb-treeview-link-color);
  display: block;
}

.treeview span[aria-label="toggle"] i {
  cursor: pointer;
  transition: all .3s;
}

.treeview-primary .active {
  background-color: #e3041c0d;
  color: #e3041c !important;
}

.treeview-primary li:focus, .treeview-primary a:focus {
  background-color: #e3041c0d;
  outline: none;
}

.treeview-secondary .active {
  background-color: #203a420d;
  color: #203a42 !important;
}

.treeview-secondary li:focus, .treeview-secondary a:focus {
  background-color: #203a420d;
  outline: none;
}

.treeview-success .active {
  background-color: #00ae000d;
  color: #00ae00 !important;
}

.treeview-success li:focus, .treeview-success a:focus {
  background-color: #00ae000d;
  outline: none;
}

.treeview-danger .active {
  background-color: #e3041c0d;
  color: #e3041c !important;
}

.treeview-danger li:focus, .treeview-danger a:focus {
  background-color: #e3041c0d;
  outline: none;
}

.treeview-warning .active {
  background-color: #ffc1070d;
  color: #ffc107 !important;
}

.treeview-warning li:focus, .treeview-warning a:focus {
  background-color: #ffc1070d;
  outline: none;
}

.treeview-info .active {
  background-color: #00abf00d;
  color: #00abf0 !important;
}

.treeview-info li:focus, .treeview-info a:focus {
  background-color: #00abf00d;
  outline: none;
}

.treeview-light .active {
  background-color: #d2d8d90d;
  color: #d2d8d9 !important;
}

.treeview-light li:focus, .treeview-light a:focus {
  background-color: #d2d8d90d;
  outline: none;
}

.treeview-category:hover {
  background-color: #060c0d0d;
}

.treeview-dark .active {
  background-color: #060c0d0d;
  color: #060c0d !important;
}

.treeview-dark li:focus, .treeview-dark a:focus {
  background-color: #060c0d0d;
  outline: none;
}

.link {
  text-decoration: none;
}

.link:hover:not(i) {
  cursor: pointer;
  text-decoration: underline;
}

.scrollable {
  max-height: 100%;
  overflow-y: auto !important;
}

.tip {
  cursor: help;
}

.noclick {
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.pointer-nodrop {
  cursor: no-drop;
}

.pointer-move {
  cursor: move !important;
}

.lh-lg {
  line-height: 2.5 !important;
}

#tooltip {
  color: #fff;
  z-index: 100000;
  max-width: 400px;
  box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  background-color: #132328;
  border-radius: .25rem;
  padding: 7px 14px;
  position: absolute;
}

#tooltip img {
  max-width: 100%;
}

.pdf-preview {
  z-index: 100000;
  max-width: 75vw;
  max-height: 75vh;
  box-shadow: 0 4px 9px -4px rgba(var(--mdb-box-shadow-color-rgb), .35);
  border-radius: .25rem;
  position: absolute;
}

#reclaim_2_document_file img {
  height: 30vh;
}

.helper {
  width: auto;
  height: auto;
  font-family: var(--mdb-font-roboto);
  padding: 1rem 1.4rem;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  top: 0;
}

#widthHelper {
  white-space: nowrap;
}

html, body {
  height: 100%;
}

body {
  overflow: hidden;
}

iframe {
  width: 100%;
}

::-webkit-scrollbar {
  height: 25px;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.loading * {
  cursor: progress;
}

body.overlay:before {
  z-index: 10000;
  content: " ";
  background: #203a4299;
  width: 100%;
  height: 100%;
  position: absolute;
}

body.overlay:after {
  content: " ";
  z-index: 10001;
  color: #fff;
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-border-width: .25em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-border;
  border: var(--mdb-spinner-border-width) solid;
  width: var(--mdb-spinner-width);
  height: var(--mdb-spinner-height);
  vertical-align: var(--mdb-spinner-vertical-align);
  animation: var(--mdb-spinner-animation-speed) linear infinite var(--mdb-spinner-animation-name);
  border-right-color: #0000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: calc(50% - 3rem);
  left: calc(50% + 6.75rem);
}

.search {
  position: relative;
}

.search .fa-search {
  color: #203a42;
  display: inline-block;
  position: absolute;
  top: calc(50% - .5rem);
  left: .75rem;
}

.search .fa-search:hover, .search .fa-search:focus {
  color: var(--mdb-surface-color);
}

.search .form-control {
  padding-left: 2.5rem;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .search .form-control {
    transition: none;
  }
}

@media (width >= 768px) and (width <= 1399.98px) {
  .searchBar {
    margin-bottom: 1rem !important;
  }
}

@media (width <= 575.98px) {
  .searchBar {
    margin-bottom: 0 !important;
  }
}

.table {
  --mdb-table-border-color: #0000002d;
  width: 100%;
  margin-bottom: 0;
}

.table .table-small-show {
  display: none;
}

.table .table-big-show {
  display: table-cell;
}

.table .row-select {
  width: 1em;
  padding: 0;
}

.table .row-select .form-check {
  cursor: pointer;
  padding: 1rem 1.4rem;
}

.table .row-select .form-check-input[type="checkbox"] {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

.table tr.text-decoration-line-through span, .table tr.text-decoration-line-through p {
  text-decoration: line-through !important;
}

.table td.image img {
  max-width: 100px;
  max-height: 100px;
}

.table td .pdf {
  max-width: 15vw;
  max-height: 15vh;
}

.table td > p, .table td > span {
  max-height: 150px;
  display: inline-block;
  overflow: hidden;
}

.view-overview.view-small .table th[scope="row"], .view-overview.view-small .table td[scope="row"] {
  display: table-cell !important;
}

.view-overview.view-small .table th:not(.table-small-show), .view-overview.view-small .table td:not(.table-small-show) {
  display: none;
}

.view-overview.view-small .table th.table-small-show, .view-overview.view-small .table td.table-small-show {
  display: table-cell;
}

.table-scroll {
  max-width: 100%;
  max-height: 1000px;
}

.table-scroll thead th, .table-scroll thead td, .table-scroll tfoot th, .table-scroll tfoot td {
  z-index: 3;
  box-shadow: var(--datatable-border-color) 0 1px;
  background-color: #fff;
  position: sticky;
}

.table-scroll thead th {
  top: 0;
}

.table-scroll thead th span {
  position: relative;
}

.table-scroll thead th span .iconSort {
  position: absolute;
  right: -13px;
}

.table-scroll thead:hover th:not(.row-select) {
  border-right: 1px dotted #d3d3d3;
  padding-right: calc(1.4rem - 1px);
}

.table-scroll tfoot th, .table-scroll tfoot td {
  white-space: nowrap;
  bottom: 0;
}

.modal .table-scroll {
  max-height: 60vh;
}

@media screen and (width <= 1900px) {
  .modal .table-scroll {
    max-width: 70vw;
  }
}

@media screen and (width <= 1100px) {
  .modal .table-scroll {
    max-width: 65vw;
  }
}

@media screen and (width <= 920px) {
  .modal .table-scroll {
    max-width: 60vw;
  }
}

@media screen and (width <= 780px) {
  .modal .table-scroll {
    max-width: 55vw;
  }
}

@media screen and (width <= 697px) {
  .modal .table-scroll {
    max-width: 50vw;
  }
}

.dropdown-search {
  margin: .5rem 1rem;
  padding: .5rem 0;
}

table[border="1"] td {
  border-width: 1px;
}

table.dashboard-table {
  width: 100%;
}

.table .table-active, .table-hover tbody tr:hover {
  background-color: #d2d8d94d;
}

.form-control[readonly], .form-control:disabled {
  background-color: #e9ecef;
}

.form-group {
  position: relative;
}

.form-group .form-control {
  color: #203a42;
}

.form-group .form-control::placeholder {
  color: #a6b0b3;
}

.form-group .form-control:-ms-input-placeholder {
  color: #a6b0b3;
}

.form-group .form-control:-moz-placeholder {
  color: #a6b0b3;
}

.form-group .form-switch {
  padding-top: .5em;
  padding-bottom: .5em;
}

.form-group.disabled .select-clear-btn, .form-group.disabled .select-arrow {
  display: none !important;
}

.form-group.disabled .form-switch .pointer-nodrop {
  margin-left: -1.5em;
}

.form-check-input {
  background-color: #fff;
}

.input-group > [data-groupable="text"] + [data-groupable="text"], .input-group > [data-groupable="text"] + [data-groupable="button"], .input-group > [data-groupable="button"] + [data-groupable="text"], .input-group > [data-groupable="button"] + [data-groupable="button"] {
  border-radius: var(--mdb-btn-border-radius) !important;
  margin-left: 3px !important;
}

.input-group .select-wrapper {
  flex: 1;
}

@media (width <= 575.98px) {
  .select-wrapper {
    min-width: unset !important;
  }
}

.subtab {
  white-space: nowrap;
  text-align: center;
  width: 100%;
  height: 45px;
  margin-top: -18px;
  margin-bottom: -14px;
  display: inline-block;
  position: relative;
  overflow: scroll hidden;
}

.subtab .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

label.full {
  max-width: initial;
  width: auto !important;
}

select.label {
  -webkit-appearance: menulist;
  border: none;
  padding: 0;
  width: 33% !important;
  margin: 2px 4px 0 -14px !important;
  font-size: 13px !important;
}

.tox-statusbar__branding {
  display: none;
}

[key="sign"] canvas {
  float: left;
  border: 1px solid #eaeaea;
  margin-right: 3px;
}

.btn .sub:hover, .btn.wysihtml-command-active, .btn .sub.wysihtml-command-active {
  color: #fffffe !important;
  box-shadow: none !important;
  background-color: #4babc1 !important;
  border-color: #0000 #0000 #4babc1 !important;
  border-bottom-style: none !important;
  border-bottom-width: medium !important;
}

.btn[value="red"]:hover, .btn.active[value="red"] {
  background-color: #e3041c;
}

.btn[value="yellow"]:hover, .btn.active[value="yellow"] {
  color: #4d6168;
  background-color: #ffc107;
}

.btn[value="green"]:hover, .btn.active[value="green"] {
  background-color: #00ae00;
}

.btn .sub:hover .icon {
  color: #fffffe;
}

.btn.multi {
  cursor: inherit;
  padding: 0 5px;
}

.btn.multi .icon {
  cursor: pointer;
  margin: 0 3px;
}

.btn .submenu {
  width: auto;
  margin-left: -10px;
}

.btn .sub {
  padding: 5px 7px;
}

.btn .sub .icon {
  margin-right: 0;
}

.upload {
  display: inline-block;
  position: relative;
}

.upload .btn {
  z-index: 1;
  height: 100%;
  position: relative;
}

.upload .upload {
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.field-read.lh-lg {
  max-width: 100%;
  max-height: 1000px;
  overflow: scroll;
}

.field-read.lh-lg.read-more {
  text-overflow: ellipsis;
  height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
}

.invalid-feedback {
  right: 0;
}

.stream .btn {
  width: 150px;
}

.stream .input-group-text {
  text-align: left;
}

@media (width <= 767.98px) {
  .stream .input-group-text {
    text-align: left;
    white-space: normal;
  }
}

.form-group .tox.tox-tinymce {
  max-width: 100%;
}

@media (width <= 767.98px) {
  .form-group .input-group-text {
    text-align: left;
    white-space: normal;
  }
}

@media (width <= 575.98px) {
  .form-group .input-group-text {
    min-width: unset;
    width: unset;
  }
}

.form-group canvas.with-context {
  border: 1px solid #a6b0b3;
  border-radius: .25rem;
}

.form-group .wikiBtn, .list-group-item .wikiBtn {
  opacity: 0;
  position: relative;
}

.form-group > .wikiBtn, .list-group-item > .wikiBtn {
  position: absolute;
  right: 0;
}

.form-group:hover .wikiBtn, .list-group-item:hover .wikiBtn {
  opacity: 1;
  margin: -12px 3px 0;
  font-size: 14px;
}

.d-flex.split-line, .split-line {
  column-gap: 10px;
}

@media (width <= 767.98px) {
  .split-line {
    display: flex !important;
  }
}

.filled ul.ui-sortable {
  padding-left: 2rem;
}

.filled ul.ui-sortable .form-check-input[type="checkbox"] {
  margin-right: 0;
}

.view-right .card .list-group ul.ui-sortable li .form-check {
  position: absolute;
  left: 5px;
}

.view-right .card .list-group ul.ui-sortable li .form-check .form-check-input {
  margin-left: 0;
}

.table-striped tr td .form-switch {
  padding-left: 1.5em;
}

.table-striped tr td .form-switch .form-check-input:checked[type="checkbox"] {
  margin-right: 8px;
}

.select-dropdown-container {
  min-width: 200px !important;
}

.select-dropdown-container .select-option.select-all-option .select-option-text {
  padding-left: 25px;
  position: relative;
}

.select-dropdown-container .select-option.select-all-option .select-option-text .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
}

div[key="salut"] .input-group {
  display: block;
}

.btn:only-child {
  margin-left: 0;
}

.btn + .btn, .btn + .btn-cont, .btn-cont + .btn, .btn-cont + .btn-cont {
  margin-left: .25rem;
}

.btn-cont {
  display: inline-block;
}

.btn-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
  padding-left: 7px;
  overflow: hidden;
}

.btn-link, .btn-link:hover, .btn-link:focus {
  color: currentColor;
}

.table-row .btn {
  margin-left: 3px;
  padding: 3px 10px;
}

@media (width <= 575.98px) {
  .btn {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.dropdown .dropdown-toggle:after {
  align-self: center;
}

.dropdown + .btn {
  margin-left: .25rem;
}

.view-main > nav {
  overflow: hidden;
}

.view-main > nav .nav-tabs {
  flex-wrap: nowrap;
}

.view-main > nav .nav-tabs .icon {
  padding: .75rem 0;
}

.nav-pills.flex-column {
  margin-left: 0;
}

.nav-pills.flex-column .nav-link, .nav-pills.flex-column .sub-link {
  color: currentColor;
  margin: 0;
}

.nav-pills.flex-column .nav-link:hover, .nav-pills.flex-column .sub-link:hover, .nav-pills.flex-column .nav-link:focus, .nav-pills.flex-column .sub-link:focus, .nav-pills.flex-column .nav-link.active, .nav-pills.flex-column .active.sub-link, .nav-pills.flex-column .nav-item.show .nav-link, .nav-pills.flex-column .nav-item.show .sub-link {
  background-color: #d2d8d9;
}

.nav-pills.flex-column .nav-link.active, .nav-pills.flex-column .active.sub-link, .nav-pills.flex-column .nav-link.active:hover, .nav-pills.flex-column .nav-link.active:focus, .nav-pills.flex-column .nav-item.show .nav-link, .nav-pills.flex-column .nav-item.show .sub-link, .nav-pills.flex-column .nav-item.show .nav-link:hover, .nav-pills.flex-column .nav-item.show .nav-link:focus {
  color: currentColor;
}

.view-left .nav-link .icon, .view-left .sub-link .icon {
  margin-left: 1rem;
}

.sub-menu:empty {
  display: none;
}

.collapse > .sub-menu, .collapsing > .sub-menu {
  margin-top: .25rem;
}

.sub-link {
  padding-left: calc(2rem + 1.25em) !important;
}

@media (width <= 767.98px) {
  .view-main > nav {
    margin-bottom: 0 !important;
  }

  .view-main > nav .nav-tabs {
    flex-wrap: nowrap;
    padding-bottom: 0;
    overflow: auto hidden;
  }

  .view-main > nav .nav-tabs button.nav-link, .view-main > nav .nav-tabs button.sub-link {
    white-space: nowrap;
  }

  .view-main > nav .nav-tabs::-webkit-scrollbar {
    display: none;
  }
}

.card {
  margin-bottom: 1rem;
}

.card:last-child {
  margin-bottom: 0;
}

.card.card-minimal {
  height: 70px;
  overflow: hidden;
}

.card.card-sticky {
  max-height: 25vh;
  overflow-y: scroll;
}

.search .card {
  width: calc(33% - 19px);
  min-width: 414px;
  margin: 0 25px 25px 0;
  overflow-x: hidden;
}

.swatches {
  padding: .5rem;
}

.swatch {
  border: 2px solid var(--mdb-swatch-border);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 100%;
}

.swatch:hover {
  border-color: var(--mdb-swatch-hover-border);
}

.swatch.active {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230d171a' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.swatch-red {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ffd5cc;
  --mdb-swatch-border: #ccaaa3;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #ecc5bd;
  --mdb-swatch-hover-border: #99807a;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #e6bfb8;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-orange {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ffe4cc;
  --mdb-swatch-border: #ccb6a3;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #ecd3bd;
  --mdb-swatch-hover-border: #99897a;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #e6cdb8;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-yellow {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ffc;
  --mdb-swatch-border: #cccca3;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #ececbd;
  --mdb-swatch-hover-border: #99997a;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #e6e6b8;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-green {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ccfacc;
  --mdb-swatch-border: #a3c8a3;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #bde7bd;
  --mdb-swatch-hover-border: #7a967a;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #b8e1b8;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-teal {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ccebe5;
  --mdb-swatch-border: #a3bcb8;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #bdd9d4;
  --mdb-swatch-hover-border: #7a8d8a;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #b8d3ce;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-cyan {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #ccfafa;
  --mdb-swatch-border: #a3c8c8;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #bde7e7;
  --mdb-swatch-hover-border: #7a9696;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #b8e1e1;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-blue {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #cce6ff;
  --mdb-swatch-border: #a3b8cc;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #bdd4ec;
  --mdb-swatch-hover-border: #7a8a99;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #b8cfe6;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-indigo {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #dcf;
  --mdb-swatch-border: #b1a3cc;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #ccbdec;
  --mdb-swatch-hover-border: #857a99;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #c7b8e6;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-purple {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #e7ccf5;
  --mdb-swatch-border: #b9a3c4;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #d6bde2;
  --mdb-swatch-hover-border: #8b7a93;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #d0b8dc;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.swatch-pink {
  --mdb-swatch-color: #000;
  --mdb-swatch-bg: #fcf;
  --mdb-swatch-border: #cca3cc;
  --mdb-swatch-hover-color: #000;
  --mdb-swatch-hover-bg: #ecbdec;
  --mdb-swatch-hover-border: #997a99;
  --mdb-swatch-active-color: #000;
  --mdb-swatch-active-bg: #e6b8e6;
  color: var(--mdb-swatch-color);
  background-color: var(--mdb-swatch-bg);
  border-color: var(--mdb-swatch-border);
}

.badge:empty:not(.badge-notification) {
  display: inline-block;
}

.badge:not(.badge-notification) {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  padding: 0;
}

.color:not(input) {
  border-radius: 50rem;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.list-group ul {
  margin-bottom: 1rem;
}

.list-group-item {
  background-color: #fff;
}

.list-group-item.h5 {
  border: none;
  margin-bottom: 0;
}

.list-group-item > :last-child {
  margin-bottom: 0 !important;
}

.list-group-item img {
  max-height: 250px;
}

.modal .modal-dialog {
  margin-left: auto;
  margin-right: auto;
}

.modal .modal-dialog.width-sidebar {
  padding-right: 260px;
}

.modal .modal-dialog .modal-content {
  overflow: inherit;
}

.modal .modal-dialog .modal-content > .card {
  margin-bottom: 0 !important;
}

.modal .modal-dialog .modal-content > .card .card-title {
  display: none;
}

.modal .modal-dialog .modal-content .modal-sidebar {
  background-color: #fff;
  border-radius: calc(.5rem - 1px);
  width: 260px;
  max-height: 100%;
  padding: .75rem;
  position: absolute;
  right: -270px;
  overflow-y: scroll;
}

.modal .modal-dialog .modal-content .modal-footer {
  justify-content: initial;
}

.modal-dialog-scrollable .modal-body {
  background-color: #e8ebec;
}

.modal-dialog-no-scrollable .modal-body, .modal-dialog-no-scrollable .modal-body > .card > .card-body {
  overflow-y: hidden;
}

.modal-file object, .modal-file image {
  height: 84vh;
}

.modal-header {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

@media (width <= 767.98px) {
  .modal-header {
    display: block !important;
  }

  .modal-header .btn {
    --mdb-btn-padding-top: .1rem !important;
    --mdb-btn-padding-bottom: .1rem !important;
  }
}

.modal-header .btn {
  --mdb-btn-padding-x: 1rem !important;
}

.modal-xs {
  min-width: 20vw !important;
}

.modal-sm {
  min-width: 60% !important;
  max-width: 65% !important;
}

.modal-md {
  min-width: 75% !important;
  max-width: 80% !important;
}

.modal-lg {
  min-width: 80% !important;
  max-width: 85% !important;
}

.modal-xl {
  min-width: 85% !important;
  max-width: 90% !important;
}

.modal-xxl {
  min-width: 90% !important;
  max-width: 95% !important;
}

.action-toggle {
  --kd-action-toggle-padding-y: .5rem;
  --kd-action-toggle-padding-x: .554rem;
  --kd-action-toggle-color: currentColor;
  --kd-action-toggle-bg: transparent;
  --kd-action-toggle-hover-color: #060c0d;
  --kd-action-toggle-hover-bg: #e8ebec;
  --kd-action-toggle-focus-color: #060c0d;
  --kd-action-toggle-focus-bg: #d2d8d9;
  --kd-acction-toggle-border-radius: 50rem;
  padding: var(--kd-action-toggle-padding-y) var(--kd-action-toggle-padding-x);
  color: var(--kd-action-toggle-color);
  border-radius: var(--kd-acction-toggle-border-radius);
  background-color: var(--kd-action-toggle-bg);
}

.action-toggle:hover {
  color: var(--kd-action-toggle-hover-color);
  background-color: var(--kd-action-toggle-hover-bg);
}

.action-toggle:focus {
  color: var(--kd-action-toggle-focus-color);
  background-color: var(--kd-action-toggle-focus-bg);
}

.action-toggle [class*="svg-inline"] {
  vertical-align: -.125em;
}

.action-toggle:after {
  display: none;
}

.login {
  align-items: center;
  display: flex;
}

#login {
  width: 100%;
  max-width: 340px;
  margin: auto;
}

#login #logo img {
  max-width: 150px;
  max-height: 75px;
}

#login #logo img[src*=".svg"] {
  width: 100px;
  height: 50px;
}

#login .alert:empty {
  display: none;
}

.avatar {
  text-align: center;
  display: inline-block;
}

.avatar img {
  color: var(--mdb-secondary-color);
  max-height: 100%;
  font-size: .875rem;
}

.avatar-title {
  background-color: #d2d8d9;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  display: flex;
}

.avatar-xl, .view [id*="logo_file"] img, .view [id*="pic_file"] img {
  --kd-avatar-size: 120px;
  width: var(--kd-avatar-size);
  height: var(--kd-avatar-size);
}

.avatar-xl .avatar-title, .view [id*="logo_file"] img .avatar-title, .view [id*="pic_file"] img .avatar-title {
  font-size: 1.25rem;
}

.avatar-lg {
  --kd-avatar-size: 60px;
  width: var(--kd-avatar-size);
  height: var(--kd-avatar-size);
}

.avatar-lg .avatar-title {
  font-size: 1.25rem;
}

.avatar-md {
  --kd-avatar-size: 40px;
  width: var(--kd-avatar-size);
  height: var(--kd-avatar-size);
}

.avatar-md .avatar-title {
  font-size: 1.25rem;
}

.avatar-sm {
  --kd-avatar-size: 30px;
  width: var(--kd-avatar-size);
  height: var(--kd-avatar-size);
  float: left;
}

.avatar-sm .avatar-title {
  font-size: .875rem;
}

.avatar-xs {
  --kd-avatar-size: 20px;
  width: var(--kd-avatar-size);
  height: var(--kd-avatar-size);
}

.avatar-xs .avatar-title {
  font-size: .875rem;
}

.bg-white .avatar-title {
  color: var(--mdb-secondary-color);
}

.bg-white .dropdown-toggle:hover .avatar, .bg-white .dropdown-toggle:focus .avatar {
  background-color: #203a42;
  box-shadow: 0 0 0 .25rem #d2d8d940;
}

.bg-white .dropdown-toggle:hover .avatar img, .bg-white .dropdown-toggle:focus .avatar img {
  opacity: .8;
}

.bg-secondary .avatar {
  background-color: #fff;
}

.bg-secondary .avatar-title {
  color: #203a42;
}

header {
  z-index: 1030;
  position: relative;
}

header .nav-item {
  margin: 0 .25rem;
}

header .nav-item:first-child {
  margin-left: 0;
}

header .nav-item:last-child {
  margin-right: 0;
}

header .nav-link, header .sub-link {
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  position: relative;
}

@media (width <= 991.98px) {
  header .nav-link, header .sub-link {
    width: 35px;
  }
}

header .nav-link:hover, header .sub-link:hover, header .nav-link:focus, header .sub-link:focus {
  background-color: #d2d8d9;
}

header .nav-link.dropdown-toggle:after, header .dropdown-toggle.sub-link:after {
  display: none;
}

header .badge.top-0 {
  top: .5rem !important;
}

header .badge.start-100 {
  left: calc(100% - .5rem) !important;
}

header .progress {
  cursor: pointer;
  width: 150px;
  margin-right: 10px;
}

header .search .fa-search {
  color: #79898e;
}

header .search:hover .fa-search, header .search:focus .fa-search {
  color: #4d6168;
}

header .search .form-control {
  background-color: #fbfbfb;
}

header .search .form-control::placeholder {
  color: #79898e;
}

header .search .form-control:hover, header .search .form-control:focus {
  color: #0d171a;
  background-color: #e8ebec;
}

header .search .form-control:hover::placeholder, header .search .form-control:focus::placeholder {
  color: #4d6168;
}

header .search {
  margin-right: 1rem;
}

@media (width >= 992px) and (width <= 1199.98px) {
  header .search .form-control {
    max-width: 180px;
  }
}

.navbar-start .left-icon_toggle {
  z-index: 123;
  width: 31px;
  height: 26px;
  margin-left: 15px;
  margin-right: 0;
  padding: 0;
  font-size: 20px;
  line-height: 26px;
}

@media (width >= 576px) {
  .navbar-start .left-icon_toggle {
    display: none;
  }
}

.navbar-start .left-icon_toggle span {
  background: #203a42;
  border-radius: 0;
  flex-flow: wrap;
  width: 100%;
  height: 2px;
  margin: 2px 0;
  display: flex;
}

.navbar-start .left-icon_toggle span:first-child {
  margin-top: 0;
}

.navbar-start .left-icon_toggle span:last-child {
  margin-bottom: 0;
}

@media (width <= 991.98px) {
  .navbar-start #headerLeftButtons {
    background: #fff;
    flex-wrap: wrap;
    width: 240px;
    transition: all .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 55px;
    left: 0;
    transform: translateX(-300px);
    margin: 0 !important;
  }
}

.navbar-start #headerLeftButtons.show {
  transform: translateX(0);
}

.navbar-start #headerLeftButtons li {
  margin: 0;
}

@media (width <= 991.98px) {
  .navbar-start #headerLeftButtons li a {
    background: none;
    border-radius: 0;
    height: auto;
    margin: 0 5px;
    line-height: normal;
    padding: 10px 0 !important;
  }
}

@media (width <= 767.98px) {
  .navbar-end .search {
    z-index: 123;
    justify-content: flex-end;
    max-width: 140px;
    height: 32px;
    margin-right: 0;
    padding-left: 0;
    display: flex;
    position: relative;
  }

  .navbar-end .search .fa-search {
    color: #203a42;
    z-index: 12;
    transition: all .2s ease-in-out;
    position: absolute;
    left: auto;
    right: 4px;
  }

  .navbar-end .search input.form-control {
    z-index: 123;
    opacity: 0;
    background-color: #0000;
    width: 0;
    height: 32px;
    padding-left: 10px;
    padding-right: 2.5rem;
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
  }

  .navbar-end .search input.form-control:focus {
    opacity: 1;
    z-index: 1;
    background-color: #e8ebec;
    width: 140px;
    max-width: 140px;
  }
}

.navbar-end .notification-icon {
  margin: 0 0 0 15px;
  padding: 0;
}

@media (width >= 576px) {
  .navbar-end .notification-icon {
    display: none;
  }
}

.navbar-end .notification-icon a {
  width: auto;
  height: auto;
  line-height: normal;
  background: none !important;
}

.navbar-end .notification-icon .icon {
  font-size: 15px;
}

.navbar-end .icons_toggle-btn {
  z-index: 123;
  flex-wrap: wrap;
  place-content: center;
  align-items: center;
  width: 21px;
  height: 25px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  font-size: 20px;
  display: flex;
}

@media (width >= 576px) {
  .navbar-end .icons_toggle-btn {
    display: none;
  }
}

.navbar-end .icons_toggle-btn span {
  background: #203a42;
  border-radius: 0;
  flex-flow: wrap;
  width: 100%;
  height: 2px;
  margin: 2px 0;
  display: flex;
}

.navbar-end .icons_toggle-btn span:first-child {
  margin-top: 0;
}

.navbar-end .icons_toggle-btn span:last-child {
  margin-bottom: 0;
}

@media (width <= 575.98px) {
  .navbar-end #headerRightButtons {
    margin: 0 15px 0 0 !important;
  }
}

@media (width <= 767.98px) {
  .navbar-end #headerRightButtons li {
    margin: 0;
  }
}

@media (width <= 991.98px) {
  .navbar-end #headerRightButtons li #tabBtn, .navbar-end #headerRightButtons li #refreshBtn, .navbar-end #headerRightButtons li #chatBtn {
    display: none;
  }
}

@media (width <= 575.98px) {
  .navbar-end #headerRightButtons li a {
    background: none;
    border-radius: 0;
    height: auto;
    margin: 0;
    line-height: normal;
    padding: 0 !important;
  }

  .navbar-collapse {
    padding: 0;
  }

  .navbar-collapse .user-content {
    justify-content: flex-start;
    gap: 1rem;
    display: flex;
  }

  .navbar-collapse .user-info {
    justify-content: center;
    display: flex;
  }

  .navbar-collapse .user-info h5, .navbar-collapse .user-info .h5 {
    margin-bottom: 0;
  }

  .navbar-collapse .user-info > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .navbar-collapse .nav-pills .nav-link, .navbar-collapse .nav-pills .sub-link {
    font-weight: normal;
  }
}

@media (width >= 576px) {
  .navbar-start {
    margin-right: auto;
  }
}

@media (width <= 767.98px) {
  .navbar-start .navbar-brand {
    margin-right: 0 !important;
  }
}

@media (width <= 575.98px) {
  .navbar-start .navbar-brand {
    width: 35px;
    margin-right: 0;
    margin-bottom: 0 !important;
  }

  .navbar-start .navbar-brand img {
    width: 170px;
    min-width: 170px;
    max-width: none;
  }
}

.navbar-start .navbar-nav li.nav-item {
  margin: 0 2px;
}

@media (width <= 575.98px) {
  .navbar-start .navbar-nav li.nav-item a {
    padding: 0 !important;
  }
}

@media (width <= 767.98px) {
  header.navbar #logo {
    margin-right: 10px;
  }
}

@media (width <= 575.98px) {
  .nav, .sub-menu {
    margin-bottom: 0 !important;
  }
}

.toggle-btn {
  cursor: pointer;
  z-index: 123;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  width: 24px;
  height: 20px;
  margin-left: 6px;
  margin-right: 10px;
  display: flex;
  position: relative;
}

@media (width >= 992px) {
  .toggle-btn {
    display: none;
  }
}

.toggle-btn span {
  background: #203a42;
  border-radius: 20px;
  flex-wrap: wrap;
  width: 100%;
  height: 2px;
  margin: 2px 0;
  display: flex;
}

.toggle-btn span:first-child {
  margin-top: 0;
}

.toggle-btn span:last-child {
  margin-bottom: 0;
}

@media (width >= 768px) {
  .navbar-end .search {
    margin-right: .5rem;
  }
}

@media (width >= 768px) and (width <= 991.98px) {
  .navbar-end .search .form-control {
    max-width: 180px;
  }
}

.overlay-layer header, .show header {
  z-index: 99999;
}

.sidebar {
  width: 240px;
  padding: 1rem 0 1rem 1rem;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

.sidebar .store-content {
  padding: 0;
}

.sidebar .store-content > a {
  justify-content: flex-start;
  gap: 1rem;
}

.sidebar .store-content.dropdown .dropdown-toggle:after {
  opacity: 1;
  display: flex;
}

.sidebar .store-content.dropdown .store-info {
  max-width: calc(192px - 3rem);
}

.sidebar .store-content:not(.dropdown) .store-info {
  max-width: calc(192px - 2rem);
}

.sidebar .store-content .avatar {
  --kd-avatar-size: 48px;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .store-content .avatar {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .sidebar .store-content .avatar {
    --kd-avatar-size: 30px;
  }
}

.sidebar .store-content .store-info {
  opacity: 1;
  justify-content: space-evenly;
  display: flex;
}

.sidebar .store-content .store-info small:empty, .sidebar .store-content .store-info .small:empty {
  display: none;
}

.sidebar .nav-link, .sidebar .sub-link {
  --mdb-nav-pills-link-border-radius: .25rem;
}

.sidebar .nav-link .icon, .sidebar .sub-link .icon {
  margin-right: 1rem;
}

.sidebar .link-title {
  opacity: 1;
  display: inline-flex;
}

.sidebar #menu-cont {
  max-height: calc(100% - 48px);
}

.sidebar #menu {
  width: 225px;
}

.sidebar .search {
  padding: 0 1rem 0 0;
}

.sidebar .search .fa-search {
  left: .75rem;
}

.sidebar .search .form-control {
  padding-left: 2.5rem;
}

.sidebar .store-content > a {
  display: flex;
}

.sidebar .avatar {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 0 .125rem #d2d8d940;
}

.sidebar .avatar img {
  max-height: 100%;
}

.sidebar .store-info {
  justify-content: center;
}

.sidebar .store-info h5, .sidebar .store-info .h5 {
  margin-bottom: 0;
}

.sidebar .store-info > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar .dropdown-toggle {
  position: relative;
}

.sidebar .dropdown-toggle:after {
  position: absolute;
  right: 0;
}

.sidebar #history.hidden {
  display: none;
}

.sidebar.bg-secondary .nav-link:hover, .sidebar.bg-secondary .sub-link:hover, .sidebar.bg-secondary .nav-link:focus, .sidebar.bg-secondary .sub-link:focus {
  color: #fff !important;
  background-color: #4d6168 !important;
}

.sidebar.bg-secondary .nav-link.active, .sidebar.bg-secondary .active.sub-link, .sidebar.bg-secondary .nav-link.active:hover, .sidebar.bg-secondary .nav-link.active:focus, .sidebar.bg-secondary .nav-item.show .nav-link, .sidebar.bg-secondary .nav-item.show .sub-link, .sidebar.bg-secondary .nav-item.show .nav-link:hover, .sidebar.bg-secondary .nav-item.show .nav-link:focus {
  color: #fff !important;
  background-color: #e3041c !important;
}

.sidebar.bg-secondary .sub-link.active, .sidebar.bg-secondary .sub-link.active:hover, .sidebar.bg-secondary .sub-link.active:focus, .sidebar.bg-secondary .nav-item.show .sub-link, .sidebar.bg-secondary .nav-item.show .sub-link:hover, .sidebar.bg-secondary .nav-item.show .sub-link:focus {
  background-color: #4d6168 !important;
}

.sidebar.bg-secondary .activate-link:focus {
  background-color: #0000 !important;
}

.sidebar.bg-secondary .dropdown-divider {
  border-width: 1px;
  border-top-color: #4d6168;
}

.sidebar .ps__thumb-y {
  background-color: #d2d8d9;
}

.sidebar .search .fa-search, .sidebar .search:hover .fa-search, .sidebar .search:focus .fa-search {
  color: #a6b0b3;
}

.sidebar .search .form-control {
  color: #d2d8d9;
  background-color: #0000;
  border-color: #4d6168;
}

.sidebar .search .form-control::placeholder {
  color: #a6b0b3;
}

.sidebar .search .form-control:hover, .sidebar .search .form-control:focus {
  background-color: #4d6168;
}

.sidebar .search .form-control:hover::placeholder, .sidebar .search .form-control:focus::placeholder {
  color: #a6b0b3;
}

.sidebar.sidebar-mini {
  width: 45px;
  padding: 1rem 0;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar.sidebar-mini {
    transition: none;
  }
}

.sidebar.sidebar-mini .store-content {
  padding: 0 .5rem;
}

.sidebar.sidebar-mini .store-content > a {
  justify-content: center;
  gap: 0;
}

.sidebar.sidebar-mini .store-content .dropdown-toggle:after {
  opacity: 0;
  display: none;
}

.sidebar.sidebar-mini .store-content .avatar {
  --kd-avatar-size: 30px;
}

.sidebar.sidebar-mini .store-content .avatar .avatar-title {
  font-size: .875rem;
}

.sidebar.sidebar-mini .store-content .store-info {
  opacity: 0;
  display: none;
}

.sidebar.sidebar-mini .nav-link, .sidebar.sidebar-mini .sub-link {
  --mdb-nav-pills-link-border-radius: 0;
}

.sidebar.sidebar-mini .nav-link .icon, .sidebar.sidebar-mini .sub-link .icon {
  margin-right: 0;
}

.sidebar.sidebar-mini .link-title {
  opacity: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
  overflow: hidden;
}

.sidebar.sidebar-mini .nav-item .collapse {
  display: none;
}

.sidebar.sidebar-mini .nav-item .collapse.show {
  display: block;
}

.sidebar.sidebar-mini .search {
  padding: 0 .5rem;
}

.sidebar.sidebar-mini .search .fa-search {
  left: 1.125rem;
}

.sidebar.sidebar-mini .search .form-control {
  padding-left: 6px;
  padding-right: 6px;
}

.sidebar.sidebar-mini .search .form-control::placeholder {
  color: #0000;
}

.sidebar.sidebar-mini.hover {
  width: 240px;
  padding: 1rem;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar.sidebar-mini.hover {
    transition: none;
  }
}

.sidebar.sidebar-mini.hover .store-content {
  padding: 0;
}

.sidebar.sidebar-mini.hover .store-content > a {
  justify-content: flex-start;
  gap: 1rem;
}

.sidebar.sidebar-mini.hover .store-content.dropdown .dropdown-toggle:after {
  opacity: 1;
  display: flex;
}

.sidebar.sidebar-mini.hover .store-content.dropdown .store-info {
  max-width: calc(192px - 3rem);
}

.sidebar.sidebar-mini.hover .store-content:not(.dropdown) .store-info {
  max-width: calc(192px - 2rem);
}

.sidebar.sidebar-mini.hover .store-content .avatar {
  --kd-avatar-size: 48px;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar.sidebar-mini.hover .store-content .avatar {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .sidebar.sidebar-mini.hover .store-content .avatar {
    --kd-avatar-size: 30px;
  }
}

.sidebar.sidebar-mini.hover .store-content .store-info {
  opacity: 1;
  justify-content: space-evenly;
  display: flex;
}

.sidebar.sidebar-mini.hover .store-content .store-info small:empty, .sidebar.sidebar-mini.hover .store-content .store-info .small:empty {
  display: none;
}

.sidebar.sidebar-mini.hover .nav-link, .sidebar.sidebar-mini.hover .sub-link {
  --mdb-nav-pills-link-border-radius: .25rem;
}

.sidebar.sidebar-mini.hover .nav-link .icon, .sidebar.sidebar-mini.hover .sub-link .icon {
  margin-right: 1rem;
}

.sidebar.sidebar-mini.hover .link-title {
  opacity: 1;
  display: inline-flex;
}

.sidebar.sidebar-mini.hover #menu-cont {
  max-height: calc(100% - 48px);
}

.sidebar.sidebar-mini.hover #menu {
  width: 225px;
}

.sidebar.sidebar-mini.hover .search {
  padding: 0 1rem 0 0;
}

.sidebar.sidebar-mini.hover .search .fa-search {
  left: .75rem;
}

.sidebar.sidebar-mini.hover .search .form-control {
  padding-left: 2.5rem;
}

.sidebar.sidebar-mini.hover .search .form-control::placeholder {
  color: #a6b0b3;
}

.sidebar.sidebar-mini.hover .nav-item .collapse.show {
  display: block !important;
}

#offcanvasSidebar {
  --mdb-offcanvas-width: 240px;
  top: calc(40px + 1rem);
}

@media (width <= 767.98px) {
  .sidebar .store-content .avatar {
    --kd-avatar-size: 30px;
  }
}

@media (width <= 991.98px) {
  #sidebar.sidebar.sidebar-mini {
    width: 240px;
    transition: all .3s ease-in-out;
    transform: translateX(-300px);
  }
}

#sidebar.sidebar.sidebar-mini.show {
  transform: translateX(0);
}

@media (width <= 991.98px) {
  #sidebar.sidebar {
    z-index: 99999;
    width: 240px;
    height: calc(100% - 90px);
    position: fixed;
    top: 90px;
    left: 0;
    transform: translateX(-300px);
  }
}

#sidebar.sidebar.show {
  transform: translateX(0);
}

@media (width <= 991.98px) {
  #sidebar.sidebar .store-content a {
    justify-content: flex-start;
    gap: 15px;
  }

  #sidebar.sidebar .store-content .store-info {
    opacity: 1;
    display: flex;
  }

  #sidebar.sidebar #menu-cont {
    height: 95%;
    max-height: 95%;
    padding-bottom: 30px;
    overflow-y: auto !important;
  }

  #sidebar.sidebar #menu-cont .ps__rail-x, #sidebar.sidebar #menu-cont .ps__rail-y {
    display: none;
  }

  #sidebar.sidebar #menu {
    width: 100%;
  }

  #sidebar.sidebar #menu > li:last-child {
    display: none;
  }

  #sidebar.sidebar #menu .icon {
    margin-right: 1rem;
  }

  #sidebar.sidebar #menu .link-title {
    opacity: 1;
    display: inline-flex;
    overflow: visible;
  }

  #sidebar.sidebar .search {
    display: none;
  }
}

body.show .navbar-start #headerLeftButtons, body.show #sidebar.sidebar, body.show #sidebar.sidebar.sidebar-mini {
  transform: translateX(0);
}

main {
  width: 100%;
  height: calc(100% - 54px);
  position: absolute;
  top: 54px;
  bottom: 0;
  overflow: hidden;
}

main #chat {
  max-width: 60px;
  max-height: 100%;
}

main #views {
  max-width: calc(100% - 46px);
  max-height: 100%;
  padding-top: 15px;
  overflow: hidden;
}

@media (width <= 575.98px) {
  main #views {
    max-width: 100%;
  }
}

main #help {
  flex-shrink: 0;
  width: 350px;
  left: 0 !important;
}

main #help .card {
  height: 100%;
}

main #help .card .search {
  flex: auto;
}

main #help .card #help-detail {
  max-height: calc(100% - 250px);
}

main #help .card #help-detail img {
  max-width: 100%;
}

main #help .card #help-detail .field-file {
  max-height: 500px;
  padding-right: 15px;
}

#dashboard {
  max-width: calc(100% - 46px);
}

@media (width <= 575.98px) {
  #dashboard {
    max-width: 100%;
  }
}

@media (width <= 767.98px) {
  #dashboard {
    margin-bottom: 20px;
  }
}

#dashboard .row {
  max-height: calc(100vh - 25px);
  overflow: hidden;
}

@media (width <= 991.98px) {
  #dashboard .row {
    max-height: calc(100vh - 190px);
  }
}

@media (width <= 767.98px) {
  #dashboard .row {
    max-height: calc(100vh - 65px);
  }
}

@media (width <= 991.98px) {
  #dashboard .row .col:last-child {
    margin-bottom: 100px;
  }
}

@media (width <= 767.98px) {
  #dashboard .row .col:last-child {
    margin-bottom: 40px;
  }
}

#dashboard .card {
  height: 100%;
}

@media (width <= 767.98px) {
  #dashboard .card:last-child {
    margin-bottom: 20px;
  }
}

#dashboard .card .table-scroll {
  max-height: 30rem;
}

#dashboard .chart {
  max-height: 500px;
}

#dashboard .diagram {
  width: 450px;
  height: 223px;
  overflow: hidden;
}

.view > * {
  margin-bottom: 1rem;
}

.view > :last-child {
  margin-bottom: 0;
}

.view .view-header {
  white-space: nowrap;
}

@media (width <= 767.98px) {
  .view .view-header {
    flex-wrap: wrap;
  }
}

.view .view-title {
  overflow: hidden;
}

@media (width <= 767.98px) {
  .view .view-title {
    margin-bottom: 10px;
    margin-left: 10px;
    overflow: visible;
  }
}

.view .view-title .warning {
  margin-right: .5rem;
}

.view .view-title h2, .view .view-title .h2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (width <= 767.98px) {
  .view .view-title h2, .view .view-title .h2 {
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
  }

  .view .action-buttons-left {
    white-space: normal;
    flex-wrap: wrap;
    gap: 4px 5px;
    width: max-content;
    margin-bottom: 4px;
    display: flex;
  }

  .view .action-buttons-left button {
    margin: 0 !important;
  }

  .view .me-auto {
    margin-right: unset !important;
    margin-left: 10px !important;
  }
}

@media (width <= 575.98px) {
  .view .me-auto {
    margin-right: unset !important;
    margin-left: 10px !important;
  }
}

.view .action-buttons-right {
  white-space: nowrap;
}

@media screen and (width <= 575.98px), screen and (height <= 700px) {
  .view .action-buttons-right {
    margin-left: 1rem;
  }
}

.view .view-nav {
  max-height: calc(100vh - 40px - 5.625rem);
  margin-top: 1rem;
  overflow: hidden auto;
}

.view .view-main > * {
  margin-bottom: 1rem;
}

.view .view-main > :last-child {
  margin-bottom: 0;
}

.view .view-right {
  max-height: calc(100vh - 40px - 5.625rem);
  margin-top: 3.625rem;
  padding-left: 0;
  overflow: hidden auto;
}

.view .view-right .read {
  padding: 0;
}

.view.view-overview .view-right {
  margin-top: calc(3.625rem + 51px);
}

.view.view-small .action-buttons-left .btn:not(.btn-fix), .view.view-small .view-left, .view.view-small .view-right {
  display: none;
}

.view:focus-visible {
  outline: none;
}

.card-minimal .card-body {
  overflow: hidden;
}

.view-body.scrollable, .card-body.scrollable {
  max-height: calc(100vh - 130px);
}

.view-single .view .view-body {
  height: calc(100vh - 165px);
  overflow: hidden scroll;
}

.view-duo .view-overview .view-header {
  min-height: 2.625rem;
}

.field-steps {
  text-align: center;
}

.field-steps .btn {
  color: var(--mdb-secondary-color);
  text-align: left;
  white-space: nowrap;
  box-shadow: none;
  background-color: #0000;
  padding-left: 0;
  padding-right: 0;
  border: 0 !important;
}

.field-steps .btn:hover, .field-steps .btn:focus {
  background-color: #0000;
}

.field-steps .btn:before {
  background-color: var(--mdb-secondary-color);
  color: #fff;
  text-align: center;
  border-radius: 50rem;
  width: 32px;
  height: 32px;
  margin-right: .5rem;
  font-weight: 700;
  line-height: 32px;
  display: inline-block;
}

.field-steps .btn:after {
  content: "";
  border-bottom: 1px solid #d2d8d9;
  width: 48px;
  margin-bottom: .25rem;
  margin-left: .5rem;
  display: inline-block;
}

.field-steps .btn.success:before {
  background-color: #00ae00;
}

.field-steps .btn.warning:before {
  background-color: #ffc107;
}

.field-steps .btn.danger:before {
  background-color: #e3041c;
}

.field-steps .btn:first-child:before {
  content: "1";
}

.field-steps .btn:nth-child(2):before {
  content: "2";
}

.field-steps .btn:nth-child(3):before {
  content: "3";
}

.field-steps .btn:nth-child(4):before {
  content: "4";
}

.field-steps .btn:nth-child(5):before {
  content: "5";
}

.field-steps .btn:nth-child(6):before {
  content: "6";
}

.field-steps .btn:nth-child(7):before {
  content: "7";
}

.field-steps .btn:nth-child(8):before {
  content: "8";
}

.field-steps .btn:nth-child(9):before {
  content: "9";
}

.field-steps .btn:nth-child(10):before {
  content: "10";
}

.field-steps .btn:nth-child(11):before {
  content: "11";
}

.field-steps .btn:nth-child(12):before {
  content: "12";
}

.field-steps .btn:nth-child(13):before {
  content: "13";
}

.field-steps .btn:nth-child(14):before {
  content: "14";
}

.field-steps .btn:nth-child(15):before {
  content: "15";
}

.field-steps .btn:last-child:after {
  width: 0;
}

.field-steps .btn + .btn {
  margin-left: .5rem;
}

.field-steps.smaller1 .btn:not(:last-child):after {
  width: 10px;
}

.field-steps.smaller2 .btn:not(:last-child):after {
  width: 0;
}

.field-steps.smaller3 .btn {
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
}

.progress {
  border-radius: .25rem;
  height: 20px;
}

.view-chart .chart {
  height: 680px;
}

.view-chart .chart rect {
  fill: #fff;
}

.wysiwyg {
  color: #000;
  font-family: Helvetica, serif;
  font-size: 13px;
}

.wysiwyg page, .wysiwyg .mce-pagebreak {
  border-top: 3px dashed #000;
  width: 100%;
  height: 1px;
  margin: 50px -10px;
  display: block;
}

.wysiwyg div {
  margin-left: .1px;
}

.wysiwyg a {
  color: #000;
}

.wysiwyg li {
  padding: 5px 0;
}

.wysiwyg h1, .wysiwyg .h1, .wysiwyg h2, .wysiwyg .h2, .wysiwyg h3, .wysiwyg .h3, .wysiwyg h4, .wysiwyg .h4, .wysiwyg h5, .wysiwyg .h5, .wysiwyg h6, .wysiwyg .h6, .wysiwyg p {
  margin: 0;
  padding: 5px 0;
}

.wysiwyg h2, .wysiwyg .h2 {
  font-size: 18px;
}

.wysiwyg h3, .wysiwyg .h3 {
  font-size: 15px;
}

.wysiwyg table thead th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: 1px solid gray;
  padding: 3px;
  font-weight: normal;
}

.wysiwyg table td {
  vertical-align: top;
  min-width: 20px;
  padding: 3px;
}

.wysiwyg table[border="1"] td {
  border: 1px solid gray !important;
}

.wysiwyg table.text td:first-child {
  padding-bottom: 5px;
  padding-right: 25px;
}

.wysiwyg table.tableDynamic {
  margin-bottom: 15px;
}

.wysiwyg table.tableDynamic thead tr th {
  background-color: #cecece;
}

.wysiwyg table.tableDynamic tbody tr td {
  border-bottom: 1px solid #f0f0f0;
}

.wysiwyg table .borderTop {
  border-top: 1px solid gray !important;
}

.wysiwyg table .borderBottom {
  border-bottom: 1px solid gray !important;
}

.wysiwyg table table {
  width: auto;
}

.wysiwyg table table td.wysiwyg-font-size-12 table td {
  text-align: right;
  padding: 2px 0;
}

.wysiwyg table img {
  max-width: initial;
}

.wysiwyg .var {
  color: inherit;
  font-family: Roboto, serif;
  font-size: 100%;
  font-weight: inherit;
  text-decoration: inherit;
  background-color: #d3d3d3;
  border: none;
  border-radius: 2px;
  margin: 0 1px;
  padding: 0 3px;
}

.wysiwyg .wysiwyg-paragraph {
  margin: 0;
  padding: 5px 0;
  display: block;
}

.wysiwyg .wysiwyg-text-align-left {
  text-align: left;
}

.wysiwyg .wysiwyg-text-align-center {
  text-align: center;
}

.wysiwyg .wysiwyg-text-align-right {
  text-align: right;
}

.wysiwyg .wysiwyg-font-size-8 {
  font-size: 8px;
}

.wysiwyg .wysiwyg-font-size-10 {
  font-size: 10px;
}

.wysiwyg .wysiwyg-font-size-11 {
  font-size: 11px;
}

.wysiwyg .wysiwyg-font-size-12 {
  font-size: 12px;
}

.wysiwyg .wysiwyg-font-size-13 {
  font-size: 13px;
}

.wysiwyg .wysiwyg-font-size-14 {
  font-size: 14px;
}

.wysiwyg .wysiwyg-font-size-16 {
  font-size: 16px;
}

.wysiwyg .wysiwyg-font-size-18 {
  font-size: 18px;
}

.wysiwyg .wysiwyg-font-size-20 {
  font-size: 20px;
}

.tox-tinymce .tox-promotion {
  display: none;
}

.tox-tinymce .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #d3d3d3 !important;
}

.tox-notification, .tox-notifications-container {
  display: none !important;
}

.admin-info {
  font-family: var(--mdb-font-monospace);
  opacity: .65;
  display: none;
}

* > .admin-info:last-child {
  padding-right: 0 !important;
}

th .admin-info {
  padding-left: 0 !important;
}

.form-label .admin-info {
  opacity: 1;
}

.debug-info .admin-info {
  display: inline-block;
}

.debug-info th .admin-info {
  display: block;
}

@media (width <= 767.98px) {
  .row > * {
    padding-right: calc(var(--mdb-gutter-x) * .2) !important;
    padding-left: calc(var(--mdb-gutter-x) * .2) !important;
  }
}

.preview-images {
  flex: 1 0;
  width: 20%;
  margin-top: .25rem;
  margin-left: .25rem;
  margin-right: .25rem;
  display: inline-block;
}

.wiki-preview {
  object-fit: contain;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  height: auto !important;
}

.form-outline .form-control {
  padding-right: 3rem !important;
}

.form-switch .form-check-input:checked[type="checkbox"]:after {
  background-color: #e3041c;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fas, .fass, .far, .fasr, .fal, .fasl, .fat, .fast, .fad, .fadr, .fadl, .fadt, .fasds, .fasdr, .fasdl, .fasdt, .fab, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-brands, .fa-classic, .fa-duotone, .fa-sharp, .fa-sharp-duotone, .fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas:before, .fass:before, .far:before, .fasr:before, .fal:before, .fasl:before, .fat:before, .fast:before, .fad:before, .fadr:before, .fadl:before, .fadt:before, .fasds:before, .fasdr:before, .fasdl:before, .fasdt:before, .fab:before, .fa-solid:before, .fa-regular:before, .fa-light:before, .fa-thin:before, .fa-brands:before, .fa-classic:before, .fa-duotone:before, .fa-sharp:before, .fa-sharp-duotone:before, .fa:before {
  content: var(--fa);
}

.fad:after, .fa-duotone.fa-solid:after, .fa-duotone:after, .fadr:after, .fa-duotone.fa-regular:after, .fadl:after, .fa-duotone.fa-light:after, .fadt:after, .fa-duotone.fa-thin:after, .fasds:after, .fa-sharp-duotone.fa-solid:after, .fa-sharp-duotone:after, .fasdr:after, .fa-sharp-duotone.fa-regular:after, .fasdl:after, .fa-sharp-duotone.fa-light:after, .fasdt:after, .fa-sharp-duotone.fa-thin:after {
  content: var(--fa--fa);
}

.fa-classic.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass, .fa-sharp, .fad, .fa-duotone, .fasds, .fa-sharp-duotone {
  font-weight: 900;
}

.fa-classic, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fa-duotone, .fad, .fadr, .fadl, .fadt {
  font-family: "Font Awesome 6 Duotone";
}

.fa-brands, .fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-sharp, .fass, .fasr, .fasl, .fast {
  font-family: "Font Awesome 6 Sharp";
}

.fa-sharp-duotone, .fasds, .fasdr, .fasdl, .fasdt {
  font-family: "Font Awesome 6 Sharp Duotone";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .0833333em;
}

.fa-sm {
  vertical-align: .0535714em;
  font-size: .875em;
  line-height: .0714286em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .0416667em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0 {
  --fa: "0";
  --fa--fa: "00";
}

.fa-1 {
  --fa: "1";
  --fa--fa: "11";
}

.fa-2 {
  --fa: "2";
  --fa--fa: "22";
}

.fa-3 {
  --fa: "3";
  --fa--fa: "33";
}

.fa-4 {
  --fa: "4";
  --fa--fa: "44";
}

.fa-5 {
  --fa: "5";
  --fa--fa: "55";
}

.fa-6 {
  --fa: "6";
  --fa--fa: "66";
}

.fa-7 {
  --fa: "7";
  --fa--fa: "77";
}

.fa-8 {
  --fa: "8";
  --fa--fa: "88";
}

.fa-9 {
  --fa: "9";
  --fa--fa: "99";
}

.fa-fill-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-right, .fa-chevron-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-wagon-covered {
  --fa: "";
  --fa--fa: "";
}

.fa-line-height {
  --fa: "";
  --fa--fa: "";
}

.fa-bagel {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-at {
  --fa: "@";
  --fa--fa: "@@";
}

.fa-rectangles-mixed {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-up-right, .fa-phone-arrow-up, .fa-phone-outgoing {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can, .fa-trash-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-l {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-goggles, .fa-head-vr {
  --fa: "";
  --fa--fa: "";
}

.fa-text-height {
  --fa: "";
  --fa--fa: "";
}

.fa-user-xmark, .fa-user-times {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-yawn {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-min, .fa-tachometer-slowest {
  --fa: "";
  --fa--fa: "";
}

.fa-stethoscope {
  --fa: "";
  --fa--fa: "";
}

.fa-coffin {
  --fa: "";
  --fa--fa: "";
}

.fa-message, .fa-comment-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-salad, .fa-bowl-salad {
  --fa: "";
  --fa--fa: "";
}

.fa-info {
  --fa: "";
  --fa--fa: "";
}

.fa-robot-astromech {
  --fa: "";
  --fa--fa: "";
}

.fa-ring-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-fondue-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-theta {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-peeking {
  --fa: "";
  --fa--fa: "";
}

.fa-square-user {
  --fa: "";
  --fa--fa: "";
}

.fa-down-left-and-up-right-to-center, .fa-compress-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-explosion {
  --fa: "";
  --fa--fa: "";
}

.fa-file-lines, .fa-file-alt, .fa-file-text {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-square {
  --fa: "";
  --fa--fa: "";
}

.fa-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-building-un {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-three {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-pressure-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-fair, .fa-wifi-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-days, .fa-calendar-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mp3-player {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-volleyball, .fa-volleyball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-haze {
  --fa: "";
  --fa--fa: "";
}

.fa-text-size {
  --fa: "";
  --fa--fa: "";
}

.fa-ufo {
  --fa: "";
  --fa--fa: "";
}

.fa-fork, .fa-utensil-fork {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode-scan {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-down, .fa-sort-desc {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-arrow-down, .fa-folder-download {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-minus, .fa-minus-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-icicles {
  --fa: "";
  --fa--fa: "";
}

.fa-shovel {
  --fa: "";
  --fa--fa: "";
}

.fa-door-open {
  --fa: "";
  --fa--fa: "";
}

.fa-films {
  --fa: "";
  --fa--fa: "";
}

.fa-right-from-bracket, .fa-sign-out-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-glasses {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc {
  --fa: "";
  --fa--fa: "";
}

.fa-atom {
  --fa: "";
  --fa--fa: "";
}

.fa-soap {
  --fa: "";
  --fa--fa: "";
}

.fa-icons, .fa-heart-music-camera-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-lines-slash, .fa-microphone-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-closed-captioning-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-calculator-simple, .fa-calculator-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-up, .fa-sliders-v {
  --fa: "";
  --fa--fa: "";
}

.fa-location-minus, .fa-map-marker-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-pump-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-fingerprint {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-boot {
  --fa: "";
  --fa--fa: "";
}

.fa-standard-definition, .fa-rectangle-sd {
  --fa: "";
  --fa--fa: "";
}

.fa-h1 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-right {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-location, .fa-search-location {
  --fa: "";
  --fa--fa: "";
}

.fa-message-bot {
  --fa: "";
  --fa--fa: "";
}

.fa-forward-step, .fa-step-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-beam, .fa-smile-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-light-ceiling {
  --fa: "";
  --fa--fa: "";
}

.fa-message-exclamation, .fa-comment-alt-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-scoop, .fa-bowl-shaved-ice {
  --fa: "";
  --fa--fa: "";
}

.fa-square-x {
  --fa: "";
  --fa--fa: "";
}

.fa-building-memo {
  --fa: "";
  --fa--fa: "";
}

.fa-utility-pole-double {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-checkered {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-up, .fa-chevron-double-up {
  --fa: "";
  --fa--fa: "";
}

.fa-football, .fa-football-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck {
  --fa: "";
  --fa--fa: "";
}

.fa-school-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-crop {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-down, .fa-angle-double-down {
  --fa: "";
  --fa--fa: "";
}

.fa-users-rectangle {
  --fa: "";
  --fa--fa: "";
}

.fa-people-roof {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-right, .fa-arrow-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-location-plus, .fa-map-marker-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-exclamation-on {
  --fa: "";
  --fa--fa: "";
}

.fa-people-line {
  --fa: "";
  --fa--fa: "";
}

.fa-beer-mug-empty, .fa-beer {
  --fa: "";
  --fa--fa: "";
}

.fa-carpool, .fa-car-people {
  --fa: "";
  --fa--fa: "";
}

.fa-crate-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-predecessor {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-long, .fa-long-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-person-carry-box, .fa-person-carry {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame-simple, .fa-burn {
  --fa: "";
  --fa--fa: "";
}

.fa-person, .fa-male {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop {
  --fa: "";
  --fa--fa: "";
}

.fa-file-csv {
  --fa: "";
  --fa--fa: "";
}

.fa-menorah {
  --fa: "";
  --fa--fa: "";
}

.fa-union {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-left, .fa-chevron-double-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-heart, .fa-heart-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-record-vinyl {
  --fa: "";
  --fa--fa: "";
}

.fa-bring-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-square-p {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-stars, .fa-grin-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-sigma {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-movie {
  --fa: "";
  --fa--fa: "";
}

.fa-bong {
  --fa: "";
  --fa--fa: "";
}

.fa-clarinet {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-flatbed {
  --fa: "";
  --fa--fa: "";
}

.fa-spaghetti-monster-flying, .fa-pastafarianism {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-up-across-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-rotate-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-building {
  --fa: "";
  --fa--fa: "";
}

.fa-cheese-swiss {
  --fa: "";
  --fa--fa: "";
}

.fa-spoon, .fa-utensil-spoon {
  --fa: "";
  --fa--fa: "";
}

.fa-jar-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-envelopes-bulk, .fa-mail-bulk {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-bow-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-xmark, .fa-times-hexagon, .fa-xmark-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-h, .fa-hospital-symbol {
  --fa: "";
  --fa--fa: "";
}

.fa-merge {
  --fa: "";
  --fa--fa: "";
}

.fa-pager {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-address-book, .fa-contact-book {
  --fa: "";
  --fa--fa: "";
}

.fa-pan-frying {
  --fa: "";
  --fa--fa: "";
}

.fa-grid, .fa-grid-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-football-helmet {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-love {
  --fa: "";
  --fa--fa: "";
}

.fa-trees {
  --fa: "";
  --fa--fa: "";
}

.fa-strikethrough {
  --fa: "";
  --fa--fa: "";
}

.fa-page {
  --fa: "";
  --fa--fa: "";
}

.fa-k {
  --fa: "K";
  --fa--fa: "KK";
}

.fa-diagram-previous {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-min, .fa-tachometer-alt-slowest {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-grid {
  --fa: "";
  --fa--fa: "";
}

.fa-eggplant {
  --fa: "";
  --fa--fa: "";
}

.fa-excavator {
  --fa: "";
  --fa--fa: "";
}

.fa-ram {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-lips {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil, .fa-pencil-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comments {
  --fa: "";
  --fa--fa: "";
}

.fa-paste, .fa-file-clipboard {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-code-pull-request {
  --fa: "";
  --fa--fa: "";
}

.fa-pumpkin {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-list {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-field {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-blueberries {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp-box, .fa-truck-loading {
  --fa: "";
  --fa--fa: "";
}

.fa-note {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-square {
  --fa: "";
  --fa--fa: "";
}

.fa-user-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-vial-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-book-blank, .fa-book-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-flag-hole {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-down, .fa-comment-alt-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-face-unamused {
  --fa: "";
  --fa--fa: "";
}

.fa-sheet-plastic {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-blog {
  --fa: "";
  --fa--fa: "";
}

.fa-user-ninja {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-pins {
  --fa: "";
  --fa--fa: "";
}

.fa-person-arrow-up-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll-torah, .fa-torah {
  --fa: "";
  --fa--fa: "";
}

.fa-webhook {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds-open {
  --fa: "";
  --fa--fa: "";
}

.fa-fence {
  --fa: "";
  --fa--fa: "";
}

.fa-up, .fa-arrow-alt-up {
  --fa: "";
  --fa--fa: "";
}

.fa-broom-ball, .fa-quidditch, .fa-quidditch-broom-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-drumstick {
  --fa: "";
  --fa--fa: "";
}

.fa-square-v {
  --fa: "";
  --fa--fa: "";
}

.fa-face-awesome, .fa-gave-dandy {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-off {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-off {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-box-archive, .fa-archive {
  --fa: "";
  --fa--fa: "";
}

.fa-grapes {
  --fa: "";
  --fa--fa: "";
}

.fa-person-drowning {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-max {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-m {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-image {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-down, .fa-caret-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-9-1, .fa-sort-numeric-desc, .fa-sort-numeric-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tongue-squint, .fa-grin-tongue-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-shish-kebab {
  --fa: "";
  --fa--fa: "";
}

.fa-spray-can {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-snooze {
  --fa: "";
  --fa--fa: "";
}

.fa-scarecrow {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-monster {
  --fa: "";
  --fa--fa: "";
}

.fa-gift-card {
  --fa: "";
  --fa--fa: "";
}

.fa-w {
  --fa: "W";
  --fa--fa: "WW";
}

.fa-code-pull-request-draft {
  --fa: "";
  --fa--fa: "";
}

.fa-square-b {
  --fa: "";
  --fa--fa: "";
}

.fa-elephant {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-africa, .fa-globe-africa {
  --fa: "";
  --fa--fa: "";
}

.fa-rainbow {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-notch {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-screen-button, .fa-tablet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-paw {
  --fa: "";
  --fa--fa: "";
}

.fa-message-question {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-trowel-bricks {
  --fa: "";
  --fa--fa: "";
}

.fa-square-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-flushed, .fa-flushed {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital-user {
  --fa: "";
  --fa--fa: "";
}

.fa-microwave {
  --fa: "";
  --fa--fa: "";
}

.fa-chf-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-both {
  --fa: "";
  --fa--fa: "";
}

.fa-gavel, .fa-legal {
  --fa: "";
  --fa--fa: "";
}

.fa-sprinkler-ceiling {
  --fa: "";
  --fa--fa: "";
}

.fa-browsers {
  --fa: "";
  --fa--fa: "";
}

.fa-trillium {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-music-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp {
  --fa: "";
  --fa--fa: "";
}

.fa-binoculars {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-box-tissue {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-c {
  --fa: "";
  --fa--fa: "";
}

.fa-star-christmas {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-bullet {
  --fa: "";
  --fa--fa: "";
}

.fa-motorcycle {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-christmas {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-flat {
  --fa: "";
  --fa--fa: "";
}

.fa-sunglasses {
  --fa: "";
  --fa--fa: "";
}

.fa-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-message-pen, .fa-comment-alt-edit, .fa-message-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-concierge, .fa-concierge-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-ruler, .fa-pencil-ruler {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mp3 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-progress {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-rook-piece, .fa-chess-rook-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-root {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-people-arrows, .fa-people-arrows-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-post {
  --fa: "";
  --fa--fa: "";
}

.fa-face-angry-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-and-venus-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-tombstone {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-right, .fa-caret-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scissors, .fa-cut {
  --fa: "";
  --fa--fa: "";
}

.fa-list-music {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-plant-wilt {
  --fa: "";
  --fa--fa: "";
}

.fa-toilets-portable {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-puck {
  --fa: "";
  --fa--fa: "";
}

.fa-mustache {
  --fa: "";
  --fa--fa: "";
}

.fa-hyphen {
  --fa: "-";
  --fa--fa: "--";
}

.fa-table {
  --fa: "";
  --fa--fa: "";
}

.fa-user-chef {
  --fa: "";
  --fa--fa: "";
}

.fa-message-image, .fa-comment-alt-image {
  --fa: "";
  --fa--fa: "";
}

.fa-users-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-triangle-exclamation, .fa-sensor-alert {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tachograph-digital, .fa-digital-tachograph {
  --fa: "";
  --fa--fa: "";
}

.fa-face-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-pickleball {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp-half {
  --fa: "";
  --fa--fa: "";
}

.fa-users-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-clover {
  --fa: "";
  --fa--fa: "";
}

.fa-meat {
  --fa: "";
  --fa--fa: "";
}

.fa-reply, .fa-mail-reply {
  --fa: "";
  --fa--fa: "";
}

.fa-star-and-crescent {
  --fa: "";
  --fa--fa: "";
}

.fa-empty-set {
  --fa: "";
  --fa--fa: "";
}

.fa-house-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-square-minus, .fa-minus-square {
  --fa: "";
  --fa--fa: "";
}

.fa-helicopter {
  --fa: "";
  --fa--fa: "";
}

.fa-bird {
  --fa: "";
  --fa--fa: "";
}

.fa-compass {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-down, .fa-caret-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-half-stroke, .fa-heart-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-question {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-utensils {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-code {
  --fa: "";
  --fa--fa: "";
}

.fa-joystick {
  --fa: "";
  --fa--fa: "";
}

.fa-grill-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-vertical-history {
  --fa: "";
  --fa--fa: "";
}

.fa-swatchbook {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-bars, .fa-navicon {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-left {
  --fa: "";
  --fa--fa: "";
}

.fa-people-group {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-end, .fa-hourglass-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-crack, .fa-heart-broken {
  --fa: "";
  --fa--fa: "";
}

.fa-face-beam-hand-over-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-percent, .fa-humidity {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up-right, .fa-external-link-square-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-beam, .fa-kiss-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-corn {
  --fa: "";
  --fa--fa: "";
}

.fa-roller-coaster {
  --fa: "";
  --fa--fa: "";
}

.fa-photo-film-music {
  --fa: "";
  --fa--fa: "";
}

.fa-radar {
  --fa: "";
  --fa--fa: "";
}

.fa-sickle {
  --fa: "";
  --fa--fa: "";
}

.fa-film {
  --fa: "";
  --fa--fa: "";
}

.fa-coconut {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-tape {
  --fa: "";
  --fa--fa: "";
}

.fa-square-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-people-robbery {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-left {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-list {
  --fa: "";
  --fa--fa: "";
}

.fa-block {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-exclamation, .fa-exclamation-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-school-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-bracket, .fa-sign-out {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown-slight {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-down, .fa-chevron-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-sidebar-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-unlock-keyhole, .fa-unlock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-list {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers-heavy {
  --fa: "";
  --fa--fa: "";
}

.fa-headphones-simple, .fa-headphones-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sitemap {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-section {
  --fa: "";
  --fa--fa: "";
}

.fa-space-station-moon-construction, .fa-space-station-moon-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dollar-to-slot, .fa-donate {
  --fa: "";
  --fa--fa: "";
}

.fa-memory {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sleeping {
  --fa: "";
  --fa--fa: "";
}

.fa-road-spikes {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-burner {
  --fa: "";
  --fa--fa: "";
}

.fa-squirrel {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-line, .fa-arrow-to-top {
  --fa: "";
  --fa--fa: "";
}

.fa-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-face-cowboy-hat {
  --fa: "";
  --fa--fa: "";
}

.fa-hanukiah {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter-3d {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up {
  --fa: "";
  --fa--fa: "";
}

.fa-square-code {
  --fa: "";
  --fa--fa: "";
}

.fa-feather {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-low, .fa-volume-down {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-to-slot, .fa-times-to-slot, .fa-vote-nay {
  --fa: "";
  --fa--fa: "";
}

.fa-box-taped, .fa-box-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-swords {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sun-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-album {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-n {
  --fa: "";
  --fa--fa: "";
}

.fa-compress {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn, .fa-wheat-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ankh {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-child {
  --fa: "";
  --fa--fa: "";
}

.fa-asterisk {
  --fa: "*";
  --fa--fa: "**";
}

.fa-key-skeleton-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-luchador-mask, .fa-luchador, .fa-mask-luchador {
  --fa: "";
  --fa--fa: "";
}

.fa-square-check, .fa-check-square {
  --fa: "";
  --fa--fa: "";
}

.fa-shredder {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open-cover, .fa-book-open-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sandwich {
  --fa: "";
  --fa--fa: "";
}

.fa-peseta-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-square-parking-slash, .fa-parking-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-train-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-heading, .fa-header {
  --fa: "";
  --fa--fa: "";
}

.fa-ghost {
  --fa: "";
  --fa--fa: "";
}

.fa-face-anguished {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-sticks {
  --fa: "";
  --fa--fa: "";
}

.fa-abacus {
  --fa: "";
  --fa--fa: "";
}

.fa-film-simple, .fa-film-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-list, .fa-list-squares {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-palm {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone-flip, .fa-phone-square-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-user-beard-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-gamepad {
  --fa: "";
  --fa--fa: "";
}

.fa-border-center-v {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dot, .fa-dot-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-dizzy, .fa-dizzy {
  --fa: "";
  --fa--fa: "";
}

.fa-egg {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-line, .fa-arrow-alt-to-top {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-fitness {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-nine-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-campground {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-jug {
  --fa: "";
  --fa--fa: "";
}

.fa-futbol, .fa-futbol-ball, .fa-soccer-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-snow-blowing {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush, .fa-paint-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-line, .fa-arrow-from-top {
  --fa: "";
  --fa--fa: "";
}

.fa-gas-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-slash, .fa-signal-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-monkey {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-pro, .fa-pro {
  --fa: "";
  --fa--fa: "";
}

.fa-house-night {
  --fa: "";
  --fa--fa: "";
}

.fa-hot-tub-person, .fa-hot-tub {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-blanket {
  --fa: "";
  --fa--fa: "";
}

.fa-map-location, .fa-map-marked {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flood-water {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-question-check {
  --fa: "";
  --fa--fa: "";
}

.fa-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-backpack {
  --fa: "";
  --fa--fa: "";
}

.fa-square-small {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-arrow-up, .fa-folder-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-crosshairs-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-sack-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-to-square, .fa-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-square-sliders, .fa-sliders-h-square {
  --fa: "";
  --fa--fa: "";
}

.fa-car-side {
  --fa: "";
  --fa--fa: "";
}

.fa-message-middle-top, .fa-comment-middle-top-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-on {
  --fa: "";
  --fa--fa: "";
}

.fa-knife, .fa-utensil-knife {
  --fa: "";
  --fa--fa: "";
}

.fa-share-nodes, .fa-share-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-w {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-calendar, .fa-calendar-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-half, .fa-hourglass-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-microscope {
  --fa: "";
  --fa--fa: "";
}

.fa-sunset {
  --fa: "";
  --fa--fa: "";
}

.fa-sink {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-container-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping, .fa-shopping-bag {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-z-a, .fa-sort-alpha-desc, .fa-sort-alpha-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mitten {
  --fa: "";
  --fa--fa: "";
}

.fa-reply-clock, .fa-reply-time {
  --fa: "";
  --fa--fa: "";
}

.fa-person-rays {
  --fa: "";
  --fa--fa: "";
}

.fa-right, .fa-arrow-alt-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-f {
  --fa: "";
  --fa--fa: "";
}

.fa-users {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pleading {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-vial {
  --fa: "";
  --fa--fa: "";
}

.fa-police-box {
  --fa: "";
  --fa--fa: "";
}

.fa-cucumber {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-brain {
  --fa: "";
  --fa--fa: "";
}

.fa-hand, .fa-hand-paper {
  --fa: "";
  --fa--fa: "";
}

.fa-person-biking-mountain, .fa-biking-mountain {
  --fa: "";
  --fa--fa: "";
}

.fa-utensils-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-print-magnifying-glass, .fa-print-search {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left-down {
  --fa: "";
  --fa--fa: "";
}

.fa-om {
  --fa: "";
  --fa--fa: "";
}

.fa-pi {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-round-potion, .fa-flask-potion {
  --fa: "";
  --fa--fa: "";
}

.fa-face-shush {
  --fa: "";
  --fa--fa: "";
}

.fa-worm {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-plug {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-square-i {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-up {
  --fa: "";
  --fa--fa: "";
}

.fa-face-saluting {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-low, .fa-tachometer-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-face-persevering {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-camera, .fa-camera-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-spock {
  --fa: "";
  --fa--fa: "";
}

.fa-spider-web {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-microphone, .fa-microphone-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-book-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-popsicle {
  --fa: "";
  --fa--fa: "";
}

.fa-command {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds {
  --fa: "";
  --fa--fa: "";
}

.fa-stopwatch {
  --fa: "";
  --fa--fa: "";
}

.fa-saxophone {
  --fa: "";
  --fa--fa: "";
}

.fa-square-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-field-hockey-stick-ball, .fa-field-hockey {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-square-triangle, .fa-sort-shapes-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-scream {
  --fa: "";
  --fa--fa: "";
}

.fa-square-m {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-web, .fa-webcam {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-cfl {
  --fa: "";
  --fa--fa: "";
}

.fa-window-frame-open {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss, .fa-kiss {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-period {
  --fa: ".";
  --fa--fa: "..";
}

.fa-face-grin-tongue, .fa-grin-tongue {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-thought-bubble {
  --fa: "";
  --fa--fa: "";
}

.fa-skeleton-ribs {
  --fa: "";
  --fa--fa: "";
}

.fa-raygun {
  --fa: "";
  --fa--fa: "";
}

.fa-flute {
  --fa: "";
  --fa--fa: "";
}

.fa-acorn {
  --fa: "";
  --fa--fa: "";
}

.fa-video-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-grate-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-seal-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-bishop {
  --fa: "";
  --fa--fa: "";
}

.fa-message-sms {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-beans {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-witch {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-wink, .fa-grin-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-three-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-deaf, .fa-deaf, .fa-deafness, .fa-hard-of-hearing {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-eclipse {
  --fa: "";
  --fa--fa: "";
}

.fa-face-relieved {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-five {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-minus, .fa-minus-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-square-rss, .fa-rss-square {
  --fa: "";
  --fa--fa: "";
}

.fa-face-zany {
  --fa: "";
  --fa--fa: "";
}

.fa-tricycle {
  --fa: "";
  --fa--fa: "";
}

.fa-land-mine-on {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-i-cursor {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed-up-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-salt-shaker {
  --fa: "";
  --fa--fa: "";
}

.fa-stamp {
  --fa: "";
  --fa--fa: "";
}

.fa-file-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-square {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-under-slash, .fa-toilet-paper-reverse-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-stairs {
  --fa: "";
  --fa--fa: "";
}

.fa-drone-front, .fa-drone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-high {
  --fa: "";
  --fa--fa: "";
}

.fa-user-helmet-safety, .fa-user-construction, .fa-user-hard-hat {
  --fa: "";
  --fa--fa: "";
}

.fa-i {
  --fa: "I";
  --fa--fa: "II";
}

.fa-hryvnia-sign, .fa-hryvnia {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "";
  --fa--fa: "";
}

.fa-pills {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-wide, .fa-grin-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tooth {
  --fa: "";
  --fa--fa: "";
}

.fa-basketball-hoop {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-v {
  --fa: "V";
  --fa--fa: "VV";
}

.fa-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-squid {
  --fa: "";
  --fa--fa: "";
}

.fa-leafy-green {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-calendars {
  --fa: "";
  --fa--fa: "";
}

.fa-bangladeshi-taka-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bicycle {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-war {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-d {
  --fa: "";
  --fa--fa: "";
}

.fa-spider-black-widow {
  --fa: "";
  --fa--fa: "";
}

.fa-staff-snake, .fa-rod-asclepius, .fa-rod-snake, .fa-staff-aesculapius {
  --fa: "";
  --fa--fa: "";
}

.fa-pear {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-cough-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mov {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-apartment {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-medical, .fa-ambulance {
  --fa: "";
  --fa--fa: "";
}

.fa-pepper {
  --fa: "";
  --fa--fa: "";
}

.fa-piano {
  --fa: "";
  --fa--fa: "";
}

.fa-gun-squirt {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-snowman {
  --fa: "";
  --fa--fa: "";
}

.fa-user-alien {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-check {
  --fa: "";
  --fa--fa: "";
}

.fa-mortar-pestle {
  --fa: "";
  --fa--fa: "";
}

.fa-road-barrier {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-candlestick {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-school {
  --fa: "";
  --fa--fa: "";
}

.fa-igloo {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-round, .fa-parenthesis {
  --fa: "(";
  --fa--fa: "((";
}

.fa-joint {
  --fa: "";
  --fa--fa: "";
}

.fa-horse-saddle {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-marshmallows {
  --fa: "";
  --fa--fa: "";
}

.fa-filters {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-on {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-med {
  --fa: "";
  --fa--fa: "";
}

.fa-horse {
  --fa: "";
  --fa--fa: "";
}

.fa-q {
  --fa: "Q";
  --fa--fa: "QQ";
}

.fa-monitor-waveform, .fa-monitor-heart-rate {
  --fa: "";
  --fa--fa: "";
}

.fa-link-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-whistle {
  --fa: "";
  --fa--fa: "";
}

.fa-g {
  --fa: "G";
  --fa--fa: "GG";
}

.fa-wine-glass-crack, .fa-fragile {
  --fa: "";
  --fa--fa: "";
}

.fa-slot-machine {
  --fa: "";
  --fa--fa: "";
}

.fa-notes-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-car-wash {
  --fa: "";
  --fa--fa: "";
}

.fa-escalator {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-image {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-half, .fa-temperature-2, .fa-thermometer-2, .fa-thermometer-half {
  --fa: "";
  --fa--fa: "";
}

.fa-dong-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-donut, .fa-doughnut {
  --fa: "";
  --fa--fa: "";
}

.fa-capsules {
  --fa: "";
  --fa--fa: "";
}

.fa-poo-storm, .fa-poo-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-file-vector {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown-open, .fa-frown-open {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-j {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-up {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-big-small, .fa-sort-size-up {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode-read {
  --fa: "";
  --fa--fa: "";
}

.fa-baguette {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-soft-serve {
  --fa: "";
  --fa--fa: "";
}

.fa-face-holding-back-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up, .fa-arrow-alt-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-train-subway-tunnel, .fa-subway-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-square-exclamation, .fa-exclamation-square {
  --fa: "";
  --fa--fa: "";
}

.fa-semicolon {
  --fa: ";";
  --fa--fa: ";;";
}

.fa-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-fan-table {
  --fa: "";
  --fa--fa: "";
}

.fa-align-justify {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-low, .fa-battery-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card-front {
  --fa: "";
  --fa--fa: "";
}

.fa-brain-arrow-curved-right, .fa-mind-share {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella-beach {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-un {
  --fa: "";
  --fa--fa: "";
}

.fa-location-smile, .fa-map-marker-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-line, .fa-arrow-to-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye {
  --fa: "";
  --fa--fa: "";
}

.fa-sushi, .fa-nigiri {
  --fa: "";
  --fa--fa: "";
}

.fa-message-captions, .fa-comment-alt-captions {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-list {
  --fa: "";
  --fa--fa: "";
}

.fa-bacon {
  --fa: "";
  --fa--fa: "";
}

.fa-option {
  --fa: "";
  --fa--fa: "";
}

.fa-raccoon {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-file-cad {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-top {
  --fa: "";
  --fa--fa: "";
}

.fa-folder, .fa-folder-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-face-anxious-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-file-waveform, .fa-file-medical-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-microchip-ai {
  --fa: "";
  --fa--fa: "";
}

.fa-mug {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-up-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-radiation {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-seedling {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-crutches {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-parking, .fa-parking-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-oak {
  --fa: "";
  --fa--fa: "";
}

.fa-square-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-vial {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge, .fa-dashboard, .fa-gauge-med, .fa-tachometer-alt-average {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-magic-sparkles, .fa-magic-wand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-lambda {
  --fa: "";
  --fa--fa: "";
}

.fa-e {
  --fa: "E";
  --fa--fa: "EE";
}

.fa-pizza {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-chopsticks-noodles {
  --fa: "";
  --fa--fa: "";
}

.fa-h3 {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-clip, .fa-pen-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-percent {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-user {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor {
  --fa: "";
  --fa--fa: "";
}

.fa-comma {
  --fa: ",";
  --fa--fa: ",,";
}

.fa-school-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-under, .fa-toilet-paper-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-light-emergency {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-dumpster {
  --fa: "";
  --fa--fa: "";
}

.fa-van-shuttle, .fa-shuttle-van {
  --fa: "";
  --fa--fa: "";
}

.fa-building-user {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-left, .fa-caret-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-highlighter {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-pulse, .fa-heart-rate {
  --fa: "";
  --fa--fa: "";
}

.fa-key {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-santa {
  --fa: "";
  --fa--fa: "";
}

.fa-tamale {
  --fa: "";
  --fa--fa: "";
}

.fa-box-check {
  --fa: "";
  --fa--fa: "";
}

.fa-bullhorn {
  --fa: "";
  --fa--fa: "";
}

.fa-steak {
  --fa: "";
  --fa--fa: "";
}

.fa-location-crosshairs-slash, .fa-location-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dolly {
  --fa: "";
  --fa--fa: "";
}

.fa-globe {
  --fa: "";
  --fa--fa: "";
}

.fa-synagogue {
  --fa: "";
  --fa--fa: "";
}

.fa-file-chart-column, .fa-file-chart-line {
  --fa: "";
  --fa--fa: "";
}

.fa-person-half-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-image {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-pen, .fa-calendar-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-road-bridge {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-tear {
  --fa: "";
  --fa--fa: "";
}

.fa-message-plus, .fa-comment-alt-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-location-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-c {
  --fa: "C";
  --fa--fa: "CC";
}

.fa-tablet-button {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-fairy {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-building-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-up {
  --fa: "";
  --fa--fa: "";
}

.fa-mailbox {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-posts {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-pizza-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-area, .fa-area-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-three-quarters-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-scalpel {
  --fa: "";
  --fa--fa: "";
}

.fa-ban, .fa-cancel {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-bookmark, .fa-bookmark-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-egg-fried {
  --fa: "";
  --fa--fa: "";
}

.fa-face-weary {
  --fa: "";
  --fa--fa: "";
}

.fa-uniform-martial-arts {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-dust {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-text {
  --fa: "";
  --fa--fa: "";
}

.fa-spray-can-sparkles, .fa-air-freshener {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars, .fa-signal-alt, .fa-signal-alt-4, .fa-signal-bars-strong {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-star {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-min {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat {
  --fa: "";
  --fa--fa: "";
}

.fa-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-page-caret-down, .fa-file-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-box {
  --fa: "";
  --fa--fa: "";
}

.fa-venus-mars {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-seven-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-pointer, .fa-mouse-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-four-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-good, .fa-signal-alt-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-cactus {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-maximize, .fa-expand-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-charging-station {
  --fa: "";
  --fa--fa: "";
}

.fa-shapes, .fa-triangle-circle-square {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-tail {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-max, .fa-tachometer-fastest {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-u {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone-hangup, .fa-phone-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-peanuts {
  --fa: "";
  --fa--fa: "";
}

.fa-shuffle, .fa-random {
  --fa: "";
  --fa--fa: "";
}

.fa-person-running, .fa-running {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-lines-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-bottles-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-square {
  --fa: "";
  --fa--fa: "";
}

.fa-file-dashed-line, .fa-page-break {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-curly-right {
  --fa: "}";
  --fa--fa: "}}";
}

.fa-spider {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-three {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-bound {
  --fa: "";
  --fa--fa: "";
}

.fa-scalpel-line-dashed, .fa-scalpel-path {
  --fa: "";
  --fa--fa: "";
}

.fa-file-invoice-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-smoking {
  --fa: "";
  --fa--fa: "";
}

.fa-face-astonished {
  --fa: "";
  --fa--fa: "";
}

.fa-window {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-ear {
  --fa: "";
  --fa--fa: "";
}

.fa-file-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-venn {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-x-ray {
  --fa: "";
  --fa--fa: "";
}

.fa-goal-net {
  --fa: "";
  --fa--fa: "";
}

.fa-coffin-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-octopus {
  --fa: "";
  --fa--fa: "";
}

.fa-spell-check {
  --fa: "";
  --fa--fa: "";
}

.fa-location-xmark, .fa-map-marker-times, .fa-map-marker-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarter-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-lasso {
  --fa: "";
  --fa--fa: "";
}

.fa-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-person-to-portal, .fa-portal-enter {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-star {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-mouse, .fa-mouse {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-bracket, .fa-sign-in {
  --fa: "";
  --fa--fa: "";
}

.fa-pegasus {
  --fa: "";
  --fa--fa: "";
}

.fa-files-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-cannon {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-ski-lift, .fa-ski-lift {
  --fa: "";
  --fa--fa: "";
}

.fa-square-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-shop-slash, .fa-store-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-wind-turbine {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-sheriff {
  --fa: "";
  --fa--fa: "";
}

.fa-server {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-covid-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-intersection {
  --fa: "";
  --fa--fa: "";
}

.fa-shop-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-family {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-start, .fa-hourglass-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-buns {
  --fa: "";
  --fa--fa: "";
}

.fa-blender-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-seat-reclined {
  --fa: "";
  --fa--fa: "";
}

.fa-paper-plane-top, .fa-paper-plane-alt, .fa-send {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-up, .fa-comment-alt-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-minus, .fa-layer-group-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-e {
  --fa: "";
  --fa--fa: "";
}

.fa-building-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-max, .fa-tachometer-alt-fastest {
  --fa: "";
  --fa--fa: "";
}

.fa-person-breastfeeding {
  --fa: "";
  --fa--fa: "";
}

.fa-apostrophe {
  --fa: "'";
  --fa--fa: "''";
}

.fa-file-png {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-hydrant {
  --fa: "";
  --fa--fa: "";
}

.fa-right-to-bracket, .fa-sign-in-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-video-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-right, .fa-arrow-alt-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-venus {
  --fa: "";
  --fa--fa: "";
}

.fa-passport {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbtack-slash, .fa-thumb-tack-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-in, .fa-inbox-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-pulse, .fa-heartbeat {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-8 {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-ten-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-people-carry-box, .fa-people-carry {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-user {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-high {
  --fa: "";
  --fa--fa: "";
}

.fa-microchip {
  --fa: "";
  --fa--fa: "";
}

.fa-left-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-weight-hanging {
  --fa: "";
  --fa--fa: "";
}

.fa-xmarks-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-file-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-range {
  --fa: "";
  --fa--fa: "";
}

.fa-flower-daffodil {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-up {
  --fa: "";
  --fa--fa: "";
}

.fa-weight-scale, .fa-weight {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-star-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-books {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group, .fa-user-friends {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-a-z, .fa-sort-alpha-up {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-plus, .fa-layer-group-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-play-pause {
  --fa: "";
  --fa--fa: "";
}

.fa-block-question {
  --fa: "";
  --fa--fa: "";
}

.fa-snooze, .fa-zzz {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-image {
  --fa: "";
  --fa--fa: "";
}

.fa-tv-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-square-t {
  --fa: "";
  --fa--fa: "";
}

.fa-farm, .fa-barn-silo {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-knight {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-sort {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet-boxes, .fa-palette-boxes, .fa-pallet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-squint, .fa-laugh-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-code-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-panel-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-burrito {
  --fa: "";
  --fa--fa: "";
}

.fa-violin {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-column {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-down, .fa-chevron-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-instrument, .fa-triangle-music {
  --fa: "";
  --fa--fa: "";
}

.fa-wheelchair {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pilot-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-piano-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up, .fa-arrow-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-on {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-vertical, .fa-rectangle-portrait {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking, .fa-walking {
  --fa: "";
  --fa--fa: "";
}

.fa-l {
  --fa: "L";
  --fa--fa: "LL";
}

.fa-signal-stream {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-z {
  --fa: "";
  --fa--fa: "";
}

.fa-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-pulse, .fa-procedures {
  --fa: "";
  --fa--fa: "";
}

.fa-house-day {
  --fa: "";
  --fa--fa: "";
}

.fa-shuttle-space, .fa-space-shuttle {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-long-sleeve {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple, .fa-chart-pie-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh, .fa-laugh {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-open {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-candy {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-hot, .fa-soup {
  --fa: "";
  --fa--fa: "";
}

.fa-flatbread {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-code-fork {
  --fa: "";
  --fa--fa: "";
}

.fa-city {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-weak, .fa-signal-alt-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-lines, .fa-microphone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-twelve {
  --fa: "";
  --fa--fa: "";
}

.fa-pepper-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-citrus-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-sheep {
  --fa: "";
  --fa--fa: "";
}

.fa-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-colon-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-headset {
  --fa: "";
  --fa--fa: "";
}

.fa-badger-honey {
  --fa: "";
  --fa--fa: "";
}

.fa-h4 {
  --fa: "";
  --fa--fa: "";
}

.fa-store-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-user-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-up, .fa-mars-stroke-v {
  --fa: "";
  --fa--fa: "";
}

.fa-champagne-glasses, .fa-glass-cheers {
  --fa: "";
  --fa--fa: "";
}

.fa-taco {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-plus, .fa-plus-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-file-arrow-up, .fa-file-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi, .fa-wifi-3, .fa-wifi-strong {
  --fa: "";
  --fa--fa: "";
}

.fa-messages, .fa-comments-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bath, .fa-bathtub {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella-simple, .fa-umbrella-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-underline {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle-pill {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pen, .fa-user-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-o {
  --fa: "";
  --fa--fa: "";
}

.fa-caduceus {
  --fa: "";
  --fa--fa: "";
}

.fa-signature {
  --fa: "";
  --fa--fa: "";
}

.fa-stroopwafel {
  --fa: "";
  --fa--fa: "";
}

.fa-bold {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-building-ngo {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-engine-warning, .fa-engine-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-k {
  --fa: "";
  --fa--fa: "";
}

.fa-manat-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-pen, .fa-money-check-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-not-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-border-top-left, .fa-border-style {
  --fa: "";
  --fa--fa: "";
}

.fa-map-location-dot, .fa-map-marked-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tilde {
  --fa: "~";
  --fa--fa: "~~";
}

.fa-jedi {
  --fa: "";
  --fa--fa: "";
}

.fa-square-poll-vertical, .fa-poll {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-square-triangle, .fa-sort-shapes-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-dog-leashed {
  --fa: "";
  --fa--fa: "";
}

.fa-car-battery, .fa-battery-car {
  --fa: "";
  --fa--fa: "";
}

.fa-face-downcast-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-mailbox-flag-up {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-circle-info {
  --fa: "";
  --fa--fa: "";
}

.fa-gift {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-two {
  --fa: "";
  --fa--fa: "";
}

.fa-volume, .fa-volume-medium {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-front {
  --fa: "";
  --fa--fa: "";
}

.fa-file-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-treasure-chest {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-queen {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush-fine, .fa-paint-brush-alt, .fa-paint-brush-fine, .fa-paintbrush-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-glasses {
  --fa: "";
  --fa--fa: "";
}

.fa-hood-cloak {
  --fa: "";
  --fa--fa: "";
}

.fa-square-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bring-front {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-board {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-cheese, .fa-cheeseburger {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-line, .fa-arrow-to-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-swap-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-right-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-person-chalkboard {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-right, .fa-mars-stroke-h {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-fist, .fa-hand-rock {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-tally, .fa-tally-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-up, .fa-caret-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers-water {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-bar, .fa-bar-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-bubbles, .fa-hands-wash {
  --fa: "";
  --fa--fa: "";
}

.fa-less-than-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-train {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-low-vision, .fa-low-vision {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-go {
  --fa: "";
  --fa--fa: "";
}

.fa-face-exhaling {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-user-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-00 {
  --fa: "";
  --fa--fa: "";
}

.fa-crow {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-betamax, .fa-betamax {
  --fa: "";
  --fa--fa: "";
}

.fa-sailboat {
  --fa: "";
  --fa--fa: "";
}

.fa-window-restore {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-file-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-v {
  --fa: "";
  --fa--fa: "";
}

.fa-square-plus, .fa-plus-square {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-scoops {
  --fa: "";
  --fa--fa: "";
}

.fa-mistletoe {
  --fa: "";
  --fa--fa: "";
}

.fa-custard {
  --fa: "";
  --fa--fa: "";
}

.fa-lacrosse-stick {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-sunrise {
  --fa: "";
  --fa--fa: "";
}

.fa-subtitles {
  --fa: "";
  --fa--fa: "";
}

.fa-panel-ews {
  --fa: "";
  --fa--fa: "";
}

.fa-torii-gate {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-message-lines, .fa-comment-alt-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-frog {
  --fa: "";
  --fa--fa: "";
}

.fa-bucket {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-image {
  --fa: "";
  --fa--fa: "";
}

.fa-window-frame {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone {
  --fa: "";
  --fa--fa: "";
}

.fa-cow {
  --fa: "";
  --fa--fa: "";
}

.fa-file-zip {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-line, .fa-arrow-alt-from-top {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-xmark, .fa-shield-times {
  --fa: "";
  --fa--fa: "";
}

.fa-screwdriver {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort-down, .fa-sort-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-house-tsunami {
  --fa: "";
  --fa--fa: "";
}

.fa-square-nfi {
  --fa: "";
  --fa--fa: "";
}

.fa-forklift {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-ground-water {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-square-right {
  --fa: "]";
  --fa--fa: "]]";
}

.fa-martini-glass, .fa-glass-martini-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-left, .fa-rotate-back, .fa-rotate-backward, .fa-undo-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-table-columns, .fa-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-square-a {
  --fa: "";
  --fa--fa: "";
}

.fa-tick {
  --fa: "";
  --fa--fa: "";
}

.fa-lemon {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake {
  --fa: "";
  --fa--fa: "";
}

.fa-gem {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly, .fa-dolly-box {
  --fa: "";
  --fa--fa: "";
}

.fa-smoking {
  --fa: "";
  --fa--fa: "";
}

.fa-minimize, .fa-compress-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-refrigerator {
  --fa: "";
  --fa--fa: "";
}

.fa-monument {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-xmark, .fa-times-octagon, .fa-xmark-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-align-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-snowplow {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-right, .fa-angle-double-right {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp-couch, .fa-truck-couch {
  --fa: "";
  --fa--fa: "";
}

.fa-cannabis {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-play, .fa-play-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "";
  --fa--fa: "";
}

.fa-location-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-tablets {
  --fa: "";
  --fa--fa: "";
}

.fa-360-degrees {
  --fa: "";
  --fa--fa: "";
}

.fa-ethernet {
  --fa: "";
  --fa--fa: "";
}

.fa-euro-sign, .fa-eur, .fa-euro {
  --fa: "";
  --fa--fa: "";
}

.fa-chair {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-check, .fa-check-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dashed-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-money-simple-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-bat {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-stop, .fa-stop-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-headphones {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-rotary {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-compass-drafting, .fa-drafting-compass {
  --fa: "";
  --fa--fa: "";
}

.fa-plate-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chopsticks {
  --fa: "";
  --fa--fa: "";
}

.fa-car-wrench, .fa-car-mechanic {
  --fa: "";
  --fa--fa: "";
}

.fa-icicles {
  --fa: "";
  --fa--fa: "";
}

.fa-person-shelter {
  --fa: "";
  --fa--fa: "";
}

.fa-neuter {
  --fa: "";
  --fa--fa: "";
}

.fa-id-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-kazoo {
  --fa: "";
  --fa--fa: "";
}

.fa-marker {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-bottles {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-beam, .fa-laugh-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-large {
  --fa: "";
  --fa--fa: "";
}

.fa-helicopter-symbol {
  --fa: "";
  --fa--fa: "";
}

.fa-aperture {
  --fa: "";
  --fa--fa: "";
}

.fa-universal-access {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-complex {
  --fa: "";
  --fa--fa: "";
}

.fa-file-magnifying-glass, .fa-file-search {
  --fa: "";
  --fa--fa: "";
}

.fa-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-up, .fa-chevron-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-user-police {
  --fa: "";
  --fa--fa: "";
}

.fa-lari-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-volcano {
  --fa: "";
  --fa--fa: "";
}

.fa-teddy-bear {
  --fa: "";
  --fa--fa: "";
}

.fa-stocking {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-dashed-line-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-image-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-snorkel {
  --fa: "";
  --fa--fa: "";
}

.fa-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-sterling-sign, .fa-gbp, .fa-pound-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-viruses {
  --fa: "";
  --fa--fa: "";
}

.fa-square-person-confined {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-long, .fa-long-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-certificate {
  --fa: "";
  --fa--fa: "";
}

.fa-crystal-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-reply-all, .fa-mail-reply-all {
  --fa: "";
  --fa--fa: "";
}

.fa-suitcase {
  --fa: "";
  --fa--fa: "";
}

.fa-person-skating, .fa-skating {
  --fa: "";
  --fa--fa: "";
}

.fa-star-shooting {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-circle-dollar, .fa-funnel-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down, .fa-arrow-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-pen, .fa-comment-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-file-import, .fa-arrow-right-to-file {
  --fa: "";
  --fa--fa: "";
}

.fa-banjo {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up-right, .fa-external-link-square {
  --fa: "";
  --fa--fa: "";
}

.fa-light-emergency-on {
  --fa: "";
  --fa--fa: "";
}

.fa-kerning {
  --fa: "";
  --fa--fa: "";
}

.fa-box-open {
  --fa: "";
  --fa--fa: "";
}

.fa-square-f {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll {
  --fa: "";
  --fa--fa: "";
}

.fa-spa {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-line, .fa-arrow-from-right {
  --fa: "";
  --fa--fa: "";
}

.fa-strawberry {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-pause {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eight-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-engines, .fa-plane-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hill-avalanche {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-empty, .fa-temperature-0, .fa-thermometer-0, .fa-thermometer-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-bomb {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-low, .fa-tachometer-alt-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-registered {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-address-card, .fa-contact-card, .fa-vcard {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-fft {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-unbalanced-flip, .fa-balance-scale-right {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-subscript {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-turn-right, .fa-directions {
  --fa: "";
  --fa--fa: "";
}

.fa-integral {
  --fa: "";
  --fa--fa: "";
}

.fa-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-house-laptop, .fa-laptop-house {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tired, .fa-tired {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bills {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds-raised {
  --fa: "";
  --fa--fa: "";
}

.fa-smog {
  --fa: "";
  --fa--fa: "";
}

.fa-ufo-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-hydra {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-up, .fa-caret-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-square-a-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-crutch {
  --fa: "";
  --fa--fa: "";
}

.fa-gas-pump-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-arrow-up, .fa-cloud-upload, .fa-cloud-upload-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-palette {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed-up-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-vest {
  --fa: "";
  --fa--fa: "";
}

.fa-pig {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-full {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-envelope, .fa-envelope-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-person-digging, .fa-construction {
  --fa: "";
  --fa--fa: "";
}

.fa-ferry {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-down-to-people {
  --fa: "";
  --fa--fa: "";
}

.fa-seedling, .fa-sprout {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-seven {
  --fa: "";
  --fa--fa: "";
}

.fa-left-right, .fa-arrows-alt-h {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes-packing {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-left, .fa-arrow-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-flashlight {
  --fa: "";
  --fa--fa: "";
}

.fa-file-jpg {
  --fa: "";
  --fa--fa: "";
}

.fa-group-arrows-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-food {
  --fa: "";
  --fa--fa: "";
}

.fa-square-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-cane {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-wide-short, .fa-sort-amount-asc, .fa-sort-amount-down {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dollar, .fa-dollar-square, .fa-usd-square {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-seedling {
  --fa: "";
  --fa--fa: "";
}

.fa-message-check, .fa-comment-alt-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt, .fa-thunderstorm {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-text-slash, .fa-remove-format {
  --fa: "";
  --fa--fa: "";
}

.fa-watch {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-text {
  --fa: "";
  --fa--fa: "";
}

.fa-projector {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-wink, .fa-smile-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-tombstone-blank, .fa-tombstone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-king-piece, .fa-chess-king-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-waves-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-left, .fa-arrow-alt-left {
  --fa: "";
  --fa--fa: "";
}

.fa-file-word {
  --fa: "";
  --fa--fa: "";
}

.fa-file-powerpoint {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down, .fa-arrow-alt-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-center-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-left-right, .fa-arrows-h {
  --fa: "";
  --fa--fa: "";
}

.fa-house-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-arrow-down, .fa-cloud-download, .fa-cloud-download-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-wreath {
  --fa: "";
  --fa--fa: "";
}

.fa-children {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-chalkboard, .fa-blackboard {
  --fa: "";
  --fa--fa: "";
}

.fa-user-large-slash, .fa-user-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-strong, .fa-signal-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-lollipop, .fa-lollypop {
  --fa: "";
  --fa--fa: "";
}

.fa-list-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-cat-space {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-simple-slash, .fa-handshake-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-rabbit-running, .fa-rabbit-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-pad {
  --fa: "";
  --fa--fa: "";
}

.fa-mattress-pillow {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-alicorn {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-question {
  --fa: "";
  --fa--fa: "";
}

.fa-gingerbread-man {
  --fa: "";
  --fa--fa: "";
}

.fa-guarani-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-fries {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-tea {
  --fa: "";
  --fa--fa: "";
}

.fa-border-top {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-rotate, .fa-refresh, .fa-sync {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-book-open, .fa-book-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-extinguisher {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-arrows-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-garage-open {
  --fa: "";
  --fa--fa: "";
}

.fa-shelves-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-cruzeiro-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-apple {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-calculator {
  --fa: "";
  --fa--fa: "";
}

.fa-list-dropdown {
  --fa: "";
  --fa--fa: "";
}

.fa-cabinet-filing {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-soda {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up, .fa-arrow-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-greater-than-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet-box {
  --fa: "";
  --fa--fa: "";
}

.fa-face-confounded {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-halved, .fa-shield-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-plow {
  --fa: "";
  --fa--fa: "";
}

.fa-book-atlas, .fa-atlas {
  --fa: "";
  --fa--fa: "";
}

.fa-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle-top {
  --fa: "";
  --fa--fa: "";
}

.fa-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-group {
  --fa: "";
  --fa--fa: "";
}

.fa-restroom-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-border-outer {
  --fa: "";
  --fa--fa: "";
}

.fa-hashtag-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-two-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-archway {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-crack, .fa-house-damage {
  --fa: "";
  --fa--fa: "";
}

.fa-file-zipper, .fa-file-archive {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-perforated {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-half {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-check {
  --fa: "";
  --fa--fa: "";
}

.fa-square {
  --fa: "";
  --fa--fa: "";
}

.fa-memo {
  --fa: "";
  --fa--fa: "";
}

.fa-martini-glass-empty, .fa-glass-martini {
  --fa: "";
  --fa--fa: "";
}

.fa-couch {
  --fa: "";
  --fa--fa: "";
}

.fa-cedi-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-italic {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-citrus {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-lines-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-column-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-church {
  --fa: "";
  --fa--fa: "";
}

.fa-person-snowmobiling, .fa-snowmobile {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hushed {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-pickaxe {
  --fa: "";
  --fa--fa: "";
}

.fa-link-simple-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-democrat {
  --fa: "";
  --fa--fa: "";
}

.fa-face-confused {
  --fa: "";
  --fa--fa: "";
}

.fa-pinball {
  --fa: "";
  --fa--fa: "";
}

.fa-z {
  --fa: "Z";
  --fa--fa: "ZZ";
}

.fa-person-skiing, .fa-skiing {
  --fa: "";
  --fa--fa: "";
}

.fa-deer {
  --fa: "";
  --fa--fa: "";
}

.fa-input-pipe {
  --fa: "";
  --fa--fa: "";
}

.fa-road-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-a {
  --fa: "A";
  --fa--fa: "AA";
}

.fa-bookmark-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-arrow-down, .fa-temperature-down {
  --fa: "";
  --fa--fa: "";
}

.fa-mace {
  --fa: "";
  --fa--fa: "";
}

.fa-feather-pointed, .fa-feather-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sausage {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-p {
  --fa: "P";
  --fa--fa: "PP";
}

.fa-broom-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflake {
  --fa: "";
  --fa--fa: "";
}

.fa-stomach {
  --fa: "";
  --fa--fa: "";
}

.fa-newspaper {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-ad, .fa-ad {
  --fa: "";
  --fa--fa: "";
}

.fa-guitar-electric {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-slice-butter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-right, .fa-arrow-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group-crown, .fa-users-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-i {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-check {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-locust {
  --fa: "";
  --fa--fa: "";
}

.fa-sort, .fa-unsorted {
  --fa: "";
  --fa--fa: "";
}

.fa-list-ol, .fa-list-1-2, .fa-list-numeric {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-waterfall {
  --fa: "";
  --fa--fa: "";
}

.fa-sparkle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-party {
  --fa: "";
  --fa--fa: "";
}

.fa-kidneys {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-network {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d4 {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-dollar, .fa-money-check-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-square {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-language {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-wink-heart, .fa-kiss-wink-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-dagger {
  --fa: "";
  --fa--fa: "";
}

.fa-podium {
  --fa: "";
  --fa--fa: "";
}

.fa-diamonds-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-route-highway {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-line, .fa-arrow-alt-to-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-filter {
  --fa: "";
  --fa--fa: "";
}

.fa-square-g {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone, .fa-phone-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-question {
  --fa: "?";
  --fa--fa: "??";
}

.fa-file-signature {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-large-on {
  --fa: "";
  --fa--fa: "";
}

.fa-up-down-left-right, .fa-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-dryer-heat, .fa-dryer-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-user {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-small-big, .fa-sort-size-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-train-track {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check {
  --fa: "";
  --fa--fa: "";
}

.fa-star-half-stroke, .fa-star-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-code {
  --fa: "";
  --fa--fa: "";
}

.fa-whiskey-glass, .fa-glass-whiskey {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-clothes-hanger {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-notch, .fa-mobile-iphone {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-from-square, .fa-external-link {
  --fa: "";
  --fa--fa: "";
}

.fa-cubes-stacked {
  --fa: "";
  --fa--fa: "";
}

.fa-images-user {
  --fa: "";
  --fa--fa: "";
}

.fa-won-sign, .fa-krw, .fa-won {
  --fa: "";
  --fa--fa: "";
}

.fa-image-polaroid-user {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-covid {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ellipsis {
  --fa: "";
  --fa--fa: "";
}

.fa-pie {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-knight-piece, .fa-chess-knight-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-austral-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-f {
  --fa: "F";
  --fa--fa: "FF";
}

.fa-leaf {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-bunk {
  --fa: "";
  --fa--fa: "";
}

.fa-road {
  --fa: "";
  --fa--fa: "";
}

.fa-taxi, .fa-cab {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie, .fa-pie-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-lightning {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eight {
  --fa: "";
  --fa--fa: "";
}

.fa-sack-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xls {
  --fa: "";
  --fa--fa: "";
}

.fa-file-excel {
  --fa: "";
  --fa--fa: "";
}

.fa-file-contract {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-fins {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-q {
  --fa: "";
  --fa--fa: "";
}

.fa-building-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-beam, .fa-grin-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-object-ungroup {
  --fa: "";
  --fa--fa: "";
}

.fa-face-disguise {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-alien-8bit, .fa-alien-monster {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-poop {
  --fa: "";
  --fa--fa: "";
}

.fa-object-exclude {
  --fa: "";
  --fa--fa: "";
}

.fa-telescope {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin, .fa-map-marker {
  --fa: "";
  --fa--fa: "";
}

.fa-square-list {
  --fa: "";
  --fa--fa: "";
}

.fa-kaaba {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-safety, .fa-hard-hat, .fa-hat-hard {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-code {
  --fa: "";
  --fa--fa: "";
}

.fa-sim-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-starship {
  --fa: "";
  --fa--fa: "";
}

.fa-eject {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-right, .fa-arrow-alt-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-seal {
  --fa: "";
  --fa--fa: "";
}

.fa-user-cowboy {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-vertical-nft {
  --fa: "";
  --fa--fa: "";
}

.fa-face-rolling-eyes, .fa-meh-rolling-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-loaf {
  --fa: "";
  --fa--fa: "";
}

.fa-rings-wedding {
  --fa: "";
  --fa--fa: "";
}

.fa-object-group {
  --fa: "";
  --fa--fa: "";
}

.fa-french-fries {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line, .fa-line-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-arrow-down, .fa-calendar-download {
  --fa: "";
  --fa--fa: "";
}

.fa-send-back {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-ventilator {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets {
  --fa: "";
  --fa--fa: "";
}

.fa-signature-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-signs-post, .fa-map-signs {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-plus, .fa-plus-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-cash-register {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-question {
  --fa: "";
  --fa--fa: "";
}

.fa-melon-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-space-station-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-message-smile, .fa-comment-alt-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-straw {
  --fa: "";
  --fa--fa: "";
}

.fa-left-from-line, .fa-arrow-alt-from-right {
  --fa: "";
  --fa--fa: "";
}

.fa-h {
  --fa: "H";
  --fa--fa: "HH";
}

.fa-basket-shopping-simple, .fa-shopping-basket-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-heart, .fa-hands-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-nine {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-tarp {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sleepy {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-screwdriver-wrench, .fa-tools {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-eye {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-trophy-star, .fa-trophy-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-thermometer {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-posts-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-running {
  --fa: "";
  --fa--fa: "";
}

.fa-book-circle-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-face-nauseated {
  --fa: "";
  --fa--fa: "";
}

.fa-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-file-chart-pie {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-and-venus {
  --fa: "";
  --fa--fa: "";
}

.fa-house-user, .fa-home-user {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dumpster-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-minus, .fa-minus-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-left-to-line, .fa-arrow-alt-to-left {
  --fa: "";
  --fa--fa: "";
}

.fa-house-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-paw-simple, .fa-paw-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-round, .fa-parentheses {
  --fa: "";
  --fa--fa: "";
}

.fa-martini-glass-citrus, .fa-cocktail {
  --fa: "";
  --fa--fa: "";
}

.fa-user-shakespeare {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-face-surprise, .fa-surprise {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-water {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-pause, .fa-pause-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-folders {
  --fa: "";
  --fa--fa: "";
}

.fa-angel {
  --fa: "";
  --fa--fa: "";
}

.fa-value-absolute {
  --fa: "";
  --fa--fa: "";
}

.fa-rabbit {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-euro {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-whole, .fa-apple-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-kitchen-set {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-half {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-keyhole, .fa-lock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-r {
  --fa: "R";
  --fa--fa: "RR";
}

.fa-temperature-quarter, .fa-temperature-1, .fa-thermometer-1, .fa-thermometer-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-square-info, .fa-info-square {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-dollar, .fa-hands-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-cube {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-triangle-square, .fa-sort-shapes-down {
  --fa: "";
  --fa--fa: "";
}

.fa-bitcoin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-shutters {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-dog {
  --fa: "";
  --fa--fa: "";
}

.fa-solar-panel {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-open {
  --fa: "";
  --fa--fa: "";
}

.fa-table-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-elevator {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-transfer {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-trend-up {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flood-water-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-poll-horizontal, .fa-poll-h {
  --fa: "";
  --fa--fa: "";
}

.fa-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-left-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-sword {
  --fa: "";
  --fa--fa: "";
}

.fa-backward-fast, .fa-fast-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-astronaut {
  --fa: "";
  --fa--fa: "";
}

.fa-interrobang {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-trademark {
  --fa: "";
  --fa--fa: "";
}

.fa-basketball, .fa-basketball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-fork-knife, .fa-utensils-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-satellite-dish {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-check {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up, .fa-arrow-alt-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-slider {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-screen-button, .fa-mobile-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-one-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-out, .fa-inbox-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-high, .fa-volume-up {
  --fa: "";
  --fa--fa: "";
}

.fa-users-rays {
  --fa: "";
  --fa--fa: "";
}

.fa-wallet {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-check {
  --fa: "";
  --fa--fa: "";
}

.fa-flatbread-stuffed {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-clock, .fa-shipping-timed {
  --fa: "";
  --fa--fa: "";
}

.fa-pool-8-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-file-audio {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-hashtag {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-radar {
  --fa: "";
  --fa--fa: "";
}

.fa-staff {
  --fa: "";
  --fa--fa: "";
}

.fa-burger, .fa-hamburger {
  --fa: "";
  --fa--fa: "";
}

.fa-utility-pole {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-bugs {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-polygon {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-nested {
  --fa: "";
  --fa--fa: "";
}

.fa-rupee-sign, .fa-rupee {
  --fa: "";
  --fa--fa: "";
}

.fa-file-image {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-question, .fa-question-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-perforated {
  --fa: "";
  --fa--fa: "";
}

.fa-image-user {
  --fa: "";
  --fa--fa: "";
}

.fa-buoy {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-departure {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-book-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-border-center-h {
  --fa: "";
  --fa--fa: "";
}

.fa-can-food {
  --fa: "";
  --fa--fa: "";
}

.fa-typewriter {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-k {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-over-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-popcorn {
  --fa: "";
  --fa--fa: "";
}

.fa-house-water, .fa-house-flood {
  --fa: "";
  --fa--fa: "";
}

.fa-object-subtract {
  --fa: "";
  --fa--fa: "";
}

.fa-code-branch {
  --fa: "";
  --fa--fa: "";
}

.fa-warehouse-full, .fa-warehouse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-cowboy {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-flip, .fa-phone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-file-doc {
  --fa: "";
  --fa--fa: "";
}

.fa-square-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-front {
  --fa: "";
  --fa--fa: "";
}

.fa-cat {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-left, .fa-caret-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-files {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-face-clouds {
  --fa: "";
  --fa--fa: "";
}

.fa-user-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-field {
  --fa: "";
  --fa--fa: "";
}

.fa-route {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-question {
  --fa: "";
  --fa--fa: "";
}

.fa-panorama {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-teeth-open {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tags {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-forward-fast, .fa-fast-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-meh-blank, .fa-meh-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-user-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-square-parking, .fa-parking {
  --fa: "";
  --fa--fa: "";
}

.fa-card-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-face-zipper {
  --fa: "";
  --fa--fa: "";
}

.fa-face-raised-eyebrow {
  --fa: "";
  --fa--fa: "";
}

.fa-house-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-up, .fa-chevron-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-progress, .fa-tasks-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-faucet-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-dolphin {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-r {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed, .fa-dolly-flatbed {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-smoking, .fa-smoking-ban {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort-up, .fa-sort-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-button {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping, .fa-shopping-basket {
  --fa: "";
  --fa--fa: "";
}

.fa-tape {
  --fa: "";
  --fa--fa: "";
}

.fa-chestnut {
  --fa: "";
  --fa--fa: "";
}

.fa-bus-simple, .fa-bus-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-eye {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-cry, .fa-sad-cry {
  --fa: "";
  --fa--fa: "";
}

.fa-heat {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-airline, .fa-ticket-perforated-plane, .fa-ticket-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-boot-heeled {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-minimize, .fa-compress-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-audio-description {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-to-person {
  --fa: "";
  --fa--fa: "";
}

.fa-file-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-manhole {
  --fa: "";
  --fa--fa: "";
}

.fa-user-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-observation {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disks {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-blank-under, .fa-toilet-paper-reverse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-code {
  --fa: "";
  --fa--fa: "";
}

.fa-signal, .fa-signal-5, .fa-signal-perfect {
  --fa: "";
  --fa--fa: "";
}

.fa-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-left-from-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney, .fa-home-lg {
  --fa: "";
  --fa--fa: "";
}

.fa-window-maximize {
  --fa: "";
  --fa--fa: "";
}

.fa-dryer {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown, .fa-frown {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-bishop-piece, .fa-chess-bishop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-tank-top {
  --fa: "";
  --fa--fa: "";
}

.fa-diploma, .fa-scroll-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-screencast {
  --fa: "";
  --fa--fa: "";
}

.fa-walker {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-shop, .fa-store-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk, .fa-save {
  --fa: "";
  --fa--fa: "";
}

.fa-vihara {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-closed-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-unbalanced, .fa-balance-scale-left {
  --fa: "";
  --fa--fa: "";
}

.fa-file-user {
  --fa: "";
  --fa--fa: "";
}

.fa-user-police-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tongue-money {
  --fa: "";
  --fa--fa: "";
}

.fa-tennis-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-square-l {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-up, .fa-sort-asc {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-arrow-up, .fa-calendar-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-dots, .fa-commenting {
  --fa: "";
  --fa--fa: "";
}

.fa-plant-wilt {
  --fa: "";
  --fa--fa: "";
}

.fa-scarf {
  --fa: "";
  --fa--fa: "";
}

.fa-album-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-square-left, .fa-arrow-alt-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-squint, .fa-grin-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ellipsis-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-dollar, .fa-hand-holding-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-dividers {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-diagram {
  --fa: "";
  --fa--fa: "";
}

.fa-bacterium {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-drum-steelpan {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-scissors {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-praying, .fa-praying-hands {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pensive {
  --fa: "";
  --fa--fa: "";
}

.fa-user-music {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-right, .fa-arrow-right-rotate, .fa-arrow-rotate-forward, .fa-redo {
  --fa: "";
  --fa--fa: "";
}

.fa-messages-dollar, .fa-comments-alt-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-on {
  --fa: "";
  --fa--fa: "";
}

.fa-balloon {
  --fa: "";
  --fa--fa: "";
}

.fa-biohazard {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-queen-piece, .fa-chess-queen-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-location-crosshairs, .fa-location {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-double {
  --fa: "";
  --fa--fa: "";
}

.fa-left-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-leave, .fa-house-leave, .fa-house-person-depart {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-card-club {
  --fa: "";
  --fa--fa: "";
}

.fa-child-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-users-between-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-lungs-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-spinner-third {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tears, .fa-grin-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-mouse-scrollwheel, .fa-mouse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-xmark, .fa-calendar-times {
  --fa: "";
  --fa--fa: "";
}

.fa-child-reaching {
  --fa: "";
  --fa--fa: "";
}

.fa-table-layout {
  --fa: "";
  --fa--fa: "";
}

.fa-narwhal {
  --fa: "";
  --fa--fa: "";
}

.fa-ramp-loading {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-toothbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-border-inner {
  --fa: "";
  --fa--fa: "";
}

.fa-paw-claws {
  --fa: "";
  --fa--fa: "";
}

.fa-kiwi-fruit {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-code {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-brightness {
  --fa: "";
  --fa--fa: "";
}

.fa-books-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-house-blank, .fa-home-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-square-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-heart, .fa-heart-square {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-gear, .fa-user-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-1-9, .fa-sort-numeric-up {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-low {
  --fa: "";
  --fa--fa: "";
}

.fa-door-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-mobile, .fa-phone-laptop {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-boxes, .fa-conveyor-belt-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-twin-ion-engine-advanced, .fa-starfighter-alt-advanced {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-six {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-twin-ion-engine, .fa-starfighter-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-rocket-launch {
  --fa: "";
  --fa--fa: "";
}

.fa-mosquito-net {
  --fa: "";
  --fa--fa: "";
}

.fa-file-fragment {
  --fa: "";
  --fa--fa: "";
}

.fa-vent-damper {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-water {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-bug, .fa-debug {
  --fa: "";
  --fa--fa: "";
}

.fa-person-booth {
  --fa: "";
  --fa--fa: "";
}

.fa-text-width {
  --fa: "";
  --fa--fa: "";
}

.fa-garage-car {
  --fa: "";
  --fa--fa: "";
}

.fa-square-kanban {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-wizard {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-kanban {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-fancy {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-mouse-field {
  --fa: "";
  --fa--fa: "";
}

.fa-person-digging, .fa-digging {
  --fa: "";
  --fa--fa: "";
}

.fa-shower-down, .fa-shower-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-box-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-brightness {
  --fa: "";
  --fa--fa: "";
}

.fa-car-side-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xml {
  --fa: "";
  --fa--fa: "";
}

.fa-ornament {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-down-left, .fa-phone-arrow-down, .fa-phone-incoming {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-word {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-fingers-crossed {
  --fa: "";
  --fa--fa: "";
}

.fa-trash {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple, .fa-gauge-simple-med, .fa-tachometer-average {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-small-big, .fa-sort-size-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-book-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-melting {
  --fa: "";
  --fa--fa: "";
}

.fa-poo {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-clip-slash, .fa-pen-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-right, .fa-quote-right-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll-old {
  --fa: "";
  --fa--fa: "";
}

.fa-guitars {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hose {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-six {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt, .fa-t-shirt, .fa-tshirt {
  --fa: "";
  --fa--fa: "";
}

.fa-billboard {
  --fa: "";
  --fa--fa: "";
}

.fa-square-r {
  --fa: "";
  --fa--fa: "";
}

.fa-cubes {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-floor {
  --fa: "";
  --fa--fa: "";
}

.fa-square-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-tenge-sign, .fa-tenge {
  --fa: "";
  --fa--fa: "";
}

.fa-headphones {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding {
  --fa: "";
  --fa--fa: "";
}

.fa-campfire {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ampersand {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflakes {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-clapping {
  --fa: "";
  --fa--fa: "";
}

.fa-republican {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-maple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-straw-swoosh {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-sun, .fa-temperature-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-align-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d6 {
  --fa: "";
  --fa--fa: "";
}

.fa-restroom {
  --fa: "";
  --fa--fa: "";
}

.fa-high-definition, .fa-rectangle-hd {
  --fa: "";
  --fa--fa: "";
}

.fa-j {
  --fa: "J";
  --fa--fa: "JJ";
}

.fa-galaxy {
  --fa: "";
  --fa--fa: "";
}

.fa-users-viewfinder {
  --fa: "";
  --fa--fa: "";
}

.fa-file-video {
  --fa: "";
  --fa--fa: "";
}

.fa-cherries {
  --fa: "";
  --fa--fa: "";
}

.fa-up-right-from-square, .fa-external-link-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort, .fa-sort-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells, .fa-th {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-file-pdf {
  --fa: "";
  --fa--fa: "";
}

.fa-siren {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-image-landscape, .fa-landscape {
  --fa: "";
  --fa--fa: "";
}

.fa-tank-water {
  --fa: "";
  --fa--fa: "";
}

.fa-curling-stone, .fa-curling {
  --fa: "";
  --fa--fa: "";
}

.fa-gamepad-modern, .fa-gamepad-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-messages-question {
  --fa: "";
  --fa--fa: "";
}

.fa-book-bible, .fa-bible {
  --fa: "";
  --fa--fa: "";
}

.fa-o {
  --fa: "O";
  --fa--fa: "OO";
}

.fa-suitcase-medical, .fa-medkit {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-expand-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eleven-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-rv {
  --fa: "";
  --fa--fa: "";
}

.fa-user-secret {
  --fa: "";
  --fa--fa: "";
}

.fa-otter {
  --fa: "";
  --fa--fa: "";
}

.fa-dreidel {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress, .fa-female {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-business-time, .fa-briefcase-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-flower-tulip {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pants-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-drizzle {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-large, .fa-th-large {
  --fa: "";
  --fa--fa: "";
}

.fa-book-tanakh, .fa-tanakh {
  --fa: "";
  --fa--fa: "";
}

.fa-solar-system {
  --fa: "";
  --fa--fa: "";
}

.fa-seal-question {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-volume, .fa-volume-control-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-disc-drive {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-cowboy-side {
  --fa: "";
  --fa--fa: "";
}

.fa-table-rows, .fa-rows {
  --fa: "";
  --fa--fa: "";
}

.fa-location-exclamation, .fa-map-marker-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-face-fearful {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-user {
  --fa: "";
  --fa--fa: "";
}

.fa-bus-school {
  --fa: "";
  --fa--fa: "";
}

.fa-film-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-sparkles, .fa-book-spells {
  --fa: "";
  --fa--fa: "";
}

.fa-washing-machine, .fa-washer {
  --fa: "";
  --fa--fa: "";
}

.fa-child {
  --fa: "";
  --fa--fa: "";
}

.fa-lira-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-user-visor {
  --fa: "";
  --fa--fa: "";
}

.fa-file-plus-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-clock-flip, .fa-chess-clock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-satellite {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-steering-wheel {
  --fa: "";
  --fa--fa: "";
}

.fa-tag {
  --fa: "";
  --fa--fa: "";
}

.fa-stretcher {
  --fa: "";
  --fa--fa: "";
}

.fa-book-section, .fa-book-law {
  --fa: "";
  --fa--fa: "";
}

.fa-inboxes {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-bean {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-yen {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-curly {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-stroke-vertical, .fa-ellipsis-v-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-comment {
  --fa: "";
  --fa--fa: "";
}

.fa-square-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-cake-candles, .fa-birthday-cake, .fa-cake {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ladder {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tissue {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-up, .fa-angle-double-up {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-paperclip {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-city {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-a {
  --fa: "";
  --fa--fa: "";
}

.fa-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-lungs {
  --fa: "";
  --fa--fa: "";
}

.fa-person-pinball {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-9-1, .fa-sort-numeric-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-core {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-y {
  --fa: "";
  --fa--fa: "";
}

.fa-h6 {
  --fa: "";
  --fa--fa: "";
}

.fa-litecoin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-small {
  --fa: "";
  --fa--fa: "";
}

.fa-border-none {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-wifi-circle-wifi, .fa-circle-wifi-group {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-parachute-box {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-message-medical, .fa-comment-alt-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-rugby-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-music {
  --fa: "";
  --fa--fa: "";
}

.fa-indent {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-deciduous, .fa-tree-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle-piece-simple, .fa-puzzle-piece-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-field-un {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-trash {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass, .fa-hourglass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xmark, .fa-file-times {
  --fa: "";
  --fa--fa: "";
}

.fa-house-heart, .fa-home-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor, .fa-user-md {
  --fa: "";
  --fa--fa: "";
}

.fa-slash-back {
  --fa: "\\";
  --fa--fa: "\\\\";
}

.fa-circle-info, .fa-info-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-fishing-rod {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-crash {
  --fa: "";
  --fa--fa: "";
}

.fa-message-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-meatball {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-polaroid {
  --fa: "";
  --fa--fa: "";
}

.fa-camera, .fa-camera-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-meteor {
  --fa: "";
  --fa--fa: "";
}

.fa-car-on {
  --fa: "";
  --fa--fa: "";
}

.fa-sleigh {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-1-9, .fa-sort-numeric-asc, .fa-sort-numeric-down {
  --fa: "";
  --fa--fa: "";
}

.fa-buoy-mooring {
  --fa: "";
  --fa--fa: "";
}

.fa-square-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-droplet, .fa-hand-holding-water {
  --fa: "";
  --fa--fa: "";
}

.fa-file-eps {
  --fa: "";
  --fa--fa: "";
}

.fa-tricycle-adult {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-water {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp-half-stroke, .fa-star-sharp-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-prop {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-check {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-desk {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-clock, .fa-calendar-time {
  --fa: "";
  --fa--fa: "";
}

.fa-braille {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle-medical, .fa-prescription-bottle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plate-utensils {
  --fa: "";
  --fa--fa: "";
}

.fa-family-pants {
  --fa: "";
  --fa--fa: "";
}

.fa-hose-reel {
  --fa: "";
  --fa--fa: "";
}

.fa-house-window {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark {
  --fa: "";
  --fa--fa: "";
}

.fa-truck {
  --fa: "";
  --fa--fa: "";
}

.fa-music-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-crosshairs {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-rainbow {
  --fa: "";
  --fa--fa: "";
}

.fa-person-cane {
  --fa: "";
  --fa--fa: "";
}

.fa-alien {
  --fa: "";
  --fa--fa: "";
}

.fa-tent {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-vest-patches {
  --fa: "";
  --fa--fa: "";
}

.fa-people-dress-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-check-double {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-a-z, .fa-sort-alpha-asc, .fa-sort-alpha-down {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-ball-pin {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-school-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-large {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-viewfinder, .fa-screenshot {
  --fa: "";
  --fa--fa: "";
}

.fa-message-music, .fa-comment-alt-music {
  --fa: "";
  --fa--fa: "";
}

.fa-car-building {
  --fa: "";
  --fa--fa: "";
}

.fa-border-bottom-right, .fa-border-style-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-cookie {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-left, .fa-arrow-left-rotate, .fa-arrow-rotate-back, .fa-arrow-rotate-backward, .fa-undo {
  --fa: "";
  --fa--fa: "";
}

.fa-tv-music {
  --fa: "";
  --fa--fa: "";
}

.fa-hard-drive, .fa-hdd {
  --fa: "";
  --fa--fa: "";
}

.fa-reel {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-squint-tears, .fa-grin-squint-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-dumbbell {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-list, .fa-list-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tarp-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-cone {
  --fa: "";
  --fa--fa: "";
}

.fa-grate {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-person-skiing-nordic, .fa-skiing-nordic {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-person-from-portal, .fa-portal-exit {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-arrival {
  --fa: "";
  --fa--fa: "";
}

.fa-cowbell-circle-plus, .fa-cowbell-more {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-left, .fa-arrow-alt-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-distribute-spacing-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-fair, .fa-signal-alt-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-sportsball {
  --fa: "";
  --fa--fa: "";
}

.fa-game-console-handheld-crank {
  --fa: "";
  --fa--fa: "";
}

.fa-train-subway, .fa-subway {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-gantt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-upside-down {
  --fa: "";
  --fa--fa: "";
}

.fa-ball-pile {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bills-simple, .fa-money-bills-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-list-timeline {
  --fa: "";
  --fa--fa: "";
}

.fa-indian-rupee-sign, .fa-indian-rupee, .fa-inr {
  --fa: "";
  --fa--fa: "";
}

.fa-crop-simple, .fa-crop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-1, .fa-money-bill-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-left-long, .fa-long-arrow-alt-left {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-down {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt-moon, .fa-thunderstorm-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left-up {
  --fa: "";
  --fa--fa: "";
}

.fa-dna {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-round-right {
  --fa: ")";
  --fa--fa: "))";
}

.fa-circle-sterling {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-minus, .fa-subtract {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame, .fa-flame {
  --fa: "";
  --fa--fa: "";
}

.fa-right-to-line, .fa-arrow-alt-to-right {
  --fa: "";
  --fa--fa: "";
}

.fa-gif {
  --fa: "";
  --fa--fa: "";
}

.fa-chess {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-long, .fa-long-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-font-case {
  --fa: "";
  --fa--fa: "";
}

.fa-street-view {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-franc-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-round-poison, .fa-flask-poison {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-off {
  --fa: "";
  --fa--fa: "";
}

.fa-book-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-user, .fa-user-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-asl-interpreting, .fa-american-sign-language-interpreting, .fa-asl-interpreting, .fa-hands-american-sign-language-interpreting {
  --fa: "";
  --fa--fa: "";
}

.fa-presentation-screen, .fa-presentation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-halo {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-return, .fa-house-person-arrive, .fa-house-return {
  --fa: "";
  --fa--fa: "";
}

.fa-message-xmark, .fa-comment-alt-times, .fa-message-times {
  --fa: "";
  --fa--fa: "";
}

.fa-file-certificate, .fa-file-award {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-security, .fa-camera-home {
  --fa: "";
  --fa--fa: "";
}

.fa-gear, .fa-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-slash, .fa-tint-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-book-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-mosque {
  --fa: "";
  --fa--fa: "";
}

.fa-duck {
  --fa: "";
  --fa--fa: "";
}

.fa-mosquito {
  --fa: "";
  --fa--fa: "";
}

.fa-star-of-david {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-swallowtail, .fa-flag-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-car-garage {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-shopping, .fa-shopping-cart {
  --fa: "";
  --fa--fa: "";
}

.fa-book-font {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-vials {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper-full {
  --fa: "";
  --fa--fa: "";
}

.fa-distribute-spacing-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-rugged {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-snow, .fa-temperature-frigid {
  --fa: "";
  --fa--fa: "";
}

.fa-moped {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-plus, .fa-smile-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-radio-tuner, .fa-radio-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-swear {
  --fa: "";
  --fa--fa: "";
}

.fa-water-arrow-down, .fa-water-lower {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-touchscreen {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-person-ski-jumping, .fa-ski-jump {
  --fa: "";
  --fa--fa: "";
}

.fa-place-of-worship {
  --fa: "";
  --fa--fa: "";
}

.fa-water-arrow-up, .fa-water-rise {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform-lines, .fa-waveform-path {
  --fa: "";
  --fa--fa: "";
}

.fa-split {
  --fa: "";
  --fa--fa: "";
}

.fa-film-canister, .fa-film-cannister {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-xmark, .fa-folder-times {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-blank, .fa-toilet-paper-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-screen, .fa-tablet-android-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-vertical-nft-slanted {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-music {
  --fa: "";
  --fa--fa: "";
}

.fa-display-medical, .fa-desktop-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-share-all {
  --fa: "";
  --fa--fa: "";
}

.fa-peapod {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-axe {
  --fa: "";
  --fa--fa: "";
}

.fa-square-d {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-signal-out {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-up, .fa-level-up {
  --fa: "";
  --fa--fa: "";
}

.fa-u {
  --fa: "U";
  --fa--fa: "UU";
}

.fa-arrow-up-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-square-root-variable, .fa-square-root-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch-on {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-arrow-up, .fa-sort-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-raindrops {
  --fa: "";
  --fa--fa: "";
}

.fa-dash, .fa-minus-large {
  --fa: "";
  --fa--fa: "";
}

.fa-clock, .fa-clock-four {
  --fa: "";
  --fa--fa: "";
}

.fa-input-numeric {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-tow {
  --fa: "";
  --fa--fa: "";
}

.fa-backward-step, .fa-step-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-maximize, .fa-expand-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-faucet {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sleet {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-street {
  --fa: "";
  --fa--fa: "";
}

.fa-list-radio {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-nib-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-baseball-bat-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-overline {
  --fa: "";
  --fa--fa: "";
}

.fa-s {
  --fa: "S";
  --fa--fa: "SS";
}

.fa-timeline {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-usb-drive {
  --fa: "";
  --fa--fa: "";
}

.fa-ballot {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-location-dot-slash, .fa-map-marker-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-medical, .fa-clinic-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-boxing-glove, .fa-glove-boxing {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-three-quarters, .fa-temperature-3, .fa-thermometer-3, .fa-thermometer-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-school {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-screen, .fa-mobile-android-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-up {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-location-arrow, .fa-location-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-head-bandage {
  --fa: "";
  --fa--fa: "";
}

.fa-sushi-roll, .fa-maki-roll, .fa-makizushi {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bump {
  --fa: "";
  --fa--fa: "";
}

.fa-piggy-bank {
  --fa: "";
  --fa--fa: "";
}

.fa-racquet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-mirrors {
  --fa: "";
  --fa--fa: "";
}

.fa-industry-windows, .fa-industry-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-auto {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-half, .fa-battery-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-flux-capacitor {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain-city {
  --fa: "";
  --fa--fa: "";
}

.fa-coins {
  --fa: "";
  --fa--fa: "";
}

.fa-honey-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-olive {
  --fa: "";
  --fa--fa: "";
}

.fa-khanda {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-list {
  --fa: "";
  --fa--fa: "";
}

.fa-outlet {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders, .fa-sliders-h {
  --fa: "";
  --fa--fa: "";
}

.fa-cauldron {
  --fa: "";
  --fa--fa: "";
}

.fa-people {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-network-wired {
  --fa: "";
  --fa--fa: "";
}

.fa-croissant {
  --fa: "";
  --fa--fa: "";
}

.fa-map-pin {
  --fa: "";
  --fa--fa: "";
}

.fa-hamsa {
  --fa: "";
  --fa--fa: "";
}

.fa-cent-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-swords-laser {
  --fa: "";
  --fa--fa: "";
}

.fa-flask {
  --fa: "";
  --fa--fa: "";
}

.fa-person-pregnant {
  --fa: "";
  --fa--fa: "";
}

.fa-square-u {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-router {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-vertical, .fa-ellipsis-v {
  --fa: "";
  --fa--fa: "";
}

.fa-sword-laser-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket {
  --fa: "";
  --fa--fa: "";
}

.fa-power-off {
  --fa: "";
  --fa--fa: "";
}

.fa-coin {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-right-long, .fa-long-arrow-alt-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-b {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-collar {
  --fa: "";
  --fa--fa: "";
}

.fa-lights-holiday {
  --fa: "";
  --fa--fa: "";
}

.fa-citrus {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-usa {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-file {
  --fa: "";
  --fa--fa: "";
}

.fa-tty, .fa-teletype {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-tree-map {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-next {
  --fa: "";
  --fa--fa: "";
}

.fa-person-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-five-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-valve {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-message {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-face-spiral-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-compress-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone-hangup, .fa-phone-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-complex-code {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-badminton {
  --fa: "";
  --fa--fa: "";
}

.fa-closed-captioning {
  --fa: "";
  --fa--fa: "";
}

.fa-person-hiking, .fa-hiking {
  --fa: "";
  --fa--fa: "";
}

.fa-right-from-line, .fa-arrow-alt-from-left {
  --fa: "";
  --fa--fa: "";
}

.fa-venus-double {
  --fa: "";
  --fa--fa: "";
}

.fa-images {
  --fa: "";
  --fa--fa: "";
}

.fa-calculator {
  --fa: "";
  --fa--fa: "";
}

.fa-shuttlecock {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-evil {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pulling {
  --fa: "";
  --fa--fa: "";
}

.fa-n {
  --fa: "N";
  --fa--fa: "NN";
}

.fa-swap {
  --fa: "";
  --fa--fa: "";
}

.fa-garage {
  --fa: "";
  --fa--fa: "";
}

.fa-cable-car, .fa-tram {
  --fa: "";
  --fa--fa: "";
}

.fa-shovel-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-face-lying {
  --fa: "";
  --fa--fa: "";
}

.fa-sprinkler {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-person-sledding, .fa-sledding {
  --fa: "";
  --fa--fa: "";
}

.fa-game-console-handheld {
  --fa: "";
  --fa--fa: "";
}

.fa-ship {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-six-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-tugrik-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-download {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-shelves, .fa-inventory {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin, .fa-grin {
  --fa: "";
  --fa--fa: "";
}

.fa-delete-left, .fa-backspace {
  --fa: "";
  --fa--fa: "";
}

.fa-oven {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper, .fa-eye-dropper-empty, .fa-eyedropper {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-captions {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-question {
  --fa: "";
  --fa--fa: "";
}

.fa-scribble {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-loader {
  --fa: "";
  --fa--fa: "";
}

.fa-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pilot {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile, .fa-mobile-android, .fa-mobile-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-code-pull-request-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-face-meh, .fa-meh {
  --fa: "";
  --fa--fa: "";
}

.fa-align-center {
  --fa: "";
  --fa--fa: "";
}

.fa-book-skull, .fa-book-dead {
  --fa: "";
  --fa--fa: "";
}

.fa-id-card, .fa-drivers-license {
  --fa: "";
  --fa--fa: "";
}

.fa-face-dotted {
  --fa: "";
  --fa--fa: "";
}

.fa-face-worried {
  --fa: "";
  --fa--fa: "";
}

.fa-outdent, .fa-dedent {
  --fa: "";
  --fa--fa: "";
}

.fa-court-sport {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-house, .fa-home, .fa-home-alt, .fa-home-lg-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-car-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-week {
  --fa: "";
  --fa--fa: "";
}

.fa-flying-disc {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-b {
  --fa: "B";
  --fa--fa: "BB";
}

.fa-seat-airline {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-over-sun, .fa-eclipse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe {
  --fa: "|";
  --fa--fa: "||";
}

.fa-file-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-potato {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-one {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-a {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-battle {
  --fa: "";
  --fa--fa: "";
}

.fa-butter {
  --fa: "";
  --fa--fa: "";
}

.fa-blanket-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-kiwi-bird {
  --fa: "";
  --fa--fa: "";
}

.fa-castle {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-club {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-arrow-left, .fa-exchange {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-right, .fa-redo-alt, .fa-rotate-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-utensils, .fa-cutlery {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-wide-short, .fa-sort-amount-up {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-balloons {
  --fa: "";
  --fa--fa: "";
}

.fa-mill-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-rice {
  --fa: "";
  --fa--fa: "";
}

.fa-timeline-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-skull {
  --fa: "";
  --fa--fa: "";
}

.fa-game-board-simple, .fa-game-board-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-video, .fa-video-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter-bubble {
  --fa: "";
  --fa--fa: "";
}

.fa-house-turret {
  --fa: "";
  --fa--fa: "";
}

.fa-banana {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-skull {
  --fa: "";
  --fa--fa: "";
}

.fa-people-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-loveseat, .fa-couch-small {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-broadcast, .fa-broadcast-tower {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-pickup {
  --fa: "";
  --fa--fa: "";
}

.fa-block-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-up-long, .fa-long-arrow-alt-up {
  --fa: "";
  --fa--fa: "";
}

.fa-stop {
  --fa: "";
  --fa--fa: "";
}

.fa-code-merge {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-dollar-pen, .fa-money-check-edit-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-line, .fa-arrow-alt-from-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-hurricane {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-2-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pants {
  --fa: "";
  --fa--fa: "";
}

.fa-mound {
  --fa: "";
  --fa--fa: "";
}

.fa-windsock {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-half {
  --fa: "";
  --fa--fa: "";
}

.fa-brake-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-portable {
  --fa: "";
  --fa--fa: "";
}

.fa-compact-disc {
  --fa: "";
  --fa--fa: "";
}

.fa-file-arrow-down, .fa-file-download {
  --fa: "";
  --fa--fa: "";
}

.fa-saxophone-fire, .fa-sax-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-web-slash, .fa-webcam-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-gear, .fa-folder-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-arrow-down, .fa-sort-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-caravan {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-cat {
  --fa: "";
  --fa--fa: "";
}

.fa-message-slash, .fa-comment-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt, .fa-zap {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-check {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-water {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-well {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-column-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left-up {
  --fa: "";
  --fa--fa: "";
}

.fa-vault {
  --fa: "";
  --fa--fa: "";
}

.fa-mars {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-yen-sign, .fa-cny, .fa-jpy, .fa-rmb, .fa-yen {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-code {
  --fa: "";
  --fa--fa: "";
}

.fa-notes {
  --fa: "";
  --fa--fa: "";
}

.fa-ruble-sign, .fa-rouble, .fa-rub, .fa-ruble {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-undo, .fa-trash-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-champagne-glass, .fa-glass-champagne {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-center-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-slash, .fa-trash-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-screen-users, .fa-users-class {
  --fa: "";
  --fa--fa: "";
}

.fa-guitar {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-left, .fa-arrow-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-square-8 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-hearts {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-square, .fa-brackets {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-stick-puck {
  --fa: "";
  --fa--fa: "";
}

.fa-house-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-fair, .fa-signal-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-wink, .fa-laugh-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dollar, .fa-dollar-circle, .fa-usd-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-horse-head {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-repeat, .fa-repeat-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bore-hole {
  --fa: "";
  --fa--fa: "";
}

.fa-industry {
  --fa: "";
  --fa--fa: "";
}

.fa-image-polaroid {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left-down {
  --fa: "";
  --fa--fa: "";
}

.fa-person-running-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down, .fa-arrow-alt-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-grill {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-turn-to-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed, .fa-analytics {
  --fa: "";
  --fa--fa: "";
}

.fa-florin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-short-wide, .fa-sort-amount-desc, .fa-sort-amount-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-less-than {
  --fa: "<";
  --fa--fa: "<<";
}

.fa-display-code, .fa-desktop-code {
  --fa: "";
  --fa--fa: "";
}

.fa-face-drooling {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-temperature, .fa-oil-temp {
  --fa: "";
  --fa--fa: "";
}

.fa-square-question, .fa-question-square {
  --fa: "";
  --fa--fa: "";
}

.fa-air-conditioner {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-mountains {
  --fa: "";
  --fa--fa: "";
}

.fa-omega {
  --fa: "";
  --fa--fa: "";
}

.fa-car-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dolly-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-pan-food {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-cough {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbs-down {
  --fa: "";
  --fa--fa: "";
}

.fa-user-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-long, .fa-long-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-airline, .fa-tickets-perforated-plane, .fa-tickets-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-double-peak {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis, .fa-ellipsis-h {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-pawn {
  --fa: "";
  --fa--fa: "";
}

.fa-kit-medical, .fa-first-aid {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-2-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-bells {
  --fa: "";
  --fa--fa: "";
}

.fa-person-through-window {
  --fa: "";
  --fa--fa: "";
}

.fa-toolbox {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-dot, .fa-envelope-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-bug {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-chopsticks {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card, .fa-credit-card-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-s {
  --fa: "";
  --fa--fa: "";
}

.fa-box-ballot {
  --fa: "";
  --fa--fa: "";
}

.fa-car, .fa-automobile {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-hand {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-podium-star {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-mullet, .fa-business-front, .fa-party-back, .fa-trian-balbot {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-stand {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open-reader, .fa-book-reader {
  --fa: "";
  --fa--fa: "";
}

.fa-family-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-x {
  --fa: "";
  --fa--fa: "";
}

.fa-cabin {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-simple-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-left-right-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-left {
  --fa: "";
  --fa--fa: "";
}

.fa-message-dots, .fa-comment-alt-dots, .fa-messaging {
  --fa: "";
  --fa--fa: "";
}

.fa-file-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-beer-mug, .fa-beer-foam {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d20 {
  --fa: "";
  --fa--fa: "";
}

.fa-drone {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-arrow-up, .fa-temperature-up {
  --fa: "";
  --fa--fa: "";
}

.fa-medal {
  --fa: "";
  --fa--fa: "";
}

.fa-person-fairy {
  --fa: "";
  --fa--fa: "";
}

.fa-bed {
  --fa: "";
  --fa--fa: "";
}

.fa-book-copy {
  --fa: "";
  --fa--fa: "";
}

.fa-square-h, .fa-h-square {
  --fa: "";
  --fa--fa: "";
}

.fa-square-c {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-two {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ellipsis-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-users {
  --fa: "";
  --fa--fa: "";
}

.fa-podcast {
  --fa: "";
  --fa--fa: "";
}

.fa-bee {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-full, .fa-temperature-4, .fa-thermometer-4, .fa-thermometer-full {
  --fa: "";
  --fa--fa: "";
}

.fa-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-bar, .fa-chocolate-bar {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-large {
  --fa: "";
  --fa--fa: "";
}

.fa-pinata {
  --fa: "";
  --fa--fa: "";
}

.fa-file-ppt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-superscript {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-spoon {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-check {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-star-of-life {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-stop {
  --fa: "";
  --fa--fa: "";
}

.fa-paint-roller {
  --fa: "";
  --fa--fa: "";
}

.fa-accent-grave {
  --fa: "`";
  --fa--fa: "``";
}

.fa-handshake-angle, .fa-hands-helping {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-med-low {
  --fa: "";
  --fa--fa: "";
}

.fa-location-dot, .fa-map-marker-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-crab {
  --fa: "";
  --fa--fa: "";
}

.fa-box-open-full, .fa-box-full {
  --fa: "";
  --fa--fa: "";
}

.fa-file {
  --fa: "";
  --fa--fa: "";
}

.fa-greater-than {
  --fa: ">";
  --fa--fa: ">>";
}

.fa-quotes {
  --fa: "";
  --fa--fa: "";
}

.fa-pretzel {
  --fa: "";
  --fa--fa: "";
}

.fa-t-rex {
  --fa: "";
  --fa--fa: "";
}

.fa-person-swimming, .fa-swimmer {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-user-robot-xmarks {
  --fa: "";
  --fa--fa: "";
}

.fa-message-quote, .fa-comment-alt-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-corn {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-magnifying-glass, .fa-folder-search {
  --fa: "";
  --fa--fa: "";
}

.fa-notebook {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet, .fa-tint {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-eraser {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-image {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-americas, .fa-earth, .fa-earth-america, .fa-globe-americas {
  --fa: "";
  --fa--fa: "";
}

.fa-file-svg {
  --fa: "";
  --fa--fa: "";
}

.fa-crate-apple, .fa-apple-crate {
  --fa: "";
  --fa--fa: "";
}

.fa-person-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-game-board {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-chef {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-right {
  --fa: "";
  --fa--fa: "";
}

.fa-dove {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflake-droplets {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-empty, .fa-battery-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-socks {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sunglasses {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox {
  --fa: "";
  --fa--fa: "";
}

.fa-square-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-section {
  --fa: "";
  --fa--fa: "";
}

.fa-square-this-way-up, .fa-box-up {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-high, .fa-tachometer-alt, .fa-tachometer-alt-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ampersand {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open-text {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-desk {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital, .fa-hospital-alt, .fa-hospital-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-poll-people {
  --fa: "";
  --fa--fa: "";
}

.fa-whiskey-glass-ice, .fa-glass-whiskey-rocks {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-rook {
  --fa: "";
  --fa--fa: "";
}

.fa-user-bounty-hunter {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-staggered, .fa-reorder, .fa-stream {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-sankey {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-hail-mixed {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dharmachakra {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-left {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-can-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smiling-hands {
  --fa: "";
  --fa--fa: "";
}

.fa-broccoli {
  --fa: "";
  --fa--fa: "";
}

.fa-route-interstate {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-muffs {
  --fa: "";
  --fa--fa: "";
}

.fa-hotdog {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-with-cane, .fa-blind {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-90 {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-kite {
  --fa: "";
  --fa--fa: "";
}

.fa-drum {
  --fa: "";
  --fa--fa: "";
}

.fa-scrubber {
  --fa: "";
  --fa--fa: "";
}

.fa-ice-cream {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-bones {
  --fa: "";
  --fa--fa: "";
}

.fa-deer-rudolph {
  --fa: "";
  --fa--fa: "";
}

.fa-fax {
  --fa: "";
  --fa--fa: "";
}

.fa-paragraph {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-square-e {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-hail {
  --fa: "";
  --fa--fa: "";
}

.fa-check-to-slot, .fa-vote-yea {
  --fa: "";
  --fa--fa: "";
}

.fa-money-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-star-half {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-speaker {
  --fa: "";
  --fa--fa: "";
}

.fa-timer {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes-stacked, .fa-boxes, .fa-boxes-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-grill-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-ballot-check {
  --fa: "";
  --fa--fa: "";
}

.fa-link, .fa-chain {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-listen, .fa-assistive-listening-systems {
  --fa: "";
  --fa--fa: "";
}

.fa-file-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-city {
  --fa: "";
  --fa--fa: "";
}

.fa-play {
  --fa: "";
  --fa--fa: "";
}

.fa-font {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-togo, .fa-coffee-togo {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-lettuce {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-row-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-rupiah-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass, .fa-search {
  --fa: "";
  --fa--fa: "";
}

.fa-table-tennis-paddle-ball, .fa-ping-pong-paddle-ball, .fa-table-tennis {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dots-from-line, .fa-diagnoses {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-down, .fa-chevron-double-down {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-arrow-up, .fa-trash-restore-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-good, .fa-signal-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-location-question, .fa-map-marker-question {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-circle-xmark, .fa-floppy-disk-times, .fa-save-circle-xmark, .fa-save-times {
  --fa: "";
  --fa--fa: "";
}

.fa-naira-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-peach {
  --fa: "";
  --fa--fa: "";
}

.fa-circles-overlap-3, .fa-pronoun {
  --fa: "";
  --fa--fa: "";
}

.fa-taxi-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-curly, .fa-bracket-curly-left {
  --fa: "{";
  --fa--fa: "{{";
}

.fa-lobster {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-empty, .fa-dolly-flatbed-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-colon {
  --fa: ":";
  --fa--fa: "::";
}

.fa-cart-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-wand {
  --fa: "";
  --fa--fa: "";
}

.fa-walkie-talkie {
  --fa: "";
  --fa--fa: "";
}

.fa-file-pen, .fa-file-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-receipt {
  --fa: "";
  --fa--fa: "";
}

.fa-table-picnic {
  --fa: "";
  --fa--fa: "";
}

.fa-square-pen, .fa-pen-square, .fa-pencil-square {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-microphone-lines, .fa-microphone-circle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-display-slash, .fa-desktop-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-suitcase-rolling {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hoodie {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-diamond, .fa-hand-receiving {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-simple-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-down {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-full, .fa-battery, .fa-battery-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-book-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-hospitals {
  --fa: "";
  --fa--fa: "";
}

.fa-club {
  --fa: "";
  --fa--fa: "";
}

.fa-skull-crossbones {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-degree, .fa-dewpoint {
  --fa: "";
  --fa--fa: "";
}

.fa-code-compare {
  --fa: "";
  --fa--fa: "";
}

.fa-list-ul, .fa-list-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-magic {
  --fa: "";
  --fa--fa: "";
}

.fa-watermelon-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ellipsis {
  --fa: "";
  --fa--fa: "";
}

.fa-school-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-cell {
  --fa: "";
  --fa--fa: "";
}

.fa-sd-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-jug-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-down-long, .fa-long-arrow-alt-down {
  --fa: "";
  --fa--fa: "";
}

.fa-envelopes {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-office {
  --fa: "";
  --fa--fa: "";
}

.fa-ranking-star {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-king {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-person-harassing {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-play {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-winter {
  --fa: "";
  --fa--fa: "";
}

.fa-brazilian-real-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-dome, .fa-landmark-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bone-break {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-tv, .fa-television, .fa-tv-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-border-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-shrimp {
  --fa: "";
  --fa--fa: "";
}

.fa-list-check, .fa-tasks {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-subtask {
  --fa: "";
  --fa--fa: "";
}

.fa-jug-detergent {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-user, .fa-user-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-square-y {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-planet-ringed {
  --fa: "";
  --fa--fa: "";
}

.fa-mushroom {
  --fa: "";
  --fa--fa: "";
}

.fa-user-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-megaphone {
  --fa: "";
  --fa--fa: "";
}

.fa-wreath-laurel {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-exclamation-check {
  --fa: "";
  --fa--fa: "";
}

.fa-wind {
  --fa: "";
  --fa--fa: "";
}

.fa-box-dollar, .fa-box-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-car-burst, .fa-car-crash {
  --fa: "";
  --fa--fa: "";
}

.fa-y {
  --fa: "Y";
  --fa--fa: "YY";
}

.fa-user-headset {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-retweet, .fa-retweet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-snowboarding, .fa-snowboarding {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-right, .fa-chevron-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-lacrosse-stick-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-fast, .fa-shipping-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-user-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-star, .fa-star-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-fish {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-fog, .fa-fog {
  --fa: "";
  --fa--fa: "";
}

.fa-waffle {
  --fa: "";
  --fa--fa: "";
}

.fa-music-note, .fa-music-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-shopping-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-object-union {
  --fa: "";
  --fa--fa: "";
}

.fa-user-graduate {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-half-stroke, .fa-adjust {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down, .fa-arrow-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-clapperboard {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-left, .fa-chevron-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-intercom {
  --fa: "";
  --fa--fa: "";
}

.fa-link-horizontal, .fa-chain-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-mango {
  --fa: "";
  --fa--fa: "";
}

.fa-music-note-slash, .fa-music-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-radiation, .fa-radiation-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tongue-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-stand {
  --fa: "";
  --fa--fa: "";
}

.fa-baseball, .fa-baseball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-p {
  --fa: "";
  --fa--fa: "";
}

.fa-award-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-jet-fighter-up {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-project, .fa-project-diagram {
  --fa: "";
  --fa--fa: "";
}

.fa-pedestal {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pyramid {
  --fa: "";
  --fa--fa: "";
}

.fa-sidebar {
  --fa: "";
  --fa--fa: "";
}

.fa-snowman-head, .fa-frosty-head {
  --fa: "";
  --fa--fa: "";
}

.fa-copy {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-xmark, .fa-volume-mute, .fa-volume-times {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-filter {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush-pencil {
  --fa: "";
  --fa--fa: "";
}

.fa-party-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-jack-o-lantern {
  --fa: "";
  --fa--fa: "";
}

.fa-grip, .fa-grip-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-share-from-square, .fa-share-square {
  --fa: "";
  --fa--fa: "";
}

.fa-keynote {
  --fa: "";
  --fa--fa: "";
}

.fa-child-combatant, .fa-child-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-gun {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone, .fa-phone-square {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-beach {
  --fa: "";
  --fa--fa: "";
}

.fa-plus, .fa-add {
  --fa: "+";
  --fa--fa: "++";
}

.fa-expand {
  --fa: "";
  --fa--fa: "";
}

.fa-computer {
  --fa: "";
  --fa--fa: "";
}

.fa-fort {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-check {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark, .fa-close, .fa-multiply, .fa-remove, .fa-times {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smirking {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-down-left-right, .fa-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-chalkboard-user, .fa-chalkboard-teacher {
  --fa: "";
  --fa--fa: "";
}

.fa-rhombus {
  --fa: "";
  --fa--fa: "";
}

.fa-claw-marks {
  --fa: "";
  --fa--fa: "";
}

.fa-peso-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-tongue {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-building-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone-flip, .fa-phone-circle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-users-line {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-left, .fa-quote-left-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tractor {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-key-skeleton {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-arrow-up, .fa-trash-restore {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-up-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-lines-leaning {
  --fa: "";
  --fa--fa: "";
}

.fa-square-q {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-combined {
  --fa: "";
  --fa--fa: "";
}

.fa-symbols, .fa-icons-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-copyright {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-highlighter-line {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-square, .fa-bracket, .fa-bracket-left {
  --fa: "[";
  --fa--fa: "[[";
}

.fa-island-tropical, .fa-island-tree-palm {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-line, .fa-arrow-from-left {
  --fa: "";
  --fa--fa: "";
}

.fa-h2 {
  --fa: "";
  --fa--fa: "";
}

.fa-equals {
  --fa: "=";
  --fa--fa: "==";
}

.fa-cake-slice, .fa-shortcake {
  --fa: "";
  --fa--fa: "";
}

.fa-building-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-peanut {
  --fa: "";
  --fa--fa: "";
}

.fa-wrench-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-blender {
  --fa: "";
  --fa--fa: "";
}

.fa-teeth {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-shekel-sign, .fa-ils, .fa-shekel, .fa-sheqel, .fa-sheqel-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-cars {
  --fa: "";
  --fa--fa: "";
}

.fa-axe-battle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-map {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-info {
  --fa: "";
  --fa--fa: "";
}

.fa-face-disappointed {
  --fa: "";
  --fa--fa: "";
}

.fa-lasso-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eleven {
  --fa: "";
  --fa--fa: "";
}

.fa-rocket {
  --fa: "";
  --fa--fa: "";
}

.fa-siren-on {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-ten {
  --fa: "";
  --fa--fa: "";
}

.fa-candle-holder {
  --fa: "";
  --fa--fa: "";
}

.fa-video-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-photo-film, .fa-photo-video {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-circle-arrow-right, .fa-save-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-nodes-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-planet-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-face-eyes-xmarks {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-gf {
  --fa: "";
  --fa--fa: "";
}

.fa-display-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-store {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-trend-up {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-olive-branch {
  --fa: "";
  --fa--fa: "";
}

.fa-angle {
  --fa: "";
  --fa--fa: "";
}

.fa-vacuum-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-hanging, .fa-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-square-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-check {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-stream-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bezier-curve {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper-half {
  --fa: "";
  --fa--fa: "";
}

.fa-store-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt-sun, .fa-thunderstorm-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet, .fa-tablet-android {
  --fa: "";
  --fa--fa: "";
}

.fa-school-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-message-code {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-half, .fa-glass-half-empty, .fa-glass-half-full {
  --fa: "";
  --fa--fa: "";
}

.fa-fill {
  --fa: "";
  --fa--fa: "";
}

.fa-message-minus, .fa-comment-alt-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-dinosaur {
  --fa: "";
  --fa--fa: "";
}

.fa-drumstick-bite {
  --fa: "";
  --fa--fa: "";
}

.fa-link-horizontal-slash, .fa-chain-horizontal-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-holly-berry {
  --fa: "";
  --fa--fa: "";
}

.fa-nose {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bacteria {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-lizard {
  --fa: "";
  --fa--fa: "";
}

.fa-table-pivot {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-undo, .fa-trash-can-arrow-turn-left, .fa-trash-undo-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-notdef {
  --fa: "";
  --fa--fa: "";
}

.fa-disease {
  --fa: "";
  --fa--fa: "";
}

.fa-person-to-door {
  --fa: "";
  --fa--fa: "";
}

.fa-turntable {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-genderless {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-right {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-weak, .fa-signal-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-five {
  --fa: "";
  --fa--fa: "";
}

.fa-retweet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-rear, .fa-car-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-pump-soap {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-classic {
  --fa: "";
  --fa--fa: "";
}

.fa-frame {
  --fa: "";
  --fa--fa: "";
}

.fa-video-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-quarter, .fa-battery-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-stroke, .fa-ellipsis-h-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-radio {
  --fa: "";
  --fa--fa: "";
}

.fa-baby-carriage, .fa-carriage-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-face-expressionless {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-music {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-vr-cardboard {
  --fa: "";
  --fa--fa: "";
}

.fa-car-tilt {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-brightness-low {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-middle-finger {
  --fa: "";
  --fa--fa: "";
}

.fa-percent, .fa-percentage {
  --fa: "%";
  --fa--fa: "%%";
}

.fa-truck-moving {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-water-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt {
  --fa: "";
  --fa--fa: "";
}

.fa-location-check, .fa-map-marker-check {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-display {
  --fa: "";
  --fa--fa: "";
}

.fa-person-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile, .fa-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-hangup {
  --fa: "";
  --fa--fa: "";
}

.fa-signature-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbtack, .fa-thumb-tack {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-trophy {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-person-praying, .fa-pray {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer {
  --fa: "";
  --fa--fa: "";
}

.fa-face-vomit {
  --fa: "";
  --fa--fa: "";
}

.fa-speakers {
  --fa: "";
  --fa--fa: "";
}

.fa-tty-answer, .fa-teletype-answer {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-tea-saucer {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-lean-canvas {
  --fa: "";
  --fa--fa: "";
}

.fa-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-dial, .fa-dial-med-high {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-peace {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-trash, .fa-trash-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate, .fa-sync-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-spinner {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-control {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-triangle-square, .fa-sort-shapes-up {
  --fa: "";
  --fa--fa: "";
}

.fa-whale {
  --fa: "";
  --fa--fa: "";
}

.fa-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-peace {
  --fa: "";
  --fa--fa: "";
}

.fa-party-horn {
  --fa: "";
  --fa--fa: "";
}

.fa-gears, .fa-cogs {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-bright, .fa-sun-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-warehouse {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-arm {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-keyhole-open, .fa-lock-open-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-fragile, .fa-box-fragile, .fa-square-wine-glass-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-square-n {
  --fa: "";
  --fa--fa: "";
}

.fa-splotch {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-hearts, .fa-grin-hearts {
  --fa: "";
  --fa--fa: "";
}

.fa-meter {
  --fa: "";
  --fa--fa: "";
}

.fa-mandolin {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-four {
  --fa: "";
  --fa--fa: "";
}

.fa-sim-card {
  --fa: "";
  --fa--fa: "";
}

.fa-transgender, .fa-transgender-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mercury {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-knife-kitchen {
  --fa: "";
  --fa--fa: "";
}

.fa-border-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down, .fa-level-down {
  --fa: "";
  --fa--fa: "";
}

.fa-spade {
  --fa: "";
  --fa--fa: "";
}

.fa-card-spade {
  --fa: "";
  --fa--fa: "";
}

.fa-line-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-ant {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-line, .fa-arrow-to-right {
  --fa: "";
  --fa--fa: "";
}

.fa-person-falling-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-pennant, .fa-pennant {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-award {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-simple, .fa-ticket-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-building {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-left, .fa-angle-double-left {
  --fa: "";
  --fa--fa: "";
}

.fa-camcorder, .fa-video-handheld {
  --fa: "";
  --fa--fa: "";
}

.fa-pancakes {
  --fa: "";
  --fa--fa: "";
}

.fa-album-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-subtitles-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-qrcode {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d10 {
  --fa: "";
  --fa--fa: "";
}

.fa-fireplace {
  --fa: "";
  --fa--fa: "";
}

.fa-browser {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-paintbrush, .fa-pencil-paintbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-cooked {
  --fa: "";
  --fa--fa: "";
}

.fa-chair-office {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-music {
  --fa: "";
  --fa--fa: "";
}

.fa-nesting-dolls {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-rotate-left, .fa-history {
  --fa: "";
  --fa--fa: "";
}

.fa-trumpet {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-beam-sweat, .fa-grin-beam-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-missed {
  --fa: "";
  --fa--fa: "";
}

.fa-file-export, .fa-arrow-right-from-file {
  --fa: "";
  --fa--fa: "";
}

.fa-shield, .fa-shield-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-short-wide, .fa-sort-amount-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-repeat-1, .fa-repeat-1-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-gun-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-avocado {
  --fa: "";
  --fa--fa: "";
}

.fa-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-glasses-round, .fa-glasses-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-ditto {
  --fa: "\"";
  --fa--fa: "\"\"";
}

.fa-person-seat {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-ball-tee, .fa-golf-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-left, .fa-chevron-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-window {
  --fa: "";
  --fa--fa: "";
}

.fa-scythe {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-nib {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-parking, .fa-parking-circle-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-face-diagonal-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-cells {
  --fa: "";
  --fa--fa: "";
}

.fa-cricket-bat-ball, .fa-cricket {
  --fa: "";
  --fa--fa: "";
}

.fa-tents {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-magic, .fa-magic {
  --fa: "";
  --fa--fa: "";
}

.fa-dog {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-line {
  --fa: "";
  --fa--fa: "";
}

.fa-atom-simple, .fa-atom-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ampersand {
  --fa: "&";
  --fa--fa: "&&";
}

.fa-carrot {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-line, .fa-arrow-from-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-glass-empty, .fa-wine-glass-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-star {
  --fa: "";
  --fa--fa: "";
}

.fa-cheese {
  --fa: "";
  --fa--fa: "";
}

.fa-send-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-yin-yang {
  --fa: "";
  --fa--fa: "";
}

.fa-music {
  --fa: "";
  --fa--fa: "";
}

.fa-compass-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-one {
  --fa: "";
  --fa--fa: "";
}

.fa-file-music {
  --fa: "";
  --fa--fa: "";
}

.fa-code-commit {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-low {
  --fa: "";
  --fa--fa: "";
}

.fa-person-biking, .fa-biking {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-skeleton {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-g {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-broom {
  --fa: "";
  --fa--fa: "";
}

.fa-vacuum {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-card-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-cfl-on {
  --fa: "";
  --fa--fa: "";
}

.fa-melon {
  --fa: "";
  --fa--fa: "";
}

.fa-gopuram {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-oceania, .fa-globe-oceania {
  --fa: "";
  --fa--fa: "";
}

.fa-container-storage {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pouting {
  --fa: "";
  --fa--fa: "";
}

.fa-square-xmark, .fa-times-square, .fa-xmark-square {
  --fa: "";
  --fa--fa: "";
}

.fa-face-explode, .fa-exploding-head {
  --fa: "";
  --fa--fa: "";
}

.fa-hashtag {
  --fa: "#";
  --fa--fa: "##";
}

.fa-up-right-and-down-left-from-center, .fa-expand-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-can {
  --fa: "";
  --fa--fa: "";
}

.fa-t {
  --fa: "T";
  --fa--fa: "TT";
}

.fa-transformer-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-hippo {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-column {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-vhs, .fa-vhs {
  --fa: "";
  --fa--fa: "";
}

.fa-infinity {
  --fa: "";
  --fa--fa: "";
}

.fa-vial-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-chimney {
  --fa: "";
  --fa--fa: "";
}

.fa-object-intersect {
  --fa: "";
  --fa--fa: "";
}

.fa-person-arrow-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-voicemail {
  --fa: "";
  --fa--fa: "";
}

.fa-block-brick, .fa-wall-brick {
  --fa: "";
  --fa--fa: "";
}

.fa-fan {
  --fa: "";
  --fa--fa: "";
}

.fa-bags-shopping {
  --fa: "";
  --fa--fa: "";
}

.fa-paragraph-left, .fa-paragraph-rtl {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-luggage {
  --fa: "";
  --fa--fa: "";
}

.fa-caravan-simple, .fa-caravan-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-turtle {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-mechanical {
  --fa: "";
  --fa--fa: "";
}

.fa-up-down, .fa-arrows-alt-v {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-moon-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-booth-curtain {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar {
  --fa: "";
  --fa--fa: "";
}

.fa-box-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-trailer {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-message, .fa-user-md-chat {
  --fa: "";
  --fa--fa: "";
}

.fa-bahai, .fa-haykal {
  --fa: "";
  --fa--fa: "";
}

.fa-lighthouse {
  --fa: "";
  --fa--fa: "";
}

.fa-amp-guitar {
  --fa: "";
  --fa--fa: "";
}

.fa-sd-card {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-border-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-weak, .fa-wifi-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-dragon {
  --fa: "";
  --fa--fa: "";
}

.fa-shoe-prints {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-plus, .fa-plus-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tongue-wink, .fa-grin-tongue-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-link-slash, .fa-chain-broken, .fa-chain-slash, .fa-unlink {
  --fa: "";
  --fa--fa: "";
}

.fa-clone {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-arrow-loop-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-z-a, .fa-sort-alpha-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame-curved, .fa-fire-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tornado {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-delete-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-quran, .fa-quran {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor {
  --fa: "";
  --fa--fa: "";
}

.fa-border-all {
  --fa: "";
  --fa--fa: "";
}

.fa-function {
  --fa: "";
  --fa--fa: "";
}

.fa-face-angry, .fa-angry {
  --fa: "";
  --fa--fa: "";
}

.fa-people-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-cookie-bite {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-trend-down {
  --fa: "";
  --fa--fa: "";
}

.fa-rss, .fa-feed {
  --fa: "";
  --fa--fa: "";
}

.fa-face-monocle {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-polygon {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-balanced, .fa-balance-scale {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-lines, .fa-calendar-note {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-big-small, .fa-sort-size-down {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-high, .fa-tachometer, .fa-tachometer-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-do-not-enter {
  --fa: "";
  --fa--fa: "";
}

.fa-shower {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d8 {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop, .fa-desktop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-m {
  --fa: "M";
  --fa--fa: "MM";
}

.fa-spinner-scale {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-dots-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-viewfinder {
  --fa: "";
  --fa--fa: "";
}

.fa-soft-serve, .fa-creemee {
  --fa: "";
  --fa--fa: "";
}

.fa-h5 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-down {
  --fa: "";
  --fa--fa: "";
}

.fa-table-list, .fa-th-list {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-sms, .fa-sms {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle, .fa-rectangle-landscape {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-list-check {
  --fa: "";
  --fa--fa: "";
}

.fa-turkey {
  --fa: "";
  --fa--fa: "";
}

.fa-book {
  --fa: "";
  --fa--fa: "";
}

.fa-user-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-ice-skate {
  --fa: "";
  --fa--fa: "";
}

.fa-check {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-three-quarters, .fa-battery-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-tomato {
  --fa: "";
  --fa--fa: "";
}

.fa-sword-laser {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-buildings {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-boxes, .fa-dolly-flatbed-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-successor {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-w {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-split-up-and-left {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp {
  --fa: "";
  --fa--fa: "";
}

.fa-airplay {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-fist, .fa-fist-raised {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-quartered {
  --fa: "";
  --fa--fa: "";
}

.fa-slash-forward {
  --fa: "/";
  --fa--fa: "//";
}

.fa-location-pen, .fa-map-marker-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-pot-food {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase {
  --fa: "";
  --fa--fa: "";
}

.fa-person-falling {
  --fa: "";
  --fa--fa: "";
}

.fa-image-portrait, .fa-portrait {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tag {
  --fa: "";
  --fa--fa: "";
}

.fa-rug {
  --fa: "";
  --fa--fa: "";
}

.fa-print-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-europe, .fa-globe-europe {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-suitcase, .fa-luggage-cart {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-xmark, .fa-rectangle-times, .fa-times-rectangle, .fa-window-close {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-rugged {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-cowbell {
  --fa: "";
  --fa--fa: "";
}

.fa-baht-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-corner {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-right, .fa-chevron-double-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open {
  --fa: "";
  --fa--fa: "";
}

.fa-book-journal-whills, .fa-journal-whills {
  --fa: "";
  --fa--fa: "";
}

.fa-inhaler {
  --fa: "";
  --fa--fa: "";
}

.fa-handcuffs {
  --fa: "";
  --fa--fa: "";
}

.fa-snake {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-exclamation, .fa-exclamation-triangle, .fa-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-note-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-database {
  --fa: "";
  --fa--fa: "";
}

.fa-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-share, .fa-mail-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-thinking {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-face {
  --fa: "";
  --fa--fa: "";
}

.fa-hill-rockslide {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-o {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-message-dollar, .fa-comment-alt-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-right-left, .fa-exchange-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-columns-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-paper-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-dungeon {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-box {
  --fa: "";
  --fa--fa: "";
}

.fa-input-text {
  --fa: "";
  --fa--fa: "";
}

.fa-window-flip, .fa-window-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-align-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-gun, .fa-scanner {
  --fa: "";
  --fa--fa: "";
}

.fa-tire {
  --fa: "";
  --fa--fa: "";
}

.fa-engine {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-1-wave, .fa-money-bill-wave-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-life-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-hands, .fa-sign-language, .fa-signing {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-right, .fa-caret-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-file-spreadsheet {
  --fa: "";
  --fa--fa: "";
}

.fa-audio-description-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-day {
  --fa: "";
  --fa--fa: "";
}

.fa-water-ladder, .fa-ladder-water, .fa-swimming-pool {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-down, .fa-arrows-v {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-pawn-piece, .fa-chess-pawn-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grimace, .fa-grimace {
  --fa: "";
  --fa--fa: "";
}

.fa-wheelchair-move, .fa-wheelchair-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down, .fa-level-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-s {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-barcode, .fa-barcode-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-envelope, .fa-envelope-square {
  --fa: "";
  --fa--fa: "";
}

.fa-dice {
  --fa: "";
  --fa--fa: "";
}

.fa-unicorn {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-pompebled {
  --fa: "";
  --fa--fa: "";
}

.fa-brain {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-smart {
  --fa: "";
  --fa--fa: "";
}

.fa-book-user {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-cloud, .fa-sensor-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-clapperboard-play {
  --fa: "";
  --fa--fa: "";
}

.fa-bandage, .fa-band-aid {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-xmark, .fa-times-circle, .fa-xmark-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-gifts {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection {
  --fa: "";
  --fa--fa: "";
}

.fa-hotel {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-asia, .fa-globe-asia {
  --fa: "";
  --fa--fa: "";
}

.fa-id-card-clip, .fa-id-card-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-plus, .fa-search-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbs-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers {
  --fa: "";
  --fa--fa: "";
}

.fa-user-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-onion {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-twelve-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-dots, .fa-allergies {
  --fa: "";
  --fa--fa: "";
}

.fa-file-invoice {
  --fa: "";
  --fa--fa: "";
}

.fa-window-minimize {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-garlic {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-saucer, .fa-coffee {
  --fa: "";
  --fa--fa: "";
}

.fa-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-file-half-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-decorated {
  --fa: "";
  --fa--fa: "";
}

.fa-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-minus, .fa-search-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-flower {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-right-left-large {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-circles-overlap {
  --fa: "";
  --fa--fa: "";
}

.fa-user-large, .fa-user-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-starship-freighter {
  --fa: "";
  --fa--fa: "";
}

.fa-train-tram {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-suspension {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-check {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse {
  --fa: "";
  --fa--fa: "";
}

.fa-boombox {
  --fa: "";
  --fa--fa: "";
}

.fa-syringe {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-stopwatch-20 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-full {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-swirl {
  --fa: "";
  --fa--fa: "";
}

.fa-falafel {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-magnet {
  --fa: "";
  --fa--fa: "";
}

.fa-jar {
  --fa: "";
  --fa--fa: "";
}

.fa-gramophone {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d12 {
  --fa: "";
  --fa--fa: "";
}

.fa-note-sticky, .fa-sticky-note {
  --fa: "";
  --fa--fa: "";
}

.fa-down, .fa-arrow-alt-down {
  --fa: "";
  --fa--fa: "";
}

.fa-hundred-points, .fa-100 {
  --fa: "";
  --fa--fa: "";
}

.fa-paperclip-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-wind-warning, .fa-wind-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin-slash, .fa-map-marker-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-bug-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cupcake {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch-off {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-large-off {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-fancy-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-container {
  --fa: "";
  --fa--fa: "";
}

.fa-boot {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-water-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-file-check {
  --fa: "";
  --fa--fa: "";
}

.fa-bone {
  --fa: "";
  --fa--fa: "";
}

.fa-cards-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-bench-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-brightness-low {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-boot-ski {
  --fa: "";
  --fa--fa: "";
}

.fa-brain-circuit {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-row-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-user-injured {
  --fa: "";
  --fa--fa: "";
}

.fa-block-brick-fire, .fa-firewall {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-tear, .fa-sad-tear {
  --fa: "";
  --fa--fa: "";
}

.fa-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrows-down {
  --fa: "";
  --fa--fa: "";
}

.fa-exclamation {
  --fa: "!";
  --fa--fa: "!!";
}

.fa-arrows-spin {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-relaxed {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-xmark, .fa-comment-times {
  --fa: "";
  --fa--fa: "";
}

.fa-print {
  --fa: "";
  --fa--fa: "";
}

.fa-turkish-lira-sign, .fa-try, .fa-turkish-lira {
  --fa: "";
  --fa--fa: "";
}

.fa-face-nose-steam {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-waveform-lines, .fa-waveform-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-dollar-sign, .fa-dollar, .fa-usd {
  --fa: "$";
  --fa--fa: "$$";
}

.fa-ferris-wheel {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-speaker {
  --fa: "";
  --fa--fa: "";
}

.fa-skull-cow {
  --fa: "";
  --fa--fa: "";
}

.fa-x {
  --fa: "X";
  --fa--fa: "XX";
}

.fa-magnifying-glass-dollar, .fa-search-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-users-gear, .fa-users-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-pointing {
  --fa: "";
  --fa--fa: "";
}

.fa-building-columns, .fa-bank, .fa-institution, .fa-museum, .fa-university {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-t {
  --fa: "";
  --fa--fa: "";
}

.fa-sack {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-cctv, .fa-cctv {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella {
  --fa: "";
  --fa--fa: "";
}

.fa-trowel {
  --fa: "";
  --fa--fa: "";
}

.fa-horizontal-rule {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-front, .fa-bed-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-d {
  --fa: "D";
  --fa--fa: "DD";
}

.fa-stapler {
  --fa: "";
  --fa--fa: "";
}

.fa-masks-theater, .fa-theater-masks {
  --fa: "";
  --fa--fa: "";
}

.fa-file-gif {
  --fa: "";
  --fa--fa: "";
}

.fa-kip-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-woozy {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-question {
  --fa: "";
  --fa--fa: "";
}

.fa-pineapple {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-left {
  --fa: "";
  --fa--fa: "";
}

.fa-gallery-thumbnails {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-j {
  --fa: "";
  --fa--fa: "";
}

.fa-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-simple, .fa-handshake-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-page-caret-up, .fa-file-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-jet-fighter, .fa-fighter-jet {
  --fa: "";
  --fa--fa: "";
}

.fa-comet {
  --fa: "";
  --fa--fa: "";
}

.fa-square-share-nodes, .fa-share-alt-square {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-keyhole {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mp4 {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode {
  --fa: "";
  --fa--fa: "";
}

.fa-bulldozer {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-sliders-vertical, .fa-sliders-v-square {
  --fa: "";
  --fa--fa: "";
}

.fa-video, .fa-video-camera {
  --fa: "";
  --fa--fa: "";
}

.fa-message-middle, .fa-comment-middle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-graduation-cap, .fa-mortar-board {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-square-z {
  --fa: "";
  --fa--fa: "";
}

.fa-message-text, .fa-comment-alt-text {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-up, .fa-level-up-alt {
  --fa: "";
  --fa--fa: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.f0ab824e.woff2") format("woff2"), url("fa-regular-400.b471cbc7.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.58622068.woff2") format("woff2"), url("fa-solid-900.34487dce.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.7f75bed2.woff2") format("woff2"), url("fa-light-300.f9a464a4.ttf") format("truetype");
}

.fal, .fa-light {
  font-weight: 300;
}

.fc a, .fc a:hover {
  color: #0d171a;
}

.fc .fc-event-main .fc-event-time + .fc-event-title-container {
  max-width: calc(100% - 50px);
}

.fc .fc-event-main .fc-event-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.fc .fc-event-main .fc-event-descr {
  font-weight: normal;
}

.fc .fc-h-event .fc-event-main, .fc .fc-v-event .fc-event-main {
  --fc-event-text-color: #0d171a;
  color: var(--fc-event-text-color);
}

.fc .fc-day-today {
  background-color: var(--fc-today-bg-color);
}

.fc .fc-scrollgrid-section-body table {
  border-bottom-style: inherit;
}

.fc-event {
  --fc-event-color: #0d171a;
  --fc-event-bg-color: #fff;
  --fc-event-border-color: #fff;
  --fc-small-font-size: 1rem;
  font-size: var(--fc-small-font-size);
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
  border-left-style: inset;
  border-left-width: 3px;
  padding-left: 3px;
}

.calendar-navigator {
  cursor: pointer;
}

.calendar-navigator .fc-multimonth-header-table, .calendar-navigator .fc-multimonth-daygrid-table {
  margin-left: 30px;
}

.calendar-navigator .fc-daygrid-week-number {
  left: -30px;
}

.view-small .fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  display: none;
}

.event-red {
  --fc-event-color: #000;
  --fc-event-bg-color: #ffd5cc;
  --fc-event-border-color: #ffd5cc;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #ecc5bd;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-red.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #ecc5bd;
  --fc-border-color: #ccaaa3;
  border-color: var(--fc-border-color);
}

.event-orange {
  --fc-event-color: #000;
  --fc-event-bg-color: #ffe4cc;
  --fc-event-border-color: #ffe4cc;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #ecd3bd;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-orange.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #ecd3bd;
  --fc-border-color: #ccb6a3;
  border-color: var(--fc-border-color);
}

.event-yellow {
  --fc-event-color: #000;
  --fc-event-bg-color: #ffc;
  --fc-event-border-color: #ffc;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #ececbd;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-yellow.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #ececbd;
  --fc-border-color: #cccca3;
  border-color: var(--fc-border-color);
}

.event-green {
  --fc-event-color: #000;
  --fc-event-bg-color: #ccfacc;
  --fc-event-border-color: #ccfacc;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #bde7bd;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-green.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #bde7bd;
  --fc-border-color: #a3c8a3;
  border-color: var(--fc-border-color);
}

.event-teal {
  --fc-event-color: #000;
  --fc-event-bg-color: #ccebe5;
  --fc-event-border-color: #ccebe5;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #bdd9d4;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-teal.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #bdd9d4;
  --fc-border-color: #a3bcb8;
  border-color: var(--fc-border-color);
}

.event-cyan {
  --fc-event-color: #000;
  --fc-event-bg-color: #ccfafa;
  --fc-event-border-color: #ccfafa;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #bde7e7;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-cyan.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #bde7e7;
  --fc-border-color: #a3c8c8;
  border-color: var(--fc-border-color);
}

.event-blue {
  --fc-event-color: #000;
  --fc-event-bg-color: #cce6ff;
  --fc-event-border-color: #cce6ff;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #bdd4ec;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-blue.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #bdd4ec;
  --fc-border-color: #a3b8cc;
  border-color: var(--fc-border-color);
}

.event-indigo {
  --fc-event-color: #000;
  --fc-event-bg-color: #dcf;
  --fc-event-border-color: #dcf;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #ccbdec;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-indigo.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #ccbdec;
  --fc-border-color: #b1a3cc;
  border-color: var(--fc-border-color);
}

.event-purple {
  --fc-event-color: #000;
  --fc-event-bg-color: #e7ccf5;
  --fc-event-border-color: #e7ccf5;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #d6bde2;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-purple.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #d6bde2;
  --fc-border-color: #b9a3c4;
  border-color: var(--fc-border-color);
}

.event-pink {
  --fc-event-color: #000;
  --fc-event-bg-color: #fcf;
  --fc-event-border-color: #fcf;
  --fc-event-hover-color: #000;
  --fc-event-hover-bg: #ecbdec;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-color);
  border-color: var(--fc-event-border-color);
}

.event-pink.fc-list-event:hover td {
  --fc-list-event-hover-bg-color: #ecbdec;
  --fc-border-color: #cca3cc;
  border-color: var(--fc-border-color);
}

.event-red .fc-list-event-dot {
  --fc-event-border-color: #ff2b00;
  border-color: var(--fc-event-border-color);
}

.event-orange .fc-list-event-dot {
  --fc-event-border-color: #ff7800;
  border-color: var(--fc-event-border-color);
}

.event-yellow .fc-list-event-dot {
  --fc-event-border-color: #ff0;
  border-color: var(--fc-event-border-color);
}

.event-green .fc-list-event-dot {
  --fc-event-border-color: #00e500;
  border-color: var(--fc-event-border-color);
}

.event-teal .fc-list-event-dot {
  --fc-event-border-color: #00997f;
  border-color: var(--fc-event-border-color);
}

.event-cyan .fc-list-event-dot {
  --fc-event-border-color: #00e6e6;
  border-color: var(--fc-event-border-color);
}

.event-blue .fc-list-event-dot {
  --fc-event-border-color: #0080ff;
  border-color: var(--fc-event-border-color);
}

.event-indigo .fc-list-event-dot {
  --fc-event-border-color: #50f;
  border-color: var(--fc-event-border-color);
}

.event-purple .fc-list-event-dot {
  --fc-event-border-color: #80c;
  border-color: var(--fc-event-border-color);
}

.event-pink .fc-list-event-dot {
  --fc-event-border-color: #f0f;
  border-color: var(--fc-event-border-color);
}

#guideBox {
  color: #fff;
  box-sizing: border-box;
  z-index: 1000;
  text-align: justify;
  background-color: #2980b9;
  border-radius: 2px;
  max-width: 500px;
  padding: 15px 25px 25px;
  line-height: 1.5;
  position: absolute;
  box-shadow: 0 20px 24px #0000001a, 0 12px 12px #0000000a, 0 4px 24px #0000000a;
}

#guideBox .pointer {
  border-bottom: 12px solid #2980b9;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  position: absolute;
  top: -12px;
  left: calc(50% - 6px);
}

#guideBox .title {
  text-align: center;
  padding-bottom: 10px;
  text-decoration: underline;
}

#guideBox .close {
  float: left;
  margin-top: 25px;
}

#guideBox .prev, #guideBox .next {
  float: right;
  background-color: #fff;
  height: auto;
  margin-top: 25px;
  padding: 5px 15px;
}

#guideBox .btn {
  background-color: #fff;
  height: auto;
  padding: 5px 15px;
}

.iconNumber {
  color: #fff;
  text-align: center;
  background-color: #e3041c;
  border-radius: 50%;
  height: 16px;
  margin-left: -16px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 16px;
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
}

#guideBtn .iconNumber {
  display: inline-block;
}

.marked {
  border: 2px solid red !important;
}

.confirm .ui-icon {
  float: left;
}

.confirm .text {
  margin-left: 20px;
}

#contextMenu {
  z-index: 100000;
  color: #333;
  background: #fff;
  max-width: 370px;
  position: absolute;
  box-shadow: 1px 1px 10px #888;
}

#contextMenu div {
  cursor: pointer;
  padding: 3px 15px;
}

#contextMenu div:not(.contextSub):hover, #contextMenu div.active {
  color: #16181b;
  background-color: #eee;
}

#contextMenu div div {
  display: none;
}

.settings * {
  float: left;
}

.settings .label {
  border: none;
  width: 150px;
  margin-bottom: 3px;
}

.settings .image {
  cursor: pointer;
  height: 31px;
  margin-top: 5px;
  margin-bottom: -5px;
  list-style-type: disc;
  display: list-item;
}

.settings .image.open {
  list-style-type: circle;
}

.settings .break {
  clear: left;
}

.settings .levelblock {
  width: 100%;
  margin-left: 20px;
  display: none;
}

.settings .array {
  border: 1px dotted #000;
}

.settings .value {
  width: calc(100% - 340px);
}

.iconBox {
  text-align: center;
  vertical-align: top;
  border: 1px solid gray;
  width: 70px;
  height: 50px;
  padding: 5px;
  display: inline-block;
  overflow: hidden;
}

.iconBox span {
  display: block;
}
/*# sourceMappingURL=index.d4ea6da3.css.map */
